import { Box, Grid, Skeleton } from '@mui/material'
import { MouseEventHandler } from 'react'
import { useIntl } from 'react-intl'
import { Category } from './categories.constants'
import { useSnackbar } from 'notistack'
import { animated, useSpring, config } from 'react-spring'

interface Props {
  category: Category
  index: number
  onClick: MouseEventHandler
  disabled: boolean
  fetching: boolean
}

export const CategoryTile = ({ category, index, onClick, disabled, fetching }: Props) => {
  const { locale, formatMessage } = useIntl()
  const { enqueueSnackbar } = useSnackbar()

  const handleOnClick = (event): void => {
    if (disabled) {
      enqueueSnackbar(formatMessage({ id: 'categories.categoryDisabled' }), {
        variant: 'warning',
      })
    } else {
      onClick(event)
    }
  }

  const style = useSpring({
    delay: index * 40,
    from: { opacity: 0, transform: 'translateY(60px)' },
    to: { opacity: 1, transform: 'translateY(0)' },
    config: config.gentle,
  })

  return (
    <Grid item xs={6} sm={4}>
      <animated.div style={style}>
        {fetching && <Skeleton variant="rectangular" height="230px" width="230px" />}
        {!fetching && (
          <Box
            sx={{
              aspectRatio: '1',
              fontWeight: 'bold',
              borderRadius: '5px',
              backgroundColor: category.subTheme.palette.primary.main,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              position: 'relative',
              cursor: `${disabled ? '' : 'pointer'}`,
            }}
            onClick={(event) => handleOnClick(event)}
          >
            {disabled && (
              <Box
                sx={{
                  backgroundColor: 'rgba(0,0,0,0.5)',
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                }}
              />
            )}
            <Box display="flex" justifyContent="center" alignItems="center" height="43.5%" mt="18.5%" mb="4%">
              <img src={`/static/media/${category.icon}.svg`} width="70%" />
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              textAlign="center"
              px="11.5%"
              fontSize="1.3vw"
            >
              {category.name[locale]}
            </Box>
          </Box>
        )}
      </animated.div>
    </Grid>
  )
}
