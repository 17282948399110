/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import { GoalType, GoalTypeFromJSON, GoalTypeFromJSONTyped, GoalTypeToJSON } from './GoalType'
import { Sort, SortFromJSON, SortFromJSONTyped, SortToJSON } from './Sort'

/**
 *
 * @export
 * @interface PlaythroughFilter
 */
export interface PlaythroughFilter {
  /**
   *
   * @type {Array<string>}
   * @memberof PlaythroughFilter
   */
  levelIds?: Array<string>
  /**
   *
   * @type {Array<string>}
   * @memberof PlaythroughFilter
   */
  patientIds: Array<string>
  /**
   *
   * @type {Array<string>}
   * @memberof PlaythroughFilter
   */
  planIds?: Array<string>
  /**
   *
   * @type {GoalType}
   * @memberof PlaythroughFilter
   */
  goalType?: GoalType
  /**
   *
   * @type {Array<string>}
   * @memberof PlaythroughFilter
   */
  fields?: Array<string>
  /**
   *
   * @type {Sort}
   * @memberof PlaythroughFilter
   */
  sort?: Sort
}

export function PlaythroughFilterFromJSON(json: any): PlaythroughFilter {
  return PlaythroughFilterFromJSONTyped(json, false)
}

export function PlaythroughFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlaythroughFilter {
  if (json === undefined || json === null) {
    return json
  }
  return {
    levelIds: !exists(json, 'levelIds') ? undefined : json['levelIds'],
    patientIds: json['patientIds'],
    planIds: !exists(json, 'planIds') ? undefined : json['planIds'],
    goalType: !exists(json, 'goalType') ? undefined : GoalTypeFromJSON(json['goalType']),
    fields: !exists(json, 'fields') ? undefined : json['fields'],
    sort: !exists(json, 'sort') ? undefined : SortFromJSON(json['sort']),
  }
}

export function PlaythroughFilterToJSON(value?: PlaythroughFilter | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    levelIds: value.levelIds,
    patientIds: value.patientIds,
    planIds: value.planIds,
    goalType: GoalTypeToJSON(value.goalType),
    fields: value.fields,
    sort: SortToJSON(value.sort),
  }
}
