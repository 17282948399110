import { Box, Typography } from '@mui/material'
import { useBoolean, useCounter, useHarmonicIntervalFn } from 'react-use'
import { captureMessage } from '@sentry/react'
import { useEffect } from 'react'
import { useCubes2 } from './bluetooth/useCubes'
import { LevelDetailFragment } from './get-level.generated'
import { useNavigate, useParams } from 'react-router-dom'
import { useRequiredCubesIds } from './prepare-game/prepare-game.screen'
import { useSpring, animated } from 'react-spring'

interface ReadySteadyGoProps {
  onGo()
  level: LevelDetailFragment
}

export const ReadySteadyGo = ({ onGo, level }: ReadySteadyGoProps) => {
  const { id, categoryCode } = useParams<{ id: string; categoryCode: string }>()
  const { cubes } = useCubes2()
  const push = useNavigate()
  const [isHidden, toggleHidden] = useBoolean(false)
  const [value, { dec }] = useCounter(3)
  const [requiredCubesIds] = useRequiredCubesIds()
  const cubesForCurrentGame = Object.entries(cubes)
    .filter(([cubeId]) => requiredCubesIds.includes(cubeId))
    .map(([, cube]) => cube)

  const handleReady = () => {
    if (value === 1) {
      const areCubesReady =
        Object.entries(cubes).length > 0 &&
        cubesForCurrentGame.find((cube) => cube.isConnected === false) === undefined
      if (!areCubesReady) {
        push(`/category/${categoryCode}/prepare-game/${id}`)
      }
      onGo()
      toggleHidden()
    }
  }

  useEffect(() => {
    if (value === -1) {
      captureMessage('Level ready-steady-go countdown reached -1', (context) => {
        context.setExtras({
          level,
          cubes,
        })
        context.setLevel('fatal')
        return context
      })
    }
  }, [value])

  const [springs, api] = useSpring(() => ({
    config: { duration: 1000 },
    from: { opacity: 0.2, transform: 'scale(3.5)' },
    to: { opacity: 1, transform: 'scale(1)' },
  }))

  useHarmonicIntervalFn(
    () => {
      api.start({
        config: { duration: 1000 },
        from: { opacity: 0.2, transform: 'scale(3.5)' },
        to: { opacity: 1, transform: 'scale(1)' },
      })
      dec()
      handleReady()
    },
    !isHidden ? 1000 : null,
  )

  if (isHidden) {
    return null
  }

  return (
    <Box
      sx={{
        display: 'flex',
        overflow: 'hidden',
        width: '100vw',
        height: 'calc(100vh - 74px)',
        position: 'absolute',
        top: '74px',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'white',
        zIndex: 20,
      }}
    >
      <Typography sx={{ fontSize: '250px' }}>
        <animated.div style={springs}>{value}</animated.div>
      </Typography>
    </Box>
  )
}
