import { Dialog, Typography } from '@mui/material'
import { FC } from 'react'
import { Flex } from '@proxyqb/ui'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import { useIntl } from 'react-intl'

interface Props {
  isOnline: boolean
}

const NoInternetDialog: FC<Props> = ({ isOnline }) => {
  const { formatMessage } = useIntl()

  return (
    <Dialog open={!isOnline}>
      <Flex alignItems={'center'} sx={{ p: 2 }}>
        <WarningAmberIcon sx={{ fontSize: '60px', marginRight: '20px' }} />
        <Typography variant="h5" sx={{ width: '400px' }}>
          {formatMessage({ id: 'home.noConnection' })}
        </Typography>
      </Flex>
    </Dialog>
  )
}

export default NoInternetDialog
