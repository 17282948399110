import { LanguageContext } from '@proxyqb/ui'
import { useContext } from 'react'

export const useVoice = () => {
  const { userLanguage } = useContext(LanguageContext)

  return {
    voice: (message) => {
      const utter = new SpeechSynthesisUtterance()
      utter.lang = userLanguage
      utter.text = message
      utter.volume = 1
      window.speechSynthesis.speak(utter)
    },
    cancelVoice: () => {
      window.speechSynthesis.cancel()
    },
  }
}
