import SaveIcon from '@mui/icons-material/Save'
import { LoadingButton } from '@mui/lab'
import { Autocomplete, Button, Grid, TextField } from '@mui/material'
import { Flex } from '@proxyqb/ui'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { PatientType, Sex, Theme } from '@proxyqb/graphql-api-types'
import { Screen } from '../shared'
import {
  useAddPatientToRehabilitationPlansMutation,
  useCreatePatientMutation,
  useQueryRehabilitationPlansQuery,
} from './create-patient-form.generated'
import { useGlobalState } from '@proxyqb/cube-global-state'
import { Form, Input, Select } from '@proxyqb/ui-forms'
import { yupResolver } from '@hookform/resolvers/yup'
import { CreatePatientFields, createPatientSchema } from './create-patient-schema'
import { getYear } from 'date-fns'

const CreatePatientScreen = () => {
  const { enqueueSnackbar } = useSnackbar()
  const { formatMessage } = useIntl()
  const { authService } = useGlobalState()
  const [{ data: rehabilitationPlans, fetching: fetchingRehabilitationPlans }] =
    useQueryRehabilitationPlansQuery()
  const [{ fetching: creatingPatient }, createPatient] = useCreatePatientMutation()
  const [{ fetching: addingPlan }, addPatientToRehabilitationPlans] =
    useAddPatientToRehabilitationPlansMutation()
  const [planIds, setPlanIds] = useState<string[]>([])

  const form = useForm<CreatePatientFields>({
    defaultValues: {
      firstName: '',
      lastName: '',
    },
    resolver: yupResolver(createPatientSchema),
  })
  const navigate = useNavigate()

  const handleSubmit = async (fields: CreatePatientFields) => {
    try {
      const result = await createPatient({
        input: {
          ...fields,
          phoneNumber: fields.phoneNumber ? fields.phoneNumber : undefined,
          email: fields.email ? fields.email : undefined,
          preferences: {
            theme: Theme.Light,
          },
          isDeleted: false,
          dateOfBirth: fields.dateOfBirth
            ? new Date(
                getYear(new Date(fields.dateOfBirth)),
                Number(fields.dateOfBirth.toString().split('-')[1]) - 1,
              )
            : null,
        },
      })
      const patientId = result.data?.createPatient
      if (patientId) {
        authService.send({
          type: 'RELOAD_USER',
        })
        if (planIds.length > 0) {
          await addPatientToRehabilitationPlans({
            patientId,
            rehabilitationPlanIds: planIds,
          })
        }
      }
      enqueueSnackbar(
        formatMessage(
          { id: 'createPatientForm.successMessageCreate' },
          { name: fields.firstName + ' ' + fields.lastName },
        ),
        {
          variant: 'success',
        },
      )
      navigate('/select-patient')
    } catch (err: any) {
      enqueueSnackbar(err.message, { variant: 'error' })
    }
  }

  const back = () => {
    navigate('/select-patient')
  }

  const plans = rehabilitationPlans?.queryRehabilitationPlans ?? []

  return (
    <Screen
      primaryTitle={'playerProfiles.createPatient'}
      backButtonHandler={back}
      backButtonLabel="playerProfiles.patientList"
    >
      <Form name="patientForm" onSubmit={handleSubmit} form={form} schema={createPatientSchema}>
        <Flex justifyContent="center" flexDirection="column" alignItems="center" gap="16px" mt={10}>
          <Grid container item xs={12} sm={6} justifyContent="center" spacing={2}>
            <Grid item xs={6}>
              <Input name="firstName" sx={{ width: '100%' }} />
            </Grid>
            <Grid item xs={6}>
              <Input name="lastName" sx={{ width: '100%' }} />
            </Grid>
            {/* TEMPORARILY COMMENTED OUT DUE TO GENT & GENDER LAWS IN ICELAND  */}
            {/* <Grid item xs={12} sm={6}>
              <Select name="sex">
                <Select.Option key={Sex.Male} value={Sex.Male}>
                  {formatMessage({ id: `createPatientForm.sex.${Sex.Male}` })}
                </Select.Option>
                <Select.Option key={Sex.Female} value={Sex.Female}>
                  {formatMessage({ id: `createPatientForm.sex.${Sex.Female}` })}
                </Select.Option>
              </Select>
            </Grid> */}
            <Grid item xs={12} sm={6}>
              <Input name="dateOfBirth" placeholder="1980-01" sx={{ width: '100%' }} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Input name="phoneNumber" placeholder="+420123456789" sx={{ width: '100%' }} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Input name="email" sx={{ width: '100%' }} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Select name="patientType">
                {Object.values(PatientType).map((value) => (
                  <Select.Option key={value} value={value}>
                    {formatMessage({ id: `createPatientForm.patientType.${value}` })}
                  </Select.Option>
                ))}
              </Select>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                loading={fetchingRehabilitationPlans}
                multiple
                onChange={(event, items) => {
                  const planIds = items.map((item) => item.id)
                  setPlanIds(planIds)
                }}
                disablePortal
                options={plans}
                getOptionLabel={(option) => option.name.local}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={formatMessage({ id: 'shared.breadcrumbs.rehabilitationPlans' })}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Flex>
        <Flex sx={{ mt: '20px' }} justifyContent="center" flexDirection="column" alignItems="center">
          {creatingPatient || addingPlan ? (
            <LoadingButton loading loadingPosition="end" endIcon={<SaveIcon />} variant="contained">
              {formatMessage({ id: 'shared.sending' })}
            </LoadingButton>
          ) : (
            <Button type="submit" variant="contained">
              {formatMessage({ id: 'playerProfiles.createPatient' })}
            </Button>
          )}
        </Flex>
      </Form>
    </Screen>
  )
}

export default CreatePatientScreen
