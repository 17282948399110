import { Button, Modal, Paper } from '@mui/material'
import { ReactElement } from 'react'
import QrReader from 'react-qr-reader'
import { useBoolean } from 'react-use'

interface UserPassword {
  email: string
  password: string
}

interface Props {
  onResult: (result: UserPassword) => void
}

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 0,
}

export const QrLogin = ({ onResult }: Props): ReactElement => {
  const [isOpen, toggleIsOpen] = useBoolean(false)
  return (
    <div>
      <Button onClick={() => toggleIsOpen()}>QR code login</Button>
      <Modal open={isOpen} onClose={() => toggleIsOpen(false)}>
        <Paper sx={style}>
          <QrReader
            onError={(error) => {
              console.error(error)
            }}
            onScan={(result) => {
              if (result) {
                onResult(JSON.parse(result))
                toggleIsOpen(false)
              }
            }}
            delay={500}
          />
        </Paper>
      </Modal>
    </div>
  )
}
