import { Alert, Box, Button, Dialog, Skeleton, Typography } from '@mui/material'
import { CubePatterns } from '@proxyqb/graphql-api-types'
import { Flex, LoadingTemplate, Text } from '@proxyqb/ui'
import { throttle } from 'lodash-es'
import { useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate, useParams } from 'react-router-dom'
import { createGlobalState, useAsync, useCounter, useLocalStorage, useUnmount } from 'react-use'
import { CubeStatus, resolveCubeStatus } from '../../helpers'
import { RehabilitationPlanProgress, Screen } from '../../shared'
import { useRehabilitationPlan } from '../../shared/use-rehabilitation-plan'
import { useCubes2 } from '../bluetooth/useCubes'
import { useGetLevelQuery } from '../get-level.generated'
import { MovementDetector } from '../movement-detection'
import { CubesNotReady } from './CubesNotReady'
import { CubeStatusView, LoadingCube } from './CubeStatusView'
import { useSnackbar } from 'notistack'
import { useGlobalState } from '@proxyqb/cube-global-state'
import { useQueryRehabilitationPlansByPatientIdQuery } from '../../rehabilitation-plans/rehabilitation-plan.generated'
import { useSkipPrepareGame } from '../../shared/use-skip-prepare-game'

export const useRequiredCubesIds = createGlobalState<string[]>([])

const PrepareGameScreen = ({ isModal }) => {
  const { id, categoryCode } = useParams<{ id: string; categoryCode: string }>()
  const [rehabPlanProgress, setRehabPlanProgress] = useLocalStorage('rehabPlanProgress', {})
  const isSyncingRef = useRef<boolean>(false)
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const { selectedPatient } = useGlobalState()
  const [isLoading, setIsLoading] = useState(true)
  const [requiredPatterns, setRequiredPatterns] = useState<CubePatterns[]>([])
  const [{ data, fetching }] = useGetLevelQuery({
    variables: {
      id: id!,
    },
  })

  const [{ data: rehabilitationPlanData, fetching: rehabilitationPlanFetching }] =
    useQueryRehabilitationPlansByPatientIdQuery({
      variables: {
        patientId: selectedPatient?.id ?? '',
      },
    })

  const level = data?.getLevel

  const stopSyncing = throttle(
    () => {
      if (isSyncingRef.current) {
        setIsSyncing(false)
        enqueueSnackbar(formatMessage({ id: 'prepareGame.someNotConnected' }), {
          variant: 'warning',
        })
      }
    },
    3000,
    { leading: false },
  )
  useEffect(() => {
    setTimeout(() => {
      // forced "loading" for more smoothness 	ヽ༼ຈل͜ຈ༽ﾉ
      setIsLoading(false)
    }, 2500)
  }, [])

  const { rehabilitationPlanId, rehabPlanFetching, rehabPlanLevels, fetchedPlanId } = useRehabilitationPlan()
  const {
    startPairing,
    cubes,
    loadAllBatteriesState,
    getIdsByPatterns,
    syncAllCubes,
    setCubeSync,
    readName,
    registerReconnect,
    calibrateNotCalibrated,
    quaternions,
    cubeVersion,
  } = useCubes2(null, stopSyncing)
  const [requiredCubesIds, setRequiredCubesIds] = useRequiredCubesIds()
  const cubesForCurrentGame = Object.entries(cubes)
    .filter(([cubeId]) => requiredCubesIds.includes(cubeId))
    .map(([, cube]) => cube)
  const { mapRequiredCubes } = useSkipPrepareGame()
  const [debugCounter, { inc: incDebugCounter }] = useCounter()
  const { formatMessage } = useIntl()

  const [debugMode, setDebugMode] = useLocalStorage('debug-mode-v2', false)
  useEffect(() => {
    if (debugCounter === 0) {
      return
    }
    const isEnabled = debugCounter % 10 === 0
    if (isEnabled !== debugMode) {
      setDebugMode(isEnabled)
    }
  }, [debugCounter])
  const [isSyncing, setIsSyncing] = useState<boolean>(false)

  useEffect(() => {
    isSyncingRef.current = isSyncing
  }, [isSyncing])

  const registerReconnectState = useAsync(registerReconnect, [])

  useEffect(() => {
    if (level && !rehabPlanFetching) {
      const patterns = mapRequiredCubes(level.requiredCubes, rehabPlanLevels)
      setRequiredPatterns(patterns)
    }
  }, [level, cubes, rehabPlanFetching, rehabPlanLevels])

  useAsync(async () => {
    const requiredIds = await getIdsByPatterns(requiredPatterns)
    setRequiredCubesIds(requiredIds)
  }, [requiredPatterns, cubes])

  const resetLed = () => {
    for (const cube of cubesForCurrentGame) {
      cube.setColor({ r: 0, g: 0, b: 0 })
    }
  }

  useUnmount(() => {
    if (!isModal) {
      resetLed()
    }
  })

  const { current: movementDetector } = useRef(new MovementDetector())

  const goToGame = ({ replace }: { replace?: boolean } = {}) => {
    navigate(
      `/category/${categoryCode}/level-description/${id}${
        rehabilitationPlanId ? `?rehabilitationPlanId=${rehabilitationPlanId}` : ''
      }`,
      { replace: replace },
    )
  }

  const color = () => {
    for (const cube of cubesForCurrentGame) {
      cube.setColor({ r: 10, g: 0, b: 0 })
    }
  }

  const fullScreen = () => {
    const elem = document.documentElement
    if (elem.requestFullscreen) {
      elem.requestFullscreen()
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      }
    }
  }
  const deleteProgressFromLS = () => {
    setRehabPlanProgress({})
  }
  const debugMenu = (
    <div>
      <button onClick={startPairing}>startPairing</button>
      <button onClick={loadAllBatteriesState}>loadAllBatteriesState</button>
      <button onClick={color}>color</button>
      <button onClick={() => readName(Object.keys(cubes)[0])}>read name</button>
      {/*TODO: fix sync for more cubes*/}
      <button onClick={() => syncAllCubes(true)}>Sync all</button>
      <button onClick={goToGame}>Go to the game</button>
      <button onClick={fullScreen}>Fullscreen</button>
      <button onClick={deleteProgressFromLS}>Delete Progress</button>
    </div>
  )

  const debugTable = (
    <div>
      Connected devices:
      <table>
        <thead>
          <tr>
            <th>id</th>
            <th>name</th>
            <th>isConnected</th>
            <th>isCalibrated</th>
            <th>isSyncedWithDevice</th>
            <th>batteryState</th>
            <th>pattern</th>
            <th>fw</th>
          </tr>
        </thead>
        <tbody>
          {Object.values(cubes).map(
            ({
              id: cubeId,
              isConnected,
              isCalibrated,
              isSyncedWithDevice,
              batteryState,
              name,
              pattern,
              fw,
            }) => (
              <tr key={cubeId}>
                <td>{cubeId}</td>
                <td>{name}</td>
                <td>{JSON.stringify(isConnected)}</td>
                <td>{JSON.stringify(isCalibrated)}</td>
                <td>{JSON.stringify(isSyncedWithDevice)}</td>
                <td>{`${batteryState} %`}</td>
                <td>{pattern}</td>
                <td>{fw}</td>
              </tr>
            ),
          )}
        </tbody>
      </table>
    </div>
  )

  const back = () => {
    categoryCode === 'default'
      ? navigate('/rehabilitation-plan-list')
      : navigate(`/category/${categoryCode}/level-list/`)
  }

  const cubeStatuses = cubesForCurrentGame.map(resolveCubeStatus)
  const someCubesNotConnected =
    cubesForCurrentGame.length < requiredCubesIds.length ||
    cubeStatuses.some((status) => status === CubeStatus.NOT_CONNECTED)
  const allCubesReady =
    !!cubesForCurrentGame.length &&
    !someCubesNotConnected &&
    cubeStatuses.every((status) => status === CubeStatus.READY)
  const status = someCubesNotConnected ? CubeStatus.NOT_CONNECTED : CubeStatus.NOT_SYNCHRONIZED

  if (!level && !fetching) {
    return <div>Not found</div>
  }
  if (registerReconnectState.error) {
    return <div>Error: {registerReconnectState.error.message}</div>
  }

  return (
    <Screen
      primaryTitle={level?.name || <Skeleton width={250} />}
      secondaryTitle={formatMessage({ id: 'prepareGame.prepareCubesTitle' })}
      backButtonHandler={back}
      modal={isModal}
      hideFooter={debugMode}
      levelId={id}
    >
      <Button
        onClick={() => {
          incDebugCounter()
        }}
        style={{
          position: 'fixed',
          right: 0,
          top: 0,
          width: 50,
        }}
      />
      {debugMode && (
        <>
          <Alert variant="outlined" severity="error">
            <Typography variant="h5">Danger zone</Typography>
            {debugMenu}
            {debugTable}
          </Alert>
        </>
      )}
      <Flex justifyContent="space-around" alignItems="center" height={isModal ? '22rem' : '27rem'}>
        {requiredPatterns?.length && !isLoading ? (
          requiredPatterns.map((pattern, index) => (
            <CubeStatusView
              key={index}
              pattern={pattern}
              debugMode={debugMode}
              cube={cubes[requiredCubesIds[index]]}
              totalNumber={requiredPatterns.length}
              inModalWindow={isModal}
              cubeVersion={cubeVersion}
            />
          ))
        ) : (
          <LoadingCube cubeVersion={cubeVersion} />
        )}
      </Flex>
      {rehabilitationPlanId && (
        <Box>
          <RehabilitationPlanProgress
            levelId={level?.id}
            loading={rehabilitationPlanId !== fetchedPlanId || !level?.id}
            isInPrepareGame
          />
        </Box>
      )}
      <Box>
        <CubesNotReady
          status={status}
          loading={fetching || rehabilitationPlanFetching || !requiredCubesIds?.length}
          allCubesReady={allCubesReady}
          actions={{
            connect: startPairing,
            calibrate: calibrateNotCalibrated,
            synchronize: () =>
              syncAllCubes(
                false,
                () => setIsSyncing(true),
                () => setIsSyncing(false),
              ),
            continue: () => goToGame(),
          }}
          inModalWindow={isModal}
          exitFnc={back}
        />
      </Box>
      <Dialog open={isSyncing}>
        <Box
          sx={{
            width: '600px',
            height: '450px',
            padding: '20px',
            textAlign: 'center',
            overflow: 'hidden',
          }}
        >
          <Text variant="h1" t="prepareGame.syncing" />
          <LoadingTemplate />
        </Box>
      </Dialog>
    </Screen>
  )
}

export default PrepareGameScreen
