/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface Level
 */
export interface Level {
  /**
   *
   * @type {string}
   * @memberof Level
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof Level
   */
  id: string
}

export function LevelFromJSON(json: any): Level {
  return LevelFromJSONTyped(json, false)
}

export function LevelFromJSONTyped(json: any, ignoreDiscriminator: boolean): Level {
  if (json === undefined || json === null) {
    return json
  }
  return {
    name: json['name'],
    id: json['id'],
  }
}

export function LevelToJSON(value?: Level | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    name: value.name,
    id: value.id,
  }
}
