import { Box, Typography } from '@mui/material'
import { useIntl } from 'react-intl'

export const DontMoveCubes = () => {
  const intl = useIntl()

  return (
    <Box
      sx={{
        display: 'flex',
        gap: '35px',
        flexDirection: 'column',
        width: '100vw',
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'white',
        position: 'absolute',
        top: '0',
        zIndex: 20,
      }}
    >
      <Typography variant="h1">Stop</Typography>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '354px',
          height: '354px',
          background: '#F8051D',
          borderRadius: '50%',
        }}
      >
        <img src={`/static/media/disqualification.svg`} />
      </Box>
      <Typography variant="h2">{intl.messages['dontMoveCubes.text']}</Typography>
    </Box>
  )
}
