export const env = {
  graphqlUrl: import.meta.env.VITE_GRAPHQL_API_URL,
  firebase: {
    apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
    authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
    projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
    appId: import.meta.env.VITE_FIREBASE_APP_ID,
    enablePerformance: import.meta.env.VITE_FIREBASE_ENABLE_PERFORMANCE === 'true',
    connectAuthEmulator: import.meta.env.VITE_FIREBASE_CONNECT_AUTH_EMULATOR,
  },
  buildTime:
    import.meta.env.VITE_BUILD_TIME === 'local'
      ? new Date()
      : new Date(import.meta.env.VITE_BUILD_TIME as string),
  release: import.meta.env.VITE_RELEASE,
  environment: import.meta.env.VITE_ENVIRONMENT,
  flagsmith: {
    environmentID: import.meta.env.VITE_FLAGSMITH_ENVIRONMENT_ID as string,
  },
  assetDeliveryUrl: import.meta.env.VITE_ASSET_DELIVERY_URL,
} as const
