import {
  getAuth,
  RecaptchaVerifier,
  signInWithEmailAndPassword,
  signInWithPhoneNumber,
  updateCurrentUser,
  User,
} from 'firebase/auth'
import flagsmith from 'flagsmith'
import { gql } from 'urql'
import { assign, createMachine, Sender } from 'xstate'
import { createGraphqlClient } from './graphql-client'
import * as Sentry from '@sentry/browser'

const auth = getAuth()

export type AuthenticationMachineContext = {
  userDetails?: UserDetails
  error?: FirebaseError
}

interface UserDetails {
  id: string
  email?: string | null
  firstName: string
  lastName: string
  roles: string[]
  patients?: {
    nodes: {
      node: {
        id: string
        email?: string | null
        firstName: string
        lastName: string
        roles: string[]
      }
    }[]
  }
}

interface FirebaseError {
  code: string
  customData: any
  name: string
  message: string
  stack: string
}

export type AuthenticationMachineEvent =
  | {
      type: 'REPORT_IS_LOGGED_IN' | 'USER_LOADED'
      userDetails: UserDetails
    }
  | {
      type: 'REPORT_IS_LOGGED_OUT'
    }
  | {
      type: 'LOG_OUT'
    }
  | {
      type: 'LOG_IN'
      credentials: {
        email: string
        password: string
      }
    }
  | { type: 'RELOAD_USER' }
  | {
      type: 'SEND_LOGIN_CODE_TO_PHONE'
      phoneNumber: string
    }
  | {
      type: 'LOGIN_WITH_PHONE_CODE'
      code: string
    }

export type AuthenticationMachineTypestate =
  | {
      value: 'checkingIfLoggedIn'
      context: AuthenticationMachineContext & { userDetails: undefined }
    }
  | {
      value: 'loggedIn'
      context: AuthenticationMachineContext & { userDetails: UserDetails }
    }
  | {
      value: 'loggedOff'
      context: AuthenticationMachineContext & { userDetails: undefined }
    }
  | {
      value: 'reloadingUser'
      context: AuthenticationMachineContext & { userDetails: UserDetails }
    }

function createVerifier(): void {
  // @ts-ignore
  if (!window.recaptchaVerifier) {
    // @ts-ignore
    window.recaptchaVerifier = new RecaptchaVerifier(
      'sign-in-button',
      {
        size: 'invisible',
      },
      getAuth(),
    )
  }
  // @ts-ignore
  window.recaptchaVerifier.render()
}

const fetchUserData = async () =>
  await createGraphqlClient()
    .query(
      gql`
        query me {
          me {
            id
            email
            firstName
            lastName
            roles
            ... on Doctor {
              patients {
                nodes {
                  node {
                    id
                    email
                    firstName
                    lastName
                    roles
                  }
                }
              }
            }
            ... on Admin {
              patients {
                nodes {
                  node {
                    id
                    email
                    firstName
                    lastName
                    roles
                  }
                }
              }
            }
          }
        }
      `,
    )
    .toPromise()

function getCurrentUser(auth): Promise<User> {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      unsubscribe()
      resolve(user)
    }, reject)
  })
}

let tokenRefreshIntervalId: NodeJS.Timer

/**
 * https://xstate-catalogue.com/machines/authentication
 */
export const authenticationMachine = createMachine<
  AuthenticationMachineContext,
  AuthenticationMachineEvent,
  AuthenticationMachineTypestate
>(
  {
    id: 'authentication',
    initial: 'checkingIfLoggedIn',
    states: {
      checkingIfLoggedIn: {
        invoke: {
          src: 'checkIfLoggedIn',
          onError: {
            target: 'loggedOut',
          },
        },
        on: {
          REPORT_IS_LOGGED_IN: {
            target: 'loggedIn',
            actions: 'assignUserDetailsToContext',
          },
          REPORT_IS_LOGGED_OUT: 'loggedOut',
        },
      },
      loggedIn: {
        on: {
          LOG_OUT: {
            target: 'loggedOut',
            actions: [
              'clearUserDetailsFromContext',
              'cleanFirebase',
              'cleanFlagsmith',
              'clearErrorsFromContext',
            ],
          },
          RELOAD_USER: {
            target: 'reloadingUser',
          },
        },
      },
      reloadingUser: {
        invoke: {
          id: 'reloadUser',
          src: 'reloadUser',
          onDone: {
            target: 'loggedIn',
            actions: 'assignUserDetailsToContext',
          },
        },
        on: {
          USER_LOADED: {
            actions: 'assignUserDetailsToContext',
          },
        },
      },
      loginIn: {
        invoke: {
          id: 'logIn',
          src: 'logIn',
          onError: {
            target: 'loggedOut',
            actions: assign({ error: (context, event) => event.data }),
          },
          onDone: {
            target: 'checkingIfLoggedIn',
          },
        },
      },
      sendLoginCodeToPhone: {
        invoke: {
          id: 'sendLoginCodeToPhone',
          src: 'sendLoginCodeToPhone',
          onError: {
            target: 'sendLoginCodeToPhone',
            actions: assign({ error: (context, event) => event.data }),
          },
        },
        on: {
          LOGIN_WITH_PHONE_CODE: {
            target: 'loginWithPhoneCode',
          },
          LOG_IN: {
            target: 'loginIn',
          },
          LOG_OUT: {
            target: 'loggedOut',
            actions: ['cleanFirebase'],
          },
        },
      },
      loginWithPhoneCode: {
        invoke: {
          id: 'loginWithPhoneCode',
          src: 'loginWithPhoneCode',
          onError: {
            target: 'sendLoginCodeToPhone',
            actions: assign({ error: (context, event) => event.data }),
          },
          onDone: {
            target: 'checkingIfLoggedIn',
          },
        },
      },
      loggedOut: {
        on: {
          LOG_IN: {
            target: 'loginIn',
          },
          SEND_LOGIN_CODE_TO_PHONE: {
            target: 'sendLoginCodeToPhone',
          },
        },
      },
    },
  },
  {
    services: {
      checkIfLoggedIn: () => async (send: Sender<AuthenticationMachineEvent>) => {
        const currentUser = await getCurrentUser(auth)
        if (currentUser) {
          const result = await fetchUserData()
          const { id, email, firstName, lastName, roles } = result.data.me
          flagsmith.identify(id, { email, firstName, lastName, roles })
          Sentry.setUser({ id, email, username: `${firstName} ${lastName}` })
          send({
            type: 'REPORT_IS_LOGGED_IN',
            userDetails: result.data.me,
          })
        } else {
          send({
            type: 'REPORT_IS_LOGGED_OUT',
          })
        }
      },
      logIn: (_, event: AuthenticationMachineEvent) => async (send: Sender<AuthenticationMachineEvent>) => {
        const {
          // @ts-ignore
          credentials: { email, password },
        } = event
        const currentUser = await signInWithEmailAndPassword(auth, email, password)
        await updateCurrentUser(auth, currentUser.user)
      },
      sendLoginCodeToPhone:
        (_, event: AuthenticationMachineEvent) => async (send: Sender<AuthenticationMachineEvent>) => {
          if (event.type !== 'SEND_LOGIN_CODE_TO_PHONE') {
            return
          }
          createVerifier()
          if (event.phoneNumber) {
            // @ts-ignore
            const appVerifier = window.recaptchaVerifier
            // @ts-ignore
            window.confirmationResult = await signInWithPhoneNumber(auth, event.phoneNumber, appVerifier)
          }
        },
      loginWithPhoneCode:
        (_, event: AuthenticationMachineEvent) => async (send: Sender<AuthenticationMachineEvent>) => {
          if (event.type !== 'LOGIN_WITH_PHONE_CODE') {
            return
          }
          // @ts-ignore
          const confirmationResult = window.confirmationResult
          const result = await confirmationResult.confirm(event.code)
          await updateCurrentUser(auth, result.user)
        },
      reloadUser:
        (_, event: AuthenticationMachineEvent) => async (send: Sender<AuthenticationMachineEvent>) => {
          const result = await fetchUserData()
          send({
            type: 'USER_LOADED',
            userDetails: result.data.me,
          })
        },
    },
    actions: {
      cleanFirebase: () => {
        auth
          .signOut()
          .then(() => {
            console.log('killed')
          })
          .catch(console.error)
      },
      cleanFlagsmith: () => {
        flagsmith.logout().catch(console.error)
        Sentry.setUser(null)
      },
      assignUserDetailsToContext: assign((_, event) => {
        if (event.type === 'REPORT_IS_LOGGED_IN' || event.type === 'USER_LOADED') {
          return {
            userDetails: event.userDetails,
          }
        }
        return {}
      }),
      clearUserDetailsFromContext: assign({
        userDetails: undefined,
      }),
      clearErrorsFromContext: assign({
        error: undefined,
      }),
    },
  },
)
