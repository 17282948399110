import { CubePatterns } from '@proxyqb/graphql-api-types'
import { useState } from 'react'
import { useInterval } from 'react-use'
import { Quaternion } from 'three'
import { GameCube } from './3d/GameCube'
import { CanvasScene } from './canvas-scene'

const quaternions = [
  new Quaternion(0.3535534, 0.3535534, 0.1464466, 0.8535534),
  new Quaternion(0.1464466, 0.8535534, 0.3535534, 0.3535534),
  new Quaternion(-0.1464466, 0.8535534, 0.3535534, -0.3535534),
  new Quaternion(-0.3535534, 0.3535534, 0.1464466, -0.8535534),
  new Quaternion(0.3535534, -0.1464466, 0.3535534, 0.8535534),
  new Quaternion(0.1464466, -0.3535534, 0.8535534, 0.3535534),
  new Quaternion(-0.1464466, -0.3535534, 0.8535534, -0.3535534),
  new Quaternion(-0.3535534, -0.1464466, 0.3535534, -0.8535534),
]

const DebugCubeScreen = () => {
  const [i, setI] = useState(0)
  useInterval(() => {
    setI((i) => (i + 1) % quaternions.length)
  }, 1000)

  return (
    <CanvasScene width="100vw" height="100vh">
      <GameCube pattern={CubePatterns.ColorfulLetters} quaternion={quaternions[i]} />
    </CanvasScene>
  )
}

export default DebugCubeScreen
