import { Box, Typography } from '@mui/material'
import { Flex } from '@proxyqb/ui'
import { MouseEventHandler, useContext, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Screen } from '../shared'
import { SelectedLevelContext, SelectedLevelValue } from './level.context'
import {
  LevelItemFragment,
  useQueryLevelsByRehabilitationTypeQuery,
  useUserLevelCountInCategoriesQuery,
} from './levels.generated'
import { useIntl } from 'react-intl'
import { LevelItem } from './level-item'

const LevelListScreen = () => {
  const { categoryCode } = useParams<{ categoryCode: string }>()
  const { formatMessage } = useIntl()
  const [{ data, fetching }] = useQueryLevelsByRehabilitationTypeQuery({
    variables: {
      code: categoryCode!,
    },
    requestPolicy: 'cache-and-network',
  })
  const [{ data: userLevelCountData }] = useUserLevelCountInCategoriesQuery({
    requestPolicy: 'cache-and-network',
  })

  const selectedLevelContext = useContext<SelectedLevelValue>(SelectedLevelContext)
  const navigate = useNavigate()
  const selectLevel =
    (level: LevelItemFragment): MouseEventHandler =>
    () => {
      selectedLevelContext.selectLevel(level)
      navigate(`/category/${categoryCode}/prepare-game/${level.id}`)
    }
  const back = () => {
    navigate('/category-list')
  }
  const levels = useMemo<LevelItemFragment[]>(() => {
    if (data) {
      return [...data.queryLevelsByRehabilitationType].sort((a, b) => a.name.localeCompare(b.name))
    }
    return []
  }, [data])

  const items = fetching
    ? new Array(
        userLevelCountData?.userLevelCountInCategories.find((cat) => cat.code === categoryCode!)!.hasLevels,
      ).fill(null)
    : levels
  return (
    <Screen
      primaryTitle="levels.gameLevel"
      backButtonHandler={back}
      backButtonLabel={'categories.gameCategory'}
    >
      <Box mt={3}>
        {!fetching && data?.queryLevelsByRehabilitationType.length === 0 && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography variant="h5">{formatMessage({ id: 'levelList.noLevels' })}</Typography>
          </Box>
        )}
        <Flex height="100%" width="100%" padding="20px 5%" flexDirection="column" gap="20px">
          <Flex gap="20px" flexWrap="wrap" justifyContent="center">
            {items.map((level, index) => (
              <LevelItem
                key={index}
                level={level}
                index={index}
                loading={!data || fetching}
                onClick={selectLevel}
              />
            ))}
          </Flex>
        </Flex>
      </Box>
    </Screen>
  )
}

export default LevelListScreen
