import {
  Box,
  Button,
  Dialog,
  Grid,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import WifiIcon from '@mui/icons-material/Wifi'
import { Flex, LanguageSelect, LoadingTemplate, Text } from '@proxyqb/ui'
import { useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { throttle } from 'lodash-es'
import { useNavigate } from 'react-router'
import { useCubes2 } from '../game/bluetooth/useCubes'
import { Screen } from '../shared'
import { useSnackbar } from 'notistack'
import { MovementDetector } from '../game/movement-detection'
import styled from 'styled-components'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import { useUnmount } from 'react-use'
import { env } from '../env'

const Title = styled.h2`
  margin-top: 25px;
  margin-bottom: 25px;
`

const SettingsScreen = () => {
  const push = useNavigate()
  const back = () => {
    push(-1)
  }
  const isSyncingRef = useRef<boolean>(false)
  const { enqueueSnackbar } = useSnackbar()
  const { formatMessage } = useIntl()
  const { current: movementDetector } = useRef(new MovementDetector())
  const [isSyncing, setIsSyncing] = useState<boolean>(false)

  const stopSyncing = throttle(
    () => {
      if (isSyncingRef.current) {
        enqueueSnackbar(formatMessage({ id: 'prepareGame.someNotConnected' }), {
          variant: 'warning',
        })
      }
    },
    3000,
    { leading: false },
  )

  const { cubes, unpairCubes, startPairing, syncAllCubes } = useCubes2(null, stopSyncing)

  const disconnectCube = async (cubeId: string) => {
    const devices = await navigator.bluetooth.getDevices()
    const deviceToDisconnect = devices.find(({ id }) => id === cubeId)
    try {
      unpairCubes(deviceToDisconnect)
      enqueueSnackbar(formatMessage({ id: 'settings.disconnectSucceeded' }), { variant: 'success' })
    } catch (e) {
      enqueueSnackbar(formatMessage({ id: 'shared.error' }), { variant: 'error' })
    }
  }

  return (
    <Screen primaryTitle={'settings.settings'} backButtonHandler={back} backButtonLabel="settings.back">
      <Box display="flex" alignItems="center" justifyContent="center">
        <Title>{formatMessage({ id: 'settings.selectLanguage' })}</Title>
      </Box>
      <Grid container justifyContent={'center'}>
        <LanguageSelect width={'100%'} />
      </Grid>
      <Box display="flex" alignItems="center" justifyContent="center">
        <Title>{formatMessage({ id: 'settings.connectedCubes' })}</Title>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="end">
        <Button sx={{ margin: '0 20px 20px' }} size="small" variant="contained" onClick={startPairing}>
          {formatMessage({ id: 'settings.connect' })}
        </Button>
        <Button
          sx={{ margin: '0 0px 20px' }}
          size="small"
          variant="contained"
          onClick={() =>
            syncAllCubes(
              false,
              () => setIsSyncing(true),
              () => setIsSyncing(false),
            )
          }
          disabled={false}
        >
          {formatMessage({ id: 'settings.sync' })}
        </Button>
      </Box>
      <TableContainer>
        <Table padding="normal" sx={{ backgroundColor: 'white' }}>
          <TableHead>
            <TableRow>
              <TableCell>{formatMessage({ id: 'settings.cubeName' })}</TableCell>
              <TableCell>{formatMessage({ id: 'settings.isConnected' })}</TableCell>
              <TableCell>{formatMessage({ id: 'settings.isSynced' })}</TableCell>
              <TableCell>{formatMessage({ id: 'settings.batery' })}</TableCell>
              <TableCell>{formatMessage({ id: 'settings.pattern' })}</TableCell>
              <TableCell>{formatMessage({ id: 'settings.disconnect' })}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.values(cubes).map(
              ({ id: cubeId, isConnected, isSyncedWithDevice, batteryState, name, pattern }) => (
                <TableRow key={cubeId}>
                  <TableCell>{name}</TableCell>
                  <TableCell>{isConnected ? <CheckIcon /> : <CloseIcon />}</TableCell>
                  <TableCell>{isSyncedWithDevice ? <CheckIcon /> : <CloseIcon />}</TableCell>
                  <TableCell>{`${batteryState} %`}</TableCell>
                  <TableCell>{formatMessage({ id: `requiredCubes.${pattern}` })}</TableCell>
                  <TableCell>
                    <Button
                      variant={'contained'}
                      disabled={!isConnected}
                      onClick={() => disconnectCube(cubeId)}
                    >
                      {formatMessage({ id: 'settings.disconnect' })}
                    </Button>
                  </TableCell>
                </TableRow>
              ),
            )}
            {Object.values(cubes).length === 0 && (
              <TableRow>
                <TableCell align="center">{formatMessage({ id: 'settings.noCube' })}</TableCell>
                <TableCell />
                <TableCell />
                <TableCell />
                <TableCell />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Link
        mt={2}
        sx={{ textDecoration: 'none' }}
        href="intent://#Intent;scheme=proxyqbtabletsetup;package=com.dev.prorocketeers.proxyqbtabletsetup;end"
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Flex alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
            <Flex alignItems={'center'}>
              <WifiIcon />
              <Typography ml={1}>{formatMessage({ id: 'settings.wifiSettings' })}</Typography>
            </Flex>
            <Typography>Version: {env.release}</Typography>
          </Flex>
        </Box>
      </Link>
      <Dialog open={isSyncing}>
        <Box
          sx={{
            width: '600px',
            height: '450px',
            padding: '20px',
            textAlign: 'center',
            overflow: 'hidden',
          }}
        >
          <Text variant="h1" t="prepareGame.syncing" />
          <LoadingTemplate />
        </Box>
      </Dialog>
    </Screen>
  )
}

export default SettingsScreen
