import { useEffect } from 'react'
import { useAddPlaythroughMutation, useAddPlaythroughsMutation } from './playthrough.generated'
import {
  CubePatterns,
  PlaythroughInput,
  PlaythroughStatsInput,
  QuaternionInput,
  StagePlaythroughInput,
  StageResult,
  PlaythroughCubeInput,
} from '@proxyqb/graphql-api-types'
import { Quaternion } from 'three'
import { sumBy, omit } from 'lodash-es'
import { useLocalStorage } from 'react-use'
import { gzip } from 'pako'
import { fromUint8Array } from 'js-base64'

type PlaythroughType = Partial<Omit<PlaythroughInput, 'stages'>> & {
  stages: StagePlaythroughType[]
}

let playthrough: PlaythroughType = {
  stages: [],
}

type UsePlaythroughOptions = {
  levelId?: string
  userId?: string
  levelRev?: string
  planId?: string
  planRev?: string
}

type StagePlaythroughType = StagePlaythroughInput & {
  cubes: PlaythroughCubeInput[]
}

let stage: StagePlaythroughType | null = null

type Goals = {
  displayGoals: {
    quaternion: Quaternion
    pattern: CubePatterns
  }[]
  compareGoals: Record<string, Quaternion | null>[]
}
let stageStart = Date.now()
export const PLAYTHROUGHS_LOCAL_STORAGE = 'playthroughs'

export function usePlaythrough({ levelId, userId, levelRev, planId, planRev }: UsePlaythroughOptions = {}) {
  const [_, addPlaythrough] = useAddPlaythroughMutation()
  const [__, addPlaythroughs] = useAddPlaythroughsMutation()
  const [playthroughs, setPlaythroughs] = useLocalStorage<PlaythroughInput[]>(PLAYTHROUGHS_LOCAL_STORAGE, [])

  useEffect(() => {
    playthrough = {
      stages: [],
    }
    stage = null
  }, [levelId])

  if (levelId) {
    playthrough.levelId = levelId
  }
  if (userId) {
    playthrough.userId = userId
  }
  if (levelRev) {
    playthrough.levelRev = levelRev
  }
  if (planId) {
    playthrough.planId = planId
  }
  if (planRev) {
    playthrough.planRev = planRev
  }

  return {
    startStage: (goals: Goals, cubes: { pattern: CubePatterns }[], currentTotalTime) => {
      if (stage) {
        const previousStageTimes = sumBy(playthrough.stages, 'stageTime')
        playthrough.stages?.push({
          ...stage,
          endedAt: new Date(),
          stageTime: currentTotalTime - previousStageTimes,
          result: StageResult.Success,
        } as StagePlaythroughType)
      }
      stageStart = Date.now()
      stage = {
        startedPlayingAt: new Date(stageStart),
        goalShownAt: new Date(stageStart),
        cubes:
          cubes?.map(({ pattern }) => ({
            cubePattern: pattern,
            quaternions: [],
          })) ?? [],
        goals: {
          displayGoals: goals.displayGoals.map((goal) => {
            const q = goal.quaternion
            return {
              pattern: goal.pattern,
              quaternion: {
                x: q.x,
                y: q.y,
                z: q.z,
                w: q.w,
              },
            }
          }),
        },
      }
    },
    finishStage: (currentTotalTime: number, result: StageResult) => {
      if (stage) {
        const previousStageTimes = sumBy(playthrough.stages, 'stageTime')
        playthrough.stages?.push({
          ...stage,
          endedAt: new Date(),
          stageTime: currentTotalTime - previousStageTimes,
          result,
        } as StagePlaythroughType)
        stage = null
      }
    },
    snapshotCubes: (quaternions: QuaternionInput[]) => {
      if (stage) {
        stage.cubes?.forEach((cube, index) => {
          const timeFromStart = Date.now() - stageStart
          cube.quaternions?.push([
            quaternions[index].x!,
            quaternions[index].y!,
            quaternions[index].z!,
            quaternions[index].w!,
            timeFromStart,
          ])
        })
      }
    },
    finishLevel: async (currentTotalTime: number, stats: PlaythroughStatsInput) => {
      if (stage && currentTotalTime) {
        const previousStageTimes = sumBy(playthrough.stages, 'stageTime')
        playthrough.stages?.push({
          ...stage,
          endedAt: new Date(),
          stageTime: currentTotalTime - previousStageTimes,
          result: StageResult.DidNotFinish,
        } as StagePlaythroughType)
        stage = null
      }

      // TODO: just a workaround for #200v006 - to fix this, find where the null quaternions are coming from and handle it there
      // Converting type from PlaythroughType to PlaythroughInput for storage in DB. Quaternions are changed from object to Array [X,Y,Z,W,Time] and they are exported from stages and compressed via gzip to base64 string. For converting back use ungzip(toUint8Array(compressed), {to: 'string'})
      const cubeRun = playthrough.stages![0].cubes!.map(({ cubePattern }, cubeIndex) => ({
        pattern: cubePattern,
        quaternions: playthrough.stages?.map(({ cubes }) => cubes![cubeIndex].quaternions),
      }))
      const filteredPlaythrough: PlaythroughInput = {
        ...playthrough,
        stages: playthrough.stages.map((stage) => omit(stage, ['cubes'])),
        cubeRun: fromUint8Array(gzip(JSON.stringify(cubeRun)), true),
      }

      const playthroughInput = { ...filteredPlaythrough, stats } as PlaythroughInput
      try {
        await addPlaythrough({
          input: playthroughInput,
        })
      } catch (e) {
        setPlaythroughs([...(playthroughs || []), playthroughInput])
      }

      playthrough = {
        stages: [],
      }
    },
    addPlaythroughsToDatabase: async () => {
      const playthroughsFromLocalStorage = JSON.parse(localStorage.getItem(PLAYTHROUGHS_LOCAL_STORAGE)!) ?? []
      if (playthroughsFromLocalStorage.length > 0) {
        return await addPlaythroughs({
          input: playthroughsFromLocalStorage,
        })
      }
    },
  }
}
