/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { LevelStatistics, LevelStatisticsFromJSON, LevelStatisticsToJSON } from './LevelStatistics'

/**
 *
 * @export
 * @interface RehabPlanStats
 */
export interface RehabPlanStats {
  /**
   *
   * @type {string}
   * @memberof RehabPlanStats
   */
  levelId: string
  /**
   *
   * @type {string}
   * @memberof RehabPlanStats
   */
  levelName: string
  /**
   *
   * @type {string}
   * @memberof RehabPlanStats
   */
  gameType: string
  /**
   *
   * @type {Array<LevelStatistics>}
   * @memberof RehabPlanStats
   */
  stats: Array<LevelStatistics>
}

export function RehabPlanStatsFromJSON(json: any): RehabPlanStats {
  return RehabPlanStatsFromJSONTyped(json, false)
}

export function RehabPlanStatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): RehabPlanStats {
  if (json === undefined || json === null) {
    return json
  }
  return {
    levelId: json['levelId'],
    levelName: json['levelName'],
    gameType: json['gameType'],
    stats: (json['stats'] as Array<any>).map(LevelStatisticsFromJSON),
  }
}

export function RehabPlanStatsToJSON(value?: RehabPlanStats | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    levelId: value.levelId,
    levelName: value.levelName,
    gameType: value.gameType,
    stats: (value.stats as Array<any>).map(LevelStatisticsToJSON),
  }
}
