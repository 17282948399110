/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
  PlansLevelsStatistic,
  PlansLevelsStatisticFromJSON,
  PlansLevelsStatisticToJSON,
} from './PlansLevelsStatistic'
import { RehabPlanStats, RehabPlanStatsFromJSON, RehabPlanStatsToJSON } from './RehabPlanStats'

/**
 *
 * @export
 * @interface RehabPlanStatistic
 */
export interface RehabPlanStatistic {
  /**
   *
   * @type {Array<PlansLevelsStatistic>}
   * @memberof RehabPlanStatistic
   */
  plansLevelsStatistic: Array<PlansLevelsStatistic>
  /**
   *
   * @type {Array<RehabPlanStats>}
   * @memberof RehabPlanStatistic
   */
  rehabPlanStats: Array<RehabPlanStats>
}

export function RehabPlanStatisticFromJSON(json: any): RehabPlanStatistic {
  return RehabPlanStatisticFromJSONTyped(json, false)
}

export function RehabPlanStatisticFromJSONTyped(json: any, ignoreDiscriminator: boolean): RehabPlanStatistic {
  if (json === undefined || json === null) {
    return json
  }
  return {
    plansLevelsStatistic: (json['plansLevelsStatistic'] as Array<any>).map(PlansLevelsStatisticFromJSON),
    rehabPlanStats: (json['rehabPlanStats'] as Array<any>).map(RehabPlanStatsFromJSON),
  }
}

export function RehabPlanStatisticToJSON(value?: RehabPlanStatistic | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    plansLevelsStatistic: (value.plansLevelsStatistic as Array<any>).map(PlansLevelsStatisticToJSON),
    rehabPlanStats: (value.rehabPlanStats as Array<any>).map(RehabPlanStatsToJSON),
  }
}
