import { merge, shuffle } from 'lodash-es'
import { assign, createMachine, MachineConfig } from 'xstate'
import {
  Assignment,
  BucketImage,
  CubePatterns,
  Emotion,
  LocalizedText,
  SocialGenerator,
  Solution,
  StageResult,
} from '@proxyqb/graphql-api-types'
import {
  baseGeneratorMachineConfig,
  BaseGeneratorMachineContext,
  BaseStageHistory,
  getCubeSides,
} from './base-generator-machine-config'
import { groupSidesByPattern } from './group-sides-by-pattern'

export interface SocialStageGeneratorMachineContext extends BaseGeneratorMachineContext {
  stageGenerator: SocialGenerator
  socialCognition: SocialImages[]
  stageHistory: Array<BaseStageHistory & { imageId: string }>
  imageId: string
}

export type SocialImages = {
  emotionChoices: Emotion[]
  id: string
  assetUrl: string
  text: LocalizedText
  type: string
}

type EmotionOption = Emotion & { color: string; url?: string }

// zluta       : 255   255    33       #FFFF21
// oranzova    : 255   183    33       #FFB721
// ruzova      : 255   33     175      #FF21AF
// zelena      : 71    239    33       #47EF21
// cerna       : 33    33     33       #212121
// cervena     : 248   0      0        #F80000
// modra       : 33    160    255      #21A0FF

const colorsHash = (side: number | undefined) => {
  const hashedColors = {
    1: '#FFFF21',
    2: '#FFB721',
    3: '#FF21AF',
    4: '#47EF21',
    5: flagsmith.flags.led_color_cubes?.enabled ? '#F80000' : '#212121',
    6: '#21A0FF',
  }
  return hashedColors[side!]
}

const calculateNextSide = assign<SocialStageGeneratorMachineContext>((context) => {
  const stageHistory = context.stageHistory
  const socialCognition = context.socialCognition
  const stageGenerator = context.level.stageGenerator as SocialGenerator
  const lastSides: number[] = []

  const stageImages = socialCognition.filter((x) => x.type === stageGenerator.assignment)

  //if success last stage then find last succ sides
  if (stageHistory) {
    for (let i = stageHistory.length - 1; i >= 0; i--) {
      if (stageHistory[i].type === 'SUCCESS') {
        lastSides.push(...stageHistory[i].stageGoals.map((side) => side.side))
        break
      }
    }
  }

  const currentSides: Record<string, number | null> = {}
  context.cubes.forEach(({ id, getCubeCurrentSide }) => {
    currentSides[id] = getCubeCurrentSide()
  })
  const currentSidesByPattern = groupSidesByPattern(currentSides, context.cubes)
  //pick unplayed picture
  const availableWinSides = shuffle(
    getCubeSides(stageGenerator).filter(
      (side) => !lastSides.includes(side) && !currentSidesByPattern.COLORS?.includes(side),
    ),
  )

  const usedImages: string[] = stageHistory.map((item) => item.imageId)
  const stage = stageImages.filter((x) => !usedImages.includes(x.id)).pop()!
  let stageSides: Record<string, number>[] = []

  const winSides = stage.emotionChoices.filter(({ correct }) => correct).map(() => availableWinSides.pop())
  const availableLossSides = shuffle(getCubeSides(stageGenerator).filter((x) => !winSides.includes(x)))
  const lossSides = stage.emotionChoices.filter(({ correct }) => !correct).map(() => availableLossSides.pop())

  // assign color to sides
  let emotionOptions: EmotionOption[] = stage.emotionChoices.map((emotion) => {
    if (emotion.correct) {
      const tmpSide = winSides.pop()
      stageSides.push({ [context.cubes[0].id]: tmpSide! })
      return { ...emotion, color: colorsHash(tmpSide!) }
    } else {
      const tmpSide = lossSides.pop()
      return { ...emotion, color: colorsHash(tmpSide!) }
    }
  })

  //if smiley solution, push links to Smileys
  if (stageGenerator.solution === Solution.SmileyColor) {
    const smileys = socialCognition.filter((x) => x.type === Assignment.Smiley)
    emotionOptions = emotionOptions.map((em) => {
      const smileyUrl = smileys.find(
        (smiley) =>
          smiley.emotionChoices.find((emotion) => emotion.correct)!.emotion.code === em.emotion.code,
      )!.assetUrl
      return { ...em, url: smileyUrl }
    })
  }

  // max possible emotionChoices options
  const correctOptions = emotionOptions.filter((em) => em.correct === true)
  const wrongOptions = shuffle(emotionOptions.filter((em) => em.correct === false)).slice(
    0,
    stageGenerator.maxOptions - correctOptions.length,
  )
  const maxEmotionOptions = shuffle(wrongOptions.concat(correctOptions))

  return {
    compareGoals: [...stageSides],
    image: stage.assetUrl,
    imageId: stage.id,
    emotionChoices: maxEmotionOptions,
  }
})

const decrementCount = assign<SocialStageGeneratorMachineContext>({
  stagesCount: (context) => context.stagesCount - 1,
})

const stagesCountNotZero = (context, event, meta) => {
  const isNonEndingFailure = [
    StageResult.Disqualification,
    StageResult.Skiped,
    StageResult.WrongSolution,
  ].includes(meta?.state?.event?.result)
  return context.stagesCount !== 0 || isNonEndingFailure
}

const recordStage = assign<SocialStageGeneratorMachineContext>((context, event, meta) => {
  const stageHistory = context.stageHistory
  const compareGoals = context.compareGoals
  const stageArray: SocialStageGeneratorMachineContext['displayGoals'] = []

  for (let i = 0; i < compareGoals.length; i++) {
    const goal = {
      side: Object.values(compareGoals[i])[0]!,
      pattern: CubePatterns.Colors,
      id: context.cubes[0].id,
    }
    stageArray.push(goal)
  }

  const stage = {
    stageGoals: stageArray,
    result: meta?.state?.event?.result, // result exist on some levels only?
    type: meta?.state?.event?.type,
    imageId: context.imageId,
  }
  console.log({ stage, context })

  return { stageHistory: [...stageHistory, stage] as SocialStageGeneratorMachineContext['stageHistory'] }
})

type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>
}

type ConfigType = MachineConfig<SocialStageGeneratorMachineContext, any, any, any>

const extension: DeepPartial<ConfigType> = {
  id: 'simpleStageGeneratorMachine',
}
const config = merge(extension, baseGeneratorMachineConfig) as ConfigType

export const socialStageGeneratorMachine = createMachine<SocialStageGeneratorMachineContext>(config, {
  actions: { recordStage, calculateNextSide, decrementCount },
  guards: {
    stagesCountNotZero,
  },
})
