import { RehabilitationPlanProgress, Screen } from '../shared'
import { Flex } from '@proxyqb/ui'
import { useNavigate, useParams } from 'react-router-dom'
import { useQueryRehabilitationPlanByIdQuery } from './rehabilitation-plan.generated'
import { RehabPlanLevelItem } from './rehab-plan-level-item'
import { getNextLevelId } from './rehabilitation-plan-list.screen'
import { useLocalStorage, usePrevious } from 'react-use'
import { useGlobalState } from '@proxyqb/cube-global-state'
import { Box, Button } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { useSkipPrepareGame } from '../shared/use-skip-prepare-game'
import { useIntl } from 'react-intl'

export const PreparePlanScreen = () => {
  const navigate = useNavigate()
  const { formatMessage } = useIntl()
  const { shouldSkipPrepareGame } = useSkipPrepareGame()
  const { rehabilitationPlanId } = useParams<{ rehabilitationPlanId: string }>()
  const { selectedPatient } = useGlobalState()
  const [nextLevelId, setNextLevelId] = useState<string>()
  const [rehabPlanProgress, _] = useLocalStorage<Record<string, Record<string, { levelId: string }>>>(
    'rehabPlanProgress',
    {},
  )

  const boxRef = useRef<HTMLDivElement>(null)

  const [{ data, fetching }] = useQueryRehabilitationPlanByIdQuery({
    variables: {
      planId: rehabilitationPlanId ?? '',
    },
  })

  useEffect(() => {
    if (Object.keys(rehabPlanProgress).length === 0) {
      setNextLevelId(data?.getRehabilitationPlan.levels[0].id ?? '')
    } else {
      if (!fetching) {
        setNextLevelId(getNextLevelId(rehabPlanProgress, selectedPatient, data?.getRehabilitationPlan))
      }
    }
  }, [data])

  const previousData = usePrevious(data)

  useEffect(() => {
    if (boxRef.current && data && !!nextLevelId) {
      const div = boxRef.current
      const scrollDivWidth = div?.getBoundingClientRect().width ?? 0
      const nextLevelIndex = data.getRehabilitationPlan.levels.map((level) => level.id).indexOf(nextLevelId)
      const number = nextLevelIndex + 1
      const baseNumber = 800 + 30 + 90 - scrollDivWidth / 2
      const leftScroll = baseNumber + (number - 1) * 190
      div?.scrollTo({ left: leftScroll, behavior: 'smooth' })
    }
  }, [previousData, boxRef.current, data, nextLevelId])

  const continueRehabilitationPlan = async () => {
    const levels = data?.getRehabilitationPlan.levels
    const id = data?.getRehabilitationPlan.id
    const shouldSkip = await shouldSkipPrepareGame(levels, id, nextLevelId)
    shouldSkip
      ? navigate(`/category/default/level-description/${nextLevelId}?rehabilitationPlanId=${id}`)
      : navigate(`/category/default/prepare-game/${nextLevelId}?rehabilitationPlanId=${id}`)
  }

  return (
    <Screen
      primaryTitle={data?.getRehabilitationPlan.name.local}
      backButtonHandler={() => navigate('/')}
      backButtonLabel="shared.back"
    >
      <Flex
        height="100%"
        width="100%"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        position={'relative'}
      >
        <Box
          sx={{
            marginTop: 10,
            width: '100%',
            height: '280px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            flexDirection: 'row',
            overflowX: 'scroll',
            overflowY: 'hidden',
            position: 'relative',
            scrollbarWidth: 'none',
            '&::-webkit-scrollbar': {
              display: 'none',
            },
            '&::-webkit-scrollbar-track': {
              display: 'none',
            },
            gap: '30px',
          }}
          ref={boxRef}
        >
          <Box
            sx={{
              minWidth: '800px',
              minHeight: '420px',
            }}
          />
          {data?.getRehabilitationPlan.levels.map((level, index) => (
            <RehabPlanLevelItem
              key={index}
              index={index}
              loading={fetching}
              name={level.name}
              allLevels={data?.getRehabilitationPlan.levels}
              levelId={level.id}
              nextLevelId={nextLevelId}
            />
          ))}
          <Box
            sx={{
              minWidth: '420px',
              minHeight: '420px',
            }}
          />
        </Box>
        <Box sx={{ position: 'absolute', right: -1, top: 100 }}>
          <Box
            sx={{
              background:
                'linear-gradient(to left, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 25%, rgba(255,255,255,0) 100%)',
              height: '250px',
              width: '40px',
            }}
          />
        </Box>
        <Box sx={{ position: 'absolute', left: -1, top: 100 }}>
          <Box
            sx={{
              background:
                'linear-gradient(to right, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 25%, rgba(255,255,255,0) 100%)',
              height: '250px',
              width: '40px',
            }}
          />
        </Box>
        <Box marginTop={10}>
          <RehabilitationPlanProgress levelId={nextLevelId} loading={fetching} isInPrepareGame />
        </Box>
        <Button sx={{ mt: 13 }} variant={'contained'} onClick={continueRehabilitationPlan} size={'large'}>
          {formatMessage({ id: 'shared.continue' })}
        </Button>
      </Flex>
    </Screen>
  )
}
