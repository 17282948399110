const UUID_SERVICE = 'a64267f3-5745-48de-8725-4de3f20894c4'
const UUID_NAME_SERVICE = '37e00ac8-2853-11ed-a261-0242ac120002'

/**
 * console.log(toBinString(Uint8Array.from([42, 100, 255, 0])))
 * // => '00101010011001001111111100000000'
 */
const fixToBinLength = (str: string, length: number): string =>
  str.length === length ? str : '0' + fixToBinLength(str, length - 1) // eslint-disable-line @typescript-eslint/no-unused-vars

// @ts-ignore
export function connectBT(): Promise<BluetoothRemoteGATTServer> {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise<any>(async (resolve) => {
    // @ts-ignore
    const isAvailable = await navigator.bluetooth.getAvailability()
    if (!isAvailable) {
      throw new Error('BT not available')
    }

    const isGetDevicesAvailable = !!navigator.bluetooth.getDevices

    const bluetoothDevices: BluetoothDevice[] | null = isGetDevicesAvailable
      ? await navigator.bluetooth.getDevices()
      : null
    if (!bluetoothDevices) {
      /* Available only in chrome 83+ as experimental feature.
       Heh, became available as experimental feature in one browser about half a year after development has started.
       Bleeding edge as fuck. We almost had to implement this fucking feature our selves in chrome codebase. */
      console.log(
        'update chrome and enable chrome://flags/#enable-experimental-web-platform-features',
        'https://stackoverflow.com/questions/60603666/web-bluetooth-bypass-pairing-screen-for-a-known-device-id',
      )
    }
    console.log(bluetoothDevices)

    console.log('basic connect')
    // @ts-ignore
    // const device = await navigator.bluetooth.requestDevice({ filters: [{ services: [UUID_SERVICE] }] })
    const device = await navigator.bluetooth.requestDevice({
      filters: [{ namePrefix: 'Kostka' }],
      optionalServices: [UUID_SERVICE, UUID_NAME_SERVICE],
    })
    if (device?.gatt) {
      const server = await device.gatt.connect()
      resolve(server)
    }
  })
}
