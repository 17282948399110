import { useGlobalState } from '@proxyqb/cube-global-state'
import { Screen } from '../shared'
import { AvatarInitials, DataGrid, DataGridProps, LoadingTemplate } from '@proxyqb/ui'
import { useIntl } from 'react-intl'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from '@xstate/react'
import { useEffectOnce } from 'react-use'
import { useQueryRehabilitationPlansByPatientIdQuery } from '../rehabilitation-plans/rehabilitation-plan.generated'
import { useNavigate } from 'react-router-dom'
import { Patient } from '@proxyqb/graphql-api-types'
import { Box, Button, Typography } from '@mui/material'
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle'

const AvatarCell = ({ row }) => <AvatarInitials firstName={row.firstName} lastName={row.lastName} />

const SelectPatientScreen = () => {
  const { setSelectedPatient, authService } = useGlobalState()
  const authState = useSelector(authService, (state) => state)

  const [patientToFetch, setPatientToFetch] = useState<Patient>()
  const push = useNavigate()

  const { formatMessage } = useIntl()
  const user = authState.context?.userDetails

  const patients = useMemo(() => {
    return user?.patients?.nodes.map((p) => p.node)
  }, [user])

  const logout = () => authService.send({ type: 'LOG_OUT' })
  const initialState: DataGridProps['initialState'] = {
    sorting: {
      sortModel: [{ field: 'lastName', sort: 'asc' }],
    },
  }

  const handleSelectPatient = (patient) => {
    setSelectedPatient(patient)
    setPatientToFetch(patient)
  }

  useEffectOnce(() => {
    setSelectedPatient(null)
  })

  const [{ data: dataRehabPlan, fetching: fetchingRehabPlan }] = useQueryRehabilitationPlansByPatientIdQuery({
    pause: !patientToFetch,
    variables: {
      patientId: patientToFetch?.id || '',
    },
  })

  useEffect(() => {
    if (patientToFetch && !fetchingRehabPlan) {
      const rehabPlanId = dataRehabPlan?.queryRehabilitationPlansByPatientId?.[0]
      if (rehabPlanId) {
        push('/')
      } else {
        push('/category-list')
      }
    }
  }, [dataRehabPlan?.queryRehabilitationPlansByPatientId, fetchingRehabPlan, patientToFetch])

  if (authState.matches('reloadingUser')) {
    return <LoadingTemplate />
  }

  const columns = [
    {
      headerName: '',
      field: 'id',
      renderCell: AvatarCell,
      width: 100,
    },
    { headerName: formatMessage({ id: 'shared.lastName' }), field: 'lastName', width: 180 },
    { headerName: formatMessage({ id: 'shared.firstName' }), field: 'firstName', width: 180 },
    { headerName: formatMessage({ id: 'shared.email' }), field: 'email', width: 180 },
  ]

  const NoRowsOverlay = () => {
    return (
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <Box sx={{ fontSize: '120px', marginBottom: -6 }}>
          <SupervisedUserCircleIcon fontSize={'inherit'} sx={{ color: 'black' }} />
        </Box>
        <Typography variant={'h4'}>{formatMessage({ id: 'playerProfiles.noPatientFound' })}</Typography>
        <Button
          variant={'contained'}
          sx={{ marginTop: 3, zIndex: 1 }}
          onClick={() => push('/create-patient')}
        >
          {formatMessage({ id: 'playerProfiles.createPatient' })}
        </Button>
      </Box>
    )
  }

  return (
    <Screen
      primaryTitle={'playerProfiles.patientList'}
      backButtonHandler={() => push('/player-profiles')}
      actions={[
        {
          handler: logout,
          right: true,
          label: 'shared.logout',
        },
      ]}
      hideFooter
    >
      <Box sx={{ marginTop: 2 }}>
        {patients.length > 0 && (
          <Button
            id={'kekel'}
            variant={'contained'}
            sx={{ marginBottom: 1 }}
            onClick={() => push('/create-patient')}
          >
            {formatMessage({ id: 'playerProfiles.createPatient' })}
          </Button>
        )}
        <DataGrid
          columns={columns}
          rows={patients}
          initialState={initialState}
          dataGridHeight="calc(100vh - 175px)"
          pageSize={8}
          hideFooterSelectedRowCount
          onRowClick={({ row }) => handleSelectPatient(row)}
          rowsPerPageOptions={[8]}
          noRowsOverlay={NoRowsOverlay}
        />
      </Box>
    </Screen>
  )
}

export default SelectPatientScreen
