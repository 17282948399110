import { useRehabilitationPlan } from './use-rehabilitation-plan'
import { useCubes2 } from '../game/bluetooth/useCubes'
import { countBy } from 'lodash-es'
import { CubePatterns } from '@proxyqb/graphql-api/src/modules/graphql-types.generated'
import { QueryLevelRequiredCubesDocument } from '../levels/levels.generated'
import { useContext } from 'react'
import { Context } from 'urql'
import { useRequiredCubesIds } from '../game/prepare-game/prepare-game.screen'

export const useSkipPrepareGame = (): {
  shouldSkipPrepareGame(rehabPlanLevels, rehabPlanId, nextLevelId: string): Promise<boolean>
  mapRequiredCubes(levelRequiredCubes, rehabPlanLevels): CubePatterns[]
} => {
  const { isFirstLevelInRehabPlan } = useRehabilitationPlan()
  const { cubes, getIdsByPatterns } = useCubes2()
  const client = useContext(Context)
  const [_, setRequiredCubesIds] = useRequiredCubesIds()

  const getPatterns = async (levelId, rehabPlanLevels) => {
    const fetchedLevel = await client.query(QueryLevelRequiredCubesDocument, { levelId }).toPromise()

    return mapRequiredCubes(fetchedLevel.data.getLevel.requiredCubes, rehabPlanLevels)
  }

  const mapRequiredCubes = (levelRequiredCubes, rehabPlanLevels): CubePatterns[] => {
    let patterns = levelRequiredCubes.map(({ pattern }) => pattern)
    if (rehabPlanLevels) {
      patterns = []
      const patternsByLevel = rehabPlanLevels.map(({ requiredCubes }) =>
        requiredCubes.map(({ pattern }) => pattern),
      )

      patternsByLevel.forEach((levelPatterns) => {
        const patternCounts = countBy(levelPatterns)
        Object.entries(patternCounts).forEach(([pattern, count]) => {
          const missingPatterns = count - patterns.filter((patt) => patt === pattern).length
          for (let i = 0; i < missingPatterns; i++) {
            patterns.push(pattern as CubePatterns)
          }
        })
      })
    }
    return patterns
  }

  return {
    shouldSkipPrepareGame: async (rehabPlanLevels, rehabPlanId, nextLevelId) => {
      const isFirstLevel = isFirstLevelInRehabPlan(nextLevelId)

      const requiredPatterns = await getPatterns(nextLevelId, rehabPlanLevels)
      const requiredCubesIds = await getIdsByPatterns(requiredPatterns)
      setRequiredCubesIds(requiredCubesIds)

      const cubesForCurrentGame = Object.entries(cubes)
        .filter(([cubeId]) => requiredCubesIds.includes(cubeId))
        .map(([, cube]) => cube)

      const areAllCubesForLevelPrepared =
        cubesForCurrentGame.length > 0 &&
        cubesForCurrentGame.length === requiredPatterns.length &&
        cubesForCurrentGame.every((cube) => cube.isConnected && cube.isSyncedWithDevice)

      return rehabPlanId && !isFirstLevel && areAllCubesForLevelPrepared
    },
    mapRequiredCubes,
  }
}
