import { InferType, object, string } from 'yup'
import { PHONE_NUMBER_REGEX } from '@proxyqb/ui'

export const createPatientSchema = object()
  .shape(
    {
      firstName: string().trim().required(),
      lastName: string().trim().required(),
      email: string()
        .trim()
        .email()
        .when('checkEmail', {
          is: true,
          then: (rule) => rule.required(),
        }),
      phoneNumber: string()
        .trim()
        .matches(PHONE_NUMBER_REGEX, { excludeEmptyString: true })
        .when('checkPhoneNumber', {
          is: true,
          then: (rule) => rule.required(),
        }),
      dateOfBirth: string()
        .test('YYYY-MM format of date', function (value) {
          if (value === '' || value === undefined) {
            return true
          }
          const regex = /((19|20)[0-9]{2})-(0[1-9]|10|11|12)/
          if (regex.test(value)) {
            return true
          } else return false
        })
        .optional(),
      sex: string().optional(),
      patientType: string().optional(),
    },
    [
      ['email', 'email'],
      ['phoneNumber', 'phoneNumber'],
      ['dateOfBirth', 'dateOfBirth'],
    ],
  )
  .required()
  .default({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    dateOfBirth: '',
  })

export type CreatePatientFields = InferType<typeof createPatientSchema>
