import { PLAYTHROUGHS_LOCAL_STORAGE, usePlaythrough } from '@proxyqb/level-generators'
import { useEffect } from 'react'
import { useLocalStorage } from 'react-use'
import { PlaythroughInput } from '@proxyqb/graphql-api-types'

export const PlaytroughExporter = () => {
  const { addPlaythroughsToDatabase } = usePlaythrough()
  const [_, setPlaythroughs] = useLocalStorage<PlaythroughInput[]>(PLAYTHROUGHS_LOCAL_STORAGE, [])
  const exportPlaythroughs = async () => {
    const response = await addPlaythroughsToDatabase()
    const exportedPlaythroughs = response?.data?.addPlaythroughs
    // useLocalStorage hook sux so this is a hack to get current value
    const oldPlaythroughs = JSON.parse(localStorage.getItem('playthroughs') || '[]')
    const newPlaythroughs = oldPlaythroughs!.filter(
      (playthrough) =>
        !exportedPlaythroughs?.some(
          (e) =>
            e?.levelId === playthrough.levelId &&
            e?.userId === playthrough.userId &&
            e?.endedAt === playthrough.stages[0].endedAt,
        ),
    )
    setPlaythroughs(newPlaythroughs)
  }

  useEffect(() => {
    const interval = setInterval(async () => {
      await exportPlaythroughs()
    }, 60000)
    return () => clearInterval(interval)
  }, [])
}
