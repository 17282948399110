import { merge, shuffle } from 'lodash-es'
import { assign, createMachine, MachineConfig } from 'xstate'
import { StageResult, TextStageGeneratorTextColor } from '@proxyqb/graphql-api-types'
import {
  baseGeneratorMachineConfig,
  BaseGeneratorMachineContext,
  getCubeSides,
} from './base-generator-machine-config'
import { groupSidesByPattern } from './group-sides-by-pattern'

export type TextStageGeneratorMachineContext = BaseGeneratorMachineContext

const colors = {
  blue: '#008DD2',
  orange: '#EF7F1A',
  pink: '#E50880',
  green: '#85BD2F',
  black: '#000000',
  yellow: '#FFED00',
} as const

const assignmentCorrectColors = {
  '1': colors.yellow,
  '2': colors.orange,
  '3': colors.pink,
  '4': colors.green,
  '5': colors.black,
  '6': colors.blue,
}

export const calculateNextSideFn = (context: TextStageGeneratorMachineContext) => {
  const stageHistory = context.stageHistory
  const isMemoryLevel =
    context.level.stageGenerator.goal.hideAfter !== 0 && context.level.stageGenerator.goal.hideAfter
  let filterSides = <number[]>[]
  const sides: Record<string, number | null> = {}
  context.cubes.forEach(({ id, getCubeCurrentSide }) => {
    sides[id] = getCubeCurrentSide()
  })
  const currentSidesByPattern = groupSidesByPattern(sides, context.cubes)

  const getAvailableSides = (cube, cubeArray) => {
    const cubesWithSamePatternSides = cubeArray.filter((c) => c.pattern == cube.pattern).map((c) => c.side)
    if (context.compareGoals.length === 0) {
      return getCubeSides(context.level.stageGenerator).filter(
        (side) =>
          !cubesWithSamePatternSides.includes(side) && !currentSidesByPattern[cube.pattern]?.includes(side),
      )
    } else {
      const prevGoals = Object.values(context.compareGoals[0])
      return getCubeSides(context.level.stageGenerator).filter(
        (side) =>
          !prevGoals.includes(side) &&
          !cubesWithSamePatternSides.includes(side) &&
          !currentSidesByPattern[cube.pattern]?.includes(side),
      )
    }
  }
  const usableColors = Object.values(colors).filter(
    (color) => !context.displayGoals.map((it) => it.color).includes(color),
  )

  if (isMemoryLevel) {
    for (let i = stageHistory.length - 1; i >= 0; i--) {
      if (filterSides.length !== 0) {
        break
      }
      if (stageHistory[i].type === 'SUCCESS') {
        const currentSides = stageHistory[i].stageGoals.map((item) => item.side)
        filterSides = [...currentSides]
      }
    }
  }
  const displayGoals = context.cubes.reduce((accumulator, current) => {
    const side = shuffle(getAvailableSides(current, accumulator)).pop()
    accumulator = [
      ...accumulator,
      {
        side,
        pattern: current.pattern,
        ...(context.level.stageGenerator.textColor === TextStageGeneratorTextColor.Black && {
          color: colors.black,
        }),
        ...(context.level.stageGenerator.textColor === TextStageGeneratorTextColor.Correct && {
          color: assignmentCorrectColors[side!],
        }),
        ...(context.level.stageGenerator.textColor === TextStageGeneratorTextColor.Switched && {
          color: shuffle(usableColors.filter((c) => c != assignmentCorrectColors[side!])).pop(),
        }),
      },
    ]

    return accumulator
  }, [])

  if (isMemoryLevel) {
    for (let i = stageHistory.length - 1; i >= 0; i--) {
      if (filterSides.length !== 0) {
        break
      }
      if (stageHistory[i].type === 'SUCCESS') {
        const currentSides = stageHistory[i].stageGoals.map((item) => item.side)
        filterSides = [...currentSides]
      }
    }
  }

  // TODO: this is quick and dirty, use permutations instead
  if (context.cubes.length === 2 && context.cubes[0].pattern === context.cubes[1].pattern) {
    const compareGoals = [
      Object.fromEntries([
        [context.cubes[0].id, displayGoals[0].side],
        [context.cubes[1].id, displayGoals[1].side],
      ]),
      Object.fromEntries([
        [context.cubes[0].id, displayGoals[1].side],
        [context.cubes[1].id, displayGoals[0].side],
      ]),
    ]
    return {
      displayGoals,
      compareGoals,
    }
  }

  const compareGoals = Object.fromEntries(
    context.cubes.map(({ id }, index) => [id, displayGoals[index].side]),
  )
  return {
    displayGoals,
    compareGoals: [compareGoals],
  }
}
const calculateNextSide = assign<TextStageGeneratorMachineContext>(calculateNextSideFn)
const decrementCount = assign<TextStageGeneratorMachineContext>({
  stagesCount: (context) => context.stagesCount - 1,
})
const stagesCountNotZero = (context, event, meta) => {
  const isNonEndingFailure = [
    StageResult.Disqualification,
    StageResult.Skiped,
    StageResult.WrongSolution,
  ].includes(meta?.state?.event?.result)
  return context.stagesCount !== 0 || isNonEndingFailure
}

const recordStage = assign<TextStageGeneratorMachineContext>((context, event, meta) => {
  const stageHistory = context.stageHistory
  const displayGoals = context.displayGoals
  const stageArray = []

  for (const x of displayGoals) {
    const goal = { side: x.side, pattern: x.pattern }
    stageArray.push(goal)
  }

  const stage = {
    stageGoals: stageArray,
    result: meta?.state?.event?.result, // result exist on some levels only?
    type: meta?.state?.event?.type,
  }

  return { stageHistory: [...stageHistory, stage] }
})

type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>
}

type ConfigType = MachineConfig<TextStageGeneratorMachineContext, any, any, any>

const extension: DeepPartial<ConfigType> = {
  id: 'simpleStageGeneratorMachine',
}
const config = merge(extension, baseGeneratorMachineConfig) as ConfigType

export const textStageGeneratorMachine = createMachine<TextStageGeneratorMachineContext>(config, {
  actions: { recordStage, calculateNextSide, decrementCount },
  guards: {
    stagesCountNotZero,
  },
})
