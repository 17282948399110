/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface LevelRun
 */
export interface LevelRun {
  /**
   *
   * @type {string}
   * @memberof LevelRun
   */
  userId: string
  /**
   *
   * @type {string}
   * @memberof LevelRun
   */
  endedAt: string
  /**
   *
   * @type {Array<number>}
   * @memberof LevelRun
   */
  stages: Array<number>
}

export function LevelRunFromJSON(json: any): LevelRun {
  return LevelRunFromJSONTyped(json, false)
}

export function LevelRunFromJSONTyped(json: any, ignoreDiscriminator: boolean): LevelRun {
  if (json === undefined || json === null) {
    return json
  }
  return {
    userId: json['userId'],
    endedAt: json['endedAt'],
    stages: json['stages'],
  }
}

export function LevelRunToJSON(value?: LevelRun | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    userId: value.userId,
    endedAt: value.endedAt,
    stages: value.stages,
  }
}
