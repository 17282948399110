import TimerIcon from '@mui/icons-material/Timer'
import { Typography } from '@mui/material'
import { Flex } from '@proxyqb/ui'
import { CSSProperties, FC, MutableRefObject, useState } from 'react'
import { useAudio, useCounter, useHarmonicIntervalFn } from 'react-use'
import { getPrettyTime } from '../helpers'
import { useSpring, animated } from 'react-spring'

interface LevelTimerProps {
  /** [ms] */
  time: MutableRefObject<number>
  onTimeInc?(): void
  onTimeDec?(): void
  isPaused: MutableRefObject<boolean>
  isTimeLevelGoal: boolean
  color: CSSProperties['color']
  fontSize: CSSProperties['fontSize']
}

export const LevelTimer: FC<LevelTimerProps> = ({
  time,
  isTimeLevelGoal,
  onTimeInc,
  onTimeDec,
  isPaused,
  color,
  fontSize,
}) => {
  const [levelTimer, { set: setTimer }] = useCounter(time.current ?? 0, null, 0)
  const [flip, setFlip] = useState(false)
  const [stop, setStop] = useState(true)
  const [countdown, , setCountdown] = useAudio({
    src: '/static/media/audio/clock.ogg',
    autoPlay: false,
  })

  const props = useSpring({
    from: { opacity: 1, scale: 1.005, height: '100%' },
    to: { opacity: 0.75, scale: 1.0 },
    delay: 0,
    config: { mass: 2, tension: 70, friction: 0 },
    onRest: () => setFlip(!flip),
    reverse: flip,
    cancel: stop,
  })

  useHarmonicIntervalFn(() => {
    if (!isPaused.current) {
      if (isTimeLevelGoal) {
        setTimer(time.current - 100)
        onTimeDec?.()
      } else {
        setTimer(time.current + 100)
        onTimeInc?.()
      }
    } else {
      setTimer(time.current)
    }
    if (isTimeLevelGoal && time.current < 5000 && time.current > 0) {
      setStop(false)
      setCountdown.play()
      setCountdown.volume(0.5)
    } else {
      setCountdown.pause()
    }
  }, 100)

  return (
    <animated.div style={props}>
      {countdown}
      <Flex height="100%" alignItems="center" justifyContent="left">
        <TimerIcon
          sx={{ height: fontSize || '50px', width: fontSize || '50px', marginRight: '25px', fill: color }}
        />
        <Typography variant="h2" color={color} fontSize={fontSize}>
          {getPrettyTime(Math[isTimeLevelGoal ? 'ceil' : 'floor'](levelTimer / 1000))}
        </Typography>
      </Flex>
    </animated.div>
  )
}
