import { Screen } from '../shared'
import { CATEGORIES } from '@proxyqb/ui'
import { CategoryTile } from './category-title'
import { Grid, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { ReactElement } from 'react'
import { useGlobalState } from '@proxyqb/cube-global-state'
import { useQueryRehabilitationPlansByPatientIdQuery } from '../rehabilitation-plans/rehabilitation-plan.generated'
import { useIntl } from 'react-intl'
import { useUserLevelCountInCategoriesQuery } from '../levels/levels.generated'

const CategoryListScreen = (): ReactElement => {
  const navigate = useNavigate()
  const { formatMessage } = useIntl()
  const { authService, authState, selectedPatient } = useGlobalState()
  const roles = authState.context.userDetails?.roles
  const isPatient = roles?.includes('patient') && !roles?.some((role) => ['doctor', 'admin'].includes(role))

  const logout = () => authService.send({ type: 'LOG_OUT' })

  const [{ data, fetching }] = useQueryRehabilitationPlansByPatientIdQuery({
    variables: {
      patientId: selectedPatient?.id ?? '',
    },
  })

  const [{ data: userLevelCountData, fetching: userLevelCountDataFetching }] =
    useUserLevelCountInCategoriesQuery({
      requestPolicy: 'cache-and-network',
    })

  const hasRehabilitationPlan =
    data?.queryRehabilitationPlansByPatientId && data?.queryRehabilitationPlansByPatientId?.length > 0

  const selectCategory = (code: string) => () => {
    navigate(`/category/${code}/level-list/`)
  }

  const back = (): void => {
    if (hasRehabilitationPlan) {
      navigate('/')
    } else navigate('/player-profiles')
  }

  return (
    <Screen
      primaryTitle="categories.gameCategory"
      backButtonHandler={fetching || !isPatient || !hasRehabilitationPlan ? undefined : back}
      actions={
        !fetching && isPatient && !hasRehabilitationPlan
          ? [
              {
                handler: logout,
                right: true,
                label: 'shared.logout',
              },
            ]
          : []
      }
      backButtonLabel="shared.back"
    >
      <Grid container spacing={10} px={'10%'} sx={{ marginTop: '10px' }}>
        {CATEGORIES.map((category, index) => (
          <CategoryTile
            index={index}
            disabled={
              userLevelCountData?.userLevelCountInCategories.find((cat) => cat.code === category.code)!
                .hasLevels === 0
            }
            key={category.code}
            category={category}
            onClick={selectCategory(category.code)}
            fetching={!userLevelCountData || userLevelCountDataFetching}
          />
        ))}
      </Grid>
      {!fetching && isPatient && !hasRehabilitationPlan && (
        <Grid container direction="row" justifyContent="center" alignItems="center" marginTop={5}>
          <Typography variant={'h5'}>{formatMessage({ id: 'categories.noLevels' })}</Typography>
        </Grid>
      )}
    </Screen>
  )
}

export default CategoryListScreen
