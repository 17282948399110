import { useGlobalState } from '@proxyqb/cube-global-state'
import {
  SocialImages,
  usePlaythrough,
  useStageGenerator,
  UseStageGeneratorOptions,
} from '@proxyqb/level-generators'
import { LoadingTemplate } from '@proxyqb/ui'
import { MouseEventHandler } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useCounter } from 'react-use'
import { useRehabilitationPlan } from '../shared/use-rehabilitation-plan'
import { CubesState, useCubes2 } from './bluetooth/useCubes'
import { GameRenderer } from './GameRenderer'
import {
  LevelDetailFragment,
  useGetLevelQuery,
  useSocialCognitionImagesQuery,
  useWordImagesQuery,
} from './get-level.generated'
import { shuffle } from 'lodash-es'
import { StageGeneratorId } from '@proxyqb/graphql-api-types'
import { WordAsset } from '@proxyqb/strapi-api'
import { useIntl } from 'react-intl'
import { useSkipPrepareGame } from '../shared/use-skip-prepare-game'

const GameScreen = () => {
  const { id } = useParams<{ id: string }>()
  const [keyRemount, { inc: remountGame }] = useCounter(0)
  const push = useNavigate()
  const { selectedPatient } = useGlobalState()
  const [{ data }] = useGetLevelQuery({
    variables: {
      id: id!,
    },
    requestPolicy: 'cache-and-network',
  })
  const { locale } = useIntl()

  if (!selectedPatient) {
    push('/player-profiles')
  }
  const level = data?.getLevel
  const shouldFetchWords = !(!level || level?.stageGenerator.id !== StageGeneratorId.ImageWordGenerator)
  const [{ data: wordImages, fetching: fetchingWordImages }] = useWordImagesQuery({
    pause: !shouldFetchWords,
    requestPolicy: 'cache-first',
    variables: {
      // hack to make urql refetch this query when language changes
      lang: locale as never,
    },
  })

  const [{ data: socialCognitionImages, fetching: fetchingSocialCognitionImages }] =
    useSocialCognitionImagesQuery({
      pause: !level || level?.stageGenerator.id !== StageGeneratorId.SocialGenerator,
      requestPolicy: 'cache-first',
      variables: {
        // hack to make urql refetch this query when language changes
        lang: locale as never,
      },
    })

  const isLoading =
    !data ||
    !level ||
    (shouldFetchWords && !wordImages) ||
    fetchingWordImages ||
    fetchingSocialCognitionImages
  if (isLoading) {
    return <LoadingTemplate />
  }
  const words: WordAsset[] | undefined = wordImages?.wordImages.nodes.map((node) => ({
    id: node.id,
    image: node.assetUrl,
    word: node.word.local,
  }))
  const socialImages: SocialImages[] = socialCognitionImages?.socialCognitionImages.nodes
  const playAgain = () => {
    remountGame()
  }
  return (
    <Game
      key={keyRemount}
      playAgain={playAgain}
      level={level}
      words={words ? shuffle(words) : undefined}
      socialCognition={socialCognitionImages ? shuffle(socialImages) : undefined}
    />
  )
}

function getRequiredCubes(cubes: CubesState, level: LevelDetailFragment): UseStageGeneratorOptions['cubes'] {
  const requiredPatterns = level.requiredCubes.map(({ pattern }) => pattern)
  const requiredCubes: UseStageGeneratorOptions['cubes'] = []
  for (const entry of Object.entries(cubes)) {
    const [id, { pattern, getCubeCurrentSide }] = entry
    const index = requiredPatterns.indexOf(pattern)
    if (index === -1 || !entry[1].isConnected) {
      if (requiredPatterns.includes(pattern)) {
        console.error('Gde kostky?')
      }
      continue
    }
    delete requiredPatterns[index]
    requiredCubes.push({ id, pattern, getCubeCurrentSide })
  }
  return requiredCubes
}

const getRehabPlanInfo = (
  rehabPlanLevels,
  rehabPlanId,
  push,
  levelId,
  categoryCode,
  shouldSkipPrepareGame,
): { playNext(): void; isLastLevelFromPlan: boolean } | undefined => {
  if (!rehabPlanId || !rehabPlanLevels) {
    return
  }
  const currentLevelIndex = rehabPlanLevels.findIndex(({ id }) => id === levelId)

  if (currentLevelIndex === -1) {
    return
  }
  const isLastLevelFromPlan = currentLevelIndex === rehabPlanLevels.length - 1

  const playNext = () => {
    if (isLastLevelFromPlan) {
      push('/rehabilitation-plan-list')
    } else {
      const nextLevelId =
        rehabPlanLevels.length > currentLevelIndex + 1 && rehabPlanLevels[currentLevelIndex + 1].id
      const shouldSkip = shouldSkipPrepareGame()
      if (nextLevelId) {
        shouldSkip
          ? push(`/prepare-plan/${rehabPlanId}`)
          : push(`/category/${categoryCode}/prepare-game/${nextLevelId}?rehabilitationPlanId=${rehabPlanId}`)
      }
    }
  }

  return {
    playNext,
    isLastLevelFromPlan,
  }
}

const Game = ({
  level,
  playAgain,
  words,
  socialCognition,
}: {
  level: LevelDetailFragment
  playAgain: MouseEventHandler<HTMLButtonElement>
  words?: WordAsset[]
  socialCognition: SocialImages[]
}) => {
  const navigate = useNavigate()
  const { categoryCode, id: levelId } = useParams<{ categoryCode: string; id: string }>()
  const { selectedPatient } = useGlobalState()
  const { shouldSkipPrepareGame } = useSkipPrepareGame()

  const { rehabilitationPlanId, rehabilitationPlanRev, rehabPlanLevels } = useRehabilitationPlan()

  const rehabPlanInfo = getRehabPlanInfo(
    rehabPlanLevels,
    rehabilitationPlanId,
    navigate,
    levelId,
    categoryCode,
    shouldSkipPrepareGame,
  )

  const { cubes } = useCubes2()
  const stageGenerator = useStageGenerator(level.stageGenerator.id, {
    cubes: getRequiredCubes(cubes, level),
    // Debugging
    // cubes: level.requiredCubes.map((cube, index) => ({
    //   id: 'id' + index,
    //   pattern: cube.pattern,
    // })),
    level,
    words,
    socialCognition,
  })

  usePlaythrough({
    levelId: level.id,
    levelRev: level.rev,
    userId: selectedPatient?.id,
    planId: rehabilitationPlanId ?? undefined,
    planRev: rehabilitationPlanRev,
  })

  const playAnother = () => {
    categoryCode === 'default' ? navigate('/category-list') : navigate(`/category/${categoryCode}/level-list`)
  }

  return (
    <>
      {/*<RawDataCharts />*/}
      {/*<span>X: red, Y: green, Z: blue</span>*/}
      {/*<button onClick={sync}>Sync</button>*/}
      {/*<BluetoothConnectorScreen /> /!* TODO: should be just button not the whole screen, make proper UI flow *!/*/}
      <GameRenderer
        level={level}
        stageGenerator={stageGenerator}
        playAgain={playAgain}
        playAnother={playAnother}
        playNext={rehabPlanInfo?.playNext}
        isLastLevelFromPlan={rehabPlanInfo?.isLastLevelFromPlan}
      />
      {/*<LevelComplete*/}
      {/*  {...levelCompleteState}*/}
      {/*  onRestart={handleRestart}*/}
      {/*  onContinue={handleContinue}*/}
      {/*  showContinue={!!nextLevel.current}*/}
      {/*  onQuit={handleQuit}*/}
      {/*/>*/}
    </>
  )
}

export default GameScreen
