import * as Types from '@proxyqb/graphql-api-types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export const AddPlaythroughDocument = gql`
    mutation addPlaythrough($input: PlaythroughInput!) {
  addPlaythrough(input: $input)
}
    `;

export function useAddPlaythroughMutation() {
  return Urql.useMutation<AddPlaythroughMutation, AddPlaythroughMutationVariables>(AddPlaythroughDocument);
};
export const AddPlaythroughsDocument = gql`
    mutation addPlaythroughs($input: [PlaythroughInput]!) {
  addPlaythroughs(input: $input) {
    levelId
    userId
    endedAt
  }
}
    `;

export function useAddPlaythroughsMutation() {
  return Urql.useMutation<AddPlaythroughsMutation, AddPlaythroughsMutationVariables>(AddPlaythroughsDocument);
};
export type AddPlaythroughMutationVariables = Types.Exact<{
  input: Types.PlaythroughInput;
}>;


export type AddPlaythroughMutation = { __typename?: 'Mutation', addPlaythrough: string };

export type AddPlaythroughsMutationVariables = Types.Exact<{
  input: Array<Types.InputMaybe<Types.PlaythroughInput>> | Types.InputMaybe<Types.PlaythroughInput>;
}>;


export type AddPlaythroughsMutation = { __typename?: 'Mutation', addPlaythroughs: Array<{ __typename?: 'PlaythroughOutput', levelId: string, userId: string, endedAt: any } | null> };
