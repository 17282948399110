import { BaseGeneratorMachineContext, CubeSide } from './base-generator-machine-config'

export const groupSidesByPattern = (
  sides: Record<string, number | null> | Record<string, number | null>[],
  cubes: BaseGeneratorMachineContext['cubes'],
): Record<string, CubeSide[]> => {
  const sidesByPattern = {}
  const sideRecords = Array.isArray(sides) ? sides : [sides]
  sideRecords.forEach((sideRecord) => {
    Object.entries(sideRecord).forEach(([cubeId, side]) => {
      const pattern = cubes.find(({ id }) => id === cubeId)?.pattern
      if (pattern) {
        if (sidesByPattern[pattern]) {
          sidesByPattern[pattern].push(side)
        } else {
          sidesByPattern[pattern] = [side]
        }
      }
    })
  })
  return sidesByPattern
}
