/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import {
  UserRunsData,
  UserRunsDataFromJSON,
  UserRunsDataFromJSONTyped,
  UserRunsDataToJSON,
} from './UserRunsData'

/**
 *
 * @export
 * @interface RepeatedRunsChartData
 */
export interface RepeatedRunsChartData {
  /**
   *
   * @type {Array<number>}
   * @memberof RepeatedRunsChartData
   */
  x: Array<number>
  /**
   *
   * @type {Array<number>}
   * @memberof RepeatedRunsChartData
   */
  y: Array<number>
  /**
   *
   * @type {Array<number>}
   * @memberof RepeatedRunsChartData
   */
  yTrend: Array<number>
  /**
   *
   * @type {Array<number>}
   * @memberof RepeatedRunsChartData
   */
  yTrendGlobal: Array<number>
  /**
   *
   * @type {Array<UserRunsData>}
   * @memberof RepeatedRunsChartData
   */
  userRunsData: Array<UserRunsData>
}

export function RepeatedRunsChartDataFromJSON(json: any): RepeatedRunsChartData {
  return RepeatedRunsChartDataFromJSONTyped(json, false)
}

export function RepeatedRunsChartDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): RepeatedRunsChartData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    x: json['x'],
    y: json['y'],
    yTrend: json['yTrend'],
    yTrendGlobal: json['yTrendGlobal'],
    userRunsData: (json['userRunsData'] as Array<any>).map(UserRunsDataFromJSON),
  }
}

export function RepeatedRunsChartDataToJSON(value?: RepeatedRunsChartData | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    x: value.x,
    y: value.y,
    yTrend: value.yTrend,
    yTrendGlobal: value.yTrendGlobal,
    userRunsData: (value.userRunsData as Array<any>).map(UserRunsDataToJSON),
  }
}
