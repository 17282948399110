/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import { MovingTime, MovingTimeFromJSON, MovingTimeFromJSONTyped, MovingTimeToJSON } from './MovingTime'
import {
  PercentageCloseness,
  PercentageClosenessFromJSON,
  PercentageClosenessFromJSONTyped,
  PercentageClosenessToJSON,
} from './PercentageCloseness'

/**
 *
 * @export
 * @interface PlaythroughStats
 */
export interface PlaythroughStats {
  /**
   *
   * @type {Array<PercentageCloseness>}
   * @memberof PlaythroughStats
   */
  percentageCloseness: Array<PercentageCloseness>
  /**
   *
   * @type {Array<MovingTime>}
   * @memberof PlaythroughStats
   */
  movingTimes: Array<MovingTime>
}

export function PlaythroughStatsFromJSON(json: any): PlaythroughStats {
  return PlaythroughStatsFromJSONTyped(json, false)
}

export function PlaythroughStatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlaythroughStats {
  if (json === undefined || json === null) {
    return json
  }
  return {
    percentageCloseness: (json['percentageCloseness'] as Array<any>).map(PercentageClosenessFromJSON),
    movingTimes: (json['movingTimes'] as Array<any>).map(MovingTimeFromJSON),
  }
}

export function PlaythroughStatsToJSON(value?: PlaythroughStats | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    percentageCloseness: (value.percentageCloseness as Array<any>).map(PercentageClosenessToJSON),
    movingTimes: (value.movingTimes as Array<any>).map(MovingTimeToJSON),
  }
}
