/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface ScoreTable
 */
export interface ScoreTable {
  /**
   *
   * @type {number}
   * @memberof ScoreTable
   */
  place: number
  /**
   *
   * @type {string}
   * @memberof ScoreTable
   */
  date: string
  /**
   *
   * @type {string}
   * @memberof ScoreTable
   */
  score: string
}

export function ScoreTableFromJSON(json: any): ScoreTable {
  return ScoreTableFromJSONTyped(json, false)
}

export function ScoreTableFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScoreTable {
  if (json === undefined || json === null) {
    return json
  }
  return {
    place: json['place'],
    date: json['date'],
    score: json['score'],
  }
}

export function ScoreTableToJSON(value?: ScoreTable | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    place: value.place,
    date: value.date,
    score: value.score,
  }
}
