import styled, { keyframes } from 'styled-components'

const ghost = keyframes`
    0% {opacity: 1}
    50% {opacity: 0.5}
    100% {opacity: 1}
`

const Svg = styled.svg`
  animation: ${ghost} 1500ms infinite;
  &.disableAnim {
    animation: none;
  }
`

type Props = {
  loadingType?: 'chart' | 'chartBig' | 'donut'
  disableAnim?: boolean
}

export const ChartLoadingOverlay: React.FC<Props> = ({ loadingType, disableAnim }) => {
  const disableAnimClass = disableAnim ? 'disableAnim' : ''
  if (loadingType === 'donut') {
    return (
      <div style={{ padding: '150px 70px 70px 0', width: '100%', height: '100%' }}>
        <Svg className={disableAnimClass} width="100%" height="100%" viewBox="0 0 180 180">
          <path
            id="0"
            fill="rgb(59, 80, 87)"
            fillOpacity="0.11"
            d="M90, 0 A90,90 0 1 1 89.98429203681187,0.0000013707783779182137 L89.98778269529811,20.000001066160962 A70,70 0 1 0 90,20 Z"
          />
        </Svg>
      </div>
    )
  } else if (loadingType === 'chartBig') {
    return (
      <div style={{ padding: '0 50px 30px 50px', width: '100%', height: '100%' }}>
        <Svg
          className={disableAnimClass}
          width="100%"
          height="100%"
          viewBox="0 0 1000 500"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M 0,497.3558484007298 C 0,497.3558484007298 56,482.0640838145212 56,482.0640838145212 C 56,482.0640838145212 112,452.24494363896974 112,452.24494363896974 C 112,452.24494363896974 168,441.19072551048225 168,441.19072551048225 C 168,441.19072551048225 224,389.67422946219256 224,389.67422946219256 C 224,389.67422946219256 280,374.7163971697014 280,374.7163971697014 C 280,374.7163971697014 336,400.3635027665426 336,400.3635027665426 C 336,400.3635027665426 392,312.8587769150356 392,312.8587769150356 C 392,312.8587769150356 448,362.4010830969125 448,362.4010830969125 C 448,362.4010830969125 504,300.8983010195443 504,300.8983010195443 C 504,300.8983010195443 560,343.8437273267268 560,343.8437273267268 C 560,343.8437273267268 616,302.9494938506312 616,302.9494938506312 C 616,302.9494938506312 672,277.81337324360794 672,277.81337324360794 C 672,277.81337324360794 728,176.74711231710262 728,176.74711231710262 C 728,176.74711231710262 784,160.70874881034263 784,160.70874881034263 C 784,160.70874881034263 840,202.32178935888157 840,202.32178935888157 C 840,202.32178935888157 896,161.75379837570404 896,161.75379837570404 C 896,161.75379837570404 952,121.95267010950852 952,121.95267010950852 C 952,121.95267010950852 1008,225.45367255321736 1008,225.45367255321736,L 1000 500,L 0 500Z"
            fill="rgb(59, 80, 87)"
            fillOpacity="0.11"
          />
        </Svg>
      </div>
    )
  } else {
    return (
      <div style={{ padding: '0 50px 50px', width: '100%' }}>
        <Svg
          className={disableAnimClass}
          width="100%"
          height="80%"
          viewBox="0 0 1000 500"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M 0,497.3558484007298 C 0,497.3558484007298 56,482.0640838145212 56,482.0640838145212 C 56,482.0640838145212 112,452.24494363896974 112,452.24494363896974 C 112,452.24494363896974 168,441.19072551048225 168,441.19072551048225 C 168,441.19072551048225 224,389.67422946219256 224,389.67422946219256 C 224,389.67422946219256 280,374.7163971697014 280,374.7163971697014 C 280,374.7163971697014 336,400.3635027665426 336,400.3635027665426 C 336,400.3635027665426 392,312.8587769150356 392,312.8587769150356 C 392,312.8587769150356 448,362.4010830969125 448,362.4010830969125 C 448,362.4010830969125 504,300.8983010195443 504,300.8983010195443 C 504,300.8983010195443 560,343.8437273267268 560,343.8437273267268 C 560,343.8437273267268 616,302.9494938506312 616,302.9494938506312 C 616,302.9494938506312 672,277.81337324360794 672,277.81337324360794 C 672,277.81337324360794 728,176.74711231710262 728,176.74711231710262 C 728,176.74711231710262 784,160.70874881034263 784,160.70874881034263 C 784,160.70874881034263 840,202.32178935888157 840,202.32178935888157 C 840,202.32178935888157 896,161.75379837570404 896,161.75379837570404 C 896,161.75379837570404 952,121.95267010950852 952,121.95267010950852 C 952,121.95267010950852 1008,225.45367255321736 1008,225.45367255321736,L 1000 500,L 0 500Z"
            fill="rgb(59, 80, 87)"
            fillOpacity="0.11"
          />
        </Svg>
      </div>
    )
  }
}
