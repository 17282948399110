import { Box, Typography } from '@mui/material'
import ReactECharts from 'echarts-for-react'
import { useIntl } from 'react-intl'
import { ChartLoadingOverlay } from '../../shared/ChartLoadingOverlay'
import { ScoreChart } from '@proxyqb/gaia-api-client'
import { format } from 'date-fns'

interface Props {
  score?: ScoreChart[]
  loading: boolean
}

export const LevelChart: React.FC<Props> = ({ score, loading }) => {
  const { formatMessage } = useIntl()

  if (loading) {
    return (
      <Box margin={'60px 0 0 0'} width={'100%'} height={'500px'}>
        <ChartLoadingOverlay />
      </Box>
    )
  }
  if (!score || score?.length === 0) {
    return (
      <Box margin={'60px 0 0 0'} width={'100%'} height={'500px'} sx={{ position: 'relative' }}>
        <Typography
          variant="h6"
          sx={{ position: 'absolute', left: '50%', top: '35%', transform: 'translate(-50%, -50%)' }}
        >
          {formatMessage({ id: 'levelStatistic.noData' })}
        </Typography>
        <ChartLoadingOverlay disableAnim />
      </Box>
    )
  }
  const options = {
    grid: { top: 30, right: 50, bottom: 28, left: 40 },
    xAxis: {
      name: formatMessage({ id: 'levelStatistic.playthrough' }),
      data: score.map((data, index) => index + 1),
      nameLocation: 'middle',
    },
    yAxis: {
      name:
        score[0].gameType === 'StageCountLevelGoal'
          ? formatMessage({ id: 'levelStatistic.time' })
          : formatMessage({ id: 'levelStatistic.score' }),
      type: 'value',
      data: score.map((x) => x.score),
      axisLabel: {
        formatter: score[0].gameType === 'StageCountLevelGoal' ? '{value}s' : `{value}`,
      },
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      formatter: function (params) {
        if (score[0].gameType === 'StageCountLevelGoal') {
          return (
            `${formatMessage({ id: 'levelStatistic.time' })}:` +
            ` ${params[0].data}s` +
            '<br>' +
            `${formatMessage({ id: 'levelStatistic.date' })}:` +
            ` ${format(new Date(params[1].data), 'dd.MM.yyyy HH:mm')}` +
            '<br>' +
            `${formatMessage({ id: 'levelStatistic.nthPlaythrough' }, { count: params[0].axisValue })}`
          )
        } else {
          return (
            `${formatMessage({ id: 'levelStatistic.score' })}:` +
            ` ${params[0].data}` +
            '<br>' +
            `${formatMessage({ id: 'levelStatistic.date' })}:` +
            ` ${format(new Date(params[1].data), 'dd.MM.yyyy HH:mm')}` +
            '<br>' +
            `${formatMessage({ id: 'levelStatistic.nthPlaythrough' }, { count: params[0].axisValue })}`
          )
        }
      },
    },
    series: [
      {
        data: score.map((x) => x.score),
        type: 'line',
        symbol: 'dott',
      },
      {
        data: score.map((x) => x.date),
        type: 'line',
        symbol: 'dott',
      },
    ],
  }
  return (
    <Box margin={'60px 0 0 0'} width={'100%'} height={'500px'}>
      <ReactECharts option={options} notMerge={true} lazyUpdate={true} style={{ height: '450px' }} />
    </Box>
  )
}
