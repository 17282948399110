import { find, merge, shuffle } from 'lodash-es'
import { assign, createMachine, MachineConfig } from 'xstate'
import { CubePatterns, ImageWordGenerator, LetterPosition, StageResult } from '@proxyqb/graphql-api-types'
import { baseGeneratorMachineConfig, BaseGeneratorMachineContext } from './base-generator-machine-config'
import { WordAsset } from '@proxyqb/strapi-api'
import { groupSidesByPattern } from './group-sides-by-pattern'

export type ImageWordGeneratorMachineContext = BaseGeneratorMachineContext & {
  stageGenerator: ImageWordGenerator
  words: WordAsset
}

type WordSet =
  | {
      word: string | null
      image: string
      usedLetters: UsedLetter[]
    }
  | undefined

type UsedLetter = {
  id: string | null
  pattern: string
  index: number
  letter: string
}

type LettersSet = {
  id: string
  pattern: string
  set: string[]
}

const sideToLetterField = [
  ['f', 'b', 'c', 'd', 'e', 'a'],
  ['l', 'h', 'i', 'j', 'k', 'g'],
  ['r', 'n', 'o', 'p', 'q', 'm'],
  ['x', 't', 'u', 'v', 'w', 's'],
  ['6', '2', '3', '4', '5', '1'],
]

const patternPicker = (pattern: CubePatterns) => {
  switch (pattern) {
    case 'COLORFUL_LETTERS':
    case 'COLORFUL_LETTERS_ALT':
    case 'BLACK_AF':
      return 0
    case 'BLACK_GL':
      return 1
    // case 'BLACK_MR':
    //   return 2
    // case 'BLACK_SX':
    //   return 3
    //
    case 'COLORFUL_NUMBERS':
    case 'COLORFUL_NUMBERS_ALT':
    case 'BLACK_NUMBERS':
      return 4
  }
}

const sideToLetter = (pattern: CubePatterns, side: number) => {
  return sideToLetterField[patternPicker(pattern)][side - 1]
}

const letterToSide = (letter: string) => {
  switch (letter) {
    case 'a':
    case 'g':
    case 'm':
    case 's':
    case '1':
      return 6
    case 'b':
    case 'h':
    case 'n':
    case 't':
    case '2':
      return 2
    case 'c':
    case 'i':
    case 'o':
    case 'u':
    case '3':
      return 3
    case 'd':
    case 'j':
    case 'p':
    case 'v':
    case '4':
      return 4
    case 'e':
    case 'k':
    case 'q':
    case 'w':
    case '5':
      return 5
    case 'f':
    case 'l':
    case 'r':
    case 'x':
    case '6':
      return 1
  }
}

export const hideLetters = (letters: UsedLetter[], word: string): string => {
  const indexesToHide = letters.map(({ index }) => index)
  const newWord = word.split('')
  return newWord.map((letter, index) => (indexesToHide.includes(index) ? '_' : letter)).join('')
}

export const assignIndex = (word: string, letter: string, tmpIndex: number, usedLetters: UsedLetter[]) => {
  let index = word.indexOf(letter, tmpIndex)
  if (find(usedLetters, ['index', index])) {
    index = assignIndex(word, letter, index + 1, usedLetters)
  }
  return index
}

export const getWord = (
  fetchedWords: WordAsset[],
  stageHistory: any[],
  letterPosition: LetterPosition,
  letterSets: LettersSet[],
): WordSet => {
  //intro word loop
  let skipWord: boolean
  let word: string
  let usedLetters: UsedLetter[]
  let usedWords: string[] = stageHistory.map((item) => item.word)
  let words: WordAsset[] = fetchedWords.filter((word) => !usedWords.includes(word.word))

  //main loop
  for (let i = 0; i < words.length; i++) {
    skipWord = false
    word = ''
    usedLetters = []
    usedWords = stageHistory.map((item) => item.word)
    //look if not in history
    if (!(stageHistory.length === 0 || !usedWords.includes(words[i].word))) {
      continue
    }
    // if "start" first letter must be available, else random position everywhere
    word = words[i].word ?? ''
    let splitWord = Array.from(word ?? [])
    let tmpLetterSets = letterSets.map((set) => set)
    if (letterPosition === LetterPosition.Start) {
      for (let i = 0; i < tmpLetterSets.length; i++) {
        if (tmpLetterSets[i].set.includes(splitWord[0])) {
          usedLetters.push({
            id: tmpLetterSets[i].id,
            pattern: tmpLetterSets[i].pattern,
            letter: splitWord[0],
            index: 0,
          })
          tmpLetterSets = tmpLetterSets.filter((set) => set.id !== tmpLetterSets[i].id)
          splitWord.shift()
          break
        }
      }
      if (usedLetters.length === 1 && tmpLetterSets.length === 0) {
        const wordSet: WordSet = { word, image: words[i].image, usedLetters }
        return wordSet
      }
      if (usedLetters.length === 0) {
        skipWord = true
      }
    }
    if (skipWord) {
      continue
    }
    //check if word can be assembled from available cubes
    for (let i = 0; i < tmpLetterSets.length; i++) {
      const tmpIntersect = tmpLetterSets[i].set.filter((letter) => splitWord.includes(letter))
      if (tmpIntersect.length !== 0) {
        const letter = tmpIntersect[Math.floor(Math.random() * tmpIntersect.length)]
        const index = assignIndex(word, letter, 0, usedLetters)
        usedLetters.push({
          id: tmpLetterSets[i].id,
          pattern: tmpLetterSets[i].pattern,
          letter: letter,
          index: index,
        })
        splitWord.splice(splitWord.indexOf(letter), 1)
      }
    }
    if (usedLetters.length === letterSets.length) {
      const wordSet: WordSet = { word, image: words[i].image, usedLetters }
      return wordSet
    } else {
      skipWord = true
    }
    if (skipWord) {
      continue
    }
  }
}

const calculateNextSide = assign<ImageWordGeneratorMachineContext>((context) => {
  const stageHistory = context.stageHistory
  const words = context.words
  const levelLetterSets = context.cubes.map((cube) => {
    switch (cube.pattern) {
      case CubePatterns.BlackAf:
      case CubePatterns.ColorfulLetters:
      case CubePatterns.ColorfulLettersAlt: {
        return { id: cube.id, pattern: cube.pattern, set: ['a', 'b', 'c', 'd', 'e', 'f'] }
      }
      case CubePatterns.BlackGl: {
        return { id: cube.id, pattern: cube.pattern, set: ['g', 'h', 'i', 'j', 'k', 'l'] }
      }
    }
  })

  //reduce letter sets amount to amount of cubes played in stage
  const letterSets = shuffle(levelLetterSets)
  while (letterSets.length > context.level.stageGenerator.letterCount) {
    letterSets.pop()
  }

  const sides: Record<string, number | null> = {}
  context.cubes.forEach(({ id, getCubeCurrentSide }) => {
    sides[id] = getCubeCurrentSide()
  })
  const currentSidesByPattern = groupSidesByPattern(sides, context.cubes)

  //if succ then remove last succ letters
  letterSets.forEach((letterSet) => {
    const lastStage = stageHistory[stageHistory.length - 1]
    const tmps: typeof lastStage.stageGoals = []
    if (lastStage?.type === 'SUCCESS') {
      tmps.push(...lastStage.stageGoals.filter((cube) => cube.pattern === letterSet.pattern))
    }
    tmps.push(
      ...(currentSidesByPattern[letterSet.pattern]?.map((side) => ({
        side,
        pattern: letterSet.pattern,
        id: letterSet.id,
      })) || []),
    )

    if (tmps.length) {
      const tmpSides = tmps.map((tmp) => sideToLetter(tmp.pattern, tmp.side))
      letterSet.set = letterSet.set.filter((letter) => !tmpSides.includes(letter))
    }
  })

  let wordSet = getWord(words, stageHistory, context.level.stageGenerator.letterPosition, letterSets) ?? null
  if (wordSet === null) {
    console.error('Došly slova')
    return
  }

  //if two same cubes and same pattern, reroll assignment till not same as last

  if (
    context.level.stageGenerator.letterCount === 2 &&
    wordSet.usedLetters[0].pattern === wordSet.usedLetters[1].pattern &&
    stageHistory.length !== 0
  ) {
    const prevFirstSide = stageHistory[stageHistory.length - 1].stageGoals[0].side
    const prevSecondSide = stageHistory[stageHistory.length - 1].stageGoals[1].side
    let nowStageFirstSide = letterToSide(wordSet.usedLetters[0].letter)
    let nowStageSecondSide = letterToSide(wordSet.usedLetters[1].letter)
    let i = 0
    while (
      (prevFirstSide === nowStageFirstSide && prevSecondSide === nowStageSecondSide) ||
      (prevFirstSide === nowStageSecondSide && prevSecondSide === nowStageFirstSide)
    ) {
      wordSet =
        getWord(shuffle(words), stageHistory, context.level.stageGenerator.letterPosition, letterSets) ?? null
      nowStageFirstSide = letterToSide(wordSet.usedLetters[0].letter)
      nowStageSecondSide = letterToSide(wordSet.usedLetters[1].letter)
      i++
      if (i === 100) {
        break
      }
    }
  }

  const displayGoals = wordSet.usedLetters.map((cube) => ({
    side: null,
    pattern: cube.pattern,
    id: cube.id,
    word: wordSet.word,
    image: wordSet.image,
    hiddenWord: hideLetters(wordSet.usedLetters, wordSet.word ?? ''),
    usedLetters: wordSet.usedLetters,
  }))

  for (let i = 0; i < displayGoals.length; i++) {
    displayGoals[i].side = letterToSide(wordSet.usedLetters[i].letter)
  }

  // TODO: this is quick and dirty, use permutations instead

  if (
    context.level.stageGenerator.letterCount === 2 &&
    wordSet.usedLetters[0].pattern === wordSet.usedLetters[1].pattern
  ) {
    const compareGoals = [
      Object.fromEntries([
        [wordSet.usedLetters[0].id, displayGoals[0].side],
        [wordSet.usedLetters[1].id, displayGoals[1].side],
      ]),
      Object.fromEntries([
        [wordSet.usedLetters[0].id, displayGoals[1].side],
        [wordSet.usedLetters[1].id, displayGoals[0].side],
      ]),
    ]

    return {
      displayGoals,
      compareGoals,
    }
  }

  const compareGoals = Object.fromEntries(
    wordSet.usedLetters.map(({ id }, index) => [id, displayGoals[index].side]),
  )

  return {
    displayGoals,
    compareGoals: [compareGoals],
  }
})
const decrementCount = assign<ImageWordGeneratorMachineContext>({
  stagesCount: (context) => context.stagesCount - 1,
})

const stagesCountNotZero = (context, event, meta) => {
  const isNonEndingFailure = [
    StageResult.Disqualification,
    StageResult.Skiped,
    StageResult.WrongSolution,
  ].includes(meta?.state?.event?.result)
  return context.stagesCount !== 0 || isNonEndingFailure
}

const recordStage = assign<ImageWordGeneratorMachineContext>((context, event, meta) => {
  const stageHistory = context.stageHistory
  const displayGoals = context.displayGoals
  const stageArray = []

  for (const x of displayGoals) {
    const goal = { side: x.side, pattern: x.pattern, id: x.id }
    stageArray.push(goal)
  }

  const stage = {
    stageGoals: stageArray,
    result: meta?.state?.event?.result, // result exist on some levels only?
    type: meta?.state?.event?.type,
    word: displayGoals[0]?.word ?? 'empty',
  }

  return { stageHistory: [...stageHistory, stage] }
})

type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>
}

type ConfigType = MachineConfig<ImageWordGeneratorMachineContext, any, any, any>

const extension: DeepPartial<ConfigType> = {
  id: 'simpleStageGeneratorMachine',
}
const config = merge(extension, baseGeneratorMachineConfig) as ConfigType

export const imageWordGeneratorMachine = createMachine<ImageWordGeneratorMachineContext>(config, {
  actions: { recordStage, calculateNextSide, decrementCount },
  guards: {
    stagesCountNotZero,
  },
})
