import { forwardRef, PropsWithChildren, ReactNode, useEffect, useLayoutEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { Card } from '../atoms/Card'
import { ColorFunction } from '@proxyqb/ui'
import { palette, prop } from 'styled-tools'

const MENU_ITEM_WIDTH = 496

const getRotation = ({ index, selectedIndex }) => {
  if (index === selectedIndex) {
    return 0
  }
  return index > selectedIndex ? -40 : 40
}

const MenuItemCard = styled(Card).withConfig({
  shouldForwardProp: (prop) => !['color'].includes(prop),
})<any>`
  padding: 1rem;
  width: ${MENU_ITEM_WIDTH}px;
  height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  background: ${prop('color')};
`

const MenuContainer = styled.div`
  background: ${palette('background', 1)};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: auto;
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`

const MenuItemBox = styled.div<{ index: number; selectedIndex: number; ref: any }>`
  transform-style: preserve-3d;
  transform: perspective(1200px) rotateY(${getRotation}deg);
  position: relative;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
`

const LeftEdge = styled.div`
  left: 0;
  border-right-width: 5px;
  transform: translate3d(-1px, 0, -40px) rotateY(-90deg);
  transform-origin: 100%;

  background: grey;
  position: absolute;
  top: 0;
  width: 40px;
  height: 100%;
  backface-visibility: hidden;
`

const RightEdge = styled.div`
  right: 0;
  border-right-width: 5px;
  transform: translate3d(-1px, 0, -40px) rotateY(90deg);
  transform-origin: 100%;

  background: grey;
  position: absolute;
  top: 0;
  width: 40px;
  height: 100%;
  backface-visibility: hidden;
`

const MenuItemsHolder = styled.div`
  display: flex;

  ${MenuItemCard}:not (last-child) {
    margin-left: 1rem;
  }
`

const Expander = styled.div`
  width: 992px;
  flex-shrink: 0;
`

const MenuItem = forwardRef<
  HTMLDivElement,
  PropsWithChildren<{ index: number; selectedIndex: number; color?: string | ColorFunction }>
>(({ children, index, selectedIndex, color }, ref) => {
  return (
    <MenuItemBox ref={ref} index={index} selectedIndex={selectedIndex}>
      <MenuItemCard color={color}>{children}</MenuItemCard>
      <LeftEdge />
      <RightEdge />
    </MenuItemBox>
  )
})
MenuItem.displayName = 'MenuItem'

interface MenuProps {
  items: ReactNode[]
  color?: string | ColorFunction
}

export const Menu = ({ items = [], color }: MenuProps) => {
  const itemsHolderRef = useRef<HTMLInputElement>(null)
  const firstItemRef = useRef<HTMLDivElement>(null)

  const [selectedIndex, setSelectedIndex] = useState(0)

  useEffect(() => {
    firstItemRef?.current?.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'center' })
  }, [firstItemRef])

  useLayoutEffect(() => {
    const handleScroll = () => {
      if (itemsHolderRef?.current) {
        const scrollPosition =
          itemsHolderRef.current.scrollLeft + itemsHolderRef.current.clientWidth / 2 - MENU_ITEM_WIDTH / 3
        const newIndex = Math.floor((scrollPosition || 0) / MENU_ITEM_WIDTH) - 2
        if (newIndex !== selectedIndex) {
          setSelectedIndex(newIndex)
        }
      }
    }

    itemsHolderRef?.current?.addEventListener('scroll', handleScroll)

    return () => itemsHolderRef?.current?.removeEventListener('scroll', handleScroll)
  }, [itemsHolderRef, selectedIndex])
  useEffect(() => {
    const handleKeyUp = (e) => {
      const key = e.key
      if (key === 'ArrowRight' || key === 'ArrowLeft') {
        e.view.event.preventDefault()
      }
      setSelectedIndex((index) => {
        if (key === 'ArrowRight' && index < items.length - 1) {
          const newIndex = index + 1
          // @ts-ignore
          itemsHolderRef.current?.childNodes[0]?.childNodes[newIndex + 1]?.scrollIntoView({
            behavior: 'smooth',
            inline: 'center',
            block: 'center',
          })
          return newIndex
        } else if (key === 'ArrowLeft' && index > 0) {
          const newIndex = index - 1
          // @ts-ignore
          itemsHolderRef.current?.childNodes[0]?.childNodes[index]?.scrollIntoView({
            behavior: 'smooth',
            inline: 'center',
            block: 'center',
          })
          return newIndex
        } else {
          return index
        }
      })
    }
    document.addEventListener('keyup', handleKeyUp)
    return () => {
      document.removeEventListener('keyup', handleKeyUp)
    }
  }, [itemsHolderRef, items])
  return (
    <MenuContainer ref={itemsHolderRef}>
      <MenuItemsHolder>
        <Expander />
        {items.map((item, itemIndex) => (
          <MenuItem
            ref={itemIndex === 0 ? firstItemRef : null}
            key={itemIndex}
            index={itemIndex}
            selectedIndex={selectedIndex}
            color={color}
          >
            {item}
          </MenuItem>
        ))}
        <Expander />
      </MenuItemsHolder>
    </MenuContainer>
  )
}
