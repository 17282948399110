import { BaseGeneratorMachineContext } from './base-generator-machine-config'
import { groupBy, merge } from 'lodash-es'
import { DISPLAY2COMPARE_MAP, DISPLAY2COMPARE_MAP_NORMAL } from './switched-assets-stage-generator'
import { MatchCubesBy } from '@proxyqb/graphql-api-types'

export interface SimpleDisplayGoal {
  id: string
  side: number
  matchBy: MatchCubesBy
  pattern: string
}

const cartesian = (a: any[][]) => a.reduce((a, b) => a.flatMap((d) => b.map((e) => [d, e].flat())))
export const createCompareGoalsSwitched = (
  cubes: BaseGeneratorMachineContext['cubes'],
  displayGoals: SimpleDisplayGoal[],
): Record<string, number>[] => {
  const groups = Object.values(groupBy(displayGoals, 'pattern'))
  // for each group, generate separate solutions
  const groupSolutions = groups.map((group) => {
    const straightSolution = Object.fromEntries(
      group.map((cube) => {
        const displayGoal = displayGoals.find((goal) => goal.id === cube.id)
        const displayToCompareMap = displayGoal.pattern.includes('ALT')
          ? DISPLAY2COMPARE_MAP
          : DISPLAY2COMPARE_MAP_NORMAL
        return [cube.id, displayToCompareMap[displayGoal.matchBy][displayGoal.side]]
      }),
    )
    if (group.length === 1) {
      return [straightSolution]
    } else if (group.length === 2) {
      const [cube1, cube2] = group
      const displayGoal1 = displayGoals.find((goal) => goal.id === cube2.id)
      const displayGoal2 = displayGoals.find((goal) => goal.id === cube1.id)
      const switcheroo = Object.fromEntries([
        [cube1.id, DISPLAY2COMPARE_MAP[displayGoal1.matchBy][displayGoal1.side]],
        [cube2.id, DISPLAY2COMPARE_MAP[displayGoal2.matchBy][displayGoal2.side]],
      ])
      return [straightSolution, switcheroo]
    }
  })
  // In groupSolutions we have array of solutions for each pattern.
  // Now we need cartesian product to get all possible solutions.
  if (groupSolutions.length === 1) {
    return groupSolutions[0]
  } else {
    const cartesianProduct = cartesian(groupSolutions)
    // Now we just merge the arrays of pattern grouped solutions inside
    return cartesianProduct.map((groups) => merge({}, ...groups))
  }
}
