import { groupBy, merge } from 'lodash-es'
import { BaseGeneratorMachineContext } from './base-generator-machine-config'

export interface SimpleDisplayGoal {
  id: string
  side: number
}

const cartesian = (a: any[][]) => a.reduce((a, b) => a.flatMap((d) => b.map((e) => [d, e].flat())))
export const createCompareGoals = (
  cubes: BaseGeneratorMachineContext['cubes'],
  displayGoals: SimpleDisplayGoal[],
): Record<string, number>[] => {
  const groups = Object.values(groupBy(cubes, 'pattern'))
  // for each group, generate separate solutions
  const groupSolutions = groups.map((group) => {
    const straightSolution = Object.fromEntries(
      group.map((cube) => [cube.id, displayGoals.find((goal) => goal.id === cube.id)!.side]),
    )
    if (group.length === 1) {
      return [straightSolution]
    } else if (group.length === 2) {
      const [cube1, cube2] = group
      const switcheroo = Object.fromEntries([
        [cube1.id, displayGoals.find((goal) => goal.id === cube2.id)!.side],
        [cube2.id, displayGoals.find((goal) => goal.id === cube1.id)!.side],
      ])
      return [straightSolution, switcheroo]
    }
  })
  // In groupSolutions we have array of solutions for each pattern.
  // Now we need cartesian product to get all possible solutions.
  if (groupSolutions.length === 1) {
    return groupSolutions[0]
  } else {
    const cartesianProduct = cartesian(groupSolutions)
    // Now we just merge the arrays of pattern grouped solutions inside
    const merged = cartesianProduct.map((groups) => merge({}, ...groups))
    return merged
  }
}
