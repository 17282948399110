import { ImageWordSelect } from '@proxyqb/graphql-api-types'
import { useSpring, animated, useSpringRef } from 'react-spring'
import { Box, Typography } from '@mui/material'
import { GameEventType, useGameEvents } from './useGameEvents'
import { useEffect } from 'react'

interface Props {
  displayGoals
  imageWordSelect
  continueGame()
  horizontal: boolean
}

export const ImageWordGoalRenderer = ({ displayGoals, imageWordSelect, continueGame, horizontal }: Props) => {
  const springRef = useSpringRef()
  const styles = useSpring({
    transform: 'translateX(1200px)',
    ref: springRef,
  })

  const shouldShowImage =
    imageWordSelect === ImageWordSelect.ImageText ||
    imageWordSelect === ImageWordSelect.ImageSpeech ||
    imageWordSelect === ImageWordSelect.ImageTextSpeech

  const doStageEndAnimation = async () => {
    if (springRef.current[0].idle) {
      await springRef
        .start({
          delay: 400,
          from: { transform: 'translateX(0px)' },
          to: { transform: 'translateX(30px)' },
          config: { mass: 1, tension: 210, friction: 20 },
        })
        .pop()
      await springRef
        .start({
          from: { transform: 'translateX(30px)' },
          to: { transform: 'translateX(-1200px)' },
        })
        .pop()

      if (!shouldShowImage) {
        springRef.start({
          config: { mass: 1, tension: 300, friction: 26 },
          from: { transform: 'translateX(1200px)' },
          to: { transform: 'translateX(0px)' },
        })
      }
      continueGame()
    }
  }

  const { fireEvent } = useGameEvents([
    { type: GameEventType.STAGE_FAILURE, handler: doStageEndAnimation },
    { type: GameEventType.STAGE_SUCCESS, handler: doStageEndAnimation },
  ])

  useEffect(() => {
    if (shouldShowImage) {
      fireEvent({ type: GameEventType.IMAGE_GOAL_LOADING })
    } else {
      springRef.start({
        config: { mass: 1, tension: 300, friction: 26 },
        from: { transform: 'translateX(1200px)' },
        to: { transform: 'translateX(0px)' },
      })
      fireEvent({ type: GameEventType.IMAGE_GOAL_LOADED })
    }
  }, [displayGoals])

  return (
    <animated.div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: horizontal ? 'row' : 'column',
        gap: horizontal ? '60px' : '0',
        ...styles,
      }}
    >
      {shouldShowImage && (
        <Box
          onLoad={() => {
            fireEvent({ type: GameEventType.IMAGE_GOAL_LOADED })
            springRef.start({
              config: { mass: 1, tension: 300, friction: 26 },
              from: { transform: 'translateX(1200px)' },
              to: { transform: 'translateX(0px)' },
            })
          }}
          sx={{
            marginY: imageWordSelect === ImageWordSelect.ImageSpeech ? '20%' : '0px',
          }}
        >
          <img height={horizontal ? '200px' : '300px'} src={displayGoals ? displayGoals[0]?.image : ''} />
        </Box>
      )}
      {(imageWordSelect === ImageWordSelect.ImageText ||
        imageWordSelect === ImageWordSelect.TextOnly ||
        imageWordSelect === ImageWordSelect.ImageTextSpeech) && (
        <Typography
          variant={'h1'}
          sx={{
            marginY: imageWordSelect === ImageWordSelect.TextOnly ? '50%' : '25px',
            letterSpacing: '2px',
          }}
        >
          {displayGoals ? displayGoals[0]?.hiddenWord : ''}
        </Typography>
      )}
    </animated.div>
  )
}
