import { Box, Typography } from '@mui/material'
import { useIntl } from 'react-intl'
import { DataGrid } from '@proxyqb/ui'
import { format } from 'date-fns'
import { ScoreTable } from '@proxyqb/gaia-api-client'

interface Props {
  topScoreData?: ScoreTable[]
  lastScoreData?: ScoreTable[]
  loading: boolean
}

export const LevelTable: React.FC<Props> = ({ topScoreData, lastScoreData, loading }) => {
  const { formatMessage } = useIntl()

  const getRows = (rows) => {
    return rows.map((row) => ({
      place: row.place,
      date: format(new Date(row.date), 'dd.MM.yyyy HH:mm'),
      score: row.score,
    }))
  }

  const columns = [
    {
      headerName: formatMessage({ id: 'levelStatistic.place' }),
      field: 'place',
      width: 150,
      sortable: false,
    },
    { headerName: formatMessage({ id: 'levelStatistic.date' }), field: 'date', width: 500, sortable: false },
    {
      headerName: formatMessage({ id: 'levelStatistic.score' }),
      field: 'score',
      width: 125,
      sortable: false,
    },
  ]

  return (
    <Box flexDirection={'column'} width={'80%'}>
      <Typography variant="h6" margin={'40px 0 5px'}>
        {formatMessage({ id: 'levelStatistic.topScore' })}
      </Typography>
      <DataGrid
        columns={columns}
        rows={getRows(topScoreData)}
        getRowId={(row) => row.place}
        hideFooter
        autoHeight
        disableColumnFilter
        disableColumnMenu
        loading={loading}
        getRowClassName={(params) => `bestScorePlace${params.row.place}`}
        disableSelectionOnClick
      />
      <Typography variant="h6" margin={'40px 0 5px'}>
        {formatMessage({ id: 'levelStatistic.lastScore' })}
      </Typography>
      <DataGrid
        columns={columns}
        rows={getRows(lastScoreData)}
        getRowId={(row) => row.place}
        hideFooter
        autoHeight
        disableColumnFilter
        disableColumnMenu
        loading={loading}
        disableSelectionOnClick
      />
    </Box>
  )
}
