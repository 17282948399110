import { Suspense, useCallback } from 'react'
import { Quaternion as QuaternionType } from '@proxyqb/graphql-api-types'
import { GameCube } from '../3d/GameCube'
import { useCubes2, currentDeviceOrientationConjugated } from './useCubes'
import { Quaternion } from 'three'
import { CubeVersion } from '@proxyqb/ui'

interface Props {
  cubeId: string
  shuffleQuaternion?: QuaternionType | null
  scale?: number
  showWinningVectors?: boolean
  compareGoals?: Array<Quaternion | null>
  cubeVersion: CubeVersion
}

export function BluetoothCubeRenderer({
  cubeId,
  shuffleQuaternion,
  scale,
  compareGoals,
  showWinningVectors,
  cubeVersion,
}: Props) {
  const { cubes, quaternions } = useCubes2()

  const syncQuaternionConjugated = cubes[cubeId]?.syncQuaternionConjugated
  const sceneRef = useCallback(
    (ref) => {
      if (syncQuaternionConjugated) {
        if (shuffleQuaternion) {
          const { x, y, z, w } = shuffleQuaternion
          ref?.quaternion.set(x, y, z, w).conjugate()
        } else {
          ref?.quaternion.copy(syncQuaternionConjugated)
        }
      } else {
        console.log('No syncQuaternionConjugated for cube ' + cubeId)
      }
    },
    [syncQuaternionConjugated, shuffleQuaternion, cubeId],
  )

  const handleCubeFrameUpdate = useCallback(
    (ref) => {
      if (ref.current) {
        ref.current.quaternion.copy(currentDeviceOrientationConjugated).multiply(quaternions[cubeId])
      }
    },
    [cubeId], // eslint-disable-line react-hooks/exhaustive-deps
  )
  /*
  RENDER AXES

  const getLine = (endpoint, color) => {
    const points = [];
    points.push( new Vector3( 0, 0, 0 ))
    points.push( new Vector3( ...endpoint ))

    const material = new LineBasicMaterial({
      color: color
    });
    const geometry = new BufferGeometry().setFromPoints( points );
    const line = new Line( geometry, material );
    return line
  }


  useEffect(() => {
    sceneRef.current?.add(getLine([0, 0, 10], 'purple'))
    sceneRef.current?.add(getLine([0, -10, 0.001], 'yellow'))
    sceneRef.current?.add(getLine([10, 0, 0], 'brown'))
  }, [sceneRef.current])
 */

  // useSyncCubeColors(getTextureAssets(level.requiredCubes[0].pattern))
  return (
    <Suspense fallback={null}>
      <scene ref={sceneRef}>
        {cubes[cubeId] ? (
          <GameCube
            pattern={cubes[cubeId]!.pattern}
            onFrameUpdate={handleCubeFrameUpdate}
            scale={scale}
            compareGoals={compareGoals}
            showWinningVectors={showWinningVectors}
            cubeVersion={cubeVersion}
          />
        ) : null}
      </scene>
    </Suspense>
  )
}
