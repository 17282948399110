import { useEffect } from 'react'
import { usePrevious } from 'react-use'
import { useCubes2 } from './bluetooth/useCubes'
import { difference } from 'lodash-es'
import { useFlagsmith } from '@proxyqb/react-feature-flags'

export const standardColors = [
  { r: 255, g: 50, b: 0 }, // orange
  { r: 3, g: 255, b: 17 }, // green
  { r: 3, g: 45, b: 217 }, // Blue
  { r: 154, g: 13, b: 173 }, // pink
  { r: 255, g: 150, b: 10 }, // yellow
  { r: 248, g: 0, b: 0 }, // Red
]

export const standardColorsV3 = [
  { r: 0, g: 20, b: 0 }, // green
  { r: 45, g: 0, b: 0 }, // Red
  { r: 50, g: 15, b: 0 }, // orange
  { r: 0, g: 0, b: 55 }, // Blue
  { r: 35, g: 23, b: 0 }, // yellow
  { r: 40, g: 0, b: 48 }, // pink
]

interface UseLightUpCubes {
  lightsUp: (cubeIds: string[]) => void
  lightsDown: (cubeIds: string[]) => void
}

export function useLightUpCubes(cubeIds: string[]): UseLightUpCubes {
  const prevCubeIds = usePrevious(cubeIds)
  const { flags } = useFlagsmith()
  const { setStandardLEDColors, setCubeLedColor } = useCubes2()

  useEffect(() => {
    const on = difference(cubeIds, prevCubeIds ?? [])
    const off = difference(prevCubeIds, cubeIds)
    if (flags.led_color_cubes?.enabled) {
      for (const cubeId of on) {
        setStandardLEDColors(cubeId)
      }
    } else {
      for (const cubeId of on) {
        setCubeLedColor(cubeId)({ r: 0, g: 0, b: 0 })
      }
    }
    for (const cubeId of off) {
      setCubeLedColor(cubeId)({ r: 0, g: 0, b: 0 })
    }
  }, [cubeIds])

  const lightsUp = (cubeIds: string[]): void => {
    if (flags.led_color_cubes?.enabled) {
      for (const cubeId of cubeIds) {
        setStandardLEDColors(cubeId)
      }
    }
  }

  const lightsDown = (cubeIds: string[]): void => {
    if (flags.led_color_cubes?.enabled) {
      for (const cubeId of cubeIds) {
        setCubeLedColor(cubeId)({ r: 0, g: 0, b: 0 })
      }
    }
  }

  return {
    lightsUp,
    lightsDown,
  }
}
