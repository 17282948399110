/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import { Score, ScoreFromJSON, ScoreFromJSONTyped, ScoreToJSON } from './Score'

/**
 *
 * @export
 * @interface PlansLevelsStatistic
 */
export interface PlansLevelsStatistic {
  /**
   *
   * @type {string}
   * @memberof PlansLevelsStatistic
   */
  levelId: string
  /**
   *
   * @type {string}
   * @memberof PlansLevelsStatistic
   */
  levelName: string
  /**
   *
   * @type {string}
   * @memberof PlansLevelsStatistic
   */
  gameType: string
  /**
   *
   * @type {Array<Score>}
   * @memberof PlansLevelsStatistic
   */
  score: Array<Score>
}

export function PlansLevelsStatisticFromJSON(json: any): PlansLevelsStatistic {
  return PlansLevelsStatisticFromJSONTyped(json, false)
}

export function PlansLevelsStatisticFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PlansLevelsStatistic {
  if (json === undefined || json === null) {
    return json
  }
  return {
    levelId: json['levelId'],
    levelName: json['levelName'],
    gameType: json['gameType'],
    score: (json['score'] as Array<any>).map(ScoreFromJSON),
  }
}

export function PlansLevelsStatisticToJSON(value?: PlansLevelsStatistic | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    levelId: value.levelId,
    levelName: value.levelName,
    gameType: value.gameType,
    score: (value.score as Array<any>).map(ScoreToJSON),
  }
}
