/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import { GameType, GameTypeFromJSON, GameTypeFromJSONTyped, GameTypeToJSON } from './GameType'
import { Level, LevelFromJSON, LevelFromJSONTyped, LevelToJSON } from './Level'
import { User, UserFromJSON, UserFromJSONTyped, UserToJSON } from './User'

/**
 *
 * @export
 * @interface Playthrough
 */
export interface Playthrough {
  /**
   *
   * @type {string}
   * @memberof Playthrough
   */
  id: string
  /**
   *
   * @type {User}
   * @memberof Playthrough
   */
  user: User
  /**
   *
   * @type {Level}
   * @memberof Playthrough
   */
  level: Level
  /**
   *
   * @type {string}
   * @memberof Playthrough
   */
  endedAt: string
  /**
   *
   * @type {number}
   * @memberof Playthrough
   */
  score: number
  /**
   *
   * @type {string}
   * @memberof Playthrough
   */
  planId?: string
  /**
   *
   * @type {any}
   * @memberof Playthrough
   */
  plan?: any | null
  /**
   *
   * @type {GameType}
   * @memberof Playthrough
   */
  gameType: GameType
}

export function PlaythroughFromJSON(json: any): Playthrough {
  return PlaythroughFromJSONTyped(json, false)
}

export function PlaythroughFromJSONTyped(json: any, ignoreDiscriminator: boolean): Playthrough {
  if (json === undefined || json === null) {
    return json
  }
  return {
    id: json['id'],
    user: UserFromJSON(json['user']),
    level: LevelFromJSON(json['level']),
    endedAt: json['endedAt'],
    score: json['score'],
    planId: !exists(json, 'planId') ? undefined : json['planId'],
    plan: !exists(json, 'plan') ? undefined : json['plan'],
    gameType: GameTypeFromJSON(json['gameType']),
  }
}

export function PlaythroughToJSON(value?: Playthrough | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    id: value.id,
    user: UserToJSON(value.user),
    level: LevelToJSON(value.level),
    endedAt: value.endedAt,
    score: value.score,
    planId: value.planId,
    plan: value.plan,
    gameType: GameTypeToJSON(value.gameType),
  }
}
