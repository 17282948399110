/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface PercentageCloseness
 */
export interface PercentageCloseness {
  /**
   *
   * @type {string}
   * @memberof PercentageCloseness
   */
  cubeId: string
  /**
   *
   * @type {Array<number>}
   * @memberof PercentageCloseness
   */
  values: Array<number>
}

export function PercentageClosenessFromJSON(json: any): PercentageCloseness {
  return PercentageClosenessFromJSONTyped(json, false)
}

export function PercentageClosenessFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): PercentageCloseness {
  if (json === undefined || json === null) {
    return json
  }
  return {
    cubeId: json['cubeId'],
    values: json['values'],
  }
}

export function PercentageClosenessToJSON(value?: PercentageCloseness | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    cubeId: value.cubeId,
    values: value.values,
  }
}
