/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import { LevelRun, LevelRunFromJSON, LevelRunFromJSONTyped, LevelRunToJSON } from './LevelRun'

/**
 *
 * @export
 * @interface LevelRunsChartData
 */
export interface LevelRunsChartData {
  /**
   *
   * @type {Array<LevelRun>}
   * @memberof LevelRunsChartData
   */
  userNthRuns: Array<LevelRun>
  /**
   *
   * @type {Array<number>}
   * @memberof LevelRunsChartData
   */
  userAverageRun: Array<number>
  /**
   *
   * @type {Array<Array<number>>}
   * @memberof LevelRunsChartData
   */
  globalAverageNthRuns: Array<Array<number>>
  /**
   *
   * @type {Array<number>}
   * @memberof LevelRunsChartData
   */
  globalAverageRun: Array<number>
}

export function LevelRunsChartDataFromJSON(json: any): LevelRunsChartData {
  return LevelRunsChartDataFromJSONTyped(json, false)
}

export function LevelRunsChartDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): LevelRunsChartData {
  if (json === undefined || json === null) {
    return json
  }
  return {
    userNthRuns: (json['userNthRuns'] as Array<any>).map(LevelRunFromJSON),
    userAverageRun: json['userAverageRun'],
    globalAverageNthRuns: json['globalAverageNthRuns'],
    globalAverageRun: json['globalAverageRun'],
  }
}

export function LevelRunsChartDataToJSON(value?: LevelRunsChartData | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    userNthRuns: (value.userNthRuns as Array<any>).map(LevelRunToJSON),
    userAverageRun: value.userAverageRun,
    globalAverageNthRuns: value.globalAverageNthRuns,
    globalAverageRun: value.globalAverageRun,
  }
}
