import { createContext, useState } from 'react'
import { LevelItemFragment } from './levels.generated'

interface LevelsState {
  levels: LevelItemFragment[]
  loading: boolean
}
interface LevelsActions {
  fetchLevels(): Promise<void>
}
export type LevelContextValue = LevelsState & LevelsActions

// @ts-ignore
export const LevelsContext = createContext<LevelContextValue>(null)

interface SelectedLevelState {
  level?: LevelItemFragment
}
interface SelectedLevelActions {
  selectLevel(level?: LevelItemFragment)
}
export type SelectedLevelValue = SelectedLevelState & SelectedLevelActions

// @ts-ignore
export const SelectedLevelContext = createContext<SelectedLevelValue>(null)

export const SelectedLevelProvider = ({ children }) => {
  const [state, setState] = useState<SelectedLevelState>({
    level: undefined,
  })
  function selectLevel(level?: LevelItemFragment) {
    setState((state) => ({ ...state, level }))
  }

  return (
    <SelectedLevelContext.Provider
      value={{
        selectLevel,
        ...state,
      }}
    >
      {children}
    </SelectedLevelContext.Provider>
  )
}
