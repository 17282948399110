import { createContext, useState } from 'react'

interface GamePauseState {
  pause: boolean
}
interface GamePauseActions {
  setPause(pause): void
}
export type GamePauseContextValue = GamePauseState & GamePauseActions

// @ts-ignore
export const GamePauseContext = createContext<GamePauseContextValue>(null)

export const GamePauseProvider = ({ children }) => {
  const [pause, setPause] = useState(false)

  return (
    <GamePauseContext.Provider
      value={{
        pause,
        setPause,
      }}
    >
      {children}
    </GamePauseContext.Provider>
  )
}
