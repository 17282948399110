import { CubePatterns } from '@proxyqb/graphql-api-types'
import { FeatureFlags } from '@proxyqb/react-feature-flags'
import { env } from '../../env'

const prefixUrl = (name) => `${env.assetDeliveryUrl}/assets/transform/${name}.webp?replaceBG=false`

const texturesWithBlack: Record<CubePatterns, string[]> = {
  [CubePatterns.ColorfulNumbers]: [
    'mix-1-modra',
    'mix-6-zluta',
    'mix-2-oranzova',
    'mix-5-cerna',
    'mix-3-ruzova',
    'mix-4-zelena',
  ].map(prefixUrl),
  [CubePatterns.ColorfulNumbersAlt]: [
    'mix-alt-1-ruzova',
    'mix-alt-6-oranzova',
    'mix-alt-2-zelena',
    'mix-alt-5-modra',
    'mix-alt-3-cerna',
    'mix-alt-4-zluta',
  ].map(prefixUrl),
  [CubePatterns.ColorfulLetters]: [
    'mix-a-modra',
    'mix-f-zluta',
    'mix-b-oranzova',
    'mix-e-cerna',
    'mix-c-ruzova',
    'mix-d-zelena',
  ].map(prefixUrl),
  [CubePatterns.ColorfulLettersAlt]: [
    'mix-alt-a-ruzova',
    'mix-alt-f-oranzova',
    'mix-alt-b-zelena',
    'mix-alt-e-modra',
    'mix-alt-c-cerna',
    'mix-alt-d-zluta',
  ].map(prefixUrl),
  [CubePatterns.Colors]: [
    'kostka-modra',
    'kostka-zluta',
    'kostka-oranzova',
    'kostka-cerna',
    'kostka-ruzova',
    'kostka-zelena',
  ].map(prefixUrl),
  [CubePatterns.BlackAf]: ['a', 'f', 'b', 'e', 'c', 'd'].map(prefixUrl),
  [CubePatterns.BlackGl]: ['g', 'l', 'h', 'k', 'i', 'j'].map(prefixUrl),
  [CubePatterns.BlackNumbers]: ['1', '6', '2', '5', '3', '4'].map(prefixUrl),
}

const texturesWithRed: Record<CubePatterns, string[]> = {
  [CubePatterns.ColorfulNumbers]: [
    'mix-1-modra',
    'mix-6-zluta',
    'mix-2-oranzova',
    'mix-5-cervena',
    'mix-3-ruzova',
    'mix-4-zelena',
  ].map(prefixUrl),
  [CubePatterns.ColorfulNumbersAlt]: [
    'mix-alt-1-ruzova',
    'mix-alt-6-oranzova',
    'mix-alt-2-zelena',
    'mix-alt-5-modra',
    'mix-alt-3-cervena',
    'mix-alt-4-zluta',
  ].map(prefixUrl),
  [CubePatterns.ColorfulLetters]: [
    'mix-a-modra',
    'mix-f-zluta',
    'mix-b-oranzova',
    'mix-e-cervena',
    'mix-c-ruzova',
    'mix-d-zelena',
  ].map(prefixUrl),
  [CubePatterns.ColorfulLettersAlt]: [
    'mix-alt-a-ruzova',
    'mix-alt-f-oranzova',
    'mix-alt-b-zelena',
    'mix-alt-e-modra',
    'mix-alt-c-cervena',
    'mix-alt-d-zluta',
  ].map(prefixUrl),
  [CubePatterns.Colors]: [
    'kostka-modra',
    'kostka-zluta',
    'kostka-oranzova',
    'kostka-cervena',
    'kostka-ruzova',
    'kostka-zelena',
  ].map(prefixUrl),
  [CubePatterns.BlackAf]: ['a', 'f', 'b', 'e', 'c', 'd'].map(prefixUrl),
  [CubePatterns.BlackGl]: ['g', 'l', 'h', 'k', 'i', 'j'].map(prefixUrl),
  [CubePatterns.BlackNumbers]: ['1', '6', '2', '5', '3', '4'].map(prefixUrl),
}

export function getTextureAssets(pattern: CubePatterns, flags: FeatureFlags): string[] {
  try {
    return flags.led_color_cubes?.enabled ? texturesWithRed[pattern] : texturesWithBlack[pattern]
  } catch (error) {
    throw new Error('Unrecognized pattern')
  }
}

export function resolveTexture(pattern: CubePatterns, flags: FeatureFlags): CubeTexturesData {
  return {
    textures: getTextureAssets(pattern, flags),
    pattern: CubePatterns.ColorfulLetters,
  }
}

export type CubeTexturesData = {
  textures: string[]
  pattern: CubePatterns
}
