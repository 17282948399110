import { animated, config, useSpring } from 'react-spring'
import { Box, Skeleton, Typography, useTheme } from '@mui/material'
import { Flex } from '@proxyqb/ui'
import React, { useEffect, useState } from 'react'

interface Props {
  index: number
  loading: boolean
  name: string
  allLevels
  levelId: string
  nextLevelId?: string
}

const isLevelCompleted = (levelsInPlan, levelId, nextLevelId): boolean => {
  if (!nextLevelId) {
    return false
  } else {
    const planLevelIds = levelsInPlan.map((level) => level.id)
    return planLevelIds.indexOf(levelId) < planLevelIds.indexOf(nextLevelId)
  }
}
export const RehabPlanLevelItem = ({ index, loading, name, allLevels, levelId, nextLevelId }: Props) => {
  const theme = useTheme()
  const style = useSpring({
    delay: index * 20,
    from: { opacity: 0, transform: 'translateY(30px)' },
    to: { opacity: 1, transform: 'translateY(0)' },
    config: config.gentle,
    position: 'relative',
  })

  const [isCompleted, setIsCompleted] = useState<boolean>()
  const [isNextLevel, setIsNextLevel] = useState<boolean>()

  useEffect(() => {
    setIsCompleted(isLevelCompleted(allLevels, levelId, nextLevelId))
    setIsNextLevel(levelId === nextLevelId)
  }, [allLevels, levelId, nextLevelId])

  const getMarkerUrl = () => {
    if (isCompleted) {
      return 'circleDark'
    } else if (isNextLevel) {
      return 'circleMain'
    } else {
      return 'circleGrey'
    }
  }

  const getSvgPath = (): JSX.Element => {
    const color = isCompleted || isNextLevel ? 'black' : theme.palette.grey.A400
    const markerUrl = getMarkerUrl()
    if (isNextLevel) {
      return (
        <>
          {index + 1 !== allLevels.length && (
            <path
              d={'M86,15 210,15'}
              strokeWidth={10}
              stroke={theme.palette.grey.A400}
              markerStart={'url(#circleWhite)'}
            />
          )}

          <path
            d={`M${index === 0 ? 80 : 0},15 90,15`}
            strokeWidth={10}
            stroke={color}
            markerEnd={`url(#${markerUrl})`}
          />
        </>
      )
    } else if (index === 0) {
      return <path d={'M80,15 190,15'} strokeWidth={10} stroke={color} markerStart={`url(#${markerUrl})`} />
    } else if (index + 1 === allLevels.length) {
      return <path d={'M0,15 80,15'} strokeWidth={10} stroke={color} markerEnd={`url(#${markerUrl})`} />
    } else {
      return (
        <path d={'M0,15 80,15 190,15'} strokeWidth={10} stroke={color} markerMid={`url(#${markerUrl})`} />
      )
    }
  }

  const getOpacity = (): number => {
    if (isCompleted || isNextLevel) {
      return 1
    } else return 0.4
  }

  return (
    <animated.div style={style}>
      {loading ? (
        <Skeleton
          key={index}
          variant="rectangular"
          height="160px"
          width="160px"
          sx={{ borderRadius: '10px' }}
        />
      ) : (
        <Flex height={isNextLevel ? '180px' : '160px'} width={isNextLevel ? '180px' : '160px'}>
          <Box
            display="flex"
            sx={{
              border: '2px solid grey',
              borderColor: 'primary.main',
              fontSize: 25,
              backgroundColor: theme.palette.primary.light,
              opacity: getOpacity(),
            }}
            borderRadius="10px"
            width="100%"
            height="100%"
            alignItems="center"
            justifyContent="center"
            padding="8px"
          >
            <Typography
              sx={{
                color: 'black',
                textAlign: 'center',
                fontWeight: 500,
                fontSize: '1.5rem',
                overflowWrap: 'break-word',
                width: '100%',
              }}
            >
              {name}
            </Typography>
          </Box>
        </Flex>
      )}
      <svg width="200%" height="100%" style={{ position: 'absolute', marginTop: isNextLevel ? 10 : 20 }}>
        <defs>
          <marker id="circleDark" viewBox="0 0 10 10" refX="5" refY="5" markerWidth="3.5" markerHeight="3.5">
            <circle cx="5" cy="5" r="5" fill={'white'} />
            <circle cx="5" cy="5" r="3" fill={theme.palette.primary.dark} />
          </marker>
          <marker id="circleMain" viewBox="0 0 10 10" refX="5" refY="5" markerWidth="3.5" markerHeight="3.5">
            <circle cx="5" cy="5" r="5" fill={'white'} />
            <circle cx="5" cy="5" r="3.5" fill={theme.palette.secondary.light} />
          </marker>
          <marker id="circleGrey" viewBox="0 0 10 10" refX="5" refY="5" markerWidth="3.5" markerHeight="3.5">
            <circle cx="5" cy="5" r="5" fill={'white'} />
            <circle cx="5" cy="5" r="3" fill={theme.palette.grey.A400} />
          </marker>
        </defs>
        {getSvgPath()}
      </svg>
    </animated.div>
  )
}
