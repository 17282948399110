import { Box, Skeleton, Typography, useTheme } from '@mui/material'
import { Flex } from '@proxyqb/ui'
import { animated, useSpring, config, useChain, useSpringRef } from 'react-spring'
import { truncate } from 'lodash'
import { RehabilitationPlanItemFragment } from './rehabilitation-plan.generated'
import { useCallback } from 'react'

interface Props {
  plan: RehabilitationPlanItemFragment | null
  index: number
  progress: number
  loading: boolean
  onClick: (level: RehabilitationPlanItemFragment) => void
}

export const RehabilitationPlanItem = ({ plan, index, loading, progress, onClick }: Props) => {
  const squareSVGPath = useCallback(() => {
    switch (true) {
      case progress <= 0.125:
        return `M84,5 ${80 + 640 * progress + 4},5`
      case progress <= 0.25:
        return `M84,5 164,5 164,${(80 - 640 * progress) * -1 + 5}`
      case progress <= 0.375:
        return `M84,5 164,5 164,85 164,${(80 - 640 * progress) * -1 + 5}`
      case progress <= 0.5:
        return `M84,5 164,5 164,85 164,164 ${400 - 640 * progress + 4},164`
      case progress <= 0.625:
        return `M84,5 164,5 164,164 84,164 ${400 - 640 * progress + 4},164`
      case progress <= 0.75:
        return `M84,5 164,5 164,85 164,164 84,164 5,164 5,${560 - 640 * progress + 5}`
      case progress <= 0.875:
        return `M84,5 164,5 164,164 5,164 5,85 5,${560 - 640 * progress + 5}`
      case progress <= 1:
        return `M84,5 164,5 164,164 84,164 5,164 5,5 ${(560 - 640 * progress) * -1 + 5},5`
      default:
        throw new Error('Wrong input')
    }
  }, [progress])

  const style = useSpring({
    delay: index * 20,
    from: { opacity: 0, transform: 'translateY(30px)' },
    to: { opacity: 1, transform: 'translateY(0)' },
    config: config.gentle,
  })

  const pathRef = useSpringRef()
  const pathStyle = useSpring({
    ref: pathRef,
    from: { strokeDasharray: 644 * progress, strokeDashoffset: 644 * progress },
    to: { strokeDasharray: 644 * progress, strokeDashoffset: 0 },
    config: config.slow,
  })
  const markerRef = useSpringRef()
  const markerStyle = useSpring({
    ref: markerRef,
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: config.slow,
  })

  useChain([pathRef, markerRef], [0, 1], 450)

  const theme = useTheme()
  const editPlanName = (planName: string) => {
    const editedName = planName.length > 24 ? truncate(planName, { length: 21 }) : planName
    return editedName
  }

  return (
    <animated.div style={style}>
      {!loading && plan ? (
        <Flex key={plan.id} onClick={() => onClick(plan)} height="160px" width="160px" cursor="pointer">
          <Box
            display="flex"
            sx={{
              border: '1px solid',
              borderColor: 'grey',
              fontSize: 25,
              flexWrap: 'wrap',
            }}
            width="100%"
            height="100%"
            alignItems="center"
            justifyContent="center"
            padding="8px"
          >
            <Box sx={{ width: '161px', height: '161px', position: 'absolute' }}>
              {progress !== 0 && (
                <svg
                  width="105%"
                  height="105%"
                  style={{ top: '50%', left: '50%', transform: 'translate(-50%,-50%)', position: 'absolute' }}
                >
                  <defs>
                    <marker
                      id="triangle"
                      viewBox="0 0 10 10"
                      refX="1"
                      refY="5"
                      markerUnits="strokeWidth"
                      markerWidth="3"
                      markerHeight="3"
                      orient="auto"
                    >
                      <animated.path
                        style={markerStyle}
                        d="M 0 0 L 10 5 L 0 10 z"
                        fill={theme.palette.primary.main}
                      />
                    </marker>
                  </defs>
                  <g>
                    <animated.path
                      style={pathStyle}
                      d={squareSVGPath()}
                      fill="none"
                      markerEnd={'url(#triangle)'}
                      strokeWidth={3}
                      stroke={theme.palette.primary.main}
                    />
                  </g>
                </svg>
              )}
            </Box>
            <Typography
              sx={{
                color: 'black',
                textAlign: 'center',
                fontWeight: 500,
                fontSize: '1.5rem',
                overflowWrap: 'break-word',
                width: '100%',
              }}
            >
              {editPlanName(plan.name.local)}
            </Typography>
          </Box>
        </Flex>
      ) : (
        <Skeleton
          key={index}
          variant="rectangular"
          height="160px"
          width="160px"
          sx={{ borderRadius: '10px' }}
        />
      )}
    </animated.div>
  )
}
