import { Quaternion } from 'three'

interface MovementDetectionOptions {
  /** Angle in radians */
  movementTrigger: number
  timeToPutThemBack: number
  millisecondsToConfirmNoMovement: number
}

const defaultOptions = {
  movementTrigger: Math.PI / 36,
  timeToPutThemBack: 3000,
  millisecondsToConfirmNoMovement: 2000,
}

export class MovementDetector {
  static movement = {}
  static running = false
  static readonly cubes: Record<string, Quaternion> = {}
  static readonly timeWhenCubeStoppedMoving: Record<string, number> = {}
  static lastCheck: number = Date.now()

  static options: MovementDetectionOptions = defaultOptions
  static movementStartedCallbacks = []
  static movementStoppedCallbacks = []
  static instances: MovementDetector[] = []

  #cubeIds
  #onMovementStartedCallback
  #onMovementStoppedCallback

  movementStartedCallback(cubeId) {
    if (!this.#cubeIds || this.#cubeIds.includes(cubeId)) {
      this.#onMovementStartedCallback?.()
    }
  }

  movementStoppedCallback(cubeId) {
    if (!this.#cubeIds || this.#cubeIds.includes(cubeId)) {
      this.#onMovementStoppedCallback?.()
    }
  }

  pushQuaternion(cubeId: string, q: Quaternion) {
    if (!MovementDetector.cubes[cubeId]) {
      MovementDetector.cubes[cubeId] = q
    }
    const diff = MovementDetector.cubes[cubeId].angleTo(q)
    MovementDetector.cubes[cubeId] = q
    if (
      MovementDetector.lastCheck + MovementDetector.options.timeToPutThemBack < Date.now() &&
      MovementDetector.running
    ) {
      if (diff >= MovementDetector.options.movementTrigger) {
        MovementDetector.lastCheck = Date.now()
        MovementDetector.timeWhenCubeStoppedMoving[cubeId] = Date.now()
        if (!MovementDetector.movement[cubeId]) {
          if (!Object.values(MovementDetector.movement).includes(true)) {
            MovementDetector.instances.forEach((it) => it.movementStartedCallback(cubeId))
          }
          MovementDetector.movement[cubeId] = true
        }
      } else {
        if (MovementDetector.timeWhenCubeStoppedMoving[cubeId]) {
          if (MovementDetector.movement[cubeId]) {
            const totalTimeThatCubeIsNotMoving =
              Date.now() - MovementDetector.timeWhenCubeStoppedMoving[cubeId]
            if (totalTimeThatCubeIsNotMoving > MovementDetector.options.millisecondsToConfirmNoMovement) {
              MovementDetector.movement[cubeId] = false
              if (!Object.values(MovementDetector.movement).includes(true)) {
                MovementDetector.instances.forEach((it) => it.movementStoppedCallback(cubeId))
              }
            }
          }
        }
      }
    }
  }

  getIsSomeMoving() {
    return Object.entries(MovementDetector.movement).some(
      ([id, isMoving]) => (!this.#cubeIds || this.#cubeIds.includes(id)) && isMoving,
    )
  }

  onMovementStopped(callback) {
    this.#onMovementStoppedCallback = callback
  }

  onMovementStarted(callback) {
    this.#onMovementStartedCallback = callback
  }

  startMovementDetection({
    cubeIds,
    movementDetectionOptions = {},
  }: {
    cubeIds?: string[]
    movementDetectionOptions?: Partial<MovementDetectionOptions>
  }) {
    this.#cubeIds = cubeIds
    MovementDetector.instances.push(this)
    Object.assign(MovementDetector.options, defaultOptions, movementDetectionOptions)
    MovementDetector.running = true
  }

  stopMovementDetection() {
    delete MovementDetector.instances[MovementDetector.instances.indexOf(this)]
    MovementDetector.running = false
    MovementDetector.movement = {}
  }

  resetMovementDetection() {
    MovementDetector.movement = {}
  }

  setMovementDetectionOptions(movementDetectionOptions: Partial<MovementDetectionOptions> = {}) {
    Object.assign(MovementDetector.options, defaultOptions, movementDetectionOptions)
  }
}
