import { Box, Dialog, Grid, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { CubeData } from '../../../game/bluetooth/useCubes'
import { useEffect } from 'react'
import { useTheme } from '@mui/styles'
import { enqueueBluetoothCommand } from '../../../game/bluetooth/bluetooth-command-queue'
import { useBattery } from 'react-use'
import TabletAndroidIcon from '@mui/icons-material/TabletAndroid'

const ColoredCube = ({ color }) => {
  return (
    <Box
      sx={{
        width: '150px',
        height: '150px',
        borderRadius: 5,
        backgroundColor: color,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          width: '60%',
          height: '60%',
          borderRadius: 25,
          backgroundColor: 'white',
        }}
      />
    </Box>
  )
}

enum BatteryIntervals {
  HIGH_PERCENTAGE = 77.5,
  MEDIUM_PERCENTAGE = 55,
  LOW_PERCENTAGE = 32.5,
  ALERT_PERCENTAGE = 10,
}

export const getBatteryInterval = (
  battery: number | undefined,
  backgroundColor: string | undefined = undefined,
) => {
  let batteryState: string | undefined = undefined
  if (battery) {
    if (battery > BatteryIntervals.HIGH_PERCENTAGE) {
      batteryState = 'battery4-4'
    }
    if (battery <= BatteryIntervals.HIGH_PERCENTAGE && battery > BatteryIntervals.MEDIUM_PERCENTAGE) {
      batteryState = 'battery3-4'
    }
    if (battery <= BatteryIntervals.MEDIUM_PERCENTAGE && battery > BatteryIntervals.LOW_PERCENTAGE) {
      batteryState = 'battery2-4'
    }
    if (battery <= BatteryIntervals.LOW_PERCENTAGE && battery > BatteryIntervals.ALERT_PERCENTAGE) {
      batteryState = 'battery1-4'
    }
    if (battery <= BatteryIntervals.ALERT_PERCENTAGE) {
      batteryState = 'battery-alert'
    }
  }
  if (batteryState && (backgroundColor === '#000000' || backgroundColor === '#461683')) {
    batteryState = batteryState.concat(' - white')
  }
  return batteryState ?? 'undefined'
}

interface BatteryDialogProps {
  onClose: () => void
  cubes: CubeData[]
}

export const BatteryDialog = ({ onClose, cubes }: BatteryDialogProps) => {
  const theme = useTheme()
  const battery = useBattery()

  const colorsPaletteForCubeImages = Object.entries(theme.palette.categoryColors).map((entry) => {
    //Psychomotorics main color can't be recognized from the other green
    if (entry[0] === 'psychomotorics') {
      return '#ed3a2d'
    }
    return entry[1].main
  })

  const colorsPaletteForCubes = [
    { r: 199, g: 243, b: 4 },
    { r: 3, g: 255, b: 17 },
    { r: 3, g: 45, b: 217 },
    { r: 137, g: 54, b: 2 },
    { r: 154, g: 13, b: 173 },
    { r: 248, g: 0, b: 0 },
  ]

  const turnOffCubeLights = () => {
    for (const cube of cubes) {
      if (cube.batteryState !== 0) {
        cube.setColor({
          r: 0,
          g: 0,
          b: 0,
        })
      }
    }
  }

  useEffect(() => {
    cubes.forEach((cube, index) => {
      if (cube.batteryState !== 0) {
        const { r, g, b } = colorsPaletteForCubes[index]
        enqueueBluetoothCommand(cube.id, () => cube.setColor({ r, g, b }), 'led')
      }
    })
  }, [])

  const svgToRedColor =
    'invert(28%) sepia(37%) saturate(4370%) hue-rotate(345deg) brightness(91%) contrast(88%)'

  return (
    <Dialog maxWidth={'md'} fullWidth={true} open={true}>
      <Box sx={{ p: 5 }}>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <Grid container spacing={2}>
          <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'start', width: '56.9%' }}>
              <TabletAndroidIcon sx={{ fontSize: 150 }} />
            </Box>
            <img
              style={{
                width: '30%',
                filter: battery.level * 100 <= 32.5 ? svgToRedColor : '',
              }}
              src={`/static/media/battery/${getBatteryInterval(battery.level * 100)}.svg`}
            />
          </Grid>
          {cubes?.map((value, index) => {
            if (value.batteryState !== 0) {
              return (
                <Grid item xs={4} key={index} sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <ColoredCube color={colorsPaletteForCubeImages[index]} />
                  <img
                    style={{
                      width: '30%',
                      filter: value.batteryState <= 32.5 ? svgToRedColor : '',
                    }}
                    src={`/static/media/battery/${getBatteryInterval(value.batteryState)}.svg`}
                  />
                </Grid>
              )
            }
          })}
        </Grid>
      </Box>
    </Dialog>
  )
}
