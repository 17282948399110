import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material'
import { GlobalStateProvider, useGraphqlClient } from '@proxyqb/cube-global-state'
import { useInitFlagsmith } from '@proxyqb/react-feature-flags'
import { useTranslationsMessages } from '@proxyqb/react-translations'
import { loadSharedTranslations } from '@proxyqb/shared-translations'
import {
  CATEGORIES,
  LanguageContext,
  lightMuiThemeOptions,
  LoadingTemplate,
  ReduceProviders,
} from '@proxyqb/ui'
import { IntlProvider } from 'react-intl'
import { useLocation } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { Provider } from 'urql'
import { DEFAULT_CATEGORY } from './categories/categories.constants'
import { env } from './env'
import { CubeCalibrationProvider } from './game/CubeCalibtation.context'
import { GamePauseProvider } from './game/GamePause.context'
import { SelectedLevelProvider } from './levels/level.context'
import { loadTranslations } from './shared/translations/translations'
import { useContext } from 'react'

const translationLoaders = [loadSharedTranslations, loadTranslations]

const defaultTheme = {
  palette: {
    secondary: {
      contrastText: '#ffffff',
      main: '#000000',
      dark: '#3c3c3c',
    },
    primary: {
      contrastText: '#000000',
    },
    categoryColors: {
      attention: {
        main: '#FDD73C',
        dark: '#fdb03c',
      },
      executiveFunctions: {
        main: '#00C853',
        dark: '#01993c',
      },
      learningMemory: {
        main: '#64B5F6',
        dark: '#4887b1',
      },
      language: {
        main: '#FA892B',
        dark: '#b86624',
      },
      psychomotorics: {
        main: '#279688',
        dark: '#1f796e',
      },
      socialCognition: {
        main: '#F06292',
        dark: '#bc4570',
      },
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          size: 'large',
          height: '50px',
        },
      },
    },
  },
}

const categoryThemes = Object.values(CATEGORIES).reduce((acc, category) => {
  return {
    ...acc,
    [category.code]: createTheme(lightMuiThemeOptions, defaultTheme, category?.subTheme),
  }
}, {})

const themes = {
  [DEFAULT_CATEGORY]: createTheme(lightMuiThemeOptions, defaultTheme, {
    palette: {
      primary: { main: '#461683', contrastText: '#FFFFFF', dark: '#371169', light: '#e9dff7' },
    },
  }),
  login: createTheme(lightMuiThemeOptions, defaultTheme, {
    palette: {
      primary: { main: '#00BCF2', contrastText: '#FFFFFF', dark: '#008aae' },
    },
  }),
  homeScreen: createTheme(lightMuiThemeOptions, defaultTheme, {
    palette: {
      primary: { main: '#000000', contrastText: '#FFFFFF', dark: '#3c3c3c' },
    },
  }),
  ...categoryThemes,
}

const parseCategory = (path) => {
  const pathSplit = path.split('/')
  const categoryIndex = pathSplit.findIndex((kek) => kek === 'category')
  if (path === '/login') {
    return 'login'
  }
  if (
    ['/', '/category-list', '/player-profiles', '/select-patient', '/create-patient', '/settings'].includes(
      path,
    )
  ) {
    return 'homeScreen'
  }
  if (categoryIndex > -1 && pathSplit.length > categoryIndex + 1) {
    return pathSplit[categoryIndex + 1]
  }
  return DEFAULT_CATEGORY
}

export const GlobalProviders = ({ children }) => {
  return (
    <GlobalStateProvider>
      <InnerProviders>{children}</InnerProviders>
    </GlobalStateProvider>
  )
}

const InnerProviders = ({ children }) => {
  const { userLanguage } = useContext(LanguageContext)
  const { isLoadingFlags } = useInitFlagsmith(env.flagsmith.environmentID)
  const messages = useTranslationsMessages(userLanguage, translationLoaders)
  const { pathname } = useLocation()
  const category = parseCategory(pathname)
  const client = useGraphqlClient()
  if (messages.loading || isLoadingFlags) {
    return <LoadingTemplate />
  }
  return (
    <ReduceProviders
      providers={[
        [Provider, { value: client }],
        [IntlProvider, { locale: userLanguage, messages: messages.value }],
        SelectedLevelProvider,
        CubeCalibrationProvider,
        GamePauseProvider,
        [ThemeProvider, { theme: themes[category] }],
        [MuiThemeProvider, { theme: themes[category] }],
      ]}
    >
      {children}
    </ReduceProviders>
  )
}
