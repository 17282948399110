import { Grid, Typography } from '@mui/material'
import { DisplayGoal } from '@proxyqb/level-generators'
import { ReactElement } from 'react'
import { useIntl } from 'react-intl'
import { LevelDetailFragment } from './get-level.generated'
import { useFlagsmith } from '@proxyqb/react-feature-flags'

interface Props {
  level?: LevelDetailFragment
  displayGoals?: DisplayGoal[]
}

export const TextBaseStages = ({ level, displayGoals }: Props): ReactElement | null => {
  if (!displayGoals || !level) {
    return null
  }
  if (level.stageGenerator.__typename !== 'TextStageGenerator') {
    return null
  }
  return (
    <Grid
      container
      sx={{
        position: 'absolute',
        top: !level.renderCubes ? 10 : 40,
        width: '100vw',
        height: !level.renderCubes ? '50vh' : '25vh',
        backgroundColor: 'white',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {displayGoals.map((displayGoal, index) => (
        <Grid item key={index} xs={12 / displayGoals.length} sx={{ textAlign: 'center' }}>
          <TextAssignment displayGoal={displayGoal} />
        </Grid>
      ))}
    </Grid>
  )
}

const TextAssignment = ({ displayGoal }: { displayGoal: DisplayGoal }): ReactElement => {
  const { flags } = useFlagsmith()
  const assignment = flags.led_color_cubes?.enabled ? 'ledColorAssignments' : 'textAssignments'

  const { formatMessage } = useIntl()
  const { pattern, side, color } = displayGoal
  return (
    <Typography variant="h2" color={color} fontWeight="bold">
      {formatMessage({
        id: `textStageGenerator.${assignment}.correct.${pattern}.${side}`,
      })}
    </Typography>
  )
}
