// eslint-disable-next-line no-use-before-define
import { Box, Dialog, Typography } from '@mui/material'
import { LoadingTemplate } from '@proxyqb/ui'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import { useRegisterSW } from 'virtual:pwa-register/react' // eslint-disable-line import/no-unresolved

export function ReloadPrompt() {
  const { formatMessage } = useIntl()
  const [loading, setLoading] = useState(false)

  const {
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegistered() {},
    onRegisterError(error) {
      console.log('SW registration error', error)
    },
  })

  const refresh = () => {
    setLoading(true)
    updateServiceWorker(true)
  }

  if (needRefresh && !loading) {
    refresh()
  }

  return (
    <>
      {loading && (
        <Dialog maxWidth={'xs'} fullWidth={true} open={loading}>
          <Box sx={{ height: '55vh', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography
              variant="h6"
              sx={{
                padding: 3,
              }}
            >
              {formatMessage({ id: 'reloadPrompt.newContent' })}
            </Typography>
            <LoadingTemplate />
          </Box>
        </Dialog>
      )}
    </>
  )
}
