import { Box, Grid, Typography } from '@mui/material'
import { GoalDistribution, ImageWordSelect, StageResult, VoiceSuccessStage } from '@proxyqb/graphql-api-types'
import { DisplayGoal, usePlaythrough, UseStageGeneratorResult } from '@proxyqb/level-generators'
import { useFlagsmith } from '@proxyqb/react-feature-flags'
import { shuffle } from 'lodash-es'
import { MouseEventHandler, useEffect, useMemo, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { useAudio, useCounter, useInterval, useRafLoop, useUnmount } from 'react-use'
import { Line3, Matrix4, Quaternion, Scene, Vector3 } from 'three'
import { LevelFinishedModal } from '../levels/level-finished.modal'
import { Screen } from '../shared'
import { Progress } from '../shared/components/atoms/Progress'
import { useRehabilitationPlan } from '../shared/use-rehabilitation-plan'
import { useVoice } from '../shared/use-voice'
import { GameCube } from './3d/GameCube'
import { enqueueBluetoothCommand } from './bluetooth/bluetooth-command-queue'
import { BluetoothCubeRenderer } from './bluetooth/BluetoothCubeRenderer'
import { CubeData, CubesState, useCubes2, currentDeviceOrientationConjugated } from './bluetooth/useCubes'
import { CanvasScene } from './canvas-scene'
import { DEFAULT_ALLOWED_ERROR, TIME_BEFORE_WIN } from './constants'
import { DontMoveCubes } from './dont-move-cubes'
import { LevelDetailFragment } from './get-level.generated'
import { GoalsHider } from './goals-hider'
import { ImageWordGoalRenderer } from './ImageWordGoalRenderer'
import { LevelTimer } from './level-timer'
import { MovementDetector } from './movement-detection'
import { ReadySteadyGo } from './ready-steady-go'
import { SocialGoalRenderer } from './SocialGoalRenderer'
import { StageCounter } from './stage-counter'
import { TextBaseStages } from './text-based-stages'
import { useLightUpCubes } from './use-light-up-cubes'
import { mapStatsForBE, useStatsCollector } from './use-stats-collector'
import { GameEventType, useGameEvents } from './useGameEvents'
import PrepareGameScreen from './prepare-game/prepare-game.screen'
import { Modal } from '../shared/components/atoms/Modal'
import VolumeUpIcon from '@mui/icons-material/VolumeUp'

const VECTOR_LENGTH = 10
const MEMORY_DISQUALIFICATION_PENALTY = 3000

export const getLevelRequiredCubeIds = (
  level: LevelDetailFragment,
  cubes: Record<string, CubeData>,
  displayGoals: any | undefined = undefined,
): string[] => {
  const requiredLevelCubes = [...level.requiredCubes]
  if (displayGoals) {
    const getGoalPatternIndex = (pattern) =>
      displayGoals.findIndex(
        ({ pattern: goalPattern }) =>
          goalPattern ===
          level.stageGenerator.goalPatterns.find(({ cubePattern }) => cubePattern === pattern).goalPattern,
      )
    const getDisplayGoalPatternIndex = (pattern) =>
      displayGoals.findIndex(({ pattern: goalPattern }) => goalPattern === pattern)

    const getPatternIndex = level.stageGenerator?.goalPatterns
      ? getGoalPatternIndex
      : getDisplayGoalPatternIndex
    requiredLevelCubes.sort((a, b) => {
      const aIndex = getPatternIndex(a.pattern)
      const bIndex = getPatternIndex(b.pattern)

      if (aIndex === bIndex) {
        return 0
      } else if (aIndex > bIndex) {
        return 1
      } else {
        return -1
      }
    })
  }

  const usedLevelCubes: string[] = []
  requiredLevelCubes.forEach(({ pattern: requiredPattern }) => {
    // add any connected cube with correct pattern to usedLevelCubes
    // when no connected cube is found, add a disconnected one
    const notYetUsedCubes = Object.values(cubes).filter(({ id }) => !usedLevelCubes.includes(id))
    const connectedCubeWithMatchingPattern = notYetUsedCubes.find(
      ({ pattern, isConnected }) => pattern === requiredPattern && isConnected,
    )?.id
    const cubeId: string | undefined =
      connectedCubeWithMatchingPattern ||
      notYetUsedCubes.find(({ pattern }) => pattern === requiredPattern)?.id

    cubeId && usedLevelCubes.push(cubeId)
    return cubeId
  })
  return usedLevelCubes
}

interface GameRendererProps {
  level: LevelDetailFragment
  stageGenerator: UseStageGeneratorResult
  playAgain: MouseEventHandler<HTMLButtonElement>
  playAnother(): void
  playNext?(): void
  isLastLevelFromPlan?: boolean
}

export interface RealTimeState {
  isMovementForbidden: boolean
  showDontMoveCubes: boolean
}

const toFixed3 = (x) => Math.round(x * 1000) / 1000

function getGoalHeight(levelRequiredCubeIds, renderCubes, displayMatchBy) {
  if (levelRequiredCubeIds.length > 4) {
    return renderCubes ? '30vh' : '40vh'
  } else {
    if (renderCubes) {
      return displayMatchBy ? '33vh' : '35vh'
    } else {
      return '60vh'
    }
  }
}

export const GameRenderer = (props: GameRendererProps) => {
  const { level, stageGenerator, playAgain, playAnother, playNext, isLastLevelFromPlan } = props
  const { cubes, quaternions, vibrate, setCubeLedColor, handleRecoverColorsLed, cubeVersion } = useCubes2()
  const { flags } = useFlagsmith()

  const { voiceAssistant } = level
  const { goal } = level.stageGenerator
  const isMemoryGame = !!goal.hideAfter
  const isTimeLevelGoal = goal.__typename === 'TimeLevelGoal'
  const isTextBasedGame = level.stageGenerator.__typename === 'TextStageGenerator'
  const isImageWordBasedGame = level.stageGenerator.__typename === 'ImageWordGenerator'
  const isSocialBasedGame = level.stageGenerator.__typename === 'SocialGenerator'
  const imageWordSelect =
    level.stageGenerator.__typename === 'ImageWordGenerator'
      ? level.stageGenerator.imageWordSelect
      : undefined
  const socialSolution =
    level.stageGenerator.__typename === 'SocialGenerator' ? level.stageGenerator.solution : undefined
  const time = isTimeLevelGoal ? goal.time : undefined
  const count = goal.__typename === 'StageCountLevelGoal' ? goal.count : undefined
  const currentTime = useRef<number>(time * 1000 || 0)
  const [levelRequiredCubeIds, setLevelRequiredCubeIds] = useState<string[]>([])
  const [skipStageButtonDisabled, setSkipStageButtonDisabled] = useState<boolean>(true)
  const realTimeState = useRef<RealTimeState>({ isMovementForbidden: false, showDontMoveCubes: false })
  const isGameStarted = useRef<boolean>(false)
  const isTimePaused = useRef<boolean>(true)
  const isInBatteryModal = useRef<boolean>(false)
  const isInAnimation = useRef<boolean>(false)
  const { current: movementDetector } = useRef(new MovementDetector())
  const { pushData, getStats } = useStatsCollector()
  const renderCubes = level.renderCubes
  const intl = useIntl()
  const { voice } = useVoice()
  const [successAudio, , successAudioControls] = useAudio({
    src: '/static/media/audio/success_stage.mp3',
    autoPlay: false,
  })
  const [winAudio, , winAudioControls] = useAudio({
    src: '/static/media/audio/win_stage.mp3',
    autoPlay: false,
  })
  const successBarTime = level.successBarTime ?? TIME_BEFORE_WIN
  const [losingBar, setLosingBar] = useState(false)
  const [showDontMoveCubes, setShowDontMoveCubes] = useState(false)
  const [displayGoals, setDisplayGoals] = useState<DisplayGoal[]>()
  const hideAfter = goal.hideAfter
  const [stageNumber, { inc: incStageIndex }] = useCounter(0)
  const [levelHasFinished, setLevelHasFinished] = useState<boolean>(false)
  const [score, { inc: incrementScore, reset: resetScore }] = useCounter(0)
  const previousTimeout = useRef<number>(null)
  const counterRef = useRef()
  const isStageInProgress = useRef<boolean>(false)
  const stageCountOrSkip = useRef(0)
  const modalConnectCubes = useRef<boolean>(false)
  const displayMatchBy = displayGoals?.every((goal) => goal.matchBy)

  const assignment = flags.led_color_cubes?.enabled ? 'ledColorAssignments' : 'textAssignments'

  const modalReconnect = (cubes: CubesState) => {
    if (Object.keys(cubes).length === 0) {
      return true
    } else {
      return Object.entries(cubes).some(
        ([key, value]) =>
          (value.isConnected === false ||
            value.isCalibrated === false ||
            value.isSyncedWithDevice === false) &&
          levelRequiredCubeIds.includes(key),
      )
    }
  }

  const { lightsUp, lightsDown } = useLightUpCubes(levelRequiredCubeIds)
  const { fireEvent, registerListener, unregisterListener } = useGameEvents([
    {
      type: GameEventType.IMAGE_GOAL_LOADED,
      handler: () => {
        isInAnimation.current = false
      },
    },
    {
      type: GameEventType.IMAGE_GOAL_LOADING,
      handler: () => {
        isInAnimation.current = true
      },
    },
  ])
  const { onStageBegin, triggerStart, compareGoals, triggerSuccess, triggerFailure, triggerFinish } =
    stageGenerator
  const { levelStarted, rehabPlanFinished, rehabilitationPlanId, nextLevelInPlan, rehabPlanFetching } =
    useRehabilitationPlan()
  const shuffleQuaternion = useMemo(() => {
    if (level.shuffleQuaternion) {
      return new Quaternion(
        level.shuffleQuaternion.x!,
        level.shuffleQuaternion.y!,
        level.shuffleQuaternion.z!,
        level.shuffleQuaternion.w!,
      )
    }
  }, [level])
  const navigate = useNavigate()

  useEffect(() => {
    const prevModal = modalConnectCubes.current
    modalConnectCubes.current = modalReconnect(cubes)
    if (!modalConnectCubes.current && prevModal) {
      isTimePaused.current = false
      isInAnimation.current = false
    }
  }, [cubes, levelRequiredCubeIds])

  useUnmount(() => {
    Object.values(cubes).forEach((c) => {
      setCubeLedColor(c.id)({ r: 0, g: 0, b: 0 })
    })
    movementDetector.stopMovementDetection()
  })

  const rotateGoalsRight = (goals) => {
    let count = -(stageCountOrSkip.current % goals.length)
    count -= goals.length * Math.floor(count / goals.length)
    goals.push(...goals.splice(0, count))
    return goals
  }

  useEffect(() => {
    onStageBegin(({ displayGoals }) => {
      isStageInProgress.current = true
      if (previousTimeout.current) {
        clearTimeout(previousTimeout.current)
      }
      const sortedGoals = displayGoals.sort((a, b) => a.pattern.localeCompare(b.pattern))

      const distributedGoals = (() => {
        switch (level.goalDistribution) {
          case GoalDistribution.Random:
            return shuffle(sortedGoals)
          case GoalDistribution.RotateRight:
            return rotateGoalsRight(sortedGoals)
          case GoalDistribution.Static:
          default:
            return sortedGoals
        }
      })()

      setDisplayGoals(distributedGoals)

      if (voiceAssistant.isEnabled) {
        voice(
          sortedGoals
            .map(({ pattern, side, matchBy }) => {
              const sentence: string[] = []
              sentence.push(
                intl.formatMessage({
                  id: `textStageGenerator.${assignment}.correct.${pattern}.${side}`,
                }),
              )
              if (matchBy) {
                sentence.push(intl.formatMessage({ id: `game.${matchBy}` }))
              }
              return sentence.join(' ')
            })
            .join(', '),
        )
      }
      if (
        [ImageWordSelect.ImageSpeech, ImageWordSelect.SpeechOnly, ImageWordSelect.ImageTextSpeech].includes(
          imageWordSelect,
        )
      ) {
        const listenerId = registerListener({
          type: GameEventType.IMAGE_GOAL_LOADED,
          handler: () => {
            const voiceMessage: string[] = []
            const usedWord = [...displayGoals]
            if (usedWord?.[0]?.word) {
              voiceMessage.push(usedWord[0].word + '. ')
              const place = intl.formatMessage({ id: 'imageWordStageGenerator.letter' })
              for (let i = 0; i < usedWord?.length ?? 0; i++) {
                const position = usedWord[0].usedLetters[i].index + 1
                const numbering = intl.formatMessage({ id: 'letterNumbering.' + position })
                voiceMessage.push(`${numbering} ${place}.`)
              }
            }

            voice(voiceMessage.join(''))
            unregisterListener(listenerId)
          },
        })
      }

      if (isMemoryGame) {
        setSkipStageButtonDisabled(true)
        Object.assign(realTimeState.current, { isMovementForbidden: true })
        movementDetector.setMovementDetectionOptions({ movementTrigger: Math.PI / 36 })
        lightsDown(levelRequiredCubeIds)
        previousTimeout.current = setTimeout(() => {
          if (!movementDetector.getIsSomeMoving()) {
            lightsUp(levelRequiredCubeIds)
            Object.assign(realTimeState.current, { isMovementForbidden: false })
            setSkipStageButtonDisabled(false)
            movementDetector.setMovementDetectionOptions({ movementTrigger: Math.PI / 180 })
          }
        }, hideAfter * 1000)
      } else {
        setSkipStageButtonDisabled(false)
      }
      incStageIndex()
    })
    movementDetector.onMovementStopped(() => {
      if (realTimeState.current.isMovementForbidden) {
        movementDetector.setMovementDetectionOptions({
          timeToPutThemBack: 1,
          millisecondsToConfirmNoMovement: level.errorCounter?.millisecondsToConfirmNoMovement ?? 2000,
        })
        if (isTimeLevelGoal) {
          decTime(MEMORY_DISQUALIFICATION_PENALTY)
        } else {
          incTime(MEMORY_DISQUALIFICATION_PENALTY)
        }
        triggerFailure(
          isTimeLevelGoal ? goal.time * 1000 - currentTime.current : currentTime.current,
          StageResult.Disqualification,
        )
        triggerStart()
        setSkipStageButtonDisabled(true)
      } else {
        if (!isTimePaused.current && level.countErrors) {
          setLosingBar(true)
        }
      }
      if (realTimeState.current.showDontMoveCubes) {
        Object.assign(realTimeState.current, { showDontMoveCubes: false })
        setShowDontMoveCubes(false)
      }
    })
    // eslint-disable-next-line
  }, [level, levelRequiredCubeIds])

  useEffect(() => {
    movementDetector.onMovementStarted(() => {
      if (realTimeState.current.isMovementForbidden) {
        movementDetector.setMovementDetectionOptions({
          timeToPutThemBack: 3000,
          millisecondsToConfirmNoMovement: 2000,
        })
        levelRequiredCubeIds.forEach((id) => {
          enqueueBluetoothCommand(id, () => vibrate(id, 500, 255), 'vibrate')
        })
        voice(`Stop, ${intl.formatMessage({ id: 'dontMoveCubes.text' })}`)
        Object.assign(realTimeState.current, { showDontMoveCubes: true })
        setShowDontMoveCubes(true)
      }
    })
  }, [levelRequiredCubeIds])

  useEffect(() => {
    setLevelRequiredCubeIds(getLevelRequiredCubeIds(level, cubes, displayGoals))
  }, [level, cubes, displayGoals])

  const incTime = (value: number = 100) => {
    currentTime.current += value
  }

  const decTime = (value: number = 100) => {
    currentTime.current -= value
    if (currentTime.current <= 0) {
      triggerFinish(isTimeLevelGoal ? goal.time * 1000 - currentTime.current : currentTime.current)
    }
  }
  const startGame = () => {
    stageCountOrSkip.current = 0
    triggerStart()
    levelStarted?.(level.id)
    isGameStarted.current = true
    if (level.countErrors) {
      movementDetector.startMovementDetection({
        cubeIds: levelRequiredCubeIds,
        movementDetectionOptions: {
          timeToPutThemBack: 1,
          millisecondsToConfirmNoMovement: level.errorCounter?.millisecondsToConfirmNoMovement ?? 2000,
        },
      })
    } else if (isMemoryGame) {
      movementDetector.startMovementDetection({ cubeIds: levelRequiredCubeIds })
    }
  }

  useEffect(() => {
    if (!rehabPlanFetching) {
      stageGenerator.onLevelEnded(
        () => {
          if (rehabilitationPlanId) {
            if (isLastLevelFromPlan) {
              rehabPlanFinished!()
            } else {
              levelStarted!(nextLevelInPlan(level.id)!)
            }
          }
          if (!isTimeLevelGoal) {
            winAudioControls.seek(0)
            winAudioControls.volume(0.15)
            winAudioControls.play()
            setLevelHasFinished(true)
          }
          isGameStarted.current = false
        },
        () => {
          const stats = getStats()
          return mapStatsForBE(stats)
        },
      )
    }
  }, [isLastLevelFromPlan, rehabPlanFetching])

  const sceneRef = useRef<Scene>()
  const stageWinningHoldTime = useRef(0)
  const { snapshotCubes } = usePlaythrough()

  useInterval(() => {
    const snapshot = levelRequiredCubeIds.map((cubeId) => {
      const cube = cubes[cubeId]
      const syncQuaternionConjugated = cube.syncQuaternionConjugated
      const q = quaternions[cubeId].clone()
      let qqq = q
      if (syncQuaternionConjugated) {
        qqq = q.premultiply(syncQuaternionConjugated).premultiply(currentDeviceOrientationConjugated)
      }

      return {
        x: toFixed3(qqq.x),
        y: toFixed3(qqq.y),
        z: toFixed3(qqq.z),
        w: toFixed3(qqq.w),
      }
    })

    snapshotCubes(snapshot)
  }, 50)

  const lastTime = useRef<number>()
  useRafLoop((time) => {
    const delta = lastTime.current ? time - lastTime.current : 0
    lastTime.current = time
    const currentStatusByGoal: Array<
      Array<{
        cubeId: string
        isWinning: boolean
        distance: number
        angle: number
      }>
    > = []

    compareGoals.forEach((compareGoal) => {
      const cubeStatuses: any[] = []
      const cubeIds = Object.keys(compareGoals[0])
      cubeIds.forEach((cubeId) => {
        const syncQuaternion = shuffleQuaternion || cubes[cubeId].syncQuaternion
        const currentQ = quaternions[cubeId]
        const winQuaternion = compareGoal[cubeId]

        const vector = new Line3(new Vector3(0, 0, 0), new Vector3(0, -VECTOR_LENGTH, 0)).applyMatrix4(
          new Matrix4().makeRotationFromQuaternion(winQuaternion!.clone().conjugate()),
        )

        const { isWinning, distance, angle } = compareVectors(
          vector,
          currentQ,
          syncQuaternion!.premultiply(currentDeviceOrientationConjugated),
          level.allowedRotationDeviation || DEFAULT_ALLOWED_ERROR,
        )

        cubeStatuses.push({ cubeId, isWinning: !winQuaternion || isWinning, distance, angle })
      })
      currentStatusByGoal.push(cubeStatuses)
    })
    pushData(currentStatusByGoal, delta, isTimePaused.current)

    const winningFrame =
      (isMemoryGame && realTimeState.current.isMovementForbidden) || stageGenerator.currentState === 'idle'
        ? false
        : currentStatusByGoal.some((goalStatus) => goalStatus.every(({ isWinning }) => isWinning))
    if (winningFrame || (losingBar && !realTimeState.current.isMovementForbidden)) {
      if (isGameStarted.current || isStageInProgress.current) {
        stageWinningHoldTime.current = stageWinningHoldTime.current + delta / 1000
      }
    } else {
      if (stageWinningHoldTime.current !== 0) {
        stageWinningHoldTime.current = 0
      }
    }
    if (losingBar && movementDetector.getIsSomeMoving()) {
      stageWinningHoldTime.current = 0
      setLosingBar(false)
    }
    if (stageWinningHoldTime.current >= successBarTime) {
      stageWinningHoldTime.current = 0
      const time = isTimeLevelGoal ? goal.time * 1000 - currentTime.current : currentTime.current
      if (!losingBar) {
        isStageInProgress.current = false
        if (currentTime.current <= 0) {
          finishLevelAndPlaySounds()
        } else {
          if (
            ((level.voiceSuccessStage === VoiceSuccessStage.Global || !level.voiceSuccessStage) &&
              flags.voice_success_stage?.enabled) ||
            level.voiceSuccessStage === VoiceSuccessStage.Enabled
          ) {
            successAudioControls.seek(0)
            successAudioControls.volume(0.2)
            successAudioControls.play()
          }
          stageCountOrSkip.current++
          isInAnimation.current = true
          movementDetector.resetMovementDetection()
          triggerSuccess(time)
          incrementScore()
        }
      } else {
        stageGenerator.triggerFailure(time, StageResult.WrongSolution)
        setLosingBar(false)
        isStageInProgress.current = false
        movementDetector.resetMovementDetection()
        if (!isImageWordBasedGame && !isSocialBasedGame) {
          triggerStart()
        } else {
          isInAnimation.current = true
        }
      }
    }

    isTimePaused.current =
      isInBatteryModal.current ||
      modalConnectCubes.current ||
      isInAnimation.current ||
      !!(
        (stageWinningHoldTime.current && !losingBar) ||
        realTimeState.current.isMovementForbidden ||
        !isGameStarted.current
      )
  })

  const continueGame = (resetProgress = true, stageSuccess = true) => {
    resetProgress && stageSuccess && fireEvent({ type: GameEventType.SUCCESS_ANIMATION_FINISHED })
    isInAnimation.current = false
    movementDetector.resetMovementDetection()
    triggerStart()
  }

  useEffect(() => {
    handleRecoverColorsLed(levelRequiredCubeIds)
  }, [modalConnectCubes.current])

  /*
  RENDER AXES

  const getLine = (endpoint, color, q) => {
    const points: [Vector3?, Vector3?] = []
    points.push(new Vector3(0, 0, 0))
    const endVector = new Vector3(...endpoint)
    if (q) {
      endVector.applyQuaternion(q)
    }
    points.push(endVector)

    const material = new LineBasicMaterial({
      color: color,
    })
    const geometry = new BufferGeometry().setFromPoints(points)
    const line = new Line(geometry, material)
    return line
  }

  useEffect(() => {
    sceneRef.current?.add(getLine([0, 0, 10], 'purple'))
    sceneRef.current?.add(getLine([0, -10, 0.01], 'yellow'))
    sceneRef.current?.add(getLine([10, 0, 0], 'brown'))
  }, [sceneRef.current])
   */

  const back = () => {
    {
      if (previousTimeout.current) {
        clearTimeout(previousTimeout.current)
      }
      rehabilitationPlanId ? navigate(`/rehabilitation-plan-list`) : navigate('/category-list')
    }
  }

  const finishLevelAndPlaySounds = () => {
    winAudioControls.seek(0)
    winAudioControls.volume(0.15)
    winAudioControls.play()
    setLevelHasFinished(true)
  }

  const hasTimeInTimeLevel = currentTime.current > 0 || !isTimeLevelGoal

  const getActions = () => {
    if (hasTimeInTimeLevel) {
      return {
        label: intl.formatMessage({ id: 'game.button.skipStage' }),
        right: true,
        disabled: skipStageButtonDisabled,
        handler: () => {
          stageCountOrSkip.current++
          setSkipStageButtonDisabled(true)
          setLosingBar(false)
          movementDetector.resetMovementDetection()
          stageGenerator.triggerFailure(
            isTimeLevelGoal ? goal.time * 1000 - currentTime.current : currentTime.current,
            StageResult.Skiped,
          )
          // stageFailureCallbacks.current.forEach((callback) => callback(score + 1 === count))

          if (!isImageWordBasedGame && !isSocialBasedGame) {
            triggerStart()
          } else {
            isInAnimation.current = true
          }
        },
      }
    } else {
      return {
        label: intl.formatMessage({ id: 'game.button.finishGame' }),
        right: true,
        handler: finishLevelAndPlaySounds,
      }
    }
  }

  const timerAndScore = (social) => (
    <Box
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', pt: '20px', px: '5%' }}
    >
      <Grid container alignItems="center" justifyContent="center">
        <Grid item xs={social ? 4 : 2}>
          <Box
            sx={{
              height: '100px',
              display: 'flex',
            }}
          >
            {social && (
              <Box
                sx={{
                  width: '100px',
                  height: '100px',
                  backgroundColor: 'primary.dark',
                  borderRadius: '50%',
                  mr: '-50px',
                }}
              />
            )}

            <Box
              sx={{
                height: '100px',
                backgroundColor: social ? 'primary.dark' : 'none',
              }}
            >
              <LevelTimer
                isTimeLevelGoal={isTimeLevelGoal}
                time={currentTime}
                onTimeInc={incTime}
                onTimeDec={decTime}
                isPaused={isTimePaused}
                color={social ? 'white' : undefined}
                fontSize={social ? 35 : undefined}
              />
            </Box>
            {social && (
              <Box
                sx={{
                  width: '100px',
                  height: '100px',
                  backgroundColor: 'primary.dark',
                  ml: '-50px',
                  borderRadius: '50%',
                }}
              />
            )}
          </Box>
        </Grid>
        <Grid item xs={social ? 4 : 8} />
        <Grid
          item
          xs={social ? 4 : 2}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
          }}
        >
          <Box ref={counterRef}>
            <StageCounter
              stageNumber={score}
              count={count}
              animationFinished={!isImageWordBasedGame && !isSocialBasedGame ? continueGame : undefined}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  )

  return (
    <div>
      {successAudio}
      {winAudio}
      <Screen
        primaryTitle={level.name}
        actions={[getActions()]}
        backButtonHandler={hasTimeInTimeLevel ? back : undefined}
        backButtonLabel={'game.endGame'}
        px={0}
        handleBatteryModalPause={(isInModal: boolean) => (isInBatteryModal.current = isInModal)}
        inGame={isGameStarted.current}
        inGameCubes={levelRequiredCubeIds}
        fullHeight
        hideFooter
        isSettingsEnabled={false}
      >
        {isSocialBasedGame && (
          <Box sx={{ height: '100%' }}>
            <ReadySteadyGo onGo={startGame} level={level} />
            {level && (
              <SocialGoalRenderer
                continueGame={continueGame}
                stageGenerator={props.stageGenerator}
                solution={socialSolution}
                assignment={level?.stageGenerator?.assignment}
              />
            )}
            <Progress
              stageWinningHoldTime={stageWinningHoldTime}
              bottom="120px"
              successBarTime={successBarTime}
              isLosing={losingBar}
              counterRef={counterRef?.current}
              levelHasFinished={levelHasFinished}
            />

            <Box sx={{ width: '100%', position: 'absolute', top: '70px', px: 4 }}>{timerAndScore(true)}</Box>
          </Box>
        )}
        {!isSocialBasedGame && timerAndScore(false)}
        {!isSocialBasedGame && (
          <Box>
            {isMemoryGame && (
              <GoalsHider hideAfter={hideAfter} stageNumber={stageNumber} renderCubes={renderCubes} />
            )}
            {!voiceAssistant.hideGoals && (
              <Box sx={{ position: 'relative' }}>
                <Grid
                  container
                  sx={{
                    zIndex: -6,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {level &&
                    !isTextBasedGame &&
                    !isImageWordBasedGame &&
                    displayGoals?.map(({ pattern, quaternion, matchBy }, index) => (
                      <Grid item key={index} xs={12 / displayGoals.length}>
                        {displayMatchBy && (
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              mb: !level?.renderCubes ? -5 : 0,
                            }}
                          >
                            <Typography variant={'h4'}>
                              {intl.formatMessage({ id: `game.${matchBy}` })}
                            </Typography>
                          </Box>
                        )}
                        <CanvasScene
                          width={`${100 / displayGoals.length}vw`}
                          height={getGoalHeight(levelRequiredCubeIds, level?.renderCubes, displayMatchBy)}
                        >
                          <scene ref={sceneRef}>
                            <GameCube
                              pattern={pattern}
                              quaternion={quaternion}
                              scale={level?.renderCubes ? 2.6 : 1.9}
                              matchBy={matchBy}
                              cubeIndex={index}
                              animate={flags.cube_goal_animations?.enabled}
                              cubeVersion={cubeVersion}
                            />
                          </scene>
                        </CanvasScene>
                      </Grid>
                    ))}
                  {level && isImageWordBasedGame && (
                    <ImageWordGoalRenderer
                      horizontal={level?.renderCubes}
                      continueGame={continueGame}
                      displayGoals={displayGoals}
                      imageWordSelect={imageWordSelect}
                    />
                  )}
                </Grid>
                <TextBaseStages displayGoals={displayGoals} level={level} />
              </Box>
            )}
            {voiceAssistant.hideGoals && voiceAssistant.isEnabled && (
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <VolumeUpIcon style={{ color: 'black', fontSize: 350 }} />
              </Box>
            )}
            {level?.renderCubes && !modalConnectCubes.current && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'absolute',
                  bottom: '0',
                }}
              >
                {level &&
                  compareGoals &&
                  levelRequiredCubeIds.map((cubeId) => (
                    <Box key={cubeId}>
                      <CanvasScene
                        width={`${100 / levelRequiredCubeIds.length}vw`}
                        height={levelRequiredCubeIds.length > 4 ? '30vh' : '37vh'}
                      >
                        <BluetoothCubeRenderer
                          cubeId={cubeId}
                          shuffleQuaternion={level.shuffleQuaternion}
                          scale={2.4}
                          compareGoals={
                            flags.show_winning_vectors?.enabled
                              ? compareGoals.map((compareGoal) => compareGoal[cubeId])
                              : []
                          }
                          showWinningVectors={flags.show_winning_vectors?.enabled}
                          cubeVersion={cubeVersion}
                        />
                      </CanvasScene>
                    </Box>
                  ))}
              </Box>
            )}

            <Progress
              stageWinningHoldTime={stageWinningHoldTime}
              top={renderCubes ? '62.5vh' : '87vh'}
              successBarTime={successBarTime}
              isLosing={losingBar}
              counterRef={counterRef?.current}
              levelHasFinished={levelHasFinished}
            />
            <ReadySteadyGo onGo={startGame} level={level} />
            {isMemoryGame && showDontMoveCubes && <DontMoveCubes />}
          </Box>
        )}
      </Screen>

      <LevelFinishedModal
        open={levelHasFinished}
        levelId={level.id}
        time={!isTimeLevelGoal ? currentTime.current / 1000 : undefined}
        score={score}
        rehabilitationPlanId={rehabilitationPlanId}
        playAgainHandler={(e) => {
          resetScore()
          playAgain(e)
        }}
        playAnotherHandler={() => {
          resetScore()
          playAnother()
        }}
        playNextHandler={
          playNext
            ? () => {
                resetScore()
                playNext()
              }
            : undefined
        }
        isLastLevelFromPlan={isLastLevelFromPlan}
      />
      <Modal open={modalConnectCubes.current} sx={{ height: '80%', p: 0 }}>
        <PrepareGameScreen isModal={true} />
      </Modal>
    </div>
  )
}

const compareVectors = (
  vector: Line3,
  currentQ: Quaternion,
  calibration: Quaternion,
  allowedError: number,
) => {
  let vectorWin = new Vector3(0, -VECTOR_LENGTH, 0)

  if (calibration) {
    vectorWin = new Line3(new Vector3(0, 0, 0), vectorWin).applyMatrix4(
      new Matrix4().makeRotationFromQuaternion(calibration),
    ).end
  }
  // vectorWin is static and pointing towards the player
  // currentVector is always pointing from the 'winning side' of the playing cube
  const currentVector = vector.clone().applyMatrix4(new Matrix4().makeRotationFromQuaternion(currentQ)).end
  const distance = vectorWin.distanceTo(currentVector)
  const angle = vectorWin.angleTo(currentVector)

  return { isWinning: distance <= allowedError, distance, angle }
}
