import { Box, SvgIcon, Typography, OverridableComponent, SvgIconTypeMap } from '@mui/material'
import { MouseEventHandler, ReactElement } from 'react'
import { useIntl } from 'react-intl'
import { animated, config, useSpring } from 'react-spring'

interface Props {
  borderColor: string
  bgImage?: string
  bgColor: string
  onClick: MouseEventHandler
  typographyColor: string
  translationText?: string
  text?: string
  index: number
  bgIcon?: OverridableComponent<SvgIconTypeMap>
}

export const HomeButton = ({
  borderColor,
  bgImage,
  bgColor,
  onClick,
  typographyColor,
  translationText,
  text,
  index,
  bgIcon,
}: Props): ReactElement => {
  const { formatMessage } = useIntl()

  const style = useSpring({
    delay: index * 40,
    from: { opacity: 0, transform: 'translateY(60px)' },
    to: { opacity: 1, transform: 'translateY(0)' },
    config: config.gentle,
  })

  return (
    <animated.div style={style}>
      <Box
        sx={{
          minWidth: '300px',
          minHeight: '300px',
          border: '10px solid',
          borderRadius: '5%',
          backgroundPositionX: '50% ',
          backgroundPositionY: 'calc(50% - 25px)',
          backgroundSize: '240px',
          backgroundRepeat: 'no-repeat',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          cursor: 'pointer',
          borderColor: borderColor,
          backgroundColor: bgColor,
          fontSize: '150px',
        }}
        onClick={onClick}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {bgIcon && (
            <SvgIcon component={bgIcon} fontSize="inherit" sx={{ marginBottom: 1, color: typographyColor }} />
          )}
          {bgImage && (
            <Box
              sx={{
                backgroundImage: bgImage,
                backgroundSize: '240px',
                minWidth: '250px',
                minHeight: '250px',
                position: 'absolute',
              }}
            />
          )}
          <Typography
            align="center"
            fontWeight="bold"
            color={typographyColor}
            variant="h5"
            sx={{ marginTop: bgImage ? 25 : 0 }}
          >
            {translationText ? formatMessage({ id: translationText }) : text}
          </Typography>
        </Box>
      </Box>
    </animated.div>
  )
}
