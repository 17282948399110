/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { DensityFunction, DensityFunctionFromJSON, DensityFunctionToJSON } from './DensityFunction'
import { ZScore, ZScoreFromJSON, ZScoreToJSON } from './ZScore'

/**
 *
 * @export
 * @interface ZScoreAndDensityFunction
 */
export interface ZScoreAndDensityFunction {
  /**
   *
   * @type {ZScore}
   * @memberof ZScoreAndDensityFunction
   */
  zScore: ZScore
  /**
   *
   * @type {Array<DensityFunction>}
   * @memberof ZScoreAndDensityFunction
   */
  densityFunction: Array<DensityFunction>
}

export function ZScoreAndDensityFunctionFromJSON(json: any): ZScoreAndDensityFunction {
  return ZScoreAndDensityFunctionFromJSONTyped(json, false)
}

export function ZScoreAndDensityFunctionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): ZScoreAndDensityFunction {
  if (json === undefined || json === null) {
    return json
  }
  return {
    zScore: ZScoreFromJSON(json['zScore']),
    densityFunction: (json['densityFunction'] as Array<any>).map(DensityFunctionFromJSON),
  }
}

export function ZScoreAndDensityFunctionToJSON(value?: ZScoreAndDensityFunction | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    zScore: ZScoreToJSON(value.zScore),
    densityFunction: (value.densityFunction as Array<any>).map(DensityFunctionToJSON),
  }
}
