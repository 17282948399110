/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface ScoreChart
 */
export interface ScoreChart {
  /**
   *
   * @type {string}
   * @memberof ScoreChart
   */
  gameType: string
  /**
   *
   * @type {string}
   * @memberof ScoreChart
   */
  date: string
  /**
   *
   * @type {number}
   * @memberof ScoreChart
   */
  score: number
}

export function ScoreChartFromJSON(json: any): ScoreChart {
  return ScoreChartFromJSONTyped(json, false)
}

export function ScoreChartFromJSONTyped(json: any, ignoreDiscriminator: boolean): ScoreChart {
  if (json === undefined || json === null) {
    return json
  }
  return {
    gameType: json['gameType'],
    date: json['date'],
    score: json['score'],
  }
}

export function ScoreChartToJSON(value?: ScoreChart | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    gameType: value.gameType,
    date: value.date,
    score: value.score,
  }
}
