import { useNavigate, useParams } from 'react-router'
import { Screen } from '../../shared'
import { useGetLevelQuery } from '../get-level.generated'
import { Box, Skeleton, Switch, Typography, FormControlLabel } from '@mui/material'
import { useIntl } from 'react-intl'
import { useGlobalState } from '@proxyqb/cube-global-state'
import { useQuery } from 'react-query'
import { apiClient } from '@proxyqb/gaia-api-client'
import { LevelChart } from './LevelChart'
import { useState } from 'react'
import { LevelTable } from './LevelTable'
import { Flex } from '@proxyqb/ui'

const LevelStatisticScreen = () => {
  const { levelId } = useParams<{ levelId: string }>()
  const { formatMessage } = useIntl()
  const push = useNavigate()
  const { selectedPatient } = useGlobalState()
  const userId = selectedPatient?.id
  const [isChartDisplayed, setIsChartDisplayed] = useState(true)

  const [{ data: levelData, fetching: isLevelFetching }] = useGetLevelQuery({
    variables: {
      id: levelId!,
    },
  })
  const level = levelData?.getLevel

  const { data: levelScores, isLoading } = useQuery(
    `getLevelScoreChart${userId}${level?.id}`,
    () =>
      apiClient.getLevelScoreChart({
        playthroughFilter: {
          levelIds: [level!.id.replace('-', '/')],
          patientIds: [userId!.replace('-', '/')],
        },
      }),
    {
      enabled: !!level,
    },
  )

  const { data: topScoreData } = useQuery(
    `getLevelTopScoreTable${userId}${level?.id}`,
    () =>
      apiClient.getLevelTopScoreTable({
        playthroughFilter: {
          levelIds: [level!.id.replace('-', '/')],
          patientIds: [userId!.replace('-', '/')],
        },
        limit: 3,
      }),
    {
      enabled: !!level,
    },
  )

  const { data: lastScoreData } = useQuery(
    `getLevelLastScoreTable${userId}${level?.id}`,
    () =>
      apiClient.getLevelLastScoreTable({
        playthroughFilter: {
          levelIds: [level!.id.replace('-', '/')],
          patientIds: [userId!.replace('-', '/')],
        },
        limit: 3,
      }),
    {
      enabled: !!level,
    },
  )

  return (
    <Screen
      primaryTitle={
        `${level?.name} - ${formatMessage({ id: 'levelStatistic.statistic' })}` || <Skeleton width={250} />
      }
      backButtonHandler={() => push(-1)}
      levelId={levelId}
    >
      <Flex justifyContent={'space-between'} alignItems="center" sx={{ padding: '40px 40px 20px' }}>
        {isLevelFetching ? (
          <Skeleton width={275} height={40} />
        ) : (
          <Typography variant="h5">
            {formatMessage({ id: 'levelStatistic.title' }, { levelName: level?.name })}
          </Typography>
        )}
        <FormControlLabel
          value="top"
          control={<Switch onClick={() => setIsChartDisplayed((prev) => !prev)} />}
          label={`${formatMessage({ id: 'levelStatistic.table' })}`}
        />
      </Flex>
      <Flex justifyContent={'center'} sx={{ padding: '0 40px' }}>
        {isChartDisplayed ? (
          <LevelChart score={levelScores} loading={isLoading || isLevelFetching} />
        ) : (
          <LevelTable loading={isLoading} topScoreData={topScoreData} lastScoreData={lastScoreData} />
        )}
      </Flex>
    </Screen>
  )
}

export default LevelStatisticScreen
