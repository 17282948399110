import { Box, Button, Grid, Skeleton, Typography, useTheme } from '@mui/material'
import { useFlagsmith } from '@proxyqb/react-feature-flags'
import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate, useParams } from 'react-router-dom'
import { useUnmount } from 'react-use'
import { Screen } from '../../shared'
import { BluetoothCubeRenderer } from '../bluetooth/BluetoothCubeRenderer'
import { useCubes2 } from '../bluetooth/useCubes'
import { CanvasScene } from '../canvas-scene'
import { getLevelRequiredCubeIds } from '../GameRenderer'
import { useGetLevelQuery } from '../get-level.generated'
import { useRequiredCubesIds } from './prepare-game.screen'
import { useVoice } from '../../shared/use-voice'
import { useRehabilitationPlan } from '../../shared/use-rehabilitation-plan'

const LevelDescriptionScreen = () => {
  const { flags } = useFlagsmith()
  const { id, categoryCode } = useParams<{ id: string; categoryCode: string }>()
  const { cubes, cubeVersion } = useCubes2()
  const theme = useTheme()
  const { rehabilitationPlanId } = useRehabilitationPlan(false)
  const intl = useIntl()
  const { voice, cancelVoice } = useVoice()
  const push = useNavigate()
  const [{ data }] = useGetLevelQuery({
    variables: {
      id: id!,
    },
    requestPolicy: 'cache-and-network',
  })
  const level = data?.getLevel
  const fontSize = data && data?.getLevel?.taskDescription?.local?.length > 150 ? '3vh' : '5vh'
  const [requiredCubesIds] = useRequiredCubesIds()
  const cubesForCurrentGame = Object.entries(cubes)
    .filter(([cubeId]) => requiredCubesIds.includes(cubeId))
    .map(([, cube]) => cube)

  const startGame = () => {
    push(
      `/category/${categoryCode}/games/${id}${
        rehabilitationPlanId ? `?rehabilitationPlanId=${rehabilitationPlanId}` : ''
      }`,
    )
  }

  const goBackToCubeStatusView = () => {
    push(`/category/${categoryCode}/prepare-game/${id}`)
  }
  const taskDescription = level?.taskDescription?.local
  const rendering_cubes = flags.rendering_cubes?.enabled

  const areCubesReady =
    Object.entries(cubes).length > 0 &&
    cubesForCurrentGame.find((cube) => !cube.isConnected) === undefined &&
    !requiredCubesIds.includes('unknown')

  useUnmount(() => {
    cancelVoice()
  })

  useEffect(() => {
    if (taskDescription) {
      voice(taskDescription)
    }
  }, [taskDescription])

  useEffect(() => {
    if (!level) {
      return
    }

    const cubeIds = getLevelRequiredCubeIds(level, cubes)

    for (const id of cubeIds) {
      cubes[id].setColor({ r: 0, g: 0, b: 10 })
    }
  }, [level, cubes])

  return (
    <Screen
      primaryTitle={
        level ? (
          `${intl.formatMessage({ id: 'levelDescription.howToPlay' })} ${level?.name ?? ''}`
        ) : (
          <Skeleton width={450} />
        )
      }
      backButtonHandler={() => (rehabilitationPlanId ? push('/rehabilitation-plan-list') : push(-1))}
      levelId={id}
    >
      <Box
        sx={{
          mb: '2vh',
        }}
      >
        <Typography
          sx={{
            fontSize: fontSize ?? '3vh',
            textAlign: 'center',
            fontWeight: 'bold',
            overflowWrap: 'break-word',
            mt: '3vh',
          }}
        >
          {data?.getLevel?.taskDescription?.local}
        </Typography>
      </Box>
      <Box
        gap="5vh"
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap' }}
      >
        {!rendering_cubes && (
          <Typography
            sx={{
              fontSize: '26px',
              textAlign: 'center',
              fontWeight: 'bold',
              mt: '3vh',
            }}
          >
            {intl.formatMessage({ id: 'prepareGame.prepareCubesTitle' })}
          </Typography>
        )}
        <Grid container direction="row" justifyContent="center" alignItems="center" height="20rem">
          {data?.getLevel.requiredCubes.map((cube, index) => {
            return (
              <Grid key={index} item xs={3}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  key={index}
                >
                  <Typography
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      fontSize: '3.5vh',
                      mb: !rendering_cubes ? '50px' : '0px',
                    }}
                    variant="h4"
                  >
                    {intl.formatMessage({ id: `requiredCubes.${cube.pattern}` })}
                  </Typography>
                  {rendering_cubes && (
                    <CanvasScene height="20vh" width="20vh">
                      <BluetoothCubeRenderer
                        cubeId={requiredCubesIds[index]}
                        scale={2.5}
                        cubeVersion={cubeVersion}
                      />
                    </CanvasScene>
                  )}
                </Box>
              </Grid>
            )
          })}
        </Grid>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        {areCubesReady && (
          <Button variant="contained" color="primary" size="large" onClick={startGame}>
            {intl.formatMessage({ id: 'levelDescription.playGame' })}
          </Button>
        )}
        {!areCubesReady && (
          <Grid container alignItems="center" direction="column">
            <Typography variant="subtitle2" sx={{ color: theme.palette.error.main }}>
              {intl.formatMessage({ id: 'levelDescription.cubesNotReady' })}
            </Typography>
            <Button
              sx={{
                mt: '2vh',
              }}
              variant="contained"
              color="primary"
              size="large"
              onClick={goBackToCubeStatusView}
            >
              {intl.formatMessage({ id: 'shared.back' })}
            </Button>
          </Grid>
        )}
      </Box>
    </Screen>
  )
}

export default LevelDescriptionScreen
