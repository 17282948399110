import { merge, shuffle } from 'lodash-es'
import { assign, createMachine, MachineConfig } from 'xstate'
import { SimpleStageGenerator, StageResult } from '@proxyqb/graphql-api-types'
import {
  baseGeneratorMachineConfig,
  BaseGeneratorMachineContext,
  getCubeSides,
} from './base-generator-machine-config'
import { createCompareGoals, SimpleDisplayGoal } from './create-compare-goals'
import { groupSidesByPattern } from './group-sides-by-pattern'

export type SimpleStageGeneratorMachineContext = BaseGeneratorMachineContext & {
  stageGenerator: SimpleStageGenerator
}

const calculateNextSide = assign<SimpleStageGeneratorMachineContext>((context) => {
  const stageGenerator = context.level.stageGenerator as SimpleStageGenerator
  const stageHistory = context.stageHistory
  const isMemoryLevel = stageGenerator.goal.hideAfter !== 0 && stageGenerator.goal.hideAfter

  const compareGoalsByPattern = groupSidesByPattern(context.compareGoals, context.cubes)

  const sides: Record<string, number | null> = {}
  context.cubes.forEach(({ id, getCubeCurrentSide }) => {
    sides[id] = getCubeCurrentSide()
  })
  const currentSidesByPattern = groupSidesByPattern(sides, context.cubes)

  let filterSides = <number[]>[]

  if (isMemoryLevel) {
    for (let i = stageHistory.length - 1; i >= 0; i--) {
      if (filterSides.length !== 0) {
        break
      }
      if (stageHistory[i].type === 'SUCCESS') {
        const currentSides = stageHistory[i].stageGoals.map((item) => item.side)
        filterSides = [...currentSides]
      }
    }
  }

  const getAvailableSides = (cube) => {
    let cubeSides = getCubeSides(stageGenerator)
    if (context.compareGoals.length !== 0) {
      cubeSides = cubeSides.filter((side) => !compareGoalsByPattern[cube.pattern]?.includes(side))
    }
    return cubeSides.filter((side) => !currentSidesByPattern[cube.pattern]?.includes(side))
  }

  const displayGoals: SimpleDisplayGoal[] = context.cubes.map((cube) => {
    const side = shuffle(getAvailableSides(cube)).pop()
    return {
      id: cube.id,
      side: side,
      pattern: cube.pattern,
    }
  })

  const compareGoals = createCompareGoals(context.cubes, displayGoals)
  return {
    displayGoals,
    compareGoals,
  }
})

const decrementCount = assign<SimpleStageGeneratorMachineContext>({
  stagesCount: (context) => context.stagesCount - 1,
})
const stagesCountNotZero = (context, event, meta) => {
  const isNonEndingFailure = [
    StageResult.Disqualification,
    StageResult.Skiped,
    StageResult.WrongSolution,
  ].includes(meta?.state?.event?.result)
  return context.stagesCount !== 0 || isNonEndingFailure
}

const recordStage = assign<SimpleStageGeneratorMachineContext>((context, event, meta) => {
  const stageHistory = context.stageHistory
  const displayGoals = context.displayGoals
  const stageArray = []

  for (const x of displayGoals) {
    const goal = { side: x.side, pattern: x.pattern }
    stageArray.push(goal)
  }

  const stage = {
    stageGoals: stageArray,
    result: meta?.state?.event?.result, // result exist on some levels only?
    type: meta?.state?.event?.type,
  }

  return { stageHistory: [...stageHistory, stage] }
})

type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>
}

type ConfigType = MachineConfig<SimpleStageGeneratorMachineContext, any, any, any>

const extension: DeepPartial<ConfigType> = {
  id: 'simpleStageGeneratorMachine',
}
const config = merge(extension, baseGeneratorMachineConfig) as ConfigType

export const simpleStageGeneratorMachine = createMachine<SimpleStageGeneratorMachineContext>(config, {
  actions: { recordStage, calculateNextSide, decrementCount },
  guards: {
    stagesCountNotZero,
  },
})
