import * as Types from '@proxyqb/graphql-api-types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export const CreatePatientDocument = gql`
    mutation createPatient($input: CreatePatient!) {
  createPatient(input: $input)
}
    `;

export function useCreatePatientMutation() {
  return Urql.useMutation<CreatePatientMutation, CreatePatientMutationVariables>(CreatePatientDocument);
};
export const AddPatientToRehabilitationPlansDocument = gql`
    mutation addPatientToRehabilitationPlans($patientId: ID!, $rehabilitationPlanIds: [ID!]!) {
  addPatientToRehabilitationPlans(
    patientId: $patientId
    rehabilitationPlanIds: $rehabilitationPlanIds
  )
}
    `;

export function useAddPatientToRehabilitationPlansMutation() {
  return Urql.useMutation<AddPatientToRehabilitationPlansMutation, AddPatientToRehabilitationPlansMutationVariables>(AddPatientToRehabilitationPlansDocument);
};
export const QueryRehabilitationPlansDocument = gql`
    query queryRehabilitationPlans {
  queryRehabilitationPlans {
    id
    name {
      local
    }
  }
}
    `;

export function useQueryRehabilitationPlansQuery(options?: Omit<Urql.UseQueryArgs<QueryRehabilitationPlansQueryVariables>, 'query'>) {
  return Urql.useQuery<QueryRehabilitationPlansQuery, QueryRehabilitationPlansQueryVariables>({ query: QueryRehabilitationPlansDocument, ...options });
};
export type CreatePatientMutationVariables = Types.Exact<{
  input: Types.CreatePatient;
}>;


export type CreatePatientMutation = { __typename?: 'Mutation', createPatient: string };

export type AddPatientToRehabilitationPlansMutationVariables = Types.Exact<{
  patientId: Types.Scalars['ID'];
  rehabilitationPlanIds: Array<Types.Scalars['ID']> | Types.Scalars['ID'];
}>;


export type AddPatientToRehabilitationPlansMutation = { __typename?: 'Mutation', addPatientToRehabilitationPlans?: any | null };

export type QueryRehabilitationPlansQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type QueryRehabilitationPlansQuery = { __typename?: 'Query', queryRehabilitationPlans: Array<{ __typename?: 'RehabilitationPlan', id: string, name: { __typename?: 'LocalizedText', local: string } }> };
