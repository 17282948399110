import { useGlobalState } from '@proxyqb/cube-global-state'
import { Navigate, useRoutes } from 'react-router-dom'
import { PrivateRoute } from './shared/private-route'
import { EnableFlagsScreen } from './enable-flags-screen'
import SettingsScreen from './settings/settings.screen'
import HomeScreen from './home/home.screen'
import LevelListScreen from './levels/level-list.screen'

import LoginScreen from './login/login.screen'
import GameScreen from './game/Game.screen'
import LevelDescriptionScreen from './game/prepare-game/level-description.screen'
import PrepareGameScreen from './game/prepare-game/prepare-game.screen'
import PlayerSelectionScreen from './doctors/player-selection.screen'
import CategoryListScreen from './categories/category-list.screen'
import RehabilitationPlanList from './rehabilitation-plans/rehabilitation-plan-list.screen'
import DebugCubeScreen from './game/debug-cube.screen'
import CreatePatientScreen from './doctors/create-patient.screen'
import { Box } from '@mui/material'
import SelectPatientScreen from './doctors/select-patient.screen'
import { PreparePlanScreen } from './rehabilitation-plans/prepare-plan.screen'
import LevelStatisticScreen from './game/statistic/level-statistic.screen'

export function Routes() {
  const { authState } = useGlobalState()
  const { userDetails } = authState.context

  const element = useRoutes(
    userDetails
      ? [
          {
            path: '/',
            element: <PrivateRoute />,
            children: [
              {
                path: '/',
                element: <HomeScreen />,
              },
              {
                path: '/enable-flags',
                element: <EnableFlagsScreen />,
              },
              {
                path: '/category/:categoryCode/level-list',
                element: <LevelListScreen />,
              },
              {
                path: '/category/:categoryCode/games/:id',
                element: <GameScreen />,
              },
              {
                path: '/category/:categoryCode/level-description/:id',
                element: <LevelDescriptionScreen />,
              },
              {
                path: '/category/:categoryCode/prepare-game/:id',
                element: <PrepareGameScreen isModal={false} />,
              },
              {
                path: '/prepare-plan/:rehabilitationPlanId',
                element: <PreparePlanScreen />,
              },
              {
                path: '/player-profiles',
                element: <PlayerSelectionScreen />,
              },
              {
                path: '/select-patient',
                element: <SelectPatientScreen />,
              },
              {
                path: '/category-list',
                element: <CategoryListScreen />,
              },
              {
                path: '/rehabilitation-plan-list',
                element: <RehabilitationPlanList />,
              },
              {
                path: '/debug-cube',
                element: <DebugCubeScreen />,
              },
              {
                path: '/create-patient',
                element: <CreatePatientScreen />,
              },
              {
                path: '/login',
                element: <LoginScreen />,
              },
              {
                path: '/settings',
                element: <SettingsScreen />,
              },
              {
                path: '/category/:categoryCode/level-statistic/:levelId',
                element: <LevelStatisticScreen />,
              },
              {
                path: '/*',
                element: <Navigate to="/" />,
              },
            ],
          },
        ]
      : [
          {
            path: '/login',
            element: <LoginScreen />,
          },
          {
            path: '/*',
            element: <Navigate to="/login" />,
          },
        ],
  )
  if (authState.matches('checkingIfLoggedIn')) {
    return <Box />
  }

  return element
}
