import { Text } from '@proxyqb/ui'
import { Box, Button, Grid, Skeleton } from '@mui/material'
import { useIntl } from 'react-intl'
import { CubeStatus } from '../../helpers'
import { MouseEventHandler } from 'react'

interface Props {
  status: CubeStatus
  actions: {
    connect()
    calibrate()
    synchronize()
    continue()
  }
  inModalWindow?: boolean
  exitFnc?: MouseEventHandler<HTMLButtonElement>
  allCubesReady?: boolean
  loading?: boolean
}

const resolveStatus = (status, allCubesReady) => {
  if (allCubesReady) {
    return { label: 'prepareGame.allReady', action: 'continue' }
  }
  if (!status || status === CubeStatus.NOT_CONNECTED) {
    return { label: 'prepareGame.someNotConnected', action: 'connect' }
  }
  return status === CubeStatus.NOT_CALIBRATED
    ? { label: 'prepareGame.someNotCalibrated', action: 'calibrate' }
    : { label: 'prepareGame.someNotSynchronized', action: 'synchronize' }
}

export const CubesNotReady = ({
  status,
  actions,
  inModalWindow = false,
  exitFnc,
  allCubesReady,
  loading,
}: Props) => {
  const { label, action } = resolveStatus(status, allCubesReady)
  const { formatMessage } = useIntl()
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box mt={3} mb={6} height={16}>
        {loading ? <Skeleton width={450} height={36} /> : <Text variant="p" t={label} size={20} />}
      </Box>
      <Grid container direction="row" justifyContent="center" alignItems="center" spacing={5}>
        {loading ? (
          <Grid item>
            <Skeleton variant="rectangular" width={125} height={50} />
          </Grid>
        ) : (
          <Grid item height={50}>
            {allCubesReady ? (
              <Box sx={{ pl: 30, display: 'flex', gap: 10 }}>
                <Button onClick={actions.continue} variant="contained">
                  {formatMessage({ id: `shared.continue` })}
                </Button>
                <Button onClick={actions.synchronize} variant="outlined">
                  {formatMessage({ id: `shared.synchronize` })}
                </Button>
              </Box>
            ) : (
              <Button onClick={actions[action]} variant="contained">
                {formatMessage({ id: `shared.${action}` })}
              </Button>
            )}
          </Grid>
        )}

        {inModalWindow &&
          (loading ? (
            <Grid item>
              <Skeleton variant="rectangular" width={125} height={50} />
            </Grid>
          ) : (
            <Grid item height={50}>
              <Button onClick={exitFnc} variant="contained">
                {formatMessage({ id: `game.endGame` })}
              </Button>
            </Grid>
          ))}
      </Grid>
    </Box>
  )
}
