import { Assignment, Solution } from '@proxyqb/graphql-api-types'
import { useSpring, animated, useSpringRef } from 'react-spring'
import { Box, Grid, Typography } from '@mui/material'
import { GameEventType, useGameEvents } from './useGameEvents'
import { useEffect } from 'react'
import { DeliveredAsset, MaskIcon } from '@proxyqb/ui'
import { useFlagsmith } from '@proxyqb/react-feature-flags'

export const SocialGoalRenderer = ({ stageGenerator, solution, continueGame, assignment }) => {
  const springRef = useSpringRef()
  const styles = useSpring({
    transform: 'translateX(1200px)',
    ref: springRef,
  })
  const { flags } = useFlagsmith()

  const doStageEndAnimation = async (success: boolean) => {
    if (springRef.current[0].idle) {
      await springRef
        .start({
          delay: 400,
          from: { transform: 'translateX(0vw)' },
          to: { transform: 'translateX(1vw)' },
          config: { mass: 1, tension: 210, friction: 20 },
        })
        .pop()
      await springRef
        .start({
          from: { transform: 'translateX(1vw)' },
          to: { transform: 'translateX(-100vw)' },
        })
        .pop()
      continueGame(true, success)
    }
  }

  const doStageFailAnimation = () => {
    doStageEndAnimation(false)
  }

  const doStageSuccessAnimation = () => {
    doStageEndAnimation(true)
  }

  const { fireEvent } = useGameEvents([
    {
      type: GameEventType.STAGE_SUCCESS,
      handler: doStageSuccessAnimation,
    },
    {
      type: GameEventType.STAGE_FAILURE,
      handler: doStageFailAnimation,
    },
  ])

  useEffect(() => {
    if (stageGenerator?.imageId) {
      fireEvent({ type: GameEventType.IMAGE_GOAL_LOADING })
    }
  }, [stageGenerator?.imageId])
  const onLoadHandler = () => {
    springRef.start({
      config: { mass: 1, tension: 300, friction: 26 },
      from: { transform: 'translateX(100vw)' },
      to: { transform: 'translateX(0vw)' },
    })
    fireEvent({ type: GameEventType.IMAGE_GOAL_LOADED })
  }

  return (
    <>
      <animated.div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          width: '100%',
          paddingTop: assignment === Assignment.Smiley ? '15px' : 0,
          height: assignment === Assignment.Smiley ? 'calc(100% - 180px)' : '100%',
          ...styles,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            height: '100%',
            width: '100%',
          }}
        >
          <DeliveredAsset assetUrl={stageGenerator.image} onLoad={onLoadHandler} />
        </Box>
      </animated.div>
      <Box
        sx={{
          backgroundColor: 'primary.main',
          width: '100%',
          pb: 4,
          opacity: 0.9,
          height: 120,
        }}
        position="fixed"
        bottom="0"
      >
        <animated.div style={{ width: '100%', display: 'flex', justifyContent: 'center', ...styles }}>
          <Box position="absolute" width="65vw">
            <Grid container direction="row" justifyContent="center" alignItems="center">
              {stageGenerator.emotionChoices?.map((choice, index) => {
                return (
                  <Grid
                    key={index}
                    item
                    xs={2}
                    sx={{
                      display: 'grid',
                      gridTemplateColumns: 'repeat(auto-fill, 1fr)',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '120px',
                      background: choice.color,
                      gridGap: 1,
                    }}
                  >
                    <Box
                      sx={{
                        height: '80px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'flex-end',
                        paddingBottom: solution == Solution.SmileyColor ? '5px' : '20px',
                      }}
                    >
                      {[Solution.TextColor].includes(solution) && (
                        <Typography
                          sx={{
                            fontSize: '20px',
                            color: 'black',
                          }}
                          fontWeight={500}
                        >
                          {choice.emotion.text.local}
                        </Typography>
                      )}
                      {[Solution.SmileyColor].includes(solution) && (
                        <MaskIcon
                          color={'primary.contrastText'}
                          url={choice.url}
                          height="70px"
                          width="70px"
                        />
                      )}
                    </Box>
                  </Grid>
                )
              })}
            </Grid>
          </Box>
        </animated.div>
      </Box>
    </>
  )
}
