import { useLocalStorage } from 'react-use'
import { useQueryRehabilitationPlansByPatientIdQuery } from '../rehabilitation-plans/rehabilitation-plan.generated'
import { useGlobalState } from '@proxyqb/cube-global-state'
import * as Types from '@proxyqb/graphql-api-types'
import { useParams, useSearchParams } from 'react-router-dom'

export const useRehabilitationPlan = (
  fetchPlan = true,
): {
  rehabilitationPlanId: string | null
  rehabilitationPlanName: string | undefined
  rehabilitationPlanRev?: string
  rehabPlanLevels?: Array<{
    __typename?: 'Level'
    id: string
    requiredCubes: Array<{ __typename?: 'RequiredCube'; pattern: Types.CubePatterns }>
  }>
  fetchedPlanId?: string
  isFirstLevelInRehabPlan(currentLevelId: string): boolean
  isLastLevelInRehabPlan(currentLevelId: string): boolean
  rehabPlanFetching: boolean
  levelStarted?(levelId: string): void
  /** When you finish rehabilitation plan, remove it from the storage. */
  rehabPlanFinished(): void
  nextLevelInPlan(currentLevelId: string): string | null
} => {
  const [params] = useSearchParams()
  const rehabilitationPlanId = params.get('rehabilitationPlanId')
  const { rehabilitationPlanId: rehabilitationPlanIdPathParam } = useParams<{
    rehabilitationPlanId: string
  }>()
  const planId = rehabilitationPlanId ?? rehabilitationPlanIdPathParam ?? null
  const [rehabPlanProgress, setRehabPlanProgress] = useLocalStorage<
    Record<string, Record<string, { levelId: string }>>
  >('rehabPlanProgress', {})
  const { selectedPatient } = useGlobalState()
  const selectedPatientId = selectedPatient?.id
  const [{ data: rehabPlanData, fetching: rehabPlanFetching }] = useQueryRehabilitationPlansByPatientIdQuery({
    pause: !planId || !fetchPlan || !selectedPatient,
    variables: {
      patientId: selectedPatientId!,
    },
  })
  const currentPlan = rehabPlanData?.queryRehabilitationPlansByPatientId?.find((plan) => plan.id === planId)
  const rehabPlanLevels = currentPlan?.levels
  const rehabilitationPlanRev = currentPlan?.rev
  const rehabilitationPlanName = currentPlan?.name.local
  return {
    rehabilitationPlanId: planId,
    rehabilitationPlanName,
    rehabilitationPlanRev,
    rehabPlanLevels,
    fetchedPlanId: currentPlan?.id,
    isFirstLevelInRehabPlan: (id) =>
      rehabPlanLevels?.findIndex?.(({ id: rehabPlanLevelId }) => rehabPlanLevelId === id) === 0,
    isLastLevelInRehabPlan: (id) =>
      !!rehabPlanLevels?.length &&
      rehabPlanLevels?.findIndex?.(({ id: rehabPlanLevelId }) => rehabPlanLevelId === id) ===
        rehabPlanLevels.length - 1,
    rehabPlanFetching,
    levelStarted: planId
      ? (levelId) => {
          if (rehabPlanProgress) {
            const userRehabPlans = Object.entries(rehabPlanProgress).find(
              ([patientId]) => patientId === selectedPatient!.id,
            )?.[1]
            const newRehabPlans = {
              ...userRehabPlans,
              [planId]: { levelId },
            }
            setRehabPlanProgress({
              ...rehabPlanProgress,
              [selectedPatient!.id]: { ...newRehabPlans },
            })
          }
        }
      : undefined,
    rehabPlanFinished: () => {
      if (planId && rehabPlanProgress) {
        setRehabPlanProgress({
          ...rehabPlanProgress,
          [selectedPatient!.id]: { ...rehabPlanProgress[selectedPatient!.id], [planId]: null },
        })
      }
    },
    nextLevelInPlan: (currentLevelId) => {
      const currentLevelIndex = rehabPlanLevels?.findIndex?.(
        ({ id: rehabPlanLevelId }) => rehabPlanLevelId === currentLevelId,
      )
      if (
        currentLevelIndex === undefined ||
        currentLevelIndex < 0 ||
        currentLevelIndex > rehabPlanLevels!.length - 2
      ) {
        return null
      } else {
        return rehabPlanLevels![currentLevelIndex + 1].id
      }
    },
  }
}
