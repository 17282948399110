import { useTheme } from '@mui/material'
import { useGlobalState } from '@proxyqb/cube-global-state'
import { Flex } from '@proxyqb/ui'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Screen, useCheckForUpdates } from '../shared'
import { HomeButton } from './home-button'
import { useQueryRehabilitationPlansByPatientIdQuery } from '../rehabilitation-plans/rehabilitation-plan.generated'
import { useLocalStorage } from 'react-use'

const navigateToGameOrPlanList = (navigate, rehabPlanId?: string, levelId?: string): void => {
  if (rehabPlanId && levelId) {
    navigate(`/prepare-plan/${rehabPlanId}`)
  } else navigate('/rehabilitation-plan-list')
}
function HomeScreen() {
  useCheckForUpdates()
  const theme = useTheme()
  const navigate = useNavigate()
  const { authState, authService, selectedPatient, setSelectedPatient } = useGlobalState()
  const roles = authState.context.userDetails?.roles
  const isPatient = roles?.includes('patient') && !roles?.some((role) => ['doctor', 'admin'].includes(role))
  const [rehabPlanProgress, _] = useLocalStorage<Record<string, Record<string, { levelId: string }>>>(
    'rehabPlanProgress',
    {},
  )

  const logout = () => authService.send({ type: 'LOG_OUT' })

  const [{ data: dataRehabPlan }] = useQueryRehabilitationPlansByPatientIdQuery({
    variables: {
      patientId: selectedPatient?.id || '',
    },
    pause: !selectedPatient?.id,
  })

  useEffect(() => {
    if (!selectedPatient && !isPatient) {
      navigate('/player-profiles')
    } else if (!selectedPatient && isPatient && authState.context.userDetails) {
      setSelectedPatient(authState.context.userDetails)
    }
  }, [selectedPatient, isPatient, navigate])

  const runRehabilitationPlan = (): void => {
    const usersPlansInProgress = Object.entries(rehabPlanProgress).find(
      ([patientId]) => patientId === authState?.context?.userDetails?.id,
    )?.[1]

    if (usersPlansInProgress) {
      if (Object.keys(usersPlansInProgress).length === 1) {
        const rehabPlanId = Object.keys(usersPlansInProgress)[0]
        const nextLevelInPlanId = Object.values(usersPlansInProgress)[0]?.levelId
        navigateToGameOrPlanList(navigate, rehabPlanId, nextLevelInPlanId)
      } else {
        navigateToGameOrPlanList(navigate)
      }
    } else if (dataRehabPlan && dataRehabPlan.queryRehabilitationPlansByPatientId.length === 1) {
      const { id, levels } = dataRehabPlan.queryRehabilitationPlansByPatientId[0]
      navigateToGameOrPlanList(navigate, id, levels[0]?.id)
    } else {
      navigateToGameOrPlanList(navigate)
    }
  }

  return (
    <div>
      {(selectedPatient || isPatient) && (
        <Screen
          primaryTitle="home.primaryTitle"
          backButtonLabel={isPatient ? '' : 'shared.back'}
          backButtonHandler={isPatient ? undefined : () => navigate('/player-profiles')}
          actions={
            isPatient
              ? [
                  {
                    handler: logout,
                    right: true,
                    label: 'shared.logout',
                  },
                ]
              : []
          }
        >
          <Flex gap="150px" justifyContent="center" alignItems="center" height="65vh" mt={10}>
            <HomeButton
              borderColor={theme.palette.primary.main}
              bgImage={`url(${'static/media/play.svg'})`}
              bgColor={theme.palette.primary.contrastText}
              onClick={() => navigate('/category-list')}
              typographyColor={theme.palette.primary.main}
              translationText={'home.chooseGame'}
              index={1}
            />
            <HomeButton
              borderColor={theme.palette.primary.main}
              bgImage={`url(${'static/media/continue.svg'})`}
              bgColor={theme.palette.primary.main}
              onClick={runRehabilitationPlan}
              typographyColor={theme.palette.primary.contrastText}
              translationText={'home.continueRehabilitationPlan'}
              index={2}
            />
          </Flex>
        </Screen>
      )}
    </div>
  )
}

export default HomeScreen
