import { Typography, useTheme } from '@mui/material'
import { Flex } from '@proxyqb/ui'
import { ReactElement, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useBoolean, useCounter, useHarmonicIntervalFn } from 'react-use'

interface GoalHiderProps {
  hideAfter?: number
  stageNumber: number
  onGoalsHidden?()
  renderCubes: boolean
}

export const GoalsHider = ({
  hideAfter,
  stageNumber,
  onGoalsHidden,
  renderCubes,
}: GoalHiderProps): ReactElement | null => {
  const intl = useIntl()
  const [goalsHidden, setGoalsHidden] = useBoolean(false)
  const [isCountdownRunning, setIsCountdownRunning] = useBoolean(false)
  const [countdownToHideGoals, { dec: decCountdown, reset: resetCountdown }] = useCounter(
    hideAfter ? hideAfter * 10 : 0,
    hideAfter ? hideAfter * 10 : 0,
    0,
  )
  useHarmonicIntervalFn(
    () => {
      decCountdown()
    },
    isCountdownRunning ? 100 : null,
  )
  useEffect(() => {
    if (countdownToHideGoals === 0 && hideAfter) {
      setGoalsHidden(true)
      setIsCountdownRunning(false)
      onGoalsHidden?.()
    }
  }, [hideAfter, countdownToHideGoals, resetCountdown, setGoalsHidden, setIsCountdownRunning])
  useEffect(() => {
    if (hideAfter) {
      resetCountdown()
      setGoalsHidden(false)
      setIsCountdownRunning(true)
    }
  }, [stageNumber, hideAfter, resetCountdown, setGoalsHidden, setIsCountdownRunning])

  const theme = useTheme()

  if (!goalsHidden && hideAfter) {
    return (
      <Flex
        position="absolute"
        width="100vw"
        alignItems="center"
        justifyContent="center"
        top="120px"
        left="0vh"
      >
        <Typography variant="h2">{Math.ceil(countdownToHideGoals / 10)}</Typography>
      </Flex>
    )
  }
  if (!goalsHidden) {
    return null
  }
  return (
    <Flex
      position="absolute"
      height={renderCubes ? '35vh' : '60vh'}
      width="100vw"
      alignItems="center"
      justifyContent="center"
      top="190px"
      background={theme.palette.background.default}
      zIndex={1}
    >
      <Typography variant="h1">{intl.formatMessage({ id: 'game.start' })}</Typography>
    </Flex>
  )
}
