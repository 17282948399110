import { initializeApp } from 'firebase/app'
import { initializePerformance } from 'firebase/performance'
import { browserLocalPersistence, connectAuthEmulator, initializeAuth } from 'firebase/auth'
import { env } from './env'

const firebaseConfig = {
  apiKey: env.firebase.apiKey,
  appId: env.firebase.appId,
  authDomain: env.firebase.authDomain,
  projectId: env.firebase.projectId,
}

const app = initializeApp(firebaseConfig)
const auth = initializeAuth(app, {
  persistence: browserLocalPersistence,
})

if (env.firebase.enablePerformance) {
  initializePerformance(app)
}
if (env.firebase.connectAuthEmulator) {
  connectAuthEmulator(auth, env.firebase.connectAuthEmulator)
}
