import { merge, shuffle } from 'lodash-es'
import { assign, createMachine, MachineConfig } from 'xstate'
import { MatchCubesBy, SwitchedAssetsGenerator } from '@proxyqb/graphql-api-types'
import {
  baseGeneratorMachineConfig,
  BaseGeneratorMachineContext,
  getCubeSides,
} from './base-generator-machine-config'
import { groupSidesByPattern } from './group-sides-by-pattern'
import { createCompareGoalsSwitched } from './create-compare-goals-switched'

type SwitchedAssetsStageGeneratorMachineContext = BaseGeneratorMachineContext & {
  prevMatchBy?: MatchCubesBy
}

export const DISPLAY2COMPARE_MAP = {
  [MatchCubesBy.Color]: {
    '5': 6, //     '5': 1,6
    '6': 3, //     '6': 2,3
    '1': 2, //     '1': 3,2
    '2': 4, //     '2': 4,4
    '3': 5, //     '3': 5,5
    '4': 1, //     '4': 6,1
  },
  [MatchCubesBy.Value]: {
    '1': 1,
    '2': 2,
    '3': 3,
    '4': 4,
    '5': 5,
    '6': 6,
    '7': 7,
    '8': 8,
    '9': 9,
    '10': 10,
    '11': 11,
    '12': 12,
  },
} as const

export const DISPLAY2COMPARE_MAP_NORMAL = {
  [MatchCubesBy.Color]: {
    '1': 1,
    '2': 2,
    '3': 3,
    '4': 4,
    '5': 5,
    '6': 6,
  },
  [MatchCubesBy.Value]: {
    '1': 1,
    '2': 2,
    '3': 3,
    '4': 4,
    '5': 5,
    '6': 6,
    '7': 7,
    '8': 8,
    '9': 9,
    '10': 10,
    '11': 11,
    '12': 12,
  },
} as const

const DISPLAY2COMPARE_MAP_REVERSE = {
  [MatchCubesBy.Color]: {
    '6': 5,
    '3': 6,
    '2': 1,
    '4': 2,
    '5': 3,
    '1': 4,
  },
  [MatchCubesBy.Value]: {
    '1': 1,
    '2': 2,
    '3': 3,
    '4': 4,
    '5': 5,
    '6': 6,
    '7': 7,
    '8': 8,
    '9': 9,
    '10': 10,
    '11': 11,
    '12': 12,
  },
}

type ColorOrValue = typeof MatchCubesBy.Color | typeof MatchCubesBy.Value

// TODO: oh jeez, this is shit. Need a ton of logic but works for our simple games...
const calculateNextSide = assign<SwitchedAssetsStageGeneratorMachineContext>((context) => {
  const stageGenerator = context.level.stageGenerator as SwitchedAssetsGenerator
  const sides: Record<string, number | null> = {}
  context.cubes.forEach(({ id, getCubeCurrentSide }) => {
    sides[id] = getCubeCurrentSide()
  })
  const currentSidesByPattern = groupSidesByPattern(sides, context.cubes)

  const alternateMatching = stageGenerator.goalPatterns.some(
    (e) => e.matchBy === MatchCubesBy.AlternatePeriodically || e.matchBy === MatchCubesBy.AlternateRandomly,
  ) //every cube have its own match, not synchronized (matchby is not alternate)
  const alternateMatchBy: ColorOrValue[] | null = alternateMatching
    ? (() => {
        if (stageGenerator.syncMatchBy) {
          const matchBy = stageGenerator.goalPatterns[0].matchBy
          let matchByColorOrValue
          if (matchBy === MatchCubesBy.AlternateRandomly || context.displayGoals.length === 0) {
            matchByColorOrValue = [MatchCubesBy.Value, MatchCubesBy.Color][Math.round(Math.random())]
          } else {
            //matchBy === MatchCubesBy.AlternatePeriodically
            matchByColorOrValue =
              context.displayGoals[0].matchBy === MatchCubesBy.Value ? MatchCubesBy.Color : MatchCubesBy.Value
          }
          return Array(stageGenerator.goalPatterns.length).fill(matchByColorOrValue) as ColorOrValue[]
        }

        // Not synchronized
        return context.cubes
          .map(({ pattern }) => stageGenerator.goalPatterns.find((goal) => goal.cubePattern === pattern))
          .map(({ matchBy }, index) => {
            // randomly or first stage, so randomly select one
            if (matchBy === MatchCubesBy.AlternateRandomly || !context.displayGoals[index]) {
              return [MatchCubesBy.Value, MatchCubesBy.Color][Math.round(Math.random())]
            }
            if (matchBy === MatchCubesBy.AlternatePeriodically) {
              return context.displayGoals[index].matchBy === MatchCubesBy.Value
                ? MatchCubesBy.Color
                : MatchCubesBy.Value
            }
            return matchBy
          })
      })()
    : null

  const filterCurrentSides = (cube, cubeIndex) => (side) =>
    !currentSidesByPattern[cube.pattern]
      ?.map((side) =>
        alternateMatchBy?.[cubeIndex] === MatchCubesBy.Color
          ? DISPLAY2COMPARE_MAP_REVERSE[MatchCubesBy.Color][side]
          : side,
      )
      .includes(side)

  const getAvailableSides = (cube, cubeArray, cubeIndex) => {
    const cubesWithSamePatternSides = cubeArray
      .filter((c) => c.originalPattern == cube.pattern)
      .map((c) => c.side)
    if (context.compareGoals.length === 0) {
      return getCubeSides(context.level.stageGenerator)
        .filter((side) => !cubesWithSamePatternSides.includes(side))
        .filter(filterCurrentSides(cube, cubeIndex))
    } else {
      // Make sure new compareGoal is different than any previous compareGoal for a cube with the same pattern
      const prevDisplayGoalsForPattern = context.displayGoals.filter(
        (g) => g.originalPattern === cube.pattern,
      )
      const prevSides = prevDisplayGoalsForPattern.map((prevDisplayGoal) =>
        prevDisplayGoal?.matchBy && alternateMatchBy
          ? DISPLAY2COMPARE_MAP_REVERSE[alternateMatchBy[cubeIndex]][
              DISPLAY2COMPARE_MAP[prevDisplayGoal.matchBy][prevDisplayGoal.side]
            ]
          : prevDisplayGoal?.side,
      )

      return getCubeSides(context.level.stageGenerator)
        .filter((side) => !prevSides.includes(side) && !cubesWithSamePatternSides.includes(side))
        .filter(filterCurrentSides(cube, cubeIndex))
    }
  }

  const displayGoals = context.cubes.reduce((accumulator, current, currentIndex) => {
    const side = shuffle(getAvailableSides(current, accumulator, currentIndex)).pop()
    accumulator = [
      ...accumulator,
      {
        side: side,
        id: current.id,
        originalPattern: current.pattern,
        pattern: stageGenerator.goalPatterns.find((goal) => goal.cubePattern === current.pattern).goalPattern,
        matchBy:
          alternateMatchBy?.[currentIndex] ??
          stageGenerator.goalPatterns.find((goal) => goal.cubePattern === current.pattern)?.matchBy,
      },
    ]

    return accumulator
  }, [])

  const compareGoals = createCompareGoalsSwitched(context.cubes, displayGoals)

  return {
    displayGoals,
    compareGoals,
  }
})
const decrementCount = assign<SwitchedAssetsStageGeneratorMachineContext>({
  stagesCount: (context) => context.stagesCount - 1,
})
const stagesCountNotZero = (context) => {
  return context.stagesCount !== 0
}

const recordStage = assign<SwitchedAssetsStageGeneratorMachineContext>((context, event, meta) => {
  const stageHistory = context.stageHistory
  const displayGoals = context.displayGoals
  const stageArray = []

  for (const x of displayGoals) {
    const goal = { side: x.side, pattern: x.pattern }
    stageArray.push(goal)
  }

  const stage = {
    stageGoals: stageArray,
    result: meta?.state?.event?.result, // result exist on some levels only?
    type: meta?.state?.event?.type,
  }

  return { stageHistory: [...stageHistory, stage] }
})

type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>
}

type ConfigType = MachineConfig<SwitchedAssetsStageGeneratorMachineContext, any, any, any>

const extension: DeepPartial<ConfigType> = {
  id: 'switchedAssetsStageGeneratorMachine',
}
const config = merge(extension, baseGeneratorMachineConfig) as ConfigType

export const switchedAssetsStageGeneratorMachine = createMachine<SwitchedAssetsStageGeneratorMachineContext>(
  config,
  {
    actions: { recordStage, calculateNextSide, decrementCount },
    guards: {
      stagesCountNotZero,
    },
  },
)
