import { Typography } from '@mui/material'
import { Flex, Text, CubeVersion } from '@proxyqb/ui'
import { useIntl } from 'react-intl'
import { CubePatterns } from '@proxyqb/graphql-api-types'
import { CubeStatus, resolveCubeStatus } from '../../helpers'
import { BluetoothCubeRenderer } from '../bluetooth/BluetoothCubeRenderer'
import { CubeData } from '../bluetooth/useCubes'
import { CanvasScene } from '../canvas-scene'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useSpring, animated, useSpringRef, config } from 'react-spring'
import { usePrevious } from 'react-use'
import { Quaternion } from 'three'
import { env } from '../../env'

interface Props {
  cube?: CubeData
  pattern: CubePatterns
  debugMode?: boolean
  totalNumber: number
  inModalWindow: boolean
  cubeVersion: CubeVersion
}

const getCanvasSceneSize = (inModalWindow: boolean, totalNumber: number) => {
  if (inModalWindow) {
    return '100px'
  } else if (totalNumber < 4) {
    return '300px'
  } else return '170px'
}

const getColorByCubeStatus = (status) => (status === CubeStatus.READY ? 'green' : 'red')

export const LoadingCube = () => {
  const url = `${env.assetDeliveryUrl}/assets/transform/cube_loading.webm`
  return (
    <video
      style={{ opacity: 0.6, pointerEvents: 'none' }}
      loop
      autoPlay
      height={'200px'}
      controlsList="noremoteplayback"
    >
      <source src={url} type="video/webm" />
    </video>
  )
}

const assetKeys = {
  [CubePatterns.Colors]: 'new-green',
  [CubePatterns.ColorfulLetters]: 'new-d',
  [CubePatterns.ColorfulNumbers]: 'new-4',
}

const assetKeysV3 = {
  [CubePatterns.Colors]: 'new-blue',
  [CubePatterns.ColorfulLetters]: 'new-a',
  [CubePatterns.ColorfulNumbers]: 'new-1',
}

export const CubeStatusView = ({
  cube,
  pattern,
  debugMode,
  totalNumber,
  inModalWindow,
  cubeVersion,
}: Props) => {
  const cubeStatus = resolveCubeStatus(cube)
  const intl = useIntl()
  const center = window.innerWidth / 2
  const ref = useRef()
  const springRef = useSpringRef()
  const style = useSpring({
    ref: springRef,
    from: { opacity: 0, transform: 'translateX(0)' },
    to: { transform: 'translateX(0)' },
  })
  const [animate, setAnimate] = useState(false)

  const measuredRef = useCallback((node) => {
    if (node) {
      ref.current = node
      setAnimate(true)
    }
  }, [])

  const prevAnimate = usePrevious(animate)

  useEffect(() => {
    if (animate && !prevAnimate) {
      const position =
        center - ref.current.getBoundingClientRect().left - ref.current.getBoundingClientRect().width / 2
      springRef.start({
        from: { opacity: 0, transform: `translateX(${position}px)` },
        to: { opacity: 1, transform: 'translateX(0)' },
        config: config.gentle,
      })
    }
  }, [animate])

  return (
    <animated.div style={style}>
      <Flex flexDirection="column" padding="20px" alignItems="center">
        <div ref={measuredRef}>
          <Typography
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              textAlign: 'center',
            }}
            variant={inModalWindow || totalNumber > 3 ? 'h6' : 'h4'}
          >
            {intl.formatMessage({ id: `requiredCubes.${pattern}` })}
          </Typography>
          <img
            height={getCanvasSceneSize(inModalWindow, totalNumber)}
            src={`${env.assetDeliveryUrl}/assets/transform/${
              (cubeVersion === CubeVersion.v3 ? assetKeysV3[pattern] : assetKeys[pattern]) || 'new-a'
            }.webp`}
          />
          <Text
            t={`prepareGame.${cubeStatus}`}
            color={getColorByCubeStatus(cubeStatus)}
            fontWeight={500}
            size={17}
            textAlign="center"
          />
          {cube?.isConnected && debugMode ? (
            <CanvasScene width="100px" height="100px">
              <BluetoothCubeRenderer cubeId={cube?.id} scale={2.5} cubeVersion={cubeVersion} />
            </CanvasScene>
          ) : null}
        </div>
      </Flex>
    </animated.div>
  )
}
