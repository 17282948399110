// import 'source-map-support/register'
import './app/index.css'
import './app/initFirebase'
import * as Sentry from '@sentry/browser'
import { Integrations } from '@sentry/tracing'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { registerSW } from 'virtual:pwa-register' // eslint-disable-line import/no-unresolved
import App from './app/App'
import { env } from './app/env'
import { registerServiceWorker } from './app/shared'

// Register service worker
registerSW({
  onRegistered(r) {
    registerServiceWorker(r)
  },
})

if (env.environment !== 'local') {
  Sentry.init({
    dsn: 'https://b16231297e864b3599a7d08f500d5337@sentry.prorocketeers.com/6',
    beforeSend(event, hint) {
      const error = hint?.originalException
      if (error instanceof DOMException && error.code == 8) {
        //DOMException NotFoundError code value: 8
        return null
      }
      return event
    },
    integrations: [new Integrations.BrowserTracing()],
    environment: env.environment,
    release: env.release,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  })
}

const container = document.getElementById('root')
const root = createRoot(container!)
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)
