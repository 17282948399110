import { ReactElement, ReactNode } from 'react'
import { Box, LinearProgress, Skeleton, Typography } from '@mui/material'
import { useRehabilitationPlan } from '../../use-rehabilitation-plan'
import { useIntl } from 'react-intl'

interface Props {
  levelId?: string
  isInPrepareGame: boolean
  loading?: boolean
}

export const RehabilitationPlanProgress = ({ levelId, isInPrepareGame, loading }: Props): ReactElement => {
  const { rehabilitationPlanName, rehabPlanLevels } = useRehabilitationPlan()
  const { formatMessage } = useIntl()

  const levels = rehabPlanLevels?.map((l) => l.id) ?? []
  const indexOfCurrentLevel = levels.indexOf(levelId!)

  const levelsLeft = isInPrepareGame
    ? levels.length - indexOfCurrentLevel
    : levels.length - indexOfCurrentLevel - 1
  const progress = ((indexOfCurrentLevel + 1) / levels.length) * 100

  const getLevelsLeftText = (): ReactNode => {
    if (loading) {
      return <Skeleton width={400} />
    } else if (levelsLeft === 0) {
      return formatMessage({ id: 'levelFinished.planFinished' }, { name: rehabilitationPlanName })
    } else
      return formatMessage(
        { id: 'levelFinished.gamesLeft' },
        { count: levelsLeft, name: rehabilitationPlanName },
      )
  }

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Typography variant={'h6'}>{getLevelsLeftText()}</Typography>
      </Box>
      {!isInPrepareGame && (
        <Box width={'92%'} sx={{ mx: 'auto' }}>
          <LinearProgress variant="determinate" value={progress} />
        </Box>
      )}
    </>
  )
}
