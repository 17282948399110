import { v4 as uuidv4 } from 'uuid'
import { useEffect } from 'react'

export const GameEventType = {
  STAGE_SUCCESS: 'stageFinished',
  STAGE_FAILURE: 'stageFailure',
  PROGRESS_ANIMATION_FINISHED: 'progressAnimationFinished',
  SUCCESS_ANIMATION_FINISHED: 'successAnimationFinished',
  IMAGE_GOAL_LOADED: 'imageGoalLoaded',
  IMAGE_GOAL_LOADING: 'imageGoalLoading',
} as const

type GameEventType = typeof GameEventType[keyof typeof GameEventType]

interface GameEvent {
  type: GameEventType
}

export interface StageSuccessEvent extends GameEvent {
  type: 'stageFinished'
  isLastStage: boolean
}

export interface GameEventListener {
  type: GameEventType
  handler: (event: GameEvent) => void
}

const listeners: Record<string, GameEventListener> = {}

export function useGameEvents(eventListeners: GameEventListener[] = [], dependencies: any[] = []) {
  const registerListener = (listener: GameEventListener): string => {
    const uid = uuidv4()
    listeners[uid] = listener
    return uid
  }

  const unregisterListener = (uid) => {
    if (listeners[uid]) {
      delete listeners[uid]
    }
  }

  useEffect(() => {
    const ids = eventListeners.map(registerListener)
    return () => {
      ids.forEach(unregisterListener)
    }
  }, dependencies)

  function fireEvent<T extends GameEvent = GameEvent>(event: T) {
    Object.values(listeners)
      .filter(({ type }) => type === event.type)
      .forEach(({ handler }) => handler(event))
  }

  return {
    registerListener,
    unregisterListener,
    fireEvent,
  }
}
