import { BaseCube, BaseCubeProps, baseRotationQuaternion } from '@proxyqb/ui'
import { GameEventType, StageSuccessEvent, useGameEvents } from '../useGameEvents'
import { useRef, useEffect } from 'react'
import { Quaternion } from 'three'

export { baseRotationQuaternion }

export type GameCubeProps = Omit<BaseCubeProps, 'animationState'> & { cubeIndex?: number }
export type AnimationState = {
  animationLoop: boolean
  animate: boolean
  finalQuaternion: Quaternion
}

export const GameCube = (props: GameCubeProps) => {
  const animationState = useRef<AnimationState>({
    animationLoop: false,
    animate: false,
    finalQuaternion: new Quaternion(),
  })
  const { cubeIndex = 0 } = props

  const { registerListener, unregisterListener } = useGameEvents()

  const eventListener = {
    type: GameEventType.STAGE_SUCCESS,
    handler: (event) => {
      setTimeout(() => {
        if (!(event as StageSuccessEvent).isLastStage) {
          animationState.current.animationLoop = true
          animationState.current.animate = false
        }
      }, 200 + cubeIndex * 200)
    },
  }
  useEffect(() => {
    if (props.animate) {
      const listenerId = registerListener(eventListener)
      return () => {
        unregisterListener(listenerId)
      }
    }
  }, [props.animate])

  return <BaseCube {...props} animationState={animationState} />
}
