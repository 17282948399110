import { MouseEventHandler, ReactElement, ReactEventHandler } from 'react'
import { useFlagsmith } from '@proxyqb/react-feature-flags'

interface Props {
  assetUrl?: string
  onLoad?: ReactEventHandler
  onClick?: MouseEventHandler<HTMLImageElement | HTMLVideoElement>
  width?: number
  height?: number
}

export const DeliveredAsset = ({ assetUrl, onLoad, width, height, onClick }: Props): ReactElement | null => {
  const { flags } = useFlagsmith()

  if (!assetUrl) {
    return null
  }

  const query = new URLSearchParams()
  width && query.set('width', width.toString())
  height && query.set('height', height.toString())
  flags.remove_background?.enabled && query.set('replaceBG', 'true')
  const src = `${assetUrl}?${query.toString()}`

  const isVideo = assetUrl.includes('.webm')
  return isVideo ? (
    <video
      // Video does not reload because you change src as img does. Use key to rerender it
      key={assetUrl}
      autoPlay
      loop
      style={{ height: '100%', width: '100%', objectFit: 'fill' }}
      onLoadedData={onLoad}
      onClick={onClick}
    >
      <source src={src} type="video/webm" />
    </video>
  ) : (
    <img onLoad={onLoad} style={{ height: '100%' }} src={src} onClick={onClick} />
  )
}
