export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AccountNumber: any;
  BigInt: any;
  Byte: any;
  CountryCode: any;
  Currency: any;
  DID: any;
  Date: any;
  DateTime: any;
  Duration: any;
  EmailAddress: any;
  GUID: any;
  HSL: any;
  HSLA: any;
  HexColorCode: any;
  Hexadecimal: any;
  IBAN: any;
  IPv4: any;
  IPv6: any;
  ISBN: any;
  ISO8601Duration: any;
  JSON: any;
  JSONObject: any;
  JWT: any;
  Latitude: any;
  LocalDate: any;
  LocalEndTime: any;
  LocalTime: any;
  Locale: any;
  Long: any;
  Longitude: any;
  MAC: any;
  NegativeFloat: any;
  NegativeInt: any;
  NonEmptyString: any;
  NonNegativeFloat: any;
  NonNegativeInt: any;
  NonPositiveFloat: any;
  NonPositiveInt: any;
  ObjectID: any;
  PhoneNumber: any;
  Port: any;
  PositiveFloat: any;
  PositiveInt: any;
  PostalCode: any;
  RGB: any;
  RGBA: any;
  RoutingNumber: any;
  SafeInt: any;
  Time: any;
  TimeZone: any;
  Timestamp: any;
  URL: any;
  USCurrency: any;
  UUID: any;
  UnsignedFloat: any;
  UnsignedInt: any;
  UtcOffset: any;
  Void: any;
};

export type Ability = {
  __typename: 'Ability';
  rules: Array<AbilityRule>;
};

export type AbilityRule = {
  __typename: 'AbilityRule';
  action: Array<Scalars['String']>;
  /**  an object of conditions which restricts the rule scope  */
  conditions?: Maybe<Scalars['JSONObject']>;
  /**  indicates whether rule allows or forbids something  */
  inverted?: Maybe<Scalars['Boolean']>;
  /**  message which explains why rule is forbidden  */
  reason?: Maybe<Scalars['String']>;
  subject: Array<Scalars['String']>;
};

export type AccessRight = {
  __typename: 'AccessRight';
  accessRights: Array<AccessRightType>;
  createdAt: Scalars['DateTime'];
  createdBy: User;
  from: Scalars['ID'];
  to: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  updatedBy: User;
};

export type AccessRightForNode = {
  __typename: 'AccessRightForNode';
  accessRights: Array<AccessRight>;
  node: Identifiable;
};

export const AccessRightType = {
  ReadDocument: 'readDocument',
  RemoveDocument: 'removeDocument',
  UpdateDocument: 'updateDocument',
  UpdateRights: 'updateRights'
} as const;

export type AccessRightType = typeof AccessRightType[keyof typeof AccessRightType];
export type AccessRightsFilter = {
  node?: InputMaybe<AccessRightsNodeFilter>;
};

export type AccessRightsNodeFilter = {
  id?: InputMaybe<IdFilter>;
};

export type ActivateUser = {
  disabled?: InputMaybe<Scalars['Boolean']>;
};

export type ActivityLog = {
  __typename: 'ActivityLog';
  /** e.g. You edited name, description and type. */
  description: Scalars['String'];
  id: Scalars['ID'];
  timestamp: Scalars['DateTime'];
  user: PublicUser;
};

export type Admin = Identifiable & User & {
  __typename: 'Admin';
  /** Changes to this user made by users. */
  activityLogs: Array<ActivityLog>;
  /** Whether this user is activated or not. e.g. when doctor registers from public page, it will be automatically marked as disabled. */
  disabled: Scalars['Boolean'];
  doctorGroups: PaginatedDoctorGroupRights;
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  firstNameDeburr: Scalars['String'];
  id: Scalars['ID'];
  isDeleted: Scalars['Boolean'];
  lastName: Scalars['String'];
  lastNameDeburr: Scalars['String'];
  patientGroups: PaginatedPatientGroupRights;
  patients: PaginatedPatientRights;
  phoneNumber?: Maybe<Scalars['String']>;
  preferences: UserPreferences;
  roles: Array<Role>;
  /** Changes made by this user. */
  userActivityLogs: Array<UserGroupedActivityLog>;
};


export type AdminDoctorGroupsArgs = {
  pagination?: InputMaybe<Pagination>;
};


export type AdminPatientGroupsArgs = {
  pagination?: InputMaybe<Pagination>;
};


export type AdminPatientsArgs = {
  filter?: InputMaybe<PatientRightsFilter>;
  pagination?: InputMaybe<Pagination>;
};

export type Aggregations = {
  __typename: 'Aggregations';
  count?: Maybe<Scalars['Int']>;
};

export const Assignment = {
  Photo: 'photo',
  Smiley: 'smiley',
  Video: 'video'
} as const;

export type Assignment = typeof Assignment[keyof typeof Assignment];
export type AverageScore = {
  __typename: 'AverageScore';
  averageScore: Scalars['Float'];
  levelDomains: Array<Maybe<Scalars['String']>>;
  levelId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type BooleanFilter = {
  eq?: InputMaybe<Scalars['Boolean']>;
  equals?: InputMaybe<Scalars['Boolean']>;
  filterType?: InputMaybe<Scalars['String']>;
  ne?: InputMaybe<Scalars['Boolean']>;
  notEquals?: InputMaybe<Scalars['Boolean']>;
};

export type BucketImage = {
  __typename: 'BucketImage';
  bucket: Scalars['String'];
  fileName: Scalars['String'];
  signedUrl: Scalars['String'];
};

export type BucketImageInput = {
  bucket: Scalars['String'];
  fileName: Scalars['String'];
};

export const CacheNamespace = {
  Catalogs: 'catalogs',
  Levels: 'levels',
  Users: 'users'
} as const;

export type CacheNamespace = typeof CacheNamespace[keyof typeof CacheNamespace];
export type Catalog = {
  __typename: 'Catalog';
  id: Scalars['ID'];
  items: Array<CatalogItem>;
  name: Scalars['String'];
};

export type CatalogInput = {
  name: Scalars['String'];
};

export type CatalogItem = {
  __typename: 'CatalogItem';
  code?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  items: Array<CatalogItem>;
  text: LocalizedText;
};

export type CatalogItemFilter = {
  id?: InputMaybe<IdFilter>;
};

export type CatalogItemInput = {
  catalogId: Scalars['ID'];
  code?: InputMaybe<Scalars['String']>;
  parentId: Scalars['ID'];
  text: LocalizedTextInput;
};

export type CatalogUpdateInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type CreatePatient = {
  dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  isDeleted: Scalars['Boolean'];
  lastName: Scalars['String'];
  patientType?: InputMaybe<PatientType>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  preferences: UserPreferencesInput;
  sex?: InputMaybe<Sex>;
};

export const CubeGeometry = {
  Edge: 'edge',
  Face: 'face',
  Vertex: 'vertex'
} as const;

export type CubeGeometry = typeof CubeGeometry[keyof typeof CubeGeometry];
export const CubePatterns = {
  BlackAf: 'BLACK_AF',
  BlackGl: 'BLACK_GL',
  BlackNumbers: 'BLACK_NUMBERS',
  ColorfulLetters: 'COLORFUL_LETTERS',
  ColorfulLettersAlt: 'COLORFUL_LETTERS_ALT',
  ColorfulNumbers: 'COLORFUL_NUMBERS',
  ColorfulNumbersAlt: 'COLORFUL_NUMBERS_ALT',
  Colors: 'COLORS'
} as const;

export type CubePatterns = typeof CubePatterns[keyof typeof CubePatterns];
export type DailyActivity = {
  __typename: 'DailyActivity';
  date: Scalars['Date'];
  totalTime: Scalars['Float'];
};

export type DateFilter = {
  filterType?: InputMaybe<Scalars['String']>;
  isAfter?: InputMaybe<Scalars['DateTime']>;
  isBefore?: InputMaybe<Scalars['DateTime']>;
  isBetween?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type Doctor = Identifiable & User & {
  __typename: 'Doctor';
  /** Changes to this user made by users. */
  activityLogs: Array<ActivityLog>;
  disabled: Scalars['Boolean'];
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  firstNameDeburr: Scalars['String'];
  /** This doctor have access rights to these groups. This doctor does not have be in these groups. */
  hasRightsGroups: Array<DoctorGroupRights>;
  hideGlobalLevels?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  isDeleted: Scalars['Boolean'];
  isInFirebase?: Maybe<Scalars['Boolean']>;
  /** This doctor is in these doctor groups. This doctor does not have to have any accessRight to these groups. */
  isInGroups: Array<DoctorGroup>;
  lastName: Scalars['String'];
  lastNameDeburr: Scalars['String'];
  /** This doctor has access rights to these patients groups. */
  patientGroups: PaginatedPatientGroupRights;
  /** This doctor has access rights to these patients. It may not be direct link but access right through groups. */
  patients: PaginatedPatientRights;
  phoneNumber?: Maybe<Scalars['String']>;
  postnominalLetters?: Maybe<Scalars['String']>;
  preferences: UserPreferences;
  prenominalLetters?: Maybe<Scalars['String']>;
  roles: Array<Role>;
  /** Changes made by this user. */
  userActivityLogs: Array<UserGroupedActivityLog>;
};


export type DoctorPatientGroupsArgs = {
  pagination?: InputMaybe<Pagination>;
};


export type DoctorPatientsArgs = {
  filter?: InputMaybe<PatientRightsFilter>;
  pagination?: InputMaybe<Pagination>;
};

export type DoctorGroup = {
  __typename: 'DoctorGroup';
  collectionName: Scalars['String'];
  id: Scalars['ID'];
  name: LocalizedText;
  nodes: Array<Doctor>;
};

export type DoctorGroupRights = {
  __typename: 'DoctorGroupRights';
  accessRights: Array<AccessRightType>;
  id: Scalars['ID'];
  node: DoctorGroup;
};

export type DocumentGroup = DocumentGroupBase & Identifiable & {
  __typename: 'DocumentGroup';
  accessRights: Array<AccessRight>;
  collectionName: Scalars['String'];
  id: Scalars['ID'];
  name: LocalizedText;
  nodes: Array<Identifiable>;
};

export type DocumentGroupBase = {
  collectionName: Scalars['String'];
  id: Scalars['ID'];
  name: LocalizedText;
  nodes: Array<Identifiable>;
};

export type DocumentGroupInput = {
  collectionName: Scalars['String'];
  name: LocalizedTextInput;
  nodes: Array<Scalars['ID']>;
  ownerId?: InputMaybe<Scalars['ID']>;
};

export type DocumentNode = Doctor | DocumentGroup | Patient;

export type Emotion = {
  __typename: 'Emotion';
  correct: Scalars['Boolean'];
  emotion: CatalogItem;
};

export type EmotionInput = {
  correct: Scalars['Boolean'];
  emotion: Scalars['ID'];
};

export type ErrorCounter = {
  __typename: 'ErrorCounter';
  millisecondsToConfirmNoMovement?: Maybe<Scalars['Int']>;
};

export type ErrorCounterInput = {
  millisecondsToConfirmNoMovement?: InputMaybe<Scalars['Int']>;
};

export type Firmware = {
  __typename: 'Firmware';
  name: Scalars['String'];
  url: Scalars['String'];
  version: Scalars['String'];
};

export type FirmwareInput = {
  name: Scalars['String'];
  url: Scalars['String'];
  version: Scalars['String'];
};

export type FloatFilter = {
  eq?: InputMaybe<Scalars['Float']>;
  equals?: InputMaybe<Scalars['Float']>;
  filterType?: InputMaybe<Scalars['String']>;
};

export type FulltextSearch = {
  /** the attribute name of the attribute to search in */
  attribute: Scalars['String'];
  /** a fulltext search expression */
  query: Scalars['String'];
};

export const GoalDistribution = {
  Random: 'random',
  RotateRight: 'rotateRight',
  Static: 'static'
} as const;

export type GoalDistribution = typeof GoalDistribution[keyof typeof GoalDistribution];
export type GoalFilter = {
  _typename?: InputMaybe<StringFilter>;
};

export type GoalPattern = {
  __typename: 'GoalPattern';
  cubePattern?: Maybe<CubePatterns>;
  goalPattern?: Maybe<CubePatterns>;
  matchBy: MatchCubesBy;
};

export type GoalPatternInput = {
  cubePattern?: InputMaybe<CubePatterns>;
  goalPattern?: InputMaybe<CubePatterns>;
  matchBy: MatchCubesBy;
};

export type HasManyCatalogItems = {
  __typename: 'HasManyCatalogItems';
  catalogName: Scalars['String'];
  flatItems: Array<CatalogItem>;
  items: Array<CatalogItem>;
};

export type HasManyCatalogItemsInput = {
  catalogName: Scalars['String'];
  items: Array<Scalars['ID']>;
};

export type HasOneCatalogItem = {
  __typename: 'HasOneCatalogItem';
  catalogName: Scalars['String'];
  items: CatalogItem;
};

export type HasOneCatalogItemInput = {
  catalogName: Scalars['String'];
  items: Scalars['ID'];
};

export type IdArrayFilter = {
  filterType?: InputMaybe<Scalars['String']>;
  isAllOf?: InputMaybe<Array<Scalars['ID']>>;
  isAnyOf?: InputMaybe<Array<Scalars['ID']>>;
};

export type IdFilter = {
  eq?: InputMaybe<Scalars['ID']>;
  equals?: InputMaybe<Scalars['ID']>;
  filterType?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['ID']>>;
  startsWith?: InputMaybe<Scalars['ID']>;
};

export type Identifiable = {
  id: Scalars['ID'];
};

export type ImageWordGenerator = {
  __typename: 'ImageWordGenerator';
  goal: LevelGoal;
  id: StageGeneratorId;
  imageWordSelect: ImageWordSelect;
  letterCount: Scalars['NonNegativeInt'];
  letterPosition: LetterPosition;
};

export type ImageWordGeneratorInput = {
  goal: LevelGoalInput;
  id: StageGeneratorId;
  imageWordSelect: ImageWordSelect;
  letterCount: Scalars['NonNegativeInt'];
  letterPosition: LetterPosition;
};

export const ImageWordSelect = {
  ImageSpeech: 'imageSpeech',
  ImageText: 'imageText',
  ImageTextSpeech: 'imageTextSpeech',
  SpeechOnly: 'speechOnly',
  TextOnly: 'textOnly'
} as const;

export type ImageWordSelect = typeof ImageWordSelect[keyof typeof ImageWordSelect];
export type IntFilter = {
  eq?: InputMaybe<Scalars['Int']>;
  equals?: InputMaybe<Scalars['Int']>;
  filterType?: InputMaybe<Scalars['String']>;
};

export type IsDocumentInGroupsOutput = {
  __typename: 'IsDocumentInGroupsOutput';
  groupId: Scalars['ID'];
  isInGroup: Scalars['Boolean'];
  name: LocalizedText;
};

export const LetterPosition = {
  Random: 'random',
  Start: 'start'
} as const;

export type LetterPosition = typeof LetterPosition[keyof typeof LetterPosition];
export type Level = {
  __typename: 'Level';
  /** Changes to this level made by users. */
  activityLogs: Array<ActivityLog>;
  allowedRotationDeviation?: Maybe<Scalars['Float']>;
  countErrors?: Maybe<Scalars['Boolean']>;
  errorCounter?: Maybe<ErrorCounter>;
  global?: Maybe<Scalars['Boolean']>;
  goalDistribution?: Maybe<GoalDistribution>;
  id: Scalars['ID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  levelId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  rehabilitationType: HasManyCatalogItems;
  renderCubes: Scalars['Boolean'];
  requiredCubes: Array<RequiredCube>;
  rev: Scalars['String'];
  revision?: Maybe<Scalars['String']>;
  shuffleQuaternion?: Maybe<Quaternion>;
  stageGenerator: StageGenerator;
  successBarTime: Scalars['Float'];
  taskDescription: LocalizedText;
  updatedAt: Scalars['Timestamp'];
  updatedBy: User;
  updatedFields: Array<Scalars['String']>;
  voiceAssistant: LevelVoiceAssistant;
  voiceSuccessStage?: Maybe<VoiceSuccessStage>;
};

export type LevelGoal = StageCountLevelGoal | TimeLevelGoal;

export type LevelGoalInput = {
  countGoal?: InputMaybe<StageCountLevelGoalInput>;
  timeGoal?: InputMaybe<TimeLevelGoalInput>;
};

export const LevelGoalType = {
  StageCountLevelGoal: 'StageCountLevelGoal',
  TimeLevelGoal: 'TimeLevelGoal'
} as const;

export type LevelGoalType = typeof LevelGoalType[keyof typeof LevelGoalType];
export type LevelGroupRights = {
  __typename: 'LevelGroupRights';
  accessRights: Array<AccessRightType>;
  id: Scalars['ID'];
  name?: Maybe<LocalizedText>;
};

export type LevelInput = {
  allowedRotationDeviation?: InputMaybe<Scalars['Float']>;
  countErrors?: InputMaybe<Scalars['Boolean']>;
  errorCounter?: InputMaybe<ErrorCounterInput>;
  global?: InputMaybe<Scalars['Boolean']>;
  goalDistribution: GoalDistribution;
  name: Scalars['String'];
  rehabilitationType: HasManyCatalogItemsInput;
  renderCubes: Scalars['Boolean'];
  requiredCubes: Array<RequiredCubeInput>;
  shuffleQuaternion?: InputMaybe<QuaternionInput>;
  stageGenerator: StageGeneratorInput;
  successBarTime: Scalars['Float'];
  taskDescription: LocalizedTextInput;
  voiceAssistant: LevelVoiceAssistantInput;
  voiceSuccessStage: VoiceSuccessStage;
};

export type LevelRun = {
  __typename: 'LevelRun';
  _from: Scalars['ID'];
  endedAt: Scalars['DateTime'];
  id: Scalars['ID'];
  stages: Array<StagePlaythroughSimple>;
};

export type LevelVoiceAssistant = {
  __typename: 'LevelVoiceAssistant';
  hideGoals: Scalars['Boolean'];
  isEnabled: Scalars['Boolean'];
};

export type LevelVoiceAssistantInput = {
  hideGoals: Scalars['Boolean'];
  isEnabled: Scalars['Boolean'];
};

export type LevelsAndAggregations = {
  __typename: 'LevelsAndAggregations';
  aggregations: Aggregations;
  nodes: Array<Level>;
};

export type LevelsFilter = {
  and?: InputMaybe<Array<InputMaybe<LevelsFilter>>>;
  global?: InputMaybe<BooleanFilter>;
  id?: InputMaybe<IdFilter>;
  name?: InputMaybe<StringFilter>;
  or?: InputMaybe<Array<InputMaybe<LevelsFilter>>>;
  rehabilitationType?: InputMaybe<RehabilitationTypeFilter>;
  stageGenerator?: InputMaybe<StageGeneratorFilter>;
};

export type LocalizedText = {
  __typename: 'LocalizedText';
  cs?: Maybe<Scalars['String']>;
  en?: Maybe<Scalars['String']>;
  is?: Maybe<Scalars['String']>;
  local: Scalars['String'];
  nl?: Maybe<Scalars['String']>;
  pl?: Maybe<Scalars['String']>;
};

export type LocalizedTextInput = {
  cs?: InputMaybe<Scalars['String']>;
  en?: InputMaybe<Scalars['String']>;
  is?: InputMaybe<Scalars['String']>;
  nl?: InputMaybe<Scalars['String']>;
  pl?: InputMaybe<Scalars['String']>;
};

export const MatchCubesBy = {
  AlternatePeriodically: 'alternatePeriodically',
  AlternateRandomly: 'alternateRandomly',
  Color: 'color',
  Value: 'value'
} as const;

export type MatchCubesBy = typeof MatchCubesBy[keyof typeof MatchCubesBy];
export type MovingTimes = {
  __typename: 'MovingTimes';
  cubeId: Scalars['String'];
  value: Scalars['Float'];
};

export type MovingTimesInput = {
  cubeId: Scalars['String'];
  value: Scalars['Float'];
};

export type MultipleStatsGlobalInput = {
  __typename: 'MultipleStatsGlobalInput';
  level?: Maybe<Level>;
  levelId: Scalars['ID'];
  scores: Array<Maybe<Scalars['Int']>>;
};

export type MultipleStatsInput = {
  __typename: 'MultipleStatsInput';
  level?: Maybe<Level>;
  levelId: Scalars['ID'];
  scores: Array<Maybe<Scalars['Int']>>;
  user?: Maybe<User>;
  userId: Scalars['ID'];
};

export type Mutation = {
  __typename: 'Mutation';
  activateUser: Scalars['ID'];
  addAccessRights?: Maybe<Scalars['Void']>;
  addAccessRightsFromMultipleDocuments?: Maybe<Scalars['Void']>;
  addCatalog: Scalars['ID'];
  addCatalogItem: Scalars['ID'];
  addDocumentToGroup?: Maybe<Scalars['Void']>;
  addDocumentsToGroup?: Maybe<Scalars['Void']>;
  addFirmware: Scalars['String'];
  addLevel: Scalars['ID'];
  addPatientGroupToRehabilitationPlan?: Maybe<Scalars['Void']>;
  addPatientGroupsToRehabilitationPlan?: Maybe<Scalars['Void']>;
  addPatientToRehabilitationPlan?: Maybe<Scalars['Void']>;
  addPatientToRehabilitationPlans?: Maybe<Scalars['Void']>;
  addPatientsToRehabilitationPlan?: Maybe<Scalars['Void']>;
  addPlaythrough: Scalars['ID'];
  addPlaythroughs: Array<Maybe<PlaythroughOutput>>;
  addRehabilitationPlan: Scalars['ID'];
  addSocialCognitionImage: Scalars['String'];
  addStats: Scalars['NonNegativeInt'];
  addStatsGlobal: Scalars['NonNegativeInt'];
  addUsersFirebaseRole?: Maybe<Scalars['Void']>;
  addWordImage: Scalars['String'];
  createDocumentGroup?: Maybe<DocumentGroup>;
  createPatient: Scalars['ID'];
  deactivateDoctor?: Maybe<Scalars['Void']>;
  deburrUsers?: Maybe<Scalars['Void']>;
  deleteLevel: Scalars['ID'];
  deleteRehabilitationPlan?: Maybe<Scalars['Void']>;
  deleteSocialCognitionImage?: Maybe<Scalars['Void']>;
  deleteUser: Scalars['ID'];
  deleteWordImage?: Maybe<Scalars['Void']>;
  exportLevels: Scalars['JSON'];
  flushCache?: Maybe<Scalars['Void']>;
  importLevels: Array<Maybe<Scalars['ID']>>;
  modifyPlaythroughs?: Maybe<Scalars['Void']>;
  registerDoctor: Scalars['ID'];
  registerPublicDoctor: Scalars['ID'];
  removeAccessRight?: Maybe<Scalars['Void']>;
  removeCatalog: Scalars['ID'];
  removeCatalogItem: Scalars['ID'];
  removeDocumentFromGroup?: Maybe<Scalars['Void']>;
  removeDocumentGroup?: Maybe<Scalars['Void']>;
  removePatientFromRehabilitationPlan?: Maybe<Scalars['Void']>;
  removePatientGroupFromRehabilitationPlan?: Maybe<Scalars['Void']>;
  removePatientGroupsFromRehabilitationPlan?: Maybe<Scalars['Void']>;
  removePatientsFromRehabilitationPlan?: Maybe<Scalars['Void']>;
  renameDocumentGroup?: Maybe<DocumentGroup>;
  sendPasswordResetEmail?: Maybe<Scalars['Void']>;
  setLevelsGlobal: Array<Scalars['ID']>;
  updateCatalog: Scalars['ID'];
  updateCatalogItem: Scalars['ID'];
  updateLevel: Scalars['ID'];
  updateRehabilitationPlan: Scalars['ID'];
  updateSocialCognitionImage?: Maybe<Scalars['Void']>;
  updateUser: Scalars['ID'];
  updateWordImage?: Maybe<Scalars['Void']>;
};


export type MutationActivateUserArgs = {
  id: Scalars['ID'];
  input: ActivateUser;
};


export type MutationAddAccessRightsArgs = {
  accessRights: Array<AccessRightType>;
  from: Scalars['ID'];
  to: Scalars['ID'];
};


export type MutationAddAccessRightsFromMultipleDocumentsArgs = {
  accessRights: Array<AccessRightType>;
  from: Array<Scalars['ID']>;
  to: Scalars['ID'];
};


export type MutationAddCatalogArgs = {
  input: CatalogInput;
};


export type MutationAddCatalogItemArgs = {
  input: CatalogItemInput;
};


export type MutationAddDocumentToGroupArgs = {
  documentId: Scalars['ID'];
  groupId: Scalars['ID'];
};


export type MutationAddDocumentsToGroupArgs = {
  documentIds: Array<Scalars['ID']>;
  groupId: Scalars['ID'];
};


export type MutationAddFirmwareArgs = {
  input?: InputMaybe<FirmwareInput>;
};


export type MutationAddLevelArgs = {
  input: LevelInput;
};


export type MutationAddPatientGroupToRehabilitationPlanArgs = {
  groupId: Scalars['ID'];
  rehabilitationPlanId: Scalars['ID'];
};


export type MutationAddPatientGroupsToRehabilitationPlanArgs = {
  groupIds?: InputMaybe<Array<Scalars['ID']>>;
  rehabilitationPlanId: Scalars['ID'];
};


export type MutationAddPatientToRehabilitationPlanArgs = {
  patientId: Scalars['ID'];
  rehabilitationPlanId: Scalars['ID'];
};


export type MutationAddPatientToRehabilitationPlansArgs = {
  patientId: Scalars['ID'];
  rehabilitationPlanIds: Array<Scalars['ID']>;
};


export type MutationAddPatientsToRehabilitationPlanArgs = {
  patientIds?: InputMaybe<Array<Scalars['ID']>>;
  rehabilitationPlanId: Scalars['ID'];
};


export type MutationAddPlaythroughArgs = {
  input: PlaythroughInput;
};


export type MutationAddPlaythroughsArgs = {
  input: Array<InputMaybe<PlaythroughInput>>;
};


export type MutationAddRehabilitationPlanArgs = {
  input: RehabilitationPlanInput;
};


export type MutationAddSocialCognitionImageArgs = {
  input: SocialCognitionImageInput;
};


export type MutationAddStatsArgs = {
  input: StatsInput;
};


export type MutationAddStatsGlobalArgs = {
  input: StatsGlobalInput;
};


export type MutationAddWordImageArgs = {
  input: WordImageInput;
};


export type MutationCreateDocumentGroupArgs = {
  input: DocumentGroupInput;
};


export type MutationCreatePatientArgs = {
  input: CreatePatient;
};


export type MutationDeactivateDoctorArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteLevelArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteRehabilitationPlanArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteSocialCognitionImageArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteWordImageArgs = {
  id: Scalars['ID'];
};


export type MutationExportLevelsArgs = {
  input: Array<Scalars['ID']>;
};


export type MutationFlushCacheArgs = {
  cacheNamespaces: Array<CacheNamespace>;
};


export type MutationImportLevelsArgs = {
  input: Scalars['JSON'];
};


export type MutationRegisterDoctorArgs = {
  input: RegisterDoctor;
};


export type MutationRegisterPublicDoctorArgs = {
  input: RegisterDoctor;
};


export type MutationRemoveAccessRightArgs = {
  accessRight: Array<AccessRightType>;
  from: Scalars['ID'];
  to: Scalars['ID'];
};


export type MutationRemoveCatalogArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveCatalogItemArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveDocumentFromGroupArgs = {
  documentId: Scalars['ID'];
  groupId: Scalars['ID'];
};


export type MutationRemoveDocumentGroupArgs = {
  groupId: Scalars['ID'];
};


export type MutationRemovePatientFromRehabilitationPlanArgs = {
  patientId: Scalars['ID'];
  rehabilitationPlanId: Scalars['ID'];
};


export type MutationRemovePatientGroupFromRehabilitationPlanArgs = {
  groupId: Scalars['ID'];
  rehabilitationPlanId: Scalars['ID'];
};


export type MutationRemovePatientGroupsFromRehabilitationPlanArgs = {
  groupIds?: InputMaybe<Array<Scalars['ID']>>;
  rehabilitationPlanId: Scalars['ID'];
};


export type MutationRemovePatientsFromRehabilitationPlanArgs = {
  patientIds?: InputMaybe<Array<Scalars['ID']>>;
  rehabilitationPlanId: Scalars['ID'];
};


export type MutationRenameDocumentGroupArgs = {
  input: RenameDocumentGroupInput;
};


export type MutationSendPasswordResetEmailArgs = {
  firebaseContinueUrl?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};


export type MutationSetLevelsGlobalArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationUpdateCatalogArgs = {
  input: CatalogUpdateInput;
};


export type MutationUpdateCatalogItemArgs = {
  input: UpdateCatalogItemInput;
};


export type MutationUpdateLevelArgs = {
  id: Scalars['ID'];
  input: LevelInput;
};


export type MutationUpdateRehabilitationPlanArgs = {
  id: Scalars['ID'];
  input: RehabilitationPlanInput;
};


export type MutationUpdateSocialCognitionImageArgs = {
  id: Scalars['ID'];
  input: SocialCognitionImageInput;
};


export type MutationUpdateUserArgs = {
  id: Scalars['ID'];
  input: UpdateUser;
};


export type MutationUpdateWordImageArgs = {
  id: Scalars['ID'];
  input: WordImageInput;
};

export type PaginatedDoctorGroupRights = {
  __typename: 'PaginatedDoctorGroupRights';
  aggregations: Aggregations;
  nodes: Array<DoctorGroupRights>;
};

export type PaginatedLevelGroupRights = {
  __typename: 'PaginatedLevelGroupRights';
  aggregations: Aggregations;
  nodes: Array<LevelGroupRights>;
};

export type PaginatedPatientGroupRights = {
  __typename: 'PaginatedPatientGroupRights';
  aggregations: Aggregations;
  nodes: Array<PatientGroupRights>;
};

export type PaginatedPatientRights = {
  __typename: 'PaginatedPatientRights';
  aggregations: Aggregations;
  nodes: Array<PatientRights>;
};

export type Pagination = {
  limit: Scalars['Int'];
  skip?: InputMaybe<Scalars['Int']>;
};

export type Patient = Identifiable & User & {
  __typename: 'Patient';
  /** Changes to this user made by users. */
  activityLogs: Array<ActivityLog>;
  dateOfBirth?: Maybe<Scalars['DateTime']>;
  disabled: Scalars['Boolean'];
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  firstNameDeburr: Scalars['String'];
  id: Scalars['ID'];
  /** Patient will no longer show up in queries, but is archived for use by data analysis. */
  isDeleted: Scalars['Boolean'];
  /** This patient is in these groups. */
  isInGroups: Array<PatientGroup>;
  lastName: Scalars['String'];
  lastNameDeburr: Scalars['String'];
  patientType?: Maybe<PatientType>;
  phoneNumber?: Maybe<Scalars['String']>;
  preferences: UserPreferences;
  roles: Array<Role>;
  sex?: Maybe<Sex>;
  /** Changes made by this user. */
  userActivityLogs: Array<UserGroupedActivityLog>;
};

export type PatientGroup = {
  __typename: 'PatientGroup';
  collectionName: Scalars['String'];
  id: Scalars['ID'];
  name?: Maybe<LocalizedText>;
  nodes: Array<Patient>;
};

export type PatientGroupRights = {
  __typename: 'PatientGroupRights';
  accessRights: Array<AccessRightType>;
  id: Scalars['ID'];
  node?: Maybe<PatientGroup>;
};

export type PatientRights = {
  __typename: 'PatientRights';
  accessRights: Array<AccessRightType>;
  id: Scalars['ID'];
  node: Patient;
};

export type PatientRightsFilter = {
  accessRights?: InputMaybe<StringArrayFilter>;
};

export const PatientType = {
  Basic: 'basic',
  Tester: 'tester'
} as const;

export type PatientType = typeof PatientType[keyof typeof PatientType];
export type PatientsFilter = {
  dateOfBirth?: InputMaybe<DateFilter>;
};

export type PercentageCloseness = {
  __typename: 'PercentageCloseness';
  cubeId: Scalars['String'];
  values: Array<Maybe<Scalars['Float']>>;
};

export type PercentageClosenessInput = {
  cubeId: Scalars['String'];
  values: Array<InputMaybe<Scalars['Float']>>;
};

export type PercentileLevel = {
  __typename: 'PercentileLevel';
  levelId: Scalars['ID'];
  levelName: Scalars['String'];
  percentile: Scalars['Int'];
};

export type PercentileLevels = {
  __typename: 'PercentileLevels';
  best?: Maybe<PercentileLevel>;
  worst?: Maybe<PercentileLevel>;
};

export type Playthrough = {
  __typename: 'Playthrough';
  _from: Scalars['ID'];
  _to: Scalars['ID'];
  endedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  level?: Maybe<Level>;
  schemaVersion: Scalars['NonNegativeInt'];
  score: Scalars['Int'];
  stages: Array<StagePlaythrough>;
  startedAt?: Maybe<Scalars['DateTime']>;
  stats?: Maybe<PlaythroughStats>;
  user?: Maybe<User>;
};

export type PlaythroughCube = {
  __typename: 'PlaythroughCube';
  cubePattern: CubePatterns;
  cubeRun: Array<Quaternion>;
};

export type PlaythroughCubeInput = {
  cubePattern: CubePatterns;
  quaternions: Array<Array<Scalars['Int']>>;
};

export type PlaythroughDisplayGoal = {
  __typename: 'PlaythroughDisplayGoal';
  pattern: CubePatterns;
  quaternion: Quaternion;
};

export type PlaythroughDisplayGoalInput = {
  pattern: CubePatterns;
  quaternion: QuaternionInput;
};

export type PlaythroughGoals = {
  __typename: 'PlaythroughGoals';
  displayGoals: Array<PlaythroughDisplayGoal>;
};

export type PlaythroughGoalsInput = {
  displayGoals: Array<PlaythroughDisplayGoalInput>;
};

export type PlaythroughInput = {
  cubeRun: Scalars['String'];
  levelId: Scalars['ID'];
  levelRev?: InputMaybe<Scalars['String']>;
  planId?: InputMaybe<Scalars['ID']>;
  planRev?: InputMaybe<Scalars['String']>;
  stages: Array<StagePlaythroughInput>;
  stats?: InputMaybe<PlaythroughStatsInput>;
  userId: Scalars['ID'];
};

export type PlaythroughOutput = {
  __typename: 'PlaythroughOutput';
  endedAt: Scalars['DateTime'];
  levelId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type PlaythroughStatistics = {
  __typename: 'PlaythroughStatistics';
  dailyActivity: Array<DailyActivity>;
};


export type PlaythroughStatisticsDailyActivityArgs = {
  from: Scalars['Date'];
  to: Scalars['Date'];
  userId: Scalars['ID'];
};

export type PlaythroughStats = {
  __typename: 'PlaythroughStats';
  movingTimes: Array<MovingTimes>;
  percentageCloseness: Array<PercentageCloseness>;
};

export type PlaythroughStatsInput = {
  movingTimes: Array<MovingTimesInput>;
  percentageCloseness: Array<PercentageClosenessInput>;
};

export type PublicDoctor = Identifiable & {
  __typename: 'PublicDoctor';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type PublicGroup = {
  __typename: 'PublicGroup';
  id: Scalars['ID'];
  name: LocalizedText;
};

export type PublicGroupsFilter = {
  collectionName?: InputMaybe<StringFilter>;
  id?: InputMaybe<IdFilter>;
};

export type PublicRecord = {
  __typename: 'PublicRecord';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type PublicUser = Identifiable & {
  __typename: 'PublicUser';
  fullName: Scalars['String'];
  id: Scalars['ID'];
};

export type Quaternion = {
  __typename: 'Quaternion';
  w?: Maybe<Scalars['Float']>;
  x?: Maybe<Scalars['Float']>;
  y?: Maybe<Scalars['Float']>;
  z?: Maybe<Scalars['Float']>;
};

export type QuaternionInput = {
  w?: InputMaybe<Scalars['Float']>;
  x?: InputMaybe<Scalars['Float']>;
  y?: InputMaybe<Scalars['Float']>;
  z?: InputMaybe<Scalars['Float']>;
};

export type Query = {
  __typename: 'Query';
  ability: Ability;
  /** Access rights between two specific documents. Can return multiple access rights, because of groups. */
  accessRights: Array<AccessRight>;
  /**
   * Access of a user to a collection, e.g. doctor have access rights to documentGroups.
   * Returns array of nodes with access rights for each of them.
   */
  accessRightsToCollection: Array<AccessRightForNode>;
  activityLogs: Array<ActivityLog>;
  /**
   * Who has access rights to a specific document.
   * e.g. which doctors have access rights to a specific user, documentGroup, etc.
   */
  allAccessRightsToDocument: Array<AccessRightForNode>;
  autocompleteDoctors: Array<PublicDoctor>;
  autocompleteGroups: Array<PublicGroup>;
  autocompletePatients: Array<Patient>;
  disabledDoctors: Array<User>;
  doctorGroup: DoctorGroup;
  documentInGroups: Array<DocumentGroup>;
  documentsInGroup: Array<DocumentGroup>;
  doesUserExistByPhoneNumber?: Maybe<Scalars['Boolean']>;
  getBestPercentileLevel: PercentileLevels;
  getCatalog: Catalog;
  getCatalogByName: Catalog;
  getDataForDeficiencyDetector: Array<Maybe<AverageScore>>;
  getFirmware: Array<Firmware>;
  getLevel: Level;
  getLevelHistory: Array<Maybe<Level>>;
  getLevelRuns: Array<LevelRun>;
  getRehabilitationPlan: RehabilitationPlan;
  getUser: User;
  isDocumentInGroups: Array<IsDocumentInGroupsOutput>;
  isUserDeleted?: Maybe<Scalars['Boolean']>;
  me: User;
  names: Array<PublicRecord>;
  /** Specific patient group. */
  patientGroup: PatientGroup;
  playthroughStatistics: PlaythroughStatistics;
  publicDoctors: Array<PublicDoctor>;
  publicGroups: Array<PublicGroup>;
  queryCatalogItems: Array<Maybe<CatalogItem>>;
  queryCatalogs: Array<Catalog>;
  queryLevelGroups: PaginatedLevelGroupRights;
  queryLevels: LevelsAndAggregations;
  queryLevelsByGroup: LevelsAndAggregations;
  queryLevelsByRehabilitationType: Array<Level>;
  queryLevelsByRole: LevelsAndAggregations;
  queryMyLevels: LevelsAndAggregations;
  queryPatients?: Maybe<Array<Maybe<Patient>>>;
  queryPlaythroughs: Array<Playthrough>;
  queryRehabilitationPlans: Array<RehabilitationPlan>;
  queryRehabilitationPlansByPatientId: Array<RehabilitationPlan>;
  queryStats: Array<Stats>;
  queryStatsGlobal: Array<StatsGlobal>;
  queryUserPlayedLevels: Array<Level>;
  socialCognitionImage: SocialCognitionImage;
  socialCognitionImages: SocialCognitionImageAggregations;
  userLevelCountInCategories: Array<UserLevelCount>;
  users: Array<User>;
  wordImage: WordImage;
  wordImages: WordImageAggregations;
};


export type QueryAbilityArgs = {
  id: Scalars['ID'];
};


export type QueryAccessRightsArgs = {
  from: Scalars['ID'];
  to: Scalars['ID'];
};


export type QueryAccessRightsToCollectionArgs = {
  from: Scalars['ID'];
  toCollection: Scalars['String'];
};


export type QueryActivityLogsArgs = {
  documentId: Scalars['ID'];
};


export type QueryAllAccessRightsToDocumentArgs = {
  filter?: InputMaybe<AccessRightsFilter>;
  to: Scalars['ID'];
};


export type QueryAutocompleteDoctorsArgs = {
  search: Scalars['String'];
};


export type QueryAutocompleteGroupsArgs = {
  filter?: InputMaybe<PublicGroupsFilter>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryAutocompletePatientsArgs = {
  search: Scalars['String'];
};


export type QueryDisabledDoctorsArgs = {
  pagination: Pagination;
  search?: InputMaybe<FulltextSearch>;
};


export type QueryDoctorGroupArgs = {
  groupId: Scalars['ID'];
};


export type QueryDocumentInGroupsArgs = {
  documentId: Scalars['ID'];
};


export type QueryDocumentsInGroupArgs = {
  groupId: Scalars['ID'];
};


export type QueryDoesUserExistByPhoneNumberArgs = {
  phoneNumber: Scalars['String'];
};


export type QueryGetBestPercentileLevelArgs = {
  userId: Scalars['ID'];
};


export type QueryGetCatalogArgs = {
  id: Scalars['ID'];
};


export type QueryGetCatalogByNameArgs = {
  name: Scalars['String'];
};


export type QueryGetDataForDeficiencyDetectorArgs = {
  patientId: Scalars['ID'];
  rehabilitationPlanId: Scalars['ID'];
};


export type QueryGetLevelArgs = {
  id: Scalars['ID'];
};


export type QueryGetLevelHistoryArgs = {
  id?: InputMaybe<Scalars['ID']>;
  key?: InputMaybe<Scalars['ID']>;
  revision?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<Sort>;
};


export type QueryGetLevelRunsArgs = {
  levelId: Scalars['ID'];
};


export type QueryGetRehabilitationPlanArgs = {
  id: Scalars['ID'];
};


export type QueryGetUserArgs = {
  as?: InputMaybe<Role>;
  id: Scalars['ID'];
};


export type QueryIsDocumentInGroupsArgs = {
  documentId: Scalars['ID'];
  groupIds: Array<Scalars['ID']>;
};


export type QueryIsUserDeletedArgs = {
  email?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
};


export type QueryMeArgs = {
  as?: InputMaybe<Role>;
};


export type QueryNamesArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryPatientGroupArgs = {
  groupId: Scalars['ID'];
};


export type QueryPublicGroupsArgs = {
  filter?: InputMaybe<PublicGroupsFilter>;
};


export type QueryQueryCatalogItemsArgs = {
  filter: CatalogItemFilter;
};


export type QueryQueryLevelsArgs = {
  filter?: InputMaybe<LevelsFilter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
};


export type QueryQueryLevelsByGroupArgs = {
  groupId: Scalars['ID'];
  pagination?: InputMaybe<Pagination>;
};


export type QueryQueryLevelsByRehabilitationTypeArgs = {
  code: Scalars['String'];
};


export type QueryQueryLevelsByRoleArgs = {
  filter?: InputMaybe<LevelsFilter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
};


export type QueryQueryMyLevelsArgs = {
  filter?: InputMaybe<LevelsFilter>;
  pagination?: InputMaybe<Pagination>;
  sort?: InputMaybe<Sort>;
};


export type QueryQueryPatientsArgs = {
  filter?: InputMaybe<PatientsFilter>;
};


export type QueryQueryPlaythroughsArgs = {
  fromDate?: InputMaybe<Scalars['DateTime']>;
  goalType?: InputMaybe<LevelGoalType>;
  levelIds?: InputMaybe<Array<Scalars['ID']>>;
  limit?: InputMaybe<Scalars['NonNegativeInt']>;
  patientIds?: InputMaybe<Array<Scalars['ID']>>;
  sort?: InputMaybe<Sort>;
  toDate?: InputMaybe<Scalars['DateTime']>;
};


export type QueryQueryRehabilitationPlansArgs = {
  filter?: InputMaybe<RehabilitationPlanFilter>;
  sort?: InputMaybe<Sort>;
};


export type QueryQueryRehabilitationPlansByPatientIdArgs = {
  patientId: Scalars['ID'];
};


export type QueryQueryStatsArgs = {
  filter?: InputMaybe<StatsFilter>;
};


export type QueryQueryStatsGlobalArgs = {
  filter?: InputMaybe<StatsGlobalFilter>;
};


export type QueryQueryUserPlayedLevelsArgs = {
  userId: Scalars['ID'];
};


export type QuerySocialCognitionImageArgs = {
  id: Scalars['ID'];
};


export type QueryUsersArgs = {
  filter?: InputMaybe<UsersFilter>;
  pagination?: InputMaybe<Pagination>;
  search?: InputMaybe<FulltextSearch>;
  sort?: InputMaybe<Array<SortItem>>;
};


export type QueryWordImageArgs = {
  id: Scalars['ID'];
};

export type RegisterDoctor = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  hideGlobalLevels?: InputMaybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  password: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  postnominalLetters?: InputMaybe<Scalars['String']>;
  preferences: UserPreferencesInput;
  prenominalLetters?: InputMaybe<Scalars['String']>;
};

export type RegisterPublicDoctor = {
  disabled: Scalars['Boolean'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  postnominalLetters?: InputMaybe<Scalars['String']>;
  preferences: UserPreferencesInput;
  prenominalLetters?: InputMaybe<Scalars['String']>;
};

export type RehabilitationPlan = {
  __typename: 'RehabilitationPlan';
  /** Changes to this rehabilitation plan made by users. */
  activityLogs: Array<ActivityLog>;
  global: Scalars['Boolean'];
  id: Scalars['ID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  levels: Array<Level>;
  name: LocalizedText;
  patientGroups: Array<PatientGroup>;
  patients: Array<Patient>;
  rev: Scalars['String'];
};

export type RehabilitationPlanFilter = {
  and?: InputMaybe<Array<RehabilitationPlanFilter>>;
  global?: InputMaybe<BooleanFilter>;
  id?: InputMaybe<IdFilter>;
  name?: InputMaybe<StringFilter>;
  or?: InputMaybe<Array<RehabilitationPlanFilter>>;
};

export type RehabilitationPlanInput = {
  global: Scalars['Boolean'];
  levels: Array<Scalars['ID']>;
  name: LocalizedTextInput;
};

export type RehabilitationTypeFilter = {
  items?: InputMaybe<IdArrayFilter>;
};

export type RenameDocumentGroupInput = {
  id: Scalars['ID'];
  name: LocalizedTextInput;
};

export type RequiredCube = {
  __typename: 'RequiredCube';
  pattern: CubePatterns;
};

export type RequiredCubeInput = {
  pattern: CubePatterns;
};

export const Role = {
  Admin: 'admin',
  Doctor: 'doctor',
  Patient: 'patient'
} as const;

export type Role = typeof Role[keyof typeof Role];
export const Sex = {
  Female: 'female',
  Male: 'male'
} as const;

export type Sex = typeof Sex[keyof typeof Sex];
export type SimpleStageGenerator = {
  __typename: 'SimpleStageGenerator';
  goal: LevelGoal;
  id: StageGeneratorId;
  matchCubeGeometry?: Maybe<CubeGeometry>;
};

export type SimpleStageGeneratorInput = {
  goal: LevelGoalInput;
  id: StageGeneratorId;
  matchCubeGeometry: CubeGeometry;
};

export type SocialCognitionImage = {
  __typename: 'SocialCognitionImage';
  asset: BucketImage;
  assetUrl: Scalars['String'];
  emotionChoices: Array<Maybe<Emotion>>;
  id: Scalars['ID'];
  text: LocalizedText;
  type: SocialCognitionImageType;
};

export type SocialCognitionImageAggregations = {
  __typename: 'SocialCognitionImageAggregations';
  aggregations?: Maybe<Aggregations>;
  nodes: Array<SocialCognitionImage>;
};

export type SocialCognitionImageInput = {
  asset: BucketImageInput;
  emotionChoices: Array<InputMaybe<EmotionInput>>;
  text: LocalizedTextInput;
  type: SocialCognitionImageType;
};

export const SocialCognitionImageType = {
  Photo: 'photo',
  Smiley: 'smiley',
  Video: 'video'
} as const;

export type SocialCognitionImageType = typeof SocialCognitionImageType[keyof typeof SocialCognitionImageType];
export type SocialGenerator = {
  __typename: 'SocialGenerator';
  assignment: Assignment;
  goal: LevelGoal;
  id: StageGeneratorId;
  maxOptions: Scalars['Int'];
  solution: Solution;
};

export type SocialGeneratorInput = {
  assignment: Assignment;
  goal: LevelGoalInput;
  id: StageGeneratorId;
  maxOptions: Scalars['Int'];
  solution: Solution;
};

export const Solution = {
  SmileyColor: 'smileyColor',
  TextColor: 'textColor'
} as const;

export type Solution = typeof Solution[keyof typeof Solution];
export type Sort = {
  attribute: Scalars['String'];
  order: SortOrder;
};

export const SortDirection = {
  Asc: 'ASC',
  Desc: 'DESC'
} as const;

export type SortDirection = typeof SortDirection[keyof typeof SortDirection];
export type SortItem = {
  direction: SortDirection;
  field: Scalars['String'];
};

export const SortOrder = {
  Ascending: 'ascending',
  Descending: 'descending'
} as const;

export type SortOrder = typeof SortOrder[keyof typeof SortOrder];
export type StageCountLevelGoal = {
  __typename: 'StageCountLevelGoal';
  count: Scalars['NonNegativeInt'];
  hideAfter?: Maybe<Scalars['NonNegativeInt']>;
};

export type StageCountLevelGoalInput = {
  count: Scalars['NonNegativeInt'];
  hideAfter?: InputMaybe<Scalars['NonNegativeInt']>;
};

export type StageGenerator = ImageWordGenerator | SimpleStageGenerator | SocialGenerator | SwitchedAssetsGenerator | TextStageGenerator;

export type StageGeneratorFilter = {
  goal?: InputMaybe<GoalFilter>;
};

export const StageGeneratorId = {
  ImageWordGenerator: 'imageWordGenerator',
  SimpleStageGenerator: 'simpleStageGenerator',
  SocialGenerator: 'socialGenerator',
  SwitchedAssetsGenerator: 'switchedAssetsGenerator',
  TextGenerator: 'textGenerator'
} as const;

export type StageGeneratorId = typeof StageGeneratorId[keyof typeof StageGeneratorId];
export type StageGeneratorInput = {
  imageWordGenerator?: InputMaybe<ImageWordGeneratorInput>;
  simpleStageGenerator?: InputMaybe<SimpleStageGeneratorInput>;
  socialGenerator?: InputMaybe<SocialGeneratorInput>;
  switchedAssetsGenerator?: InputMaybe<SwitchedAssetsGeneratorInput>;
  textGenerator?: InputMaybe<TextStageGeneratorInput>;
};

export type StagePlaythrough = {
  __typename: 'StagePlaythrough';
  endedAt: Scalars['DateTime'];
  goalShownAt: Scalars['DateTime'];
  goals: PlaythroughGoals;
  result: StageResult;
  stageTime?: Maybe<Scalars['NonNegativeInt']>;
  startedPlayingAt: Scalars['DateTime'];
};

export type StagePlaythroughInput = {
  endedAt: Scalars['DateTime'];
  goalShownAt: Scalars['DateTime'];
  goals: PlaythroughGoalsInput;
  result: StageResult;
  stageTime?: InputMaybe<Scalars['NonNegativeInt']>;
  startedPlayingAt: Scalars['DateTime'];
};

export type StagePlaythroughSimple = {
  __typename: 'StagePlaythroughSimple';
  result: StageResult;
  stageTime: Scalars['Int'];
};

export const StageResult = {
  DidNotFinish: 'didNotFinish',
  Disqualification: 'disqualification',
  Skiped: 'skiped',
  Success: 'success',
  WrongSolution: 'wrongSolution'
} as const;

export type StageResult = typeof StageResult[keyof typeof StageResult];
export type Stats = {
  __typename: 'Stats';
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  levelGoal: Scalars['String'];
  levelId: Scalars['ID'];
  levelName: Scalars['String'];
  runResults: Array<Scalars['Int']>;
  userId: Scalars['ID'];
};

export type StatsFilter = {
  levelGoal?: InputMaybe<StringFilter>;
  levelId?: InputMaybe<IdFilter>;
  planId?: InputMaybe<IdFilter>;
  userId?: InputMaybe<IdFilter>;
};

export type StatsGlobal = {
  __typename: 'StatsGlobal';
  levelGoal: Scalars['String'];
  levelId: Scalars['ID'];
  levelName: Scalars['String'];
  runResults: Array<Scalars['Int']>;
};

export type StatsGlobalFilter = {
  levelGoal?: InputMaybe<StringFilter>;
  levelId?: InputMaybe<IdFilter>;
};

export type StatsGlobalInput = {
  levelId: Scalars['ID'];
  score: Scalars['Int'];
};

export type StatsInput = {
  levelId: Scalars['ID'];
  score: Scalars['Int'];
  userId: Scalars['ID'];
};

export type StringArrayFilter = {
  contains?: InputMaybe<Scalars['String']>;
  filterType?: InputMaybe<Scalars['String']>;
};

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  eq?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  filterType?: InputMaybe<Scalars['String']>;
  isAnyOf?: InputMaybe<Array<Scalars['String']>>;
  isEmpty?: InputMaybe<Scalars['Boolean']>;
  isNotEmpty?: InputMaybe<Scalars['Boolean']>;
  notEquals?: InputMaybe<Scalars['String']>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type SwitchedAssetsGenerator = {
  __typename: 'SwitchedAssetsGenerator';
  goal: LevelGoal;
  goalPatterns: Array<GoalPattern>;
  id: StageGeneratorId;
  matchCubeGeometry?: Maybe<CubeGeometry>;
  /** Synchronize the matchBy alternating in goalPatterns. */
  syncMatchBy?: Maybe<Scalars['Boolean']>;
};

export type SwitchedAssetsGeneratorInput = {
  goal: LevelGoalInput;
  goalPatterns: Array<GoalPatternInput>;
  id: StageGeneratorId;
  matchCubeGeometry: CubeGeometry;
  /** Synchronize the matchBy alternating in goalPatterns. */
  syncMatchBy?: InputMaybe<Scalars['Boolean']>;
};

export type TextStageGenerator = {
  __typename: 'TextStageGenerator';
  goal: LevelGoal;
  id: StageGeneratorId;
  textColor: TextStageGeneratorTextColor;
};

export type TextStageGeneratorInput = {
  goal: LevelGoalInput;
  id: StageGeneratorId;
  textColor: TextStageGeneratorTextColor;
};

export const TextStageGeneratorTextColor = {
  Black: 'black',
  Correct: 'correct',
  Switched: 'switched'
} as const;

export type TextStageGeneratorTextColor = typeof TextStageGeneratorTextColor[keyof typeof TextStageGeneratorTextColor];
export const Theme = {
  Dark: 'dark',
  Light: 'light'
} as const;

export type Theme = typeof Theme[keyof typeof Theme];
export type TimeLevelGoal = {
  __typename: 'TimeLevelGoal';
  hideAfter?: Maybe<Scalars['NonNegativeInt']>;
  time: Scalars['NonNegativeInt'];
};

export type TimeLevelGoalInput = {
  hideAfter?: InputMaybe<Scalars['NonNegativeInt']>;
  time: Scalars['NonNegativeInt'];
};

export type UpdateCatalogItemInput = {
  id: Scalars['ID'];
  text: LocalizedTextInput;
};

export type UpdateUser = {
  dateOfBirth?: InputMaybe<Scalars['DateTime']>;
  disabled?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  firstNameDeburr?: InputMaybe<Scalars['String']>;
  hideGlobalLevels?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  lastNameDeburr?: InputMaybe<Scalars['String']>;
  patientType?: InputMaybe<PatientType>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  postnominalLetters?: InputMaybe<Scalars['String']>;
  preferences?: InputMaybe<UserPreferencesInput>;
  prenominalLetters?: InputMaybe<Scalars['String']>;
  roles?: InputMaybe<Array<Role>>;
  sex?: InputMaybe<Sex>;
};

export type User = {
  /** Changes to this user made by users. */
  activityLogs: Array<ActivityLog>;
  /** Whether this user is activated or not. e.g. when doctor registers from public page, it will be automatically marked as disabled. */
  disabled: Scalars['Boolean'];
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  firstNameDeburr?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isDeleted: Scalars['Boolean'];
  lastName: Scalars['String'];
  lastNameDeburr?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  preferences: UserPreferences;
  roles: Array<Role>;
  /** Changes made by this user. */
  userActivityLogs: Array<UserGroupedActivityLog>;
};

export type UserActivityLog = {
  __typename: 'UserActivityLog';
  /** e.g. You edited name, description and type. */
  description: Scalars['String'];
  id: Scalars['ID'];
  timestamp: Scalars['DateTime'];
};

export type UserGroupedActivityLog = {
  __typename: 'UserGroupedActivityLog';
  /**
   * Collection of atomic changes.
   * e.g. You create group of doctors but the atomic logs would be: create group, add user 1 to group, add user 2 to group, add access rights to group to user 3.
   */
  atomicLogs: Array<UserActivityLog>;
  /** High level description e.g. You edited level XYZ. */
  description: Scalars['String'];
};

export type UserLevelCount = {
  __typename: 'UserLevelCount';
  code: Scalars['String'];
  hasLevels: Scalars['Int'];
};

export type UserPreferences = {
  __typename: 'UserPreferences';
  theme?: Maybe<Theme>;
};

export type UserPreferencesInput = {
  theme?: InputMaybe<Theme>;
};

export type UsersFilter = {
  disabled?: InputMaybe<BooleanFilter>;
  roles?: InputMaybe<StringArrayFilter>;
};

export const VoiceSuccessStage = {
  Disabled: 'disabled',
  Enabled: 'enabled',
  Global: 'global'
} as const;

export type VoiceSuccessStage = typeof VoiceSuccessStage[keyof typeof VoiceSuccessStage];
export type WordImage = {
  __typename: 'WordImage';
  asset: BucketImage;
  assetUrl: Scalars['String'];
  id: Scalars['ID'];
  word: LocalizedText;
};

export type WordImageAggregations = {
  __typename: 'WordImageAggregations';
  aggregations?: Maybe<Aggregations>;
  nodes: Array<WordImage>;
};

export type WordImageInput = {
  asset: BucketImageInput;
  word: LocalizedTextInput;
};
