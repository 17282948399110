import { CubeData } from './game/bluetooth/useCubes'

export type CubeCalibrated = {
  isCalibrated: true
  gyroX: number
  gyroY: number
  gyroZ: number
  accX: number
  accY: number
  accZ: number
  magX: number
  magY: number
  magZ: number
}

export const getCubeCalibrationFromLocalStorage = (cubeId: string) => {
  const localStorage = window.localStorage.getItem(cubeId)
  return localStorage && JSON.parse(localStorage).calibration
}

export const storeCubeCalibrationToLocalStorage = (calibration: CubeCalibrated, cubeId: string): void => {
  window.localStorage.setItem(cubeId, JSON.stringify({ calibration }))
}

export const convertCalibrationObjectToCubeCalibrationData = (localCalibrationData: CubeCalibrated) => {
  const { accX, accY, accZ, gyroX, gyroY, gyroZ, magX, magY, magZ } = localCalibrationData
  const arrayBuffer = new ArrayBuffer(19)
  const view = new DataView(arrayBuffer)
  view.setInt8(0, 0)
  const valuesArray = [gyroX, gyroY, gyroZ, accX, accY, accZ, magX, magY, magZ]
  valuesArray.forEach((value, index) => {
    view.setInt16(index * 2 + 1, value)
  })

  const resultArrayBuffer = view.buffer
  return resultArrayBuffer
}

export const convertCubeCalibrationDataToCalibrationObject = (cubeCalibration: DataView) => {
  const gyroX = cubeCalibration.getInt16(1)
  const gyroY = cubeCalibration.getInt16(3)
  const gyroZ = cubeCalibration.getInt16(5)
  const accX = cubeCalibration.getInt16(7)
  const accY = cubeCalibration.getInt16(9)
  const accZ = cubeCalibration.getInt16(11)
  const magX = cubeCalibration.getInt16(13)
  const magY = cubeCalibration.getInt16(15)
  const magZ = cubeCalibration.getInt16(17)

  return {
    isCalibrated: true,
    gyroX,
    gyroY,
    gyroZ,
    accX,
    accY,
    accZ,
    magX,
    magY,
    magZ,
  }
}

export enum CubeStatus {
  NOT_CONNECTED = 'NOT_CONNECTED',
  NOT_CALIBRATED = 'NOT_CALIBRATED',
  NOT_SYNCHRONIZED = 'NOT_SYNCHRONIZED',
  READY = 'READY',
}

export const resolveCubeStatus = (cube?: CubeData): CubeStatus => {
  if (!cube || !cube.isConnected) {
    return CubeStatus.NOT_CONNECTED
  }
  if (!cube.isCalibrated) {
    return CubeStatus.NOT_CALIBRATED
  } else if (!cube.isSyncedWithDevice) {
    return CubeStatus.NOT_SYNCHRONIZED
  } else {
    return CubeStatus.READY
  }
}

const pad = (num: number) => {
  return `${num < 10 ? `0${num}` : num}`
}

export const getPrettyTime = (timeInSeconds: number): string => {
  const minutes = Math.floor(timeInSeconds / 60)
  const seconds = parseFloat((timeInSeconds % 60).toFixed(1))
  return `${pad(minutes)}:${pad(seconds)}`
}
