import { Box, FormControlLabel, Switch } from '@mui/material'
import { useGlobalState } from '@proxyqb/cube-global-state'
import { ChangeEvent, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useEffectOnce } from 'react-use'
import { HEADER_HEIGHT, useCheckForUpdates } from '../shared'
import { useQueryRehabilitationPlansByPatientIdQuery } from '../rehabilitation-plans/rehabilitation-plan.generated'
import { useIntl } from 'react-intl'
import { PhoneLogin } from '@proxyqb/phone-login'
import { LoginForm } from '@proxyqb/login'
import { QrLogin } from './qr-login'

const LoginScreen = () => {
  useCheckForUpdates()
  const navigate = useNavigate()
  const { authService, authState, setSelectedPatient } = useGlobalState()
  const { formatMessage } = useIntl()

  const [phoneLogin, setPhoneLogin] = useState<boolean>(false)

  const [{ data, fetching }] = useQueryRehabilitationPlansByPatientIdQuery({
    variables: {
      patientId: authState.context.userDetails?.id ?? '',
    },
  })

  const handleSwitchChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setPhoneLogin(event.target.checked)
  }

  useEffectOnce(() => {
    setSelectedPatient(null)
  })
  useEffect(() => {
    if (authState.matches('loggedIn')) {
      const roles = authState.context.userDetails?.roles
      const isPatient =
        authState.context.userDetails &&
        roles?.includes('patient') &&
        !roles?.some((role) => ['doctor', 'admin'].includes(role))
      if (isPatient && authState.context.userDetails) {
        setSelectedPatient(authState.context.userDetails)
      } else {
        navigate('/player-profiles')
      }
    }
  }, [authState.value])

  useEffect(() => {
    if (authState.context.userDetails && !fetching) {
      const rehabPlanId = data?.queryRehabilitationPlansByPatientId?.[0]
      if (rehabPlanId) {
        navigate('/')
      } else {
        navigate('/category-list')
      }
    }
  }, [data, fetching])

  function onSubmit({ email, password }: { email: string; password: string }) {
    authService.send({
      type: 'LOG_IN',
      credentials: {
        email,
        password,
      },
    })
    // TODO: subscribe redirect on loggedIn in xstate
  }

  if (authState.matches('loggedIn') && fetching) {
    return <Box />
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: `calc(100% - ${HEADER_HEIGHT}px)`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box sx={{ width: 400, display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
        <Box sx={{ display: 'flex', height: 60, alignItems: 'center', justifyContent: 'center', mb: 10 }}>
          <img height={60} src="/static/media/proxyqblogo.png" />
          <Box sx={{ ml: 2, fontSize: 60, fontWeight: 'bold' }}>PROXYQB</Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            gap: 1,
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <FormControlLabel
            label={formatMessage({ id: 'sendPhoneCodeForm.switch' })}
            control={<Switch checked={phoneLogin} onChange={handleSwitchChange} />}
          />
          {!phoneLogin && (
            <LoginForm onSubmit={onSubmit} authState={authState} showDoctorRegistration={false} />
          )}
          {phoneLogin && <PhoneLogin authState={authState} authService={authService} />}
          <QrLogin onResult={onSubmit} />
        </Box>
      </Box>
    </Box>
  )
}

export default LoginScreen
