import { CubeGeometry, SimpleStageGenerator, StageGeneratorId, StageResult } from '@proxyqb/graphql-api-types'
import { useMachine } from '@xstate/react'
import { mapValues } from 'lodash-es'
import { useMemo, useRef, useState } from 'react'
import { useEffectOnce } from 'react-use'
import { Matrix4, Quaternion } from 'three'
import { StateValue } from 'xstate'
import { usePlaythrough } from '../playthrough/use-playthrough'
import { BaseGeneratorEvent, BaseGeneratorMachineContext } from './base-generator-machine-config'
import { imageWordGeneratorMachine } from './image-word-generator'
import { simpleStageGeneratorMachine } from './simple-stage-generator'
import { switchedAssetsStageGeneratorMachine } from './switched-assets-stage-generator'
import { textStageGeneratorMachine } from './text-stage-generator'
import { Goals, UseStageGeneratorOptions, UseStageGeneratorResult } from './types'
import { socialStageGeneratorMachine } from './social-stage-generator'
import { GameEventType, StageSuccessEvent, useGameEvents } from '@proxyqb/cube-fe/src/app/game/useGameEvents'

const machines = {
  [StageGeneratorId.SimpleStageGenerator]: simpleStageGeneratorMachine,
  [StageGeneratorId.SwitchedAssetsGenerator]: switchedAssetsStageGeneratorMachine,
  [StageGeneratorId.TextGenerator]: textStageGeneratorMachine,
  [StageGeneratorId.ImageWordGenerator]: imageWordGeneratorMachine,
  [StageGeneratorId.SocialGenerator]: socialStageGeneratorMachine,
} as const

function mapContext(context: BaseGeneratorMachineContext): Goals {
  const quaternions = (() => {
    const matchCubeGeometry = (context.level.stageGenerator as SimpleStageGenerator).matchCubeGeometry
    switch (matchCubeGeometry) {
      case CubeGeometry.Edge:
        return edgeQuaternions
      case CubeGeometry.Vertex:
        return vertexQuaternions
      case CubeGeometry.Face:
      default:
        return faceQuaternions
    }
  })()
  const displayGoals = context.displayGoals.map((goal) => ({
    ...goal,
    quaternion: quaternions[(goal.side ?? 1) - 1],
  }))
  const compareGoals = context.compareGoals.map((cubes) =>
    mapValues(cubes, (side) => quaternions[(side ?? 1) - 1]),
  )

  return {
    displayGoals,
    compareGoals,
    image: context.image ?? undefined,
    imageId: context.imageId ?? undefined,
    emotionChoices: context.emotionChoices ?? undefined,
  }
}

export function useStageGenerator<IDs extends string>(
  id: StageGeneratorId,
  { cubes, level, words, socialCognition }: UseStageGeneratorOptions,
): UseStageGeneratorResult {
  const { startStage, finishStage, finishLevel } = usePlaythrough()
  const [currentState, setCurrentState] = useState<StateValue | null>(null)
  const { fireEvent } = useGameEvents()
  const onStageStart = useRef<(goals: Goals) => void>()
  const machine = useMemo(
    () =>
      // @ts-ignore
      machines[id].withContext({
        cubes,
        stagesCount:
          level.stageGenerator.goal.__typename === 'StageCountLevelGoal'
            ? level.stageGenerator.goal.count
            : -1,
        displayGoals: [],
        compareGoals: [],
        stageHistory: [],
        level,
        words,
        socialCognition,
      }),
    [],
  )
  const [generator, send, service] = useMachine<BaseGeneratorMachineContext<IDs>, BaseGeneratorEvent>(machine)
  useEffectOnce(() => {
    service.onTransition((args) => {
      const { context, value, event } = args
      setCurrentState(value)
      if (value === 'playing') {
        startStage(mapContext(context), context.cubes, event.time)
        onStageStart.current?.(mapContext(context))
      }
    })
  })

  return {
    ...mapContext(generator.context),
    triggerSuccess: (time) => {
      finishStage(time, StageResult.Success)
      send('SUCCESS', { time })
      fireEvent<StageSuccessEvent>({
        type: GameEventType.STAGE_SUCCESS,
        isLastStage: generator.context.stagesCount === 1,
      })
    },
    triggerFailure: (time, result) => {
      finishStage(time, result)
      send('FAILURE', { time, result })
      fireEvent({ type: GameEventType.STAGE_FAILURE })
    },
    triggerStart: () => {
      send('START')
    },
    triggerFinish: (time) => {
      send('FINISH', { time })
    },
    onStageBegin: (callback) => {
      onStageStart.current = callback
    },
    onLevelEnded: (callback, getStats) => {
      service.onTransition((args) => {
        const { value, event } = args
        if (value === 'finish') {
          const stats = getStats()
          finishLevel(event.time, stats)
          callback()
        }
      })
    },
    currentState,
  }
}

// 6 faces
// 12 edges
// 8 verticies

const sideMatrices = [
  new Matrix4().fromArray([0, -1, 0, 0, 0, 0, 1, 0, -1, 0, 0, 0, 0, 0, 0, 1]), //1
  new Matrix4().fromArray([1, 0, 0, 0, 0, -1, 0, 0, 0, 0, -1, 0, 0, 0, 0, 1]), //2
  new Matrix4().fromArray([1, 0, 0, 0, 0, 0, 1, 0, 0, -1, 0, 0, 0, 0, 0, 1]), //3
  new Matrix4().fromArray([-1, 0, 0, 0, 0, 0, 1, 0, 0, 1, 0, 0, 0, 0, 0, 1]), //4
  new Matrix4().fromArray([1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1]), //5
  new Matrix4().fromArray([0, 1, 0, 0, 0, 0, 1, 0, 1, 0, 0, 0, 0, 0, 0, 1]), //6
] as const

export const faceQuaternions = [
  new Quaternion(-0.5, 0.5, 0.5, -0.5), // 1
  new Quaternion(0, 0.707, 0.707, 0), // 2
  new Quaternion(0, 0, 0, 1), // 3
  new Quaternion(1, 0, 0, 0), // 4
  new Quaternion(0.707, 0, 0, 0.707), // 5
  new Quaternion(0.5, 0.5, 0.5, 0.5), // 6
  // new Quaternion().setFromRotationMatrix(
  //   new Matrix4().fromArray([0, -1, 0, 0, 0, 0, 1, 0, -1, 0, 0, 0, 0, 0, 0, 1]),
  // ), //1
  // new Quaternion().setFromRotationMatrix(
  //   new Matrix4().fromArray([1, 0, 0, 0, 0, -1, 0, 0, 0, 0, -1, 0, 0, 0, 0, 1]),
  // ), //2
  // new Quaternion().setFromRotationMatrix(
  //   new Matrix4().fromArray([1, 0, 0, 0, 0, 0, 1, 0, 0, -1, 0, 0, 0, 0, 0, 1]),
  // ), //3
  // new Quaternion().setFromRotationMatrix(
  //   new Matrix4().fromArray([-1, 0, 0, 0, 0, 0, 1, 0, 0, 1, 0, 0, 0, 0, 0, 1]),
  // ), //4
  // new Quaternion().setFromRotationMatrix(
  //   new Matrix4().fromArray([1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1]),
  // ), //5
  // new Quaternion().setFromRotationMatrix(
  //   new Matrix4().fromArray([0, 1, 0, 0, 0, 0, 1, 0, 1, 0, 0, 0, 0, 0, 0, 1]),
  // ), //6
] as const

const edgeQuaternions = [
  // X rotations
  new Quaternion(0.3826834, 0, 0, 0.9238795),
  new Quaternion(0.9238795, 0, 0, 0.3826834),
  new Quaternion(0.9238795, 0, 0, -0.3826834),
  new Quaternion(0.3826834, 0, 0, -0.9238795),
  // Y rotations
  new Quaternion(0.6532815, 0.2705981, 0.2705981, 0.6532815),
  new Quaternion(0.2705981, 0.6532815, 0.6532815, 0.2705981),
  new Quaternion(-0.2705981, 0.6532815, 0.6532815, -0.2705981),
  new Quaternion(-0.6532815, 0.2705981, 0.2705981, -0.6532815),
  // Z rotations
  new Quaternion(0.6532815, 0.2705981, 0.6532815, 0.2705981),
  new Quaternion(0.6532815, -0.2705981, 0.6532815, -0.2705981),
  new Quaternion(0.2705981, -0.6532815, 0.2705981, -0.6532815),
  new Quaternion(-0.2705981, -0.6532815, -0.2705981, -0.6532815),
]

const vertexQuaternions = [
  new Quaternion(0.3535534, 0.3535534, 0.1464466, 0.8535534),
  new Quaternion(0.1464466, 0.8535534, 0.3535534, 0.3535534),
  new Quaternion(-0.1464466, 0.8535534, 0.3535534, -0.3535534),
  new Quaternion(-0.3535534, 0.3535534, 0.1464466, -0.8535534),
  new Quaternion(0.3535534, -0.1464466, 0.3535534, 0.8535534),
  new Quaternion(0.1464466, -0.3535534, 0.8535534, 0.3535534),
  new Quaternion(-0.1464466, -0.3535534, 0.8535534, -0.3535534),
  new Quaternion(-0.3535534, -0.1464466, 0.3535534, -0.8535534),
]
