import { FC, PropsWithChildren } from 'react'
import { Box, Modal as MUIModal } from '@mui/material'
import { SxProps } from '@mui/system'

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1200,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
}

interface Props {
  open: boolean
  sx: SxProps
}

export const Modal: FC<PropsWithChildren<Props>> = ({ open, sx = [], children }) => {
  return (
    <MUIModal open={open}>
      <Box sx={[style, ...(Array.isArray(sx) ? sx : [sx])]}>{children}</Box>
    </MUIModal>
  )
}
