import { Box, Skeleton, Typography } from '@mui/material'
import { Flex } from '@proxyqb/ui'
import { MouseEventHandler } from 'react'
import { animated, useSpring, config } from 'react-spring'
import { LevelItemFragment } from './levels.generated'
import { truncate } from 'lodash'

interface Props {
  level: LevelItemFragment
  index: number
  loading: boolean
  onClick: (level: LevelItemFragment) => MouseEventHandler
}

export const LevelItem = ({ level, index, loading, onClick }: Props) => {
  const style = useSpring({
    delay: index * 20,
    from: { opacity: 0, transform: 'translateY(30px)' },
    to: { opacity: 1, transform: 'translateY(0)' },
    config: config.gentle,
  })

  const editLevelName = (levelName: string) => {
    const editedName = levelName.length > 24 ? truncate(levelName, { length: 21 }) : levelName
    return editedName
  }

  return (
    <animated.div style={style}>
      {loading ? (
        <Skeleton
          key={index}
          variant="rectangular"
          height="160px"
          width="160px"
          sx={{ borderRadius: '10px' }}
        />
      ) : (
        <Flex onClick={onClick(level)} height="160px" width="160px" cursor="pointer">
          <Box
            display="flex"
            sx={{
              border: '2px solid grey',
              borderColor: 'primary.main',
              fontSize: 25,
              backgroundColor: 'primary.light',
            }}
            borderRadius="10px"
            width="100%"
            height="100%"
            alignItems="center"
            justifyContent="center"
            padding="8px"
          >
            <Typography
              sx={{
                color: 'black',
                textAlign: 'center',
                fontWeight: 500,
                fontSize: '1.5rem',
                overflowWrap: 'break-word',
                width: '100%',
              }}
            >
              {editLevelName(level.name)}
            </Typography>
          </Box>
        </Flex>
      )}
    </animated.div>
  )
}
