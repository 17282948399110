import { useRef } from 'react'
import { cloneDeep, sum, sumBy } from 'lodash-es'
import { PlaythroughStatsInput } from '@proxyqb/graphql-api-types'

interface StatCounters {
  percentageCloseness: Record<string, number[]>
  movingTimes: Record<string, number>
}

const initStatCounters = (): StatCounters => ({
  percentageCloseness: {},
  movingTimes: {},
})

export const mapStatsForBE = (stats: StatCounters): PlaythroughStatsInput => ({
  percentageCloseness: Object.entries(stats.percentageCloseness).map(([cubeId, values]) => ({
    cubeId,
    values,
  })),
  movingTimes: Object.entries(stats.movingTimes).map(([cubeId, value]) => ({
    cubeId,
    value,
  })),
})

export const useStatsCollector = () => {
  const statCounters = useRef<StatCounters>(initStatCounters())
  const lastDistance = useRef<Record<string, { angle: number; distance: number }>>({})
  const isMoving = useRef<Record<string, boolean>>({})

  const logPercentageClosenessData = (currentStatusByGoal, delta) => {
    let minTotalDistance = Number.MAX_VALUE
    let minTotalDistanceGoalIndex = 0
    currentStatusByGoal.forEach((goalCubeStatuses, goalIndex) => {
      const totalDistance = sumBy(goalCubeStatuses, (cubeStatus) => Math.abs(cubeStatus.angle))
      if (totalDistance < minTotalDistance) {
        minTotalDistance = totalDistance
        minTotalDistanceGoalIndex = goalIndex
      }
    })
    currentStatusByGoal[minTotalDistanceGoalIndex]?.forEach(({ angle, cubeId }) => {
      const percentageDistanceClass = Math.floor(angle / (Math.PI / 100) / 10)
      if (!statCounters.current.percentageCloseness[cubeId]) {
        statCounters.current.percentageCloseness[cubeId] = new Array(10).fill(0)
      }
      statCounters.current.percentageCloseness[cubeId][percentageDistanceClass] += delta
    })
  }

  const logMovingData = (currentStatusByGoal, delta) => {
    currentStatusByGoal[0]?.forEach(({ cubeId, distance, angle }) => {
      if (lastDistance.current[cubeId]) {
        const { distance: oldDistance } = lastDistance.current[cubeId]
        if (isNaN(oldDistance) || isNaN(distance)) {
          console.log({ distance, oldDistance })
        }
        // eslint-disable-next-line no-empty
        if (oldDistance === distance) {
        } else {
          if (Math.abs(oldDistance - distance) > 0.05) {
            isMoving.current[cubeId] = true
          } else {
            isMoving.current[cubeId] = false
          }
        }
      }
      lastDistance.current[cubeId] = { angle, distance }
    })
    if (Object.values(isMoving.current).every(Boolean)) {
      statCounters.current.movingTimes['all'] = (statCounters.current.movingTimes['all'] || 0) + delta
    } else {
      const movingCubes = Object.entries(isMoving.current).filter(([, moving]) => moving)
      if (movingCubes.length) {
        movingCubes.forEach(([cubeId]) => {
          statCounters.current.movingTimes[cubeId] = (statCounters.current.movingTimes[cubeId] || 0) + delta
        })
      } else {
        statCounters.current.movingTimes['none'] = (statCounters.current.movingTimes['none'] || 0) + delta
      }
    }
  }

  const pushData = (currentStatusByGoal, delta, isTimePaused) => {
    if (!isTimePaused) {
      logPercentageClosenessData(currentStatusByGoal, delta)
      logMovingData(currentStatusByGoal, delta)
    }
  }

  const resetStatCounters = () => {
    statCounters.current = initStatCounters()
  }

  const getStats = (): StatCounters => {
    const currentStats = cloneDeep(statCounters.current)
    for (const cubeId in currentStats.percentageCloseness) {
      currentStats.percentageCloseness[cubeId] = currentStats.percentageCloseness[cubeId].map(
        (time) => time / sum(statCounters.current.percentageCloseness[cubeId]),
      )
    }
    for (const cubeId in currentStats.movingTimes) {
      currentStats.movingTimes[cubeId] =
        currentStats.movingTimes[cubeId] / sum(Object.values(statCounters.current.movingTimes))
    }
    return currentStats
  }

  return {
    pushData,
    resetStatCounters,
    getStats,
  }
}
