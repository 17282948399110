/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface LevelStatistics
 */
export interface LevelStatistics {
  /**
   *
   * @type {number}
   * @memberof LevelStatistics
   */
  averageScore: number
  /**
   *
   * @type {number}
   * @memberof LevelStatistics
   */
  std: number
  /**
   *
   * @type {number}
   * @memberof LevelStatistics
   */
  sampleLength: number
}

export function LevelStatisticsFromJSON(json: any): LevelStatistics {
  return LevelStatisticsFromJSONTyped(json, false)
}

export function LevelStatisticsFromJSONTyped(json: any, ignoreDiscriminator: boolean): LevelStatistics {
  if (json === undefined || json === null) {
    return json
  }
  return {
    averageScore: json['averageScore'],
    std: json['std'],
    sampleLength: json['sampleLength'],
  }
}

export function LevelStatisticsToJSON(value?: LevelStatistics | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    averageScore: value.averageScore,
    std: value.std,
    sampleLength: value.sampleLength,
  }
}
