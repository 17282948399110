import { CubePatterns } from '@proxyqb/graphql-api-types'
import { faceQuaternions } from '@proxyqb/level-generators'
import { useFlagsmith } from '@proxyqb/react-feature-flags'
import { baseRotationQuaternion, baseV3RotationQuaternion, CubeVersion } from '@proxyqb/ui'
import { useRef } from 'react'
import { useNavigate } from 'react-router'
import { createGlobalState, useInterval } from 'react-use'
import { Line3, Matrix4, Quaternion, Vector3 } from 'three'
import { MovementDetector } from '../movement-detection'
import { standardColors, standardColorsV3 } from '../use-light-up-cubes'
import { enqueueBluetoothCommand } from './bluetooth-command-queue'
import { connectBT } from './BluetoothConnector.service'
import { CubeTexturesData, resolveTexture } from './textures'
import { omit } from 'lodash-es'

const UUID_SERVICE = 'a64267f3-5745-48de-8725-4de3f20894c4'
const UUID_NAME_SERVICE = '37e00ac8-2853-11ed-a261-0242ac120002'
const UUID_VERSION = '24b49542-2856-11ed-a261-0242ac120002'
const UUID_IMU_DATA = '6c3ab24a-9236-4d96-aa6f-ac35d64aa4f7'
const UUID_LED = 'efe4b3ca-d24e-4671-bbb5-3665edc5e126'
const UUID_CALIBRATION = '64b5b0c3-5a92-4d57-86e2-1d7d310d577e'
const UUID_VIBRATE = '91e20bb0-9e15-4247-806f-895e77955ab0'
const UUID_BATTERY = '0bee46b6-c963-4427-b069-ebb8aedb246d'
const UUID_DEVICE_NAME = 'ae94aa90-1dba-4610-9cbb-5c719f5a13b7' //characteristic
const UUID_CONTROL = 'eedcc8bc-1e15-11ed-861d-0242ac120002'

type CubeCalibration = {
  isCalibrated: true
  gyroX: number
  gyroY: number
  gyroZ: number
  accX: number
  accY: number
  accZ: number
  magX: number
  magY: number
  magZ: number
}

type Color = {
  r: number
  g: number
  b: number
}

export type CubeData = {
  id: string
  isConnected: boolean
  isCalibrated: boolean
  isSyncedWithDevice: boolean
  batteryState?: number
  syncQuaternionConjugated?: Quaternion
  syncQuaternion?: Quaternion
  pattern: CubePatterns
  setColor(color: Color): Promise<void>
  name: string
  startQuaternionPolling(): void
  stopQuaternionPolling(): void
  getCubeCurrentSide(shuffleQuaternion?: Quaternion): number
  fw: string
} & CubeTexturesData
export type Cube = CubeData & {
  connect(): Promise<void>
  startIMUPooling(): Promise<() => Promise<void>>
  calibrate(): Promise<void>
  getCalibration(): Promise<CubeCalibration>
  loadBatteryState(id: string): Promise<void>
  loadAllBatteriesState(): Promise<void>
}

export type CubesState = Record<string, CubeData>

class CharacteristicNotFoundError extends Error {
  constructor(characteristicUUID: string, ...rest) {
    super(...rest)
  }
}

let deviceSyncEnabled = false
let updateDeviceSyncQ = false
const deviceSyncQuaternion = new Quaternion()
export const currentDeviceOrientationConjugated = new Quaternion()

// @ts-ignore
const sensor = new RelativeOrientationSensor({ frequency: 60 })
sensor.addEventListener('reading', () => {
  if (sensor.quaternion && deviceSyncEnabled) {
    if (updateDeviceSyncQ) {
      deviceSyncQuaternion.fromArray(sensor.quaternion)
      updateDeviceSyncQ = false
    }

    currentDeviceOrientationConjugated
      .fromArray(sensor.quaternion)
      .conjugate()
      .premultiply(deviceSyncQuaternion)

    // Isolate single axis: https://stackoverflow.com/a/47841408
    const theta = Math.atan2(currentDeviceOrientationConjugated.z, currentDeviceOrientationConjugated.w)
    currentDeviceOrientationConjugated.set(0, 0, Math.sin(theta), Math.cos(theta))
  }
})
sensor.start()

const useGlobalCubesState = createGlobalState<CubesState>({})
const useGlobalCubeVersionState = createGlobalState<CubeVersion>(CubeVersion.v3)

const PATTERN_NAME_MAP = {
  [CubePatterns.ColorfulNumbers]: [
    'Kostka 01',
    'Kostka_v3A',
    'Kostka_v3B',
    'Kostka_v3C',
    'Kostka_v3D',
    'Kostka 12',
    'Kostka 16',
    'Kostka 21',
    'Kostka 08',
    'Kostka 25',
    'Kostka 29',
    'Kostka 33',
  ],
  [CubePatterns.ColorfulLetters]: [
    'Kostka 03',
    'Kostka 07',
    'Kostka 09',
    'Kostka_v3E',
    'Kostka_v3F',
    'Kostka_v3G',
    'Kostka 13',
    'Kostka 17',
    'Kostka 20',
    'Kostka 22',
    'Kostka 23',
    'Kostka 26',
    'Kostka 30',
    'Kostka 34',
  ],
  [CubePatterns.Colors]: [
    'Kostka 04',
    'Kostka 05',
    'Kostka 06',
    'Kostka_v3H',
    'Kostka_v3I',
    'Kostka_v3J',
    'Kostka_v4_A',
    'Kostka_v3',
    'Kostka 10',
    'Kostka 11',
    'Kostka 14',
    'Kostka 15',
    'Kostka 18',
    'Kostka 19',
    'Kostka 24',
    'Kostka 27',
    'Kostka 28',
    'Kostka 31',
    'Kostka 32',
    'Kostka 35',
    'Kostka 36',
  ], // Move 'Kostka 06' from ColorfulNumbers to Colors - nekdo prejmenoval kostku a kufr ma ted 2x numbers
  [CubePatterns.BlackAf]: [],
  [CubePatterns.BlackGl]: [],
  [CubePatterns.BlackNumbers]: [],
  [CubePatterns.ColorfulNumbersAlt]: [],
  [CubePatterns.ColorfulLettersAlt]: [],
}

const NAME_PATTERN_MAP = {}

Object.entries(PATTERN_NAME_MAP).forEach(([pattern, names]) => {
  names.forEach((name) => {
    NAME_PATTERN_MAP[name] = pattern
  })
})

const eventListeners = {}

const getPatternByCubeName = (name: string) => {
  const theOldWay = NAME_PATTERN_MAP[name]
  if (theOldWay) {
    return theOldWay
  }
  const [, pattern] = name.split(' ')
  return pattern
}

const getCubeCurrentSide = (
  currentQ: Quaternion,
  calibration?: Quaternion,
  shuffleQuaternion?: Quaternion,
): number => {
  const VECTOR_LENGTH = 10

  let vectorWin = new Vector3(0, -VECTOR_LENGTH, 0)

  const preQuaternion = shuffleQuaternion || calibration

  if (preQuaternion) {
    vectorWin = new Line3(new Vector3(0, 0, 0), vectorWin).applyMatrix4(
      new Matrix4().makeRotationFromQuaternion(preQuaternion.premultiply(currentDeviceOrientationConjugated)),
    ).end
  }
  const bestResult = { distance: Number.MAX_VALUE, faceIndex: 0 }

  faceQuaternions.forEach((q, faceIndex) => {
    const faceVector = new Vector3(0, -VECTOR_LENGTH, 0)
      .applyQuaternion(q.clone().conjugate())
      .applyQuaternion(currentQ)
    const distance = vectorWin.distanceTo(faceVector)
    if (bestResult.distance > distance) {
      bestResult.distance = distance
      bestResult.faceIndex = faceIndex
    }
  })

  return bestResult.faceIndex + 1
}

/**
 * Global ref for realtime cube quaterions read from Bluetooth, Record<ID, Quaternion>.
 * Better than using state coz we don't want to trigger rerender neither want to have old state instance somewhere.
 */
const quaternions: Record<string, Quaternion> = {}

const timeoutPromise = (): Promise<void> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve()
    }, 4000)
  })
}

const readValuePromise = async (characteristic) => {
  return await characteristic.readValue()
}

const registerEventListener = (
  eventTarget: EventTarget,
  targetId: string,
  eventType: string,
  handler: EventListenerOrEventListenerObject,
) => {
  const oldListener = eventListeners[targetId]?.[eventType]
  if (oldListener) {
    eventTarget.removeEventListener(eventType, oldListener)
  }
  eventTarget.addEventListener(eventType, handler)
  eventListeners[targetId] = {
    ...eventListeners[targetId],
    [eventType]: handler,
  }
}

let setupDevices: string[] = []

const getQuaternionFromDataViewBuffer = (buffer: ArrayBuffer): number[] => {
  const imuData = new Int32Array(buffer)
  const q1 = imuData[0] / 0x40000000
  const q2 = imuData[1] / 0x40000000
  const q3 = imuData[2] / 0x40000000
  const q05 = 1 - q1 * q1 - q2 * q2 - q3 * q3
  const q0 = q05 > 0 ? Math.sqrt(q05) : 0
  return [q0, q1, q2, q3]
}

export const useCubes2 = (disconnectCallback?: (() => void) | null, stopSyncing?: () => void) => {
  const { flags } = useFlagsmith()
  deviceSyncEnabled = !!flags.sync_with_tablet?.enabled
  if (!deviceSyncEnabled) {
    deviceSyncQuaternion.identity()
  }
  const [cubes, setCubes] = useGlobalCubesState()
  const [cubeVersion, setCubeVersion] = useGlobalCubeVersionState()
  const { current: movementDetector } = useRef(new MovementDetector())
  const imuPollTimeouts = useRef<Record<string, number>>({})
  const activePolling = useRef<Record<string, boolean>>({})
  const navigate = useNavigate()
  useInterval(() => {
    try {
      navigator.bluetooth.getDevices().then((devices) => {
        for (const device of devices) {
          const cube = cubes[device.id]
          if (!cube) {
            continue
          }
          if (cube.isConnected !== device.gatt?.connected) {
            setCubes((cubes) => ({
              ...cubes,
              [device.id]: {
                ...cubes[device.id],
                isSyncedWithDevice:
                  device.gatt?.connected && cubes[device.id].id === device.gatt?.device.id
                    ? cubes[device.id]?.isSyncedWithDevice
                    : false,
                isConnected: cubes[device.id].id === device.gatt?.device.id ? !!device.gatt?.connected : true,
              },
            }))
          }
        }
      })
    } catch (e) {
      if (e instanceof TypeError && e.message === 'navigator.bluetooth.getDevices is not a function') {
        navigate('/enable-flags')
      } else throw e
    }
  }, 500)

  const syncAllCubes = async (
    skipWaitingForMovement = false,
    onSyncStart?: () => void,
    onSyncFinish?: () => void,
  ) => {
    const syncAll = () => {
      updateDeviceSyncQ = true
      Object.keys(cubes).forEach((cubeId) => {
        if (quaternions[cubeId]) {
          const baseRotation =
            cubeVersion === CubeVersion.v3 ? baseV3RotationQuaternion : baseRotationQuaternion
          const syncQ = quaternions[cubeId].multiply(baseRotation)
          setCubeSync(cubeId, syncQ)
        }
      })
      onSyncFinish?.()
      movementDetector.stopMovementDetection()
    }

    if (skipWaitingForMovement) {
      syncAll()
    } else {
      movementDetector.startMovementDetection({
        movementDetectionOptions: { movementTrigger: Math.PI / 1800 },
      })
      onSyncStart?.()
      setTimeout(() => {
        if (movementDetector.getIsSomeMoving()) {
          movementDetector.onMovementStopped(syncAll)
        } else {
          syncAll()
        }
      }, 5000)
    }
  }

  const setCubeSync = (id, q: Quaternion) => {
    const syncQ = q.clone()
    setCubes((oldCubes) => ({
      ...oldCubes,
      [id]: {
        ...oldCubes[id],
        isSyncedWithDevice: true,
        syncQuaternion: syncQ,
        syncQuaternionConjugated: syncQ.clone().conjugate(),
        getCubeCurrentSide: (shuffleQuaternion) =>
          getCubeCurrentSide(quaternions[id], syncQ, shuffleQuaternion),
      },
    }))
  }

  async function startPairing() {
    const server = await connectBT()
    if (!server) {
      return
    }
    setupDevice(server.device)
  }

  const registerReconnectAndDisconnectHandler = (
    device: BluetoothDevice,
    disconnectCallback?: (() => void) | null,
  ) => {
    const disconnectHandler = () => {
      if (imuPollTimeouts.current[device.id]) {
        activePolling.current[device.id] = false
        clearTimeout(imuPollTimeouts.current[device.id])
        delete imuPollTimeouts.current[device.id]
      }
      setupDevices = setupDevices.filter((it) => it !== device.id)
      device.watchAdvertisements()
      disconnectCallback?.()
    }

    registerEventListener(device, device.id, 'gattserverdisconnected', disconnectHandler)
  }

  const registerDisconnectHandler = (device: BluetoothDevice) => {
    registerReconnectAndDisconnectHandler(device)
  }

  const unpairCubes = (device: BluetoothDevice) => {
    device?.gatt?.disconnect()
    device?.forget()
    setCubes((cubes) => omit(cubes, device.id))
  }

  const fwVersion = async (device: BluetoothDevice) => {
    const service = await device.gatt?.getPrimaryService(UUID_NAME_SERVICE)
    const version = await service!.getCharacteristic(UUID_VERSION)
    const versionValue = await version.readValue()
    return new TextDecoder().decode(versionValue)
  }

  async function setupDevice(device: BluetoothDevice) {
    if (setupDevices.includes(device.id)) {
      return
    }
    const id = device.id
    // Always start polling right away and keep polling as long as the cube is connected. Current cubes can lag for a while after sending notifications is activated.
    const startPolling = true
    const service = await device.gatt?.getPrimaryService(UUID_SERVICE)
    const characteristicImuData = await service?.getCharacteristic(UUID_IMU_DATA)
    const characteristic = await service!.getCharacteristic(UUID_BATTERY)
    let characteristicControl
    const result = await characteristic.readValue()
    if (!characteristicImuData) {
      return
    }
    try {
      await device!.gatt!.getPrimaryService(UUID_NAME_SERVICE)
    } catch {
      setCubeVersion(CubeVersion.v2)
      console.log('You are using old cubes')
    }

    const quaternion = id in quaternions ? quaternions[id] : new Quaternion()
    quaternions[id] = quaternion

    async function setupNotificationsListener() {
      try {
        characteristicControl = await service!.getCharacteristic(UUID_CONTROL)
      } catch {
        throw new CharacteristicNotFoundError(UUID_CONTROL)
      }

      const result = await characteristicControl.readValue()
      result.setUint8(3, flags?.ble_notifications_ms_interval?.value as number)
      await characteristicControl.writeValue(result)
      if (!activePolling.current[id]) {
        characteristicImuData!.startNotifications().then((_) => {
          registerEventListener(characteristicImuData!, device.id, 'characteristicvaluechanged', (event) => {
            const { buffer } = event.target!['value']
            const [q0, q1, q2, q3] = getQuaternionFromDataViewBuffer(buffer)
            quaternions[id].set(q1, q2, q3, q0)

            movementDetector.pushQuaternion(id, quaternions[id].clone())
          })
        })
        activePolling.current[id] = true
      }
    }

    const setupImuPollInterval = () => {
      const readValues = async () => {
        try {
          const { buffer } = await characteristicImuData.readValue()
          const [q0, q1, q2, q3] = getQuaternionFromDataViewBuffer(buffer)

          quaternion.set(q1, q2, q3, q0)
          movementDetector.pushQuaternion(id, quaternion.clone())
        } catch (e) {
          if (e instanceof DOMException) {
            stopSyncing?.()
            return
          }
          console.error('failed to read quaternion for cubeId: ', id, e)
        } finally {
          if (activePolling.current[id]) {
            imuPollTimeouts.current[id] = window.setTimeout(() => {
              enqueueBluetoothCommand(id, readValues, 'read')
            }, 0)
          }
        }
      }
      if (!activePolling.current[id]) {
        activePolling.current[id] = true
        imuPollTimeouts.current[id] = window.setTimeout(() => {
          enqueueBluetoothCommand(id, readValues, 'read')
        }, 0)
      }
    }

    const setupImuReading = async () => {
      if (!flags?.ble_notifications_ms_interval?.enabled) {
        setupImuPollInterval()
      } else {
        try {
          await setupNotificationsListener()
        } catch (e) {
          if (e instanceof CharacteristicNotFoundError) {
            console.log(
              'Connected cube doesnt support IMU data notifications, falling back to reading values manually',
              e,
            )
            setupImuPollInterval()
          }
        }
      }
    }

    if (startPolling) {
      await setupImuReading()
    }
    const name = await readName(id)
    const pattern = getPatternByCubeName(name)
    let fw = 'N/A'
    try {
      fw = await fwVersion(device)
    } catch (e) {
      console.log('couldnt read fw version', e)
    }
    setCubes((prevCubes) => ({
      ...prevCubes,
      [id]: {
        ...resolveTexture(pattern, flags),
        isConnected: device.gatt!.connected,
        startQuaternionPolling: async () => {
          // Polling is currently started right after the cube is connected. Current cubes can lag for a while after sending notifications is activated.
        },
        stopQuaternionPolling: async () => {
          // Polling is currently active as long as the cube is connected.
        },
        getCubeCurrentSide: () => getCubeCurrentSideById(device.id),
        isCalibrated: true, // TODO: it may be already calibrated
        isSyncedWithDevice: false,
        characteristic: characteristicImuData,
        pattern,
        setColor: setCubeLedColor(id),
        name,
        id,
        batteryState: result.getUint8(0),
        fw: fw,
      },
    }))

    if (!(id in activePolling.current)) {
      registerDisconnectHandler(device)
    }
    setupDevices.push(id)
  }

  async function readName(id) {
    const devices = await navigator.bluetooth.getDevices()
    const device = devices?.find((device) => device.id === id)
    let service
    let characteristic
    try {
      service = await device!.gatt!.getPrimaryService(UUID_SERVICE)
      characteristic = await service!.getCharacteristic(UUID_DEVICE_NAME)
    } catch (err) {
      service = await device!.gatt!.getPrimaryService(UUID_NAME_SERVICE)
      characteristic = await service!.getCharacteristic(UUID_DEVICE_NAME)
    }
    let result
    while (!result) {
      try {
        result = await characteristic.readValue()
      } catch (e) {
        console.log('Name read failed. Trying again...')
      }
    }
    return new TextDecoder().decode(result)
  }

  /**
   * @param {string} id cube id
   * @param {number} time to vibrate in milliseconds
   * @param {number} intensity 0-255 - 0 lowest, 255 highest
   * @returns {Promise<void>}
   */
  const vibrate = async (id: string, time: number, intensity: number) => {
    const devices = await navigator.bluetooth.getDevices()
    const device = devices.find((device) => device.id === id)

    const service = await device!.gatt!.getPrimaryService(UUID_SERVICE)
    const characteristic = await service!.getCharacteristic(UUID_VIBRATE)

    // Pořadové číslo	 Údaj	 Význam
    // Byte 0	         MSB	 Uint16 – Časový interval vibrací
    // Byte 1	         LSB
    // Byte 2	         PWM	 0-255 PWM intenzita vibrací
    const timeUint16 = Uint16Array.of(time)
    const timeView = new DataView(timeUint16.buffer)
    const request = new Uint8Array([timeView.getUint8(0), timeView.getUint8(1), intensity])
    await characteristic.writeValue(request)
  }

  async function calibrateNotCalibrated() {
    await Promise.all(
      Object.entries(cubes)
        .filter(([, cube]) => !cube.isCalibrated)
        .map(([id]) => calibrate(id)),
    )
  }

  async function loadAllBatteriesState() {
    const devices = await navigator.bluetooth.getDevices()
    const newCubes = {}
    const ids = Object.values(cubes).map((key) => key.id)
    for (const device of devices) {
      if (ids.includes(device.id)) {
        const batteryState =
          cubes[device.id].isCalibrated && cubes[device.id].isConnected
            ? await loadBatteryState(device.id)
            : 0
        newCubes[device.id] = {
          ...cubes[device.id],
          batteryState,
        }
      }
    }
    setCubes((oldCubes) => {
      return newCubes
    })
  }

  async function loadBatteryState(id: string): Promise<number> {
    const devices = await navigator.bluetooth.getDevices()
    const device = devices?.find((device) => device.id === id)
    if (!device) {
      return 0
    } else if (!device.gatt?.connected) {
      return 0
    } else {
      const service = await device.gatt!.getPrimaryService(UUID_SERVICE)
      const characteristic = await service!.getCharacteristic(UUID_BATTERY)
      const result = await Promise.race([timeoutPromise(), readValuePromise(characteristic)])
      if (result) {
        return result.getUint8(0)
      } else {
        return 0
      }
    }
  }

  async function calibrate(id: string) {
    // @ts-ignore
    const devices = await navigator.bluetooth.getDevices()
    const device = devices?.find((device) => device.id === id)
    if (!device) {
      console.error('cannot find the device')
      return
    }
    const service = await device.gatt!.getPrimaryService(UUID_SERVICE)
    const characteristic = await service!.getCharacteristic(UUID_CALIBRATION)
    const value = new Uint8Array([0xff]).buffer
    await characteristic.writeValue(value)

    async function getCalibration() {
      const result = await characteristic.readValue()
      if (result.byteLength <= 1) {
        return {
          isCalibrated: false,
        }
      }
      const gyroX = result.getInt16(1)
      const gyroY = result.getInt16(3)
      const gyroZ = result.getInt16(5)
      const accX = result.getInt16(7)
      const accY = result.getInt16(9)
      const accZ = result.getInt16(11)
      const magX = result.getInt16(13)
      const magY = result.getInt16(15)
      const magZ = result.getInt16(17)
      return {
        isCalibrated: true,
        isSyncedWithDevice: false,
        gyroX,
        gyroY,
        gyroZ,
        accX,
        accY,
        accZ,
        magX,
        magY,
        magZ,
      }
    }

    while (true) {
      const { isCalibrated } = await getCalibration()
      await new Promise((resolve) => {
        setTimeout(resolve, 200)
      })
      if (isCalibrated) {
        setCubes((prevCubes) => ({
          ...prevCubes,
          [id]: {
            ...prevCubes[id],
            isCalibrated: true,
          },
        }))
      }
    }
  }

  // TODO: get bluetooth id by cube pattern
  async function getIdsByPatterns(patterns: CubePatterns[]): Promise<string[]> {
    const usedNames: string[] = []
    return patterns.map((pattern) => {
      const names = [
        ...PATTERN_NAME_MAP[pattern],
        ...Array(100)
          .fill(null)
          .map((_, index) => `Kostka ${pattern} ${index <= 9 ? '0' + index : index}`),
      ]
      const cube = Object.values(cubes)
        .sort((a, b) => a.name.localeCompare(b.name))
        .filter(({ name }) => !usedNames.includes(name!))
        .filter(({ isConnected }) => isConnected)
        .find(({ name }) => names.includes(name))
      if (cube) {
        usedNames.push(cube!.name)
        return cube.id
      } else {
        return 'unknown'
      }
    })
  }

  const registerReconnect = async () => {
    const devices = await navigator.bluetooth.getDevices()

    devices.forEach((device) => {
      const handler = (event: any) => {
        if (!event.device.gatt.connected) {
          event.device.gatt.connect().then((gatt) => {
            setupDevice(gatt.device)
          })
        }
      }
      registerEventListener(device, device.id, 'advertisementreceived', handler)
      device.watchAdvertisements()
    })
  }

  const getCubeCurrentSideById = (cubeId: string, shuffleQuaternion?: Quaternion) => {
    return getCubeCurrentSide(quaternions[cubeId], cubes[cubeId]?.syncQuaternion, shuffleQuaternion)
  }

  const setStandardLEDColors = (id: string) => {
    const cubePattern = Object.values(cubes).find(({ id: cubeId }) => cubeId === id)?.pattern
    const colors = cubeVersion === CubeVersion.v3 ? standardColorsV3 : standardColors
    cubePattern === CubePatterns.Colors ? setCircleLEDs(id, colors) : setAddressableLEDs(id, colors)
  }

  const setCubeBrightness = async (
    colors: Color[],
    id: string,
    characteristic: BluetoothRemoteGATTCharacteristic,
  ) => {
    const brightnessDivider = 5
    const value = new Uint8Array(72).map((_, index) => {
      const i = Math.floor(index / 4) % 6
      if (index < 24) {
        return colors[i].g / brightnessDivider
      } else if (index < 48) {
        return colors[i].r / brightnessDivider
      } else {
        return colors[i].b / brightnessDivider
      }
    })
    await enqueueBluetoothCommand(
      id,
      async () => {
        await characteristic.writeValue(value.buffer)
      },
      'led',
    )
  }

  const setCircleLEDs = async (id: string, colors: Color[]) => {
    try {
      const devices = await navigator.bluetooth.getDevices()
      const device = devices?.find((device) => device.id === id)
      const service = await device!.gatt!.getPrimaryService(UUID_SERVICE)
      const characteristic = await service!.getCharacteristic(UUID_LED)

      try {
        // is new V4 cube
        await device!.gatt!.getPrimaryService(UUID_NAME_SERVICE)

        const value = new Uint8Array(36).map((_, index) => {
          const i = Math.floor(index / 6)
          if (index / 6 - i >= 0.5) {
            if (index % 3 === 0) {
              return colors[i].r
            } else if (index % 3 === 1) {
              return colors[i].b
            } else {
              return colors[i].g
            }
          } else return 0
        })
        enqueueBluetoothCommand(
          id,
          async () => {
            await characteristic.writeValue(value.buffer)
          },
          'led',
        )
      } catch {
        await setCubeBrightness(colors, id, characteristic)
      }
      // await characteristic.writeValue(value.buffer)
    } catch (err) {
      console.warn('Set cube led error', err)
    }
  }
  const setAddressableLEDs = async (id: string, colors: Color[]) => {
    try {
      const devices = await navigator.bluetooth.getDevices()
      const device = devices?.find((device) => device.id === id)
      const service = await device!.gatt!.getPrimaryService(UUID_SERVICE)
      const characteristic = await service!.getCharacteristic(UUID_LED)

      try {
        // is new V4 cube
        await device!.gatt!.getPrimaryService(UUID_NAME_SERVICE)

        const value = new Uint8Array(36).map((_, index) => {
          const i = Math.floor(index / 6)
          if (index % 3 === 0) {
            return colors[i].r
          } else if (index % 3 === 1) {
            return colors[i].b
          } else {
            return colors[i].g
          }
        })
        enqueueBluetoothCommand(
          id,
          async () => {
            await characteristic.writeValue(value.buffer)
          },
          'led',
        )
      } catch {
        await setCubeBrightness(colors, id, characteristic)
      }
      // await characteristic.writeValue(value.buffer)
    } catch (err) {
      console.warn('Set cube led error', err)
    }
  }

  const handleRecoverColorsLed = (cubesForCurrentGame: string[]) => {
    if (flags.led_color_cubes?.enabled) {
      const recoverColor = () => {
        Object.values(cubesForCurrentGame).forEach((c) => {
          setStandardLEDColors(c)
        })
      }
      setTimeout(recoverColor, 100)
    } else {
      const turnOffColor = () => {
        for (const id of cubesForCurrentGame) {
          cubes[id].setColor({ r: 0, g: 0, b: 0 })
        }
      }
      setTimeout(turnOffColor, 100)
    }
  }

  const setCubeLedColor = (id) =>
    async function ({ r, g, b }: Color) {
      try {
        const devices = await navigator.bluetooth.getDevices()
        const device = devices?.find((device) => device.id === id)
        const service = await device!.gatt!.getPrimaryService(UUID_SERVICE)
        const characteristic = await service!.getCharacteristic(UUID_LED)
        try {
          // is new V4 cube
          await device!.gatt!.getPrimaryService(UUID_NAME_SERVICE)
          const value = new Uint8Array(36).map((_, index) => {
            const i = Math.floor(index / 6)
            if (index % 3 === 0) {
              return r
            } else if (index % 3 === 1) {
              return b
            } else {
              return g
            }
          })

          await enqueueBluetoothCommand(
            id,
            async () => {
              await characteristic.writeValue(value.buffer)
            },
            'led',
          )
        } catch {
          const value = new Uint8Array(72).fill(g, 0, 24).fill(r, 24, 48).fill(b, 48, 72)
          await enqueueBluetoothCommand(
            id,
            async () => {
              await characteristic.writeValue(value.buffer)
            },
            'led',
          )
        }
      } catch (err) {
        console.warn('Set cube led error', err)
      }
    }

  return {
    startPairing,
    loadBatteryState,
    loadAllBatteriesState,
    cubes,
    setCubeLedColor,
    getIdsByPatterns,
    setAddressableLEDs,
    setStandardLEDColors,
    syncAllCubes,
    setCubeSync,
    vibrate,
    readName,
    registerReconnect,
    calibrateNotCalibrated,
    quaternions,
    getCubeCurrentSideById,
    handleRecoverColorsLed,
    cubeVersion,
    unpairCubes,
  }
}
