import * as Types from '@proxyqb/graphql-api-types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export const LevelItemFragmentDoc = gql`
    fragment LevelItem on Level {
  id
  name
}
    `;
export const QueryLevelsByRehabilitationTypeDocument = gql`
    query queryLevelsByRehabilitationType($code: String!) {
  queryLevelsByRehabilitationType(code: $code) {
    ...LevelItem
  }
}
    ${LevelItemFragmentDoc}`;

export function useQueryLevelsByRehabilitationTypeQuery(options: Omit<Urql.UseQueryArgs<QueryLevelsByRehabilitationTypeQueryVariables>, 'query'>) {
  return Urql.useQuery<QueryLevelsByRehabilitationTypeQuery, QueryLevelsByRehabilitationTypeQueryVariables>({ query: QueryLevelsByRehabilitationTypeDocument, ...options });
};
export const UserLevelCountInCategoriesDocument = gql`
    query userLevelCountInCategories {
  userLevelCountInCategories {
    code
    hasLevels
  }
}
    `;

export function useUserLevelCountInCategoriesQuery(options?: Omit<Urql.UseQueryArgs<UserLevelCountInCategoriesQueryVariables>, 'query'>) {
  return Urql.useQuery<UserLevelCountInCategoriesQuery, UserLevelCountInCategoriesQueryVariables>({ query: UserLevelCountInCategoriesDocument, ...options });
};
export const QueryLevelRequiredCubesDocument = gql`
    query queryLevelRequiredCubes($levelId: ID!) {
  getLevel(id: $levelId) {
    requiredCubes {
      pattern
    }
  }
}
    `;

export function useQueryLevelRequiredCubesQuery(options: Omit<Urql.UseQueryArgs<QueryLevelRequiredCubesQueryVariables>, 'query'>) {
  return Urql.useQuery<QueryLevelRequiredCubesQuery, QueryLevelRequiredCubesQueryVariables>({ query: QueryLevelRequiredCubesDocument, ...options });
};
export type LevelItemFragment = { __typename?: 'Level', id: string, name: string };

export type QueryLevelsByRehabilitationTypeQueryVariables = Types.Exact<{
  code: Types.Scalars['String'];
}>;


export type QueryLevelsByRehabilitationTypeQuery = { __typename?: 'Query', queryLevelsByRehabilitationType: Array<{ __typename?: 'Level', id: string, name: string }> };

export type UserLevelCountInCategoriesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type UserLevelCountInCategoriesQuery = { __typename?: 'Query', userLevelCountInCategories: Array<{ __typename?: 'UserLevelCount', code: string, hasLevels: number }> };

export type QueryLevelRequiredCubesQueryVariables = Types.Exact<{
  levelId: Types.Scalars['ID'];
}>;


export type QueryLevelRequiredCubesQuery = { __typename?: 'Query', getLevel: { __typename?: 'Level', requiredCubes: Array<{ __typename?: 'RequiredCube', pattern: Types.CubePatterns }> } };
