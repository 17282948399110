/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Density, DensityFromJSON, DensityToJSON } from './Density'
import { Domain, DomainFromJSON, DomainToJSON } from './Domain'

/**
 *
 * @export
 * @interface DensityFunction
 */
export interface DensityFunction {
  /**
   *
   * @type {string}
   * @memberof DensityFunction
   */
  level: string
  /**
   *
   * @type {Array<Domain>}
   * @memberof DensityFunction
   */
  domains: Array<Domain>
  /**
   *
   * @type {Density}
   * @memberof DensityFunction
   */
  density: Density
}

export function DensityFunctionFromJSON(json: any): DensityFunction {
  return DensityFunctionFromJSONTyped(json, false)
}

export function DensityFunctionFromJSONTyped(json: any, ignoreDiscriminator: boolean): DensityFunction {
  if (json === undefined || json === null) {
    return json
  }
  return {
    level: json['level'],
    domains: (json['domains'] as Array<any>).map(DomainFromJSON),
    density: DensityFromJSON(json['density']),
  }
}

export function DensityFunctionToJSON(value?: DensityFunction | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    level: value.level,
    domains: (value.domains as Array<any>).map(DomainToJSON),
    density: DensityToJSON(value.density),
  }
}
