import { ReactElement } from 'react'
import { Screen } from '../shared'
import { useGlobalState } from '@proxyqb/cube-global-state'
import { useQueryRehabilitationPlansByPatientIdQuery } from './rehabilitation-plan.generated'
import { useNavigate } from 'react-router-dom'
import { Flex } from '@proxyqb/ui'
import { RehabilitationPlanItem } from './rehabilitation-plan-item'
import { Typography } from '@mui/material'
import { useLocalStorage } from 'react-use'
import { difference } from 'lodash'
import { useIntl } from 'react-intl'

export const getNextLevelId = (
  localStorageData: Record<string, Record<string, { levelId: string }>>,
  patient,
  plan,
) => {
  const usersPlans = Object.entries(localStorageData).find(([patientId]) => patientId === patient.id)?.[1]
  return usersPlans ? usersPlans[plan.id]?.levelId || plan.levels[0].id : plan.levels[0].id
}

const getRehabilitationPlanInProgress = (
  localStorageData: Record<string, Record<string, { levelId: string }>>,
  patient,
  plans,
) => {
  const usersPlans = Object.entries(localStorageData).find((o) => o[0] === patient.id)?.[1]
  if (usersPlans) {
    const inProgress = plans?.filter((plan) => {
      const level = Object.entries(usersPlans).find(([key]) => key === plan.id)?.[1]
      return !!level && plan.levels.map((planLevel) => planLevel.id).indexOf(level.levelId) > 0
    })
    const notInProgress = difference(plans, inProgress)
    return {
      inProgress,
      notInProgress,
    }
  } else {
    return {
      inProgress: [],
      notInProgress: plans,
    }
  }
}
const RehabilitationPlanListScreen = (): ReactElement => {
  const [rehabPlanProgress, _] = useLocalStorage<Record<string, Record<string, { levelId: string }>>>(
    'rehabPlanProgress',
    {},
  )
  const { selectedPatient } = useGlobalState()
  const { formatMessage } = useIntl()
  const navigate = useNavigate()

  const getProgress = (plan): number => {
    const levelId = getNextLevelId(rehabPlanProgress!, selectedPatient, plan)
    const levels = plan.levels?.map((l) => l.id) ?? []
    const indexOfCurrentLevel = levels.indexOf(levelId)
    const numerator = indexOfCurrentLevel === -1 ? 0 : indexOfCurrentLevel
    return numerator / plan.levels.length
  }

  const [{ data, fetching: fetchingPlans }] = useQueryRehabilitationPlansByPatientIdQuery({
    pause: !selectedPatient,
    variables: {
      patientId: selectedPatient?.id || '',
    },
    requestPolicy: 'cache-and-network',
  })

  const fetching = !data || fetchingPlans

  const navigateToRehabilitationPlan = (plan): void => {
    navigate(`/prepare-plan/${plan.id}`)
  }

  const filteredItems = getRehabilitationPlanInProgress(
    rehabPlanProgress!,
    selectedPatient,
    data?.queryRehabilitationPlansByPatientId,
  )

  const itemsInProgress = fetching ? new Array(3).fill(null) : filteredItems!.inProgress
  const itemsNotInProgress = fetching ? new Array(3).fill(null) : filteredItems!.notInProgress

  return (
    <Screen
      primaryTitle="rehabilitationPlanList.title"
      backButtonHandler={() => navigate('/')}
      backButtonLabel="shared.back"
    >
      <Flex
        height="100%"
        width="100%"
        padding="20px 5%"
        alignItems="flex-start"
        flexDirection="column"
        gap="100px"
        mt={3}
      >
        {itemsInProgress.length > 0 && (
          <Flex
            gap="20px"
            flexWrap="wrap"
            justifyContent="flex-start"
            alignItems="flex-start"
            flexDirection="column"
          >
            <Typography variant={'h5'} mb={1}>
              {formatMessage({ id: 'rehabilitationPlanList.plansInProgress' })}
            </Typography>
            <Flex gap="20px" flexWrap="wrap" alignItems="center" justifyContent="center" flexDirection="row">
              {!fetching &&
                itemsInProgress.map((plan, index) => (
                  <RehabilitationPlanItem
                    key={index}
                    plan={plan}
                    index={index}
                    progress={!fetching ? getProgress(plan) : 0}
                    loading={fetching}
                    onClick={navigateToRehabilitationPlan}
                  />
                ))}
            </Flex>
          </Flex>
        )}
        {itemsNotInProgress.length > 0 && !fetching && (
          <Flex
            gap="20px"
            flexWrap="wrap"
            alignItems="flex-start"
            justifyContent="flex-start"
            flexDirection="column"
          >
            {!(itemsInProgress.length === 0) && (
              <Typography variant={'h5'} mb={1}>
                {formatMessage({ id: 'rehabilitationPlanList.otherPlans' })}
              </Typography>
            )}
            <Flex
              gap="20px"
              flexWrap="wrap"
              alignItems="flex-start"
              justifyContent="flex-start"
              flexDirection="row"
            >
              {itemsNotInProgress.map((plan, index) => (
                <RehabilitationPlanItem
                  key={index}
                  plan={plan}
                  index={index}
                  progress={0}
                  loading={fetching}
                  onClick={navigateToRehabilitationPlan}
                />
              ))}
            </Flex>
          </Flex>
        )}
      </Flex>
    </Screen>
  )
}

export default RehabilitationPlanListScreen
