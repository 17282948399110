import { Typography, useTheme } from '@mui/material'
import { useGlobalState } from '@proxyqb/cube-global-state'
import { Flex, LoadingTemplate } from '@proxyqb/ui'
import { useSelector } from '@xstate/react'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useEffectOnce } from 'react-use'
import { Patient } from '@proxyqb/graphql-api-types'
import { useQueryRehabilitationPlansByPatientIdQuery } from '../rehabilitation-plans/rehabilitation-plan.generated'
import { Screen, useCheckForUpdates } from '../shared'
import { env } from '../env'
import { format } from 'date-fns'
import { HomeButton } from '../home/home-button'
import PersonIcon from '@mui/icons-material/Person'
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle'

const PlayerSelectionScreen = () => {
  useCheckForUpdates()
  const theme = useTheme()
  const { setSelectedPatient, authService } = useGlobalState()
  const authState = useSelector(authService, (state) => state)
  const user = authState.context?.userDetails
  const [patientToFetch, setPatientToFetch] = useState<Patient>()
  const push = useNavigate()

  useEffectOnce(() => {
    setSelectedPatient(null)
  })

  const [{ data: dataRehabPlan, fetching: fetchingRehabPlan }] = useQueryRehabilitationPlansByPatientIdQuery({
    pause: !patientToFetch,
    variables: {
      patientId: patientToFetch?.id || '',
    },
  })

  useEffect(() => {
    if (patientToFetch && !fetchingRehabPlan) {
      const rehabPlanId = dataRehabPlan?.queryRehabilitationPlansByPatientId?.[0]
      if (rehabPlanId) {
        push('/')
      } else {
        push('/category-list')
      }
    }
  }, [dataRehabPlan?.queryRehabilitationPlansByPatientId, fetchingRehabPlan, patientToFetch])

  const logout = () => authService.send({ type: 'LOG_OUT' })

  const handleSelectPatient = (patient) => {
    setSelectedPatient(patient)
    setPatientToFetch(patient)
  }

  if (authState.matches('reloadingUser')) {
    return <LoadingTemplate />
  }

  return (
    <Screen
      primaryTitle={'playerSelectionScreen.whoWillPlay'}
      actions={[
        {
          handler: logout,
          right: true,
          label: 'shared.logout',
        },
      ]}
      hideFooter
    >
      <Flex gap="150px" justifyContent="center" alignItems="center" height="65vh" mt={10}>
        <HomeButton
          borderColor={theme.palette.primary.main}
          bgColor={theme.palette.primary.contrastText}
          onClick={() => handleSelectPatient(user)}
          typographyColor={theme.palette.primary.main}
          text={user?.firstName.concat(' ').concat(user?.lastName)}
          index={1}
          bgIcon={PersonIcon}
        />
        <HomeButton
          borderColor={theme.palette.primary.main}
          bgColor={theme.palette.primary.main}
          onClick={() => push('/select-patient')}
          typographyColor={theme.palette.primary.contrastText}
          translationText={'shared.patient'}
          index={2}
          bgIcon={SupervisedUserCircleIcon}
        />
      </Flex>
      <Typography
        sx={{
          position: 'fixed',
          bottom: 3,
          right: 10,
          zIndex: 100000,
          userSelect: 'text',
        }}
      >
        {env.release} - {format(env.buildTime, 'yyyy-MM-dd HH:mm:ss')}
      </Typography>
    </Screen>
  )
}

export default PlayerSelectionScreen
