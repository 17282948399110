import { MachineConfig } from 'xstate'
import { LevelDetailFragment } from '../../../../apps/cube-fe/src/app/game/get-level.generated'
import {
  CubeGeometry,
  CubePatterns,
  MatchCubesBy,
  SimpleStageGenerator,
  StageGenerator,
  StageResult,
} from '@proxyqb/graphql-api-types'
import { Quaternion } from 'three'

export type CubeSide = number
export const getCubeSides = (stageGenerator: StageGenerator) => {
  const matchCubeGeometry = (stageGenerator as SimpleStageGenerator).matchCubeGeometry
  switch (matchCubeGeometry) {
    case CubeGeometry.Edge:
      return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
    case CubeGeometry.Vertex:
      return [1, 2, 3, 4, 5, 6, 7, 8]
    case CubeGeometry.Face:
    default:
      return [1, 4, 5, 2, 3, 6]
  }
}

export type BaseStageHistory = {
  stageGoals: {
    side: CubeSide
    pattern: CubePatterns
    id: String
  }[]
  result: string
  type: string
}

export interface BaseGeneratorMachineContext<IDs extends string = string> {
  /** Real cubes we use for playing this level */
  readonly cubes: {
    /** <cube ID, side number 1-6> */
    id: IDs
    pattern: CubePatterns
    getCubeCurrentSide(shuffleQuaternion?: Quaternion): number
  }[]
  readonly level: LevelDetailFragment
  /** cubes we want to display, it can be any number of cubes, even more cubes than number of real cubes */
  displayGoals: {
    side: CubeSide
    pattern: CubePatterns
    /** Color of the text in the text-stage-generator.ts */
    color?: string
    /** If goal is to match by color or value in switched-asset-stage-generator.ts */
    matchBy?: typeof MatchCubesBy.Color | typeof MatchCubesBy.Value
    /** If this matchBy is synchronized (all goals are color or all are value) in switched-asset-stage-generator.ts */
    synced?: boolean
  }[]
  /** we can have more winning combinations, e.g. 1,2 is the same as 2,1 */
  compareGoals: Record<IDs, CubeSide | null>[]
  stagesCount: number
  stageHistory: BaseStageHistory[]
}
export type BaseGeneratorEvent =
  | {
      type: 'START'
    }
  | { type: 'FINISH'; time: number }
  | { type: 'SUCCESS'; time: number }
  | { type: 'FAILURE'; time: number; result: StageResult }

export const baseGeneratorMachineConfig: MachineConfig<BaseGeneratorMachineContext, any, BaseGeneratorEvent> =
  {
    id: 'baseStageGenerator',
    initial: 'idle',
    on: {
      FINISH: 'finish',
    },
    states: {
      idle: {
        on: {
          START: [
            {
              cond: 'stagesCountNotZero',
              target: 'playing',
              actions: ['calculateNextSide'],
            },
            {
              target: 'finish',
            },
          ],
        },
      },
      playing: {
        on: {
          SUCCESS: 'success',
          FAILURE: 'failure',
        },
      },
      success: {
        always: [
          {
            target: 'idle',
            actions: ['decrementCount'],
          },
        ],
        exit: ['recordStage'],
      },
      failure: {
        always: [
          {
            target: 'idle',
          },
        ],
        exit: ['recordStage'],
      },
      finish: {
        type: 'final',
      },
    },
  }
