/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import { WeakDomain, WeakDomainFromJSON, WeakDomainFromJSONTyped, WeakDomainToJSON } from './WeakDomain'

/**
 *
 * @export
 * @interface ZScore
 */
export interface ZScore {
  /**
   *
   * @type {Array<number>}
   * @memberof ZScore
   */
  zScore: Array<number>
  /**
   *
   * @type {Array<WeakDomain>}
   * @memberof ZScore
   */
  weakDomains: Array<WeakDomain>
}

export function ZScoreFromJSON(json: any): ZScore {
  return ZScoreFromJSONTyped(json, false)
}

export function ZScoreFromJSONTyped(json: any, ignoreDiscriminator: boolean): ZScore {
  if (json === undefined || json === null) {
    return json
  }
  return {
    zScore: json['zScore'],
    weakDomains: (json['weakDomains'] as Array<any>).map(WeakDomainFromJSON),
  }
}

export function ZScoreToJSON(value?: ZScore | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    zScore: value.zScore,
    weakDomains: (value.weakDomains as Array<any>).map(WeakDomainToJSON),
  }
}
