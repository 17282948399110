/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import { ZScoreAndDensityFunction, ZScoreAndDensityFunctionFromJSON } from '../models'

export interface DeficiencyDetectorRequest {
  patientId: string
  rehabilitationPlanId: string
  authorization?: string
}

/**
 *
 */
export class StatsApi extends runtime.BaseAPI {
  /**
   * Get Deficiency
   */
  async deficiencyDetectorRaw(
    requestParameters: DeficiencyDetectorRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<ZScoreAndDensityFunction>> {
    if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
      throw new runtime.RequiredError(
        'patientId',
        'Required parameter requestParameters.patientId was null or undefined when calling deficiencyDetector.',
      )
    }

    if (
      requestParameters.rehabilitationPlanId === null ||
      requestParameters.rehabilitationPlanId === undefined
    ) {
      throw new runtime.RequiredError(
        'rehabilitationPlanId',
        'Required parameter requestParameters.rehabilitationPlanId was null or undefined when calling deficiencyDetector.',
      )
    }

    const queryParameters: any = {}

    if (requestParameters.patientId !== undefined) {
      queryParameters['patient_id'] = requestParameters.patientId
    }

    if (requestParameters.rehabilitationPlanId !== undefined) {
      queryParameters['rehabilitation_plan_id'] = requestParameters.rehabilitationPlanId
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
      headerParameters['authorization'] = String(requestParameters.authorization)
    }

    const response = await this.request(
      {
        path: `/stats/deficiencyDetector/`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) => ZScoreAndDensityFunctionFromJSON(jsonValue))
  }

  /**
   * Get Deficiency
   */
  async deficiencyDetector(
    requestParameters: DeficiencyDetectorRequest,
    initOverrides?: RequestInit,
  ): Promise<ZScoreAndDensityFunction> {
    const response = await this.deficiencyDetectorRaw(requestParameters, initOverrides)
    return await response.value()
  }
}
