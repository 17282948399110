import { PropsWithChildren, ReactElement } from 'react'
import { Box } from '@mui/material'

export const Card = ({ children }: PropsWithChildren<void>): ReactElement => (
  <Box
    sx={{
      background: 'white',
      width: 'initial',
      boxShadow: '13px 13px 14px 1px rgba(99, 110, 114, 0.4)',
    }}
  >
    {children}
  </Box>
)
