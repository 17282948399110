/* eslint-disable react/no-unknown-property */
import { CSSProperties, FC, PropsWithChildren, Suspense } from 'react'
import { Vector3 } from 'three'
import { ContextForwardedCanvas } from './ContextForwardedCanvas'

interface Props {
  width?: CSSProperties['width']
  height?: CSSProperties['height']
  backgroundColor?: CSSProperties['backgroundColor']
}

const lightVector1 = new Vector3(3, -10, 0)
const lightVector2 = new Vector3(-3, -10, 0)
const zeroVector = new Vector3(0, 0, 0)

export const CanvasScene: FC<PropsWithChildren<Props>> = ({ children, width, height, backgroundColor }) => {
  return (
    <ContextForwardedCanvas style={{ width, height, backgroundColor: backgroundColor ?? 0x000000 }}>
      <pointLight position={lightVector1} intensity={0.4} />
      <pointLight position={lightVector2} intensity={0.4} />
      <Suspense fallback={null}>
        <scene position={zeroVector}>{children}</scene>
      </Suspense>
    </ContextForwardedCanvas>
  )
}
