import { Box, Typography } from '@mui/material'
import { useIntl } from 'react-intl'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export const EnableFlagsScreen = () => {
  const { formatMessage } = useIntl()
  const navigate = useNavigate()

  useEffect(() => {
    try {
      navigator.bluetooth.getDevices()
      navigate('/player-profiles')
    } catch (e) {
      console.warn('Experimental features not enabled')
    }
  }, [])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 3 }}>
      <Typography variant="h3">{formatMessage({ id: 'enableFlags.title' })}</Typography>
      <Typography sx={{ marginTop: 2 }} variant="h5">
        {formatMessage({ id: 'enableFlags.subtitle' })}
      </Typography>
      <ol>
        <li>{formatMessage({ id: 'enableFlags.steps.1' })}</li>
        <li>{formatMessage({ id: 'enableFlags.steps.2' })}</li>
        <li>{formatMessage({ id: 'enableFlags.steps.3' })}</li>
        <li>{formatMessage({ id: 'enableFlags.steps.4' })}</li>
        <li>{formatMessage({ id: 'enableFlags.steps.5' })}</li>
        <li>{formatMessage({ id: 'enableFlags.steps.6' })}</li>
      </ol>
      <Typography variant="h6">{formatMessage({ id: 'enableFlags.restart' })}</Typography>
    </Box>
  )
}
