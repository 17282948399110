import { useBattery, useInterval } from 'react-use'
import { Box, IconButton } from '@mui/material'
import { useCubes2 } from '../../../game/bluetooth/useCubes'
import { FC, useEffect, useState } from 'react'
import { BatteryDialog, getBatteryInterval } from '../molecules'
import { min } from 'lodash'
import { enqueueBluetoothCommand } from '../../../game/bluetooth/bluetooth-command-queue'
import { useFlagsmith } from '@proxyqb/react-feature-flags'
import { standardColors } from '../../../game/use-light-up-cubes'

interface Props {
  backgroundColor: string
  handleBatteryModalPause?: (isInModal: boolean) => void
  inGame?: boolean
  inGameCubes?: string[]
}

export const Battery: FC<Props> = ({ backgroundColor, handleBatteryModalPause, inGame, inGameCubes }) => {
  const { flags } = useFlagsmith()
  const cubeDisconnectedCallback = () => {
    loadAllBatteriesState()
  }
  const { cubes, loadAllBatteriesState, setStandardLEDColors } = useCubes2(cubeDisconnectedCallback)
  const battery = useBattery()
  const [lowestBatteryCube, setLowestBatteryCube] = useState<number>()
  const [openDialog, setOpenDialog] = useState<boolean>(false)

  const loadBatteries = () => {
    const allBatteryValues = [
      ...Object.values(cubes)
        .map((c) => c.batteryState)
        .filter((value) => value !== 0),
      battery.level * 100,
    ]
    setLowestBatteryCube(min(allBatteryValues))
  }

  const handleRecoverColorsLedIngame = () => {
    if (flags.led_color_cubes?.enabled && inGame?.valueOf()) {
      Object.values(cubes).forEach((c) => {
        if (c.batteryState !== 0 && inGameCubes?.includes(c.id)) {
          setStandardLEDColors(c.id)
        } else {
          c.setColor({
            r: 0,
            g: 0,
            b: 0,
          })
        }
      })
    } else {
      Object.values(cubes).forEach((c) => {
        c.setColor({
          r: 0,
          g: 0,
          b: 0,
        })
      })
    }
  }

  const handleOpenDialog = () => {
    setOpenDialog(true)
    handleBatteryModalPause?.(true)
  }

  const handleCloseDialog = () => {
    handleRecoverColorsLedIngame()
    setOpenDialog(false)
    handleBatteryModalPause?.(false)
  }

  useInterval(() => {
    if (cubes) {
      loadAllBatteriesState()
    }
  }, 60000)

  useEffect(() => {
    loadBatteries()
  }, [cubes, battery])

  return (
    <Box gap="5px" sx={{ display: 'flex', flexDirection: 'row' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyItems: 'center',
          alignItems: 'center',
        }}
      >
        <IconButton sx={{ height: '60px', width: '60px', color: 'secondary' }} onClick={handleOpenDialog}>
          {lowestBatteryCube && (
            <img
              style={{ transform: 'rotate(-90deg)' }}
              src={`/static/media/battery/${getBatteryInterval(lowestBatteryCube, backgroundColor)}.svg`}
            />
          )}
        </IconButton>
      </Box>
      {openDialog && <BatteryDialog onClose={handleCloseDialog} cubes={Object.values(cubes)} />}
    </Box>
  )
}
