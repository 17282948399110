import { useFrame, useLoader } from '@react-three/fiber'
import { MutableRefObject, Suspense, useEffect, useMemo, useRef } from 'react'
import { usePrevious } from 'react-use'
import {
  BufferGeometry,
  Euler,
  LineBasicMaterial,
  MathUtils,
  Mesh,
  MeshStandardMaterial,
  Quaternion,
  Vector3,
  Line,
  MeshLambertMaterial,
} from 'three'
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader'
import { AnimationState } from '@proxyqb/cube-fe/src/app/game/3d/GameCube'
import { CubePatterns } from '@proxyqb/graphql-api-types'

/** v3 are the cubes with fucked up colors */
export enum CubeVersion {
  v2 = 'v2',
  v3 = 'v3',
}

export interface BaseCubeProps {
  quaternion?: Quaternion
  onFrameUpdate?: (ref) => void
  scale?: number
  matchBy?: string
  animationState: MutableRefObject<AnimationState>
  pattern: CubePatterns
  animate?: boolean
  showWinningVectors?: boolean
  compareGoals?: Array<Quaternion | null>
  cubeVersion?: CubeVersion
}

export const baseRotationQuaternion = new Quaternion(0, -0.707, -0.707, 0)
export const originRotationQuaternion = new Quaternion(0.5, 0.5, 0.5, 0.5)
export const baseV3RotationQuaternion = new Quaternion(-0.5, -0.5, -0.5, 0.5)

const cubeObj: Record<CubePatterns, string> = {
  [CubePatterns.Colors]: 'static/media/cube/shape/cube_colors_v2.stl',
  [CubePatterns.ColorfulLetters]: 'static/media/cube/shape/cube_letters_v2.stl',
  [CubePatterns.ColorfulLettersAlt]: 'static/media/cube/shape/cube_letters_v2.stl',
  [CubePatterns.ColorfulNumbers]: 'static/media/cube/shape/cube_numbers_v2.stl',
  [CubePatterns.ColorfulNumbersAlt]: 'static/media/cube/shape/cube_numbers_v2.stl',
}
const shapeMaterial = new MeshStandardMaterial({
  color: 0x3b5d5e,
  roughness: 1,
  metalness: 0.2,
})
const modelScaleMultiplier = 0.035
const modelScale = new Vector3(modelScaleMultiplier, modelScaleMultiplier, modelScaleMultiplier)
const LEDScaleMultiplier = 0.88
const LEDScale = new Vector3(LEDScaleMultiplier, LEDScaleMultiplier, LEDScaleMultiplier)
// blue, yellow, orange, red, pink, green,
const LEDMaterial = [0x0052ef, 0xffff00, 0xff2800, 0xee0000, 0xff007b, 0x00dd00].map(
  (color) => new MeshLambertMaterial({ emissive: color, color }),
)

const getLine = (endpoint, color, q) => {
  const points: [Vector3?, Vector3?] = []
  points.push(new Vector3(0, 0, 0))
  const endVector = new Vector3(...endpoint)
  if (q) {
    endVector.applyQuaternion(q.clone().conjugate())
  }
  points.push(endVector)

  const material = new LineBasicMaterial({
    color: color,
  })

  const geometry = new BufferGeometry().setFromPoints(points)
  const line = new Line(geometry, material)
  return line
}

export const BaseCube = ({
  onFrameUpdate,
  quaternion,
  scale,
  matchBy,
  animationState,
  pattern,
  animate,
  compareGoals,
  showWinningVectors,
  cubeVersion,
}: BaseCubeProps) => {
  // This reference will give us direct access to the mesh
  const meshRef = useRef<Mesh>(null)
  const baseScale = scale ?? 1.6
  const baseScaleVector = useMemo(() => new Vector3(baseScale, baseScale, baseScale), [baseScale])
  const previousMeshRef = usePrevious(meshRef.current)

  useFrame((_, delta) => {
    onFrameUpdate?.(meshRef)
    if (meshRef.current) {
      if (animationState.current.animationLoop) {
        meshRef.current.rotation.z += MathUtils.degToRad(148) * delta
        meshRef.current.rotation.x += MathUtils.degToRad(176) * delta
        meshRef.current.rotation.y += MathUtils.degToRad(208) * delta
      } else if (animationState.current.animate) {
        if (meshRef.current.quaternion.angleTo(animationState.current.finalQuaternion) > 0.04) {
          meshRef.current.quaternion.slerp(animationState.current.finalQuaternion, 0.1)
        } else {
          animationState.current.animate = false
        }
      }
    }
  })

  const LEDs = useMemo(() => {
    let material
    // blue, yellow, orange, red, pink, green
    switch (pattern) {
      case CubePatterns.ColorfulLettersAlt:
      case CubePatterns.ColorfulNumbersAlt:
        material = [
          LEDMaterial[4], // A
          LEDMaterial[2], // F
          LEDMaterial[1], // D
          LEDMaterial[3], // C
          LEDMaterial[0], // E
          LEDMaterial[5], // B
        ]
        break
      default:
        material = material = [
          LEDMaterial[0], // A
          LEDMaterial[1], // F
          LEDMaterial[5], // D
          LEDMaterial[4], // C
          LEDMaterial[3], // E
          LEDMaterial[2], // B
        ]
    }
    return [
      {
        position: new Vector3(-0.96, 0, 0),
        material: material[0],
        rotation: new Euler(0, -Math.PI / 2, 0),
      },
      {
        position: new Vector3(0.96, 0, 0),
        material: material[1],
        rotation: new Euler(0, Math.PI / 2, Math.PI / 2),
      },
      {
        position: new Vector3(0, 0.96, 0),
        material: material[2],
        rotation: new Euler(-Math.PI / 2, 0, Math.PI / 2),
      },
      {
        position: new Vector3(0, -0.96, 0),
        material: material[3],
        rotation: new Euler(Math.PI / 2, 0, Math.PI / 2),
      },
      {
        position: new Vector3(0, 0, 0.96),
        material: material[4],
        rotation: new Euler(0, 0, -Math.PI / 2),
      },

      {
        position: new Vector3(0, 0, -0.96),
        material: material[5],
        rotation: new Euler(0, Math.PI, Math.PI / 2),
      },
    ]
  }, [LEDMaterial, pattern])

  useEffect(() => {
    if (quaternion && meshRef.current && (!previousMeshRef || !animate)) {
      meshRef.current.quaternion.copy(quaternion)
    } else if (meshRef.current && quaternion) {
      animationState.current = { animationLoop: false, animate: true, finalQuaternion: quaternion }
    }
  }, [quaternion, meshRef.current, matchBy, pattern, animate])

  useEffect(() => {
    if (compareGoals?.length && showWinningVectors) {
      const lines = compareGoals.map((goal, index) => {
        const colors = ['green', 'blue', 'black', 'orange', 'yellow', 'pink']
        return getLine([0, -10, 0.0], colors[index], compareGoals[index])
      })
      lines.forEach((line) => meshRef.current?.add(line))

      return () => {
        lines.forEach((line) => meshRef.current?.remove(line))
      }
    }
  }, [compareGoals, showWinningVectors])

  return (
    <group ref={meshRef} scale={baseScaleVector}>
      {LEDs.map((side, index) => (
        <mesh key={index} scale={LEDScale} {...side}>
          <circleGeometry attach="geometry" />
        </mesh>
      ))}
      <group scale={modelScale}>
        <CubeModel pattern={pattern} />
      </group>
    </group>
  )
}
function CubeModel({ pattern }: { pattern: CubePatterns }) {
  const shapeObj = cubeObj[pattern]
  const obj = useLoader(STLLoader, shapeObj)
  return (
    <Suspense fallback={null}>
      <mesh geometry={obj}>
        <meshStandardMaterial color={0x3b5d5e} roughness={1} metalness={0.2} />
      </mesh>
    </Suspense>
  )
}

/**
 * Compare rotation of this cube with provided target rotation matrix by applying mask.
 * You example of mask can be:
 * <li> 0.1 for each pair of number in matrices the deviation can be only 0.1</li>
 * <li> [2,2,2,2,  0,0.1,0,0,  2,2,2,2,  0,0,0,0] for firs pair the deviation may be up to 2, which is maximum deviation, etc.</li>
 *
 * @param matrix actual rotation from HW cube
 * @param target defined win matrix in LevelDefinition
 * @param {Array<number> | number} mask deviation for all matrix numbers or for each matrix number if you provide array.
 * @param scale matrix (cube) scale
 * @param winOffsetMatrix
 * @returns true if matches false if not
 */
// export function compareMatrices(
//   matrix: Matrix4,
//   target: readonly number[],
//   mask: readonly number[] | number,
//   scale: number = 1,
//   winOffsetMatrix?: Matrix4,
//   log = false,
// ) {
//
//   let mask2 = mask as number
//   if (typeof mask !== 'number') {
//     mask2 = 0.3 // TODO: array masks
//   }
//
//   // if (typeof mask === 'number') {
//   //   const a = new THREE.Euler().setFromRotationMatrix(matrix)
//   //   const b = new THREE.Euler().setFromRotationMatrix(new THREE.Matrix4().fromArray(target))
//   //   return Math.abs(a.x - b.x) <= 0.3 && Math.abs(a.y - b.y) <= 0.3
//   // }
//
//   const same = ([a, b], index) => {
//     if (a === null || b === null) {
//       return true
//     }
//     const maxDiff = ((typeof mask === 'number' ? mask2 : mask[index]) + 0.01) * scale
//
//     const diff = (Math.max(a, b) - Math.min(a, b)) * scale
//     return diff < maxDiff
//   }
//   let finalMatrix
//   if (winOffsetMatrix) {
//     const quaternion = new Quaternion().setFromRotationMatrix(
//       new Matrix4().fromArray(getNormalRotationFromMatrix(target)),
//     )
//     const offsetQuaternion = new Quaternion().setFromRotationMatrix(winOffsetMatrix)
//     finalMatrix = new Matrix4().makeRotationFromQuaternion(quaternion.premultiply(offsetQuaternion)).toArray()
//   } else {
//     finalMatrix = target
//   }
//   // return zip(take(12, matrix.toArray()), take(12, finalMatrix)).every(same)
//   // return false
//   return true
// }

// export const compareMatrices = (
//   actual: Matrix4,
//   target: readonly number[],
//   _,
//   __,
//   winOffsetMatrix,
//   log
// ) => {
//   let finalMatrix
//   if (winOffsetMatrix) {
//     const quaternion = new Quaternion().setFromRotationMatrix(
//       new Matrix4().fromArray(getNormalRotationFromMatrix(target)),
//     )
//     const offsetQuaternion = new Quaternion().setFromRotationMatrix(winOffsetMatrix)
//     finalMatrix = new Matrix4().makeRotationFromQuaternion(quaternion.premultiply(offsetQuaternion))
//   } else {
//     finalMatrix = new Matrix4().fromArray(target)
//   }
//
//   // Great circle distance equation
//
//   const d2r = 0.017453292519943295769236907684886
//
//   const values1 = actual.transpose().elements
//   const yaw1 = deg(Math.atan2(values1[1], values1[0]));
//   const pitch1 = deg(
//     Math.cos(yaw1) === 0
//       ? Math.atan2(-values1[2], values1[1] / Math.sin(yaw1))
//       : Math.atan2(-values1[2], values1[0] / Math.cos(yaw1))
//   )
//   const roll1 = deg(Math.atan2(values1[6], values1[10]));
//
//   const values2 = finalMatrix.transpose().elements
//   const yaw2 = deg(Math.atan2(values2[1], values2[0]));
//   const pitch2 = deg(
//     Math.cos(yaw2) === 0
//       ? Math.atan2(-values2[2], values2[1] / Math.sin(yaw2))
//       : Math.atan2(-values2[2], values2[0] / Math.cos(yaw2))
//   )
//   const roll2 = deg(Math.atan2(values2[6], values2[10]));
//
//   let theta1 = pitch1 * d2r
//   let theta2 = pitch2 * d2r
//   let phi1 = roll1 * d2r
//   let phi2 = roll2 * d2r
//
//   let rotationDifferenceInRadiansIgnoringYawAxis = Math.acos(Math.sin(phi1)*Math.sin(phi2) + Math.cos(phi1) * Math.cos(phi2) * Math.cos(Math.abs(theta1 - theta2)))
//
//
//
//   // end Great circle distance equation
//
//   const dif = new Matrix4().extractRotation(actual).transpose().premultiply(finalMatrix);
//   // const difX = Math.asin(dif.elements[6]);
//   // console.log({ difX });
//
//   const values = dif.transpose().elements;
//
//   const roll = deg(Math.atan2(values[6], values[10]));
//   const yaw = deg(Math.atan2(values[1], values[0]));
//   const pitch = deg(
//     Math.cos(yaw) === 0
//       ? Math.atan2(-values[2], values[1] / Math.sin(yaw))
//       : Math.atan2(-values[2], values[0] / Math.cos(yaw))
//   )
//
//   let actualQ = new Quaternion().setFromRotationMatrix(actual);
//   const desiredQ = new Quaternion().setFromRotationMatrix(finalMatrix);
//   actualQ = actualQ.set(actualQ.w, actualQ.x, desiredQ.y, actualQ.z)
//
//   const q = new Quaternion().setFromRotationMatrix(dif);
//   const yawQ = deg(
//     Math.atan2(
//       2.0 * (q.y * q.z + q.w * q.x),
//       q.w * q.w - q.x * q.x - q.y * q.y + q.z * q.z
//     )
//   );
//   const pitchQ = deg(Math.asin(-2.0 * (q.x * q.z - q.w * q.y)));
//   const rollQ = deg(
//     Math.atan2(
//       2.0 * (q.x * q.y + q.w * q.z),
//       q.w * q.w + q.x * q.x - q.y * q.y - q.z * q.z
//     )
//   );
//
//
//   const kekos = new Quaternion();
//   kekos.slerpQuaternions(actualQ, desiredQ, 0.01);
//
//   if(log) {
//     kek++
//     if (!(kek % 100)) {
//       // console.log(actual.elements)
//       // console.log(1)
//       // console.log(finalMatrix.elements)
//       // console.log(2)
//       console.log({ rotationDifferenceInRadiansIgnoringYawAxis })
//       // console.log(actualQ.w, actualQ.x, actualQ.y, actualQ.z)
//     }
//   }
//
//   if(rotationDifferenceInRadiansIgnoringYawAxis < 0.1) {
//     return true
//   }
//   // return zip(take(12, matrix.toArray()), take(12, finalMatrix)).every(same)
//   return false
// }
