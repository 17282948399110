/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
/**
 *
 * @export
 * @interface PlaythroughCounter
 */
export interface PlaythroughCounter {
  /**
   *
   * @type {number}
   * @memberof PlaythroughCounter
   */
  count: number
  /**
   *
   * @type {Array<string>}
   * @memberof PlaythroughCounter
   */
  distinctUsers: Array<string>
}

export function PlaythroughCounterFromJSON(json: any): PlaythroughCounter {
  return PlaythroughCounterFromJSONTyped(json, false)
}

export function PlaythroughCounterFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlaythroughCounter {
  if (json === undefined || json === null) {
    return json
  }
  return {
    count: json['count'],
    distinctUsers: json['distinctUsers'],
  }
}

export function PlaythroughCounterToJSON(value?: PlaythroughCounter | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    count: value.count,
    distinctUsers: value.distinctUsers,
  }
}
