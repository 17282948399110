/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'

/**
 *
 */
export class DefaultApi extends runtime.BaseAPI {
  /**
   * Livez
   */
  async livezLivezGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<any>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/livez`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.TextApiResponse(response) as any
  }

  /**
   * Livez
   */
  async livezLivezGet(initOverrides?: RequestInit): Promise<any> {
    const response = await this.livezLivezGetRaw(initOverrides)
    return await response.value()
  }

  /**
   * Readyz
   */
  async readyzReadyzGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<any>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/readyz`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.TextApiResponse(response) as any
  }

  /**
   * Readyz
   */
  async readyzReadyzGet(initOverrides?: RequestInit): Promise<any> {
    const response = await this.readyzReadyzGetRaw(initOverrides)
    return await response.value()
  }

  /**
   * Version
   */
  async versionVersionGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<any>> {
    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    const response = await this.request(
      {
        path: `/version`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.TextApiResponse(response) as any
  }

  /**
   * Version
   */
  async versionVersionGet(initOverrides?: RequestInit): Promise<any> {
    const response = await this.versionVersionGetRaw(initOverrides)
    return await response.value()
  }
}
