import { Canvas } from '@react-three/fiber'
import { Euler, LinearToneMapping, Vector3 } from 'three'

// FIXME: This is hack to forward contexts to Canvas, it does not work by default,
//  coz Canvas has its own tree, replace by react-spring zustand, or some other alternative,
//  or don't

const camera = {
  fov: 40,
  near: 0.1,
  far: 1000,
  position: new Vector3(0, -10, 0),
  rotation: new Euler(Math.PI / 2, 0, 0),
} as const

const gl = {
  toneMapping: LinearToneMapping,
}

export function ContextForwardedCanvas({ children, ...rest }) {
  return (
    <Canvas camera={camera} gl={gl} {...rest}>
      {children}
    </Canvas>
  )
}
