/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime'
import { Domain, DomainFromJSON, DomainFromJSONTyped, DomainToJSON } from './Domain'

/**
 *
 * @export
 * @interface WeakDomain
 */
export interface WeakDomain {
  /**
   *
   * @type {number}
   * @memberof WeakDomain
   */
  threshold: number
  /**
   *
   * @type {Array<Domain>}
   * @memberof WeakDomain
   */
  domain: Array<Domain>
}

export function WeakDomainFromJSON(json: any): WeakDomain {
  return WeakDomainFromJSONTyped(json, false)
}

export function WeakDomainFromJSONTyped(json: any, ignoreDiscriminator: boolean): WeakDomain {
  if (json === undefined || json === null) {
    return json
  }
  return {
    threshold: json['threshold'],
    domain: (json['domain'] as Array<any>).map(DomainFromJSON),
  }
}

export function WeakDomainToJSON(value?: WeakDomain | null): any {
  if (value === undefined) {
    return undefined
  }
  if (value === null) {
    return null
  }
  return {
    threshold: value.threshold,
    domain: (value.domain as Array<any>).map(DomainToJSON),
  }
}
