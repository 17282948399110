export interface Category {
  code: string
  name: {
    cs: string
    en: string
    pl: string
    is: string
    nl: string
  }
  subTheme: any
  icon: string
}

export const CATEGORIES: Category[] = [
  {
    icon: 'eye',
    code: 'attention',
    name: {
      cs: 'Pozornost',
      en: 'Attention',
      pl: 'Uwaga',
      is: 'Athygli',
      nl: 'Aandacht',
    },
    subTheme: {
      palette: {
        primary: {
          main: '#FDD73C',
          dark: '#fdb03c',
          light: '#fffbeb',
        },
      },
    },
  },
  {
    icon: 'cubes',
    code: 'executiveFunctions',
    name: {
      cs: 'Exekutivní funkce',
      en: 'Executive functions',
      pl: 'Funkcje wykonawcze',
      is: 'Framkvæmdastörf',
      nl: 'Uitvoerende functies',
    },
    subTheme: {
      palette: {
        primary: {
          main: '#00C853',
          dark: '#01993c',
          light: '#e5f9ed',
        },
      },
    },
  },
  {
    icon: 'head',
    code: 'learningMemory',
    name: {
      cs: 'Učení\u00A0a paměť',
      en: 'Learning\u00A0& memory',
      pl: 'Uczenie\u00A0i pamięć',
      is: 'Nám\u00A0og minni',
      nl: 'Leren\u00A0& geheugen',
    },
    subTheme: {
      palette: {
        primary: {
          main: '#64B5F6',
          dark: '#4887b1',
          light: '#eff7fe',
        },
      },
    },
  },
  {
    icon: 'message',
    code: 'language',
    name: {
      cs: 'Jazyk',
      en: 'Language',
      pl: 'Język',
      is: 'Tungumál',
      nl: 'Taal',
    },
    subTheme: {
      palette: {
        primary: {
          main: '#FA892B',
          dark: '#b86624',
          light: '#fef3e9',
        },
      },
    },
  },
  {
    icon: 'cube',
    code: 'psychomotorics',
    name: {
      cs: 'Psychomotorické funkce',
      en: 'Psychomotoric functions',
      pl: 'Funkcje psychomotoryczne',
      is: 'Geðhreyfingar',
      nl: 'Psychomotorische functies',
    },
    subTheme: {
      palette: {
        primary: {
          main: '#279688',
          dark: '#1f796e',
          light: '#e9f4f3',
        },
      },
    },
  },
  {
    icon: 'heart',
    code: 'socialCognition',
    name: {
      cs: 'Sociální kognice',
      en: 'Social cognition',
      pl: 'Poznanie społeczne',
      is: 'Félagsleg vitund',
      nl: 'Sociale cognitie',
    },
    subTheme: {
      palette: {
        primary: {
          main: '#F06292',
          dark: '#bc4570',
          light: '#fdeff4',
        },
      },
    },
  },
]

export const DEFAULT_CATEGORY = 'default'
