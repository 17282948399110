type CommandType = 'led' | 'vibrate' | 'read'

const queues = new Map<
  string,
  Array<{ command: () => Promise<unknown>; commandType: CommandType; attempts?: number }>
>()
const isProcessing = new Map<string, boolean>()

export const enqueueBluetoothCommand = async (
  cubeId: string,
  command: () => Promise<unknown>,
  commandType: CommandType,
) => {
  if (queues.has(cubeId)) {
    queues.set(
      cubeId,
      queues.get(cubeId)!.filter((c) => c.commandType !== commandType),
    )
    queues.get(cubeId)!.push({ command, commandType })
  } else {
    queues.set(cubeId, [{ command, commandType }])
  }
  try {
    await processQueue(cubeId)
  } catch (e) {
    console.error(e)
  }
}
const processQueue = async (cubeId: string) => {
  if (isProcessing.get(cubeId)) {
    return
  }
  isProcessing.set(cubeId, true)
  while (queues.get(cubeId)!.length > 0) {
    const queue = queues.get(cubeId)!
    const command = queue[0]

    try {
      await command?.command()
      queue.shift()
    } catch (e) {
      if (command.attempts === undefined) {
        command.attempts = 1
      } else {
        command.attempts++
      }
      if (command.attempts > 100) {
        console.log('too many attempts', command)
        queue.shift()
      }
      console.log(e)
    }
  }

  isProcessing.set(cubeId, false)
}
