import { Flex } from '@proxyqb/ui'
import { FC, MutableRefObject, useEffect, useRef, useState } from 'react'
import { useCounter, useRafLoop } from 'react-use'
import { Box, LinearProgress, useTheme } from '@mui/material'
import { animated, config, useSpring, useSpringRef } from 'react-spring'
import CheckIcon from '@mui/icons-material/Check'
import { GameEventType, useGameEvents } from '../../../game/useGameEvents'

interface Props {
  stageWinningHoldTime: MutableRefObject<number>
  top?: string
  bottom?: string
  successBarTime: number
  isLosing: boolean
  counterRef
  levelHasFinished: boolean
}

export const Progress: FC<Props> = ({
  stageWinningHoldTime,
  top,
  bottom,
  successBarTime,
  isLosing,
  counterRef,
  levelHasFinished,
}) => {
  const [progress, { set: setProgress }] = useCounter(0, 100, 0)
  const [success, setSuccess] = useState(false)
  useRafLoop(() => {
    const newProgress = Math.floor(stageWinningHoldTime.current / (successBarTime / 100))
    if (progress !== newProgress && progress >= 0) {
      setProgress(newProgress)
    }
  })

  const theme = useTheme()

  const blackLineRef = useRef()
  const outsideDivRef = useRef()
  const testRef = useRef()

  const doSuccessTrueAnimations = async () => {
    setSuccess(true)
    const success = await doAnimations()
    if (success) {
      await animate(outsideDivRef1, {
        to: {
          backgroundColor: 'green',
          width: `${blackLineRef.current?.getBoundingClientRect().width * 0.33}px`,
          transform: 'scale(1)',
          height: '33px',
          position: 'static',
        },
      })
      await animate(insideDivRef, {
        to: {
          opacity: 0,
        },
      })
    }
  }

  const doSuccessFalseAnimations = async () => {
    setSuccess(false)
    await animate(progressBarRef, {
      from: {
        width: '0%',
      },
      to: {
        width: '100%',
      },
    })
  }

  const { fireEvent } = useGameEvents(
    [
      { type: GameEventType.STAGE_SUCCESS, handler: doSuccessTrueAnimations },
      { type: GameEventType.SUCCESS_ANIMATION_FINISHED, handler: doSuccessFalseAnimations },
    ],
    [blackLineRef.current, outsideDivRef.current],
  )

  const doAnimations = async () => {
    if (counterRef === undefined) {
      return false
    }

    const counterTop = counterRef.getBoundingClientRect().top
    const counterLeft = counterRef.getBoundingClientRect().left
    animate(insideDivRef, {
      from: {
        opacity: 0,
      },
      to: {
        opacity: 1,
      },
    })

    await animate(outsideDivRef1, {
      from: {
        width: `${blackLineRef.current?.getBoundingClientRect().width * 0.33}px`,
        height: '33px',
        opacity: 1,
      },

      to: { width: '60px', height: '60px', opacity: 1 },
    })
    animate(insideDivRef, {
      from: {
        opacity: 0,
      },
      to: {
        opacity: 1,
      },
    })

    const startTop = outsideDivRef.current?.getBoundingClientRect().top
    const startLeft = outsideDivRef.current?.getBoundingClientRect().left

    await animate(outsideDivRef1, {
      from: {
        top: `${startTop}px`,
        left: `${startLeft}px`,
        position: 'static',
      },
      to: {
        top: `${counterTop + 20}px`,
        left: `${counterLeft + 20}px`,
        position: 'fixed',
      },
    })
    fireEvent({ type: GameEventType.PROGRESS_ANIMATION_FINISHED })

    await animate(outsideDivRef1, {
      from: {
        transform: 'scale(1)',
        backgroundColor: 'green',
      },
      to: {
        backgroundColor: `${theme.palette.primary.dark}`,
        transform: 'scale(1.2)',
      },
      config: config.default,
    })

    await animate(outsideDivRef1, {
      from: {
        transform: 'scale(1.2)',
        opacity: 1,
      },
      to: {
        transform: 'scale(0.2)',
        opacity: 0,
      },
      config: config.wobbly,
    })
    return true
  }

  const animate = (ref, props) => {
    return ref.start(props).pop()
  }

  const outsideDivRef1 = useSpringRef()
  const outsideDivStyle = useSpring({
    width: `${blackLineRef.current?.getBoundingClientRect().width * 0.33}px`,
    transform: 'scale(1)',
    height: '33px',
    opacity: 1,
    position: 'fixed',
    top: 'initial',
    left: 'initial',
    borderRadius: 50,
    backgroundColor: 'green',
    zIndex: 11,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    ref: outsideDivRef1,
  })

  const insideDivRef = useSpringRef()
  const insideDivStyle = useSpring({
    opacity: 0,
    ref: insideDivRef,
  })

  const progressBarRef = useSpringRef()
  const progressBarStyle = useSpring({
    width: '100%',
    height: '100%',
    ref: progressBarRef,
  })

  return (
    <Flex position="absolute" top={top} bottom={bottom} width="100vw" ref={blackLineRef}>
      <Box
        ref={blackLineRef}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '4px',
          backgroundColor: 'black',
          width: '100%',
        }}
      >
        <Box
          sx={{
            width: '33%',
            height: '33px',
            display: !success ? 'flex' : 'none',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <animated.div style={progressBarStyle} ref={testRef}>
            {!levelHasFinished && (
              <LinearProgress
                sx={{
                  position: 'relative',
                  width: '100%',
                  height: '100%',
                  borderRadius: 5,
                  border: '4px black solid',
                  backgroundColor: 'white',
                  '& .MuiLinearProgress-bar': {
                    transition: 'none',
                  },
                  zIndex: 11,
                }}
                color={isLosing ? 'error' : 'success'}
                variant="determinate"
                value={progress}
              />
            )}
          </animated.div>
        </Box>
        <Box
          sx={{
            width: '33%',
            height: '33px',
            display: success ? 'flex' : 'none',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <animated.div style={outsideDivStyle} ref={outsideDivRef}>
            <animated.div style={insideDivStyle}>
              <CheckIcon style={{ color: 'white', fontSize: 35 }} />
            </animated.div>
          </animated.div>
        </Box>
      </Box>
    </Flex>
  )
}
