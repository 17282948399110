import * as Types from '@proxyqb/graphql-api-types';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export const RehabilitationPlanItemFragmentDoc = gql`
    fragment RehabilitationPlanItem on RehabilitationPlan {
  id
  name {
    local
  }
  levels {
    id
    name
    requiredCubes {
      pattern
    }
  }
  patients {
    id
  }
  rev
}
    `;
export const QueryRehabilitationPlansByPatientIdDocument = gql`
    query queryRehabilitationPlansByPatientId($patientId: ID!) {
  queryRehabilitationPlansByPatientId(patientId: $patientId) {
    ...RehabilitationPlanItem
  }
}
    ${RehabilitationPlanItemFragmentDoc}`;

export function useQueryRehabilitationPlansByPatientIdQuery(options: Omit<Urql.UseQueryArgs<QueryRehabilitationPlansByPatientIdQueryVariables>, 'query'>) {
  return Urql.useQuery<QueryRehabilitationPlansByPatientIdQuery, QueryRehabilitationPlansByPatientIdQueryVariables>({ query: QueryRehabilitationPlansByPatientIdDocument, ...options });
};
export const QueryRehabilitationPlanByIdDocument = gql`
    query queryRehabilitationPlanById($planId: ID!) {
  getRehabilitationPlan(id: $planId) {
    ...RehabilitationPlanItem
  }
}
    ${RehabilitationPlanItemFragmentDoc}`;

export function useQueryRehabilitationPlanByIdQuery(options: Omit<Urql.UseQueryArgs<QueryRehabilitationPlanByIdQueryVariables>, 'query'>) {
  return Urql.useQuery<QueryRehabilitationPlanByIdQuery, QueryRehabilitationPlanByIdQueryVariables>({ query: QueryRehabilitationPlanByIdDocument, ...options });
};
export type RehabilitationPlanItemFragment = { __typename?: 'RehabilitationPlan', id: string, rev: string, name: { __typename?: 'LocalizedText', local: string }, levels: Array<{ __typename?: 'Level', id: string, name: string, requiredCubes: Array<{ __typename?: 'RequiredCube', pattern: Types.CubePatterns }> }>, patients: Array<{ __typename?: 'Patient', id: string }> };

export type QueryRehabilitationPlansByPatientIdQueryVariables = Types.Exact<{
  patientId: Types.Scalars['ID'];
}>;


export type QueryRehabilitationPlansByPatientIdQuery = { __typename?: 'Query', queryRehabilitationPlansByPatientId: Array<{ __typename?: 'RehabilitationPlan', id: string, rev: string, name: { __typename?: 'LocalizedText', local: string }, levels: Array<{ __typename?: 'Level', id: string, name: string, requiredCubes: Array<{ __typename?: 'RequiredCube', pattern: Types.CubePatterns }> }>, patients: Array<{ __typename?: 'Patient', id: string }> }> };

export type QueryRehabilitationPlanByIdQueryVariables = Types.Exact<{
  planId: Types.Scalars['ID'];
}>;


export type QueryRehabilitationPlanByIdQuery = { __typename?: 'Query', getRehabilitationPlan: { __typename?: 'RehabilitationPlan', id: string, rev: string, name: { __typename?: 'LocalizedText', local: string }, levels: Array<{ __typename?: 'Level', id: string, name: string, requiredCubes: Array<{ __typename?: 'RequiredCube', pattern: Types.CubePatterns }> }>, patients: Array<{ __typename?: 'Patient', id: string }> } };
