import { FC, useEffect, useState } from 'react'
import { useTheme } from '@mui/material'
import { animated, config, useSpring, useSpringRef } from 'react-spring'
import { GameEventType, useGameEvents } from './useGameEvents'

interface StageCounterProps {
  count?: number
  stageNumber: number
  animationFinished?: (resetProgress?: boolean) => void
}

export const StageCounter: FC<StageCounterProps> = ({ count, animationFinished }) => {
  const theme = useTheme()

  const [stageNumberInner, setStageNumberInner] = useState(0)

  const animate = async () => {
    setTimeout(async () => {
      stageNumberRef.start({
        from: {
          fontSize: '35px',
        },
        to: {
          fontSize: '40px',
        },
      })
      setStageNumberInner((x) => x + 1)
      animationFinished?.()
      await outsideDivRef
        .start({
          from: {
            transform: 'scale(1)',
          },
          to: {
            transform: 'scale(1.2)',
          },
          config: config.default,
        })
        .pop()
      stageNumberRef.start({
        from: {
          fontSize: '40px',
        },
        to: {
          fontSize: '35px',
        },
      })
      await outsideDivRef
        .start({
          from: {
            transform: 'scale(1.2)',
          },
          to: {
            transform: 'scale(1)',
          },
          config: config.wobbly,
        })
        .pop()
    }, 200)
  }

  useGameEvents([{ type: GameEventType.PROGRESS_ANIMATION_FINISHED, handler: animate }])

  const stageNumberRef = useSpringRef()
  const stageNumberStyle = useSpring({
    color: 'white',
    fontSize: '35px',
    ref: stageNumberRef,
  })

  const outsideDivRef = useSpringRef()
  const outsideDivStyle = useSpring({
    width: '100px',
    height: '100px',
    background: theme.palette.primary.dark,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transform: 'scale(1)',
    ref: outsideDivRef,
  })

  return (
    <animated.div style={outsideDivStyle}>
      <animated.p style={stageNumberStyle}>
        {stageNumberInner}
        {count ? '/' + count : ''}
      </animated.p>
    </animated.div>
  )
}
