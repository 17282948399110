import { Box, Button, Grid, IconButton, Typography } from '@mui/material'
import { ReactNode } from 'react'
import { useIntl } from 'react-intl'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import styled, { useTheme } from 'styled-components'
import { DEFAULT_CATEGORY } from '../../../categories/categories.constants'
import { Battery } from '../atoms/Battery'
import SettingsIcon from '@mui/icons-material/Settings'
import { useGlobalState } from '@proxyqb/cube-global-state'
import BarChartIcon from '@mui/icons-material/BarChart'
import { useRehabilitationPlan } from '../../use-rehabilitation-plan'

export const HEADER_HEIGHT = 70

interface Action {
  label: string
  icon?: ReactNode
  handler: React.MouseEventHandler<HTMLButtonElement>
  right?: boolean
  disabled?: boolean
}

interface Props {
  primaryTitle: string | ReactNode
  secondaryTitle?: string
  children: ReactNode
  actions?: Action[]
  backButtonHandler?: React.MouseEventHandler<HTMLButtonElement>
  backButtonLabel?: string
  px?: string
  modal?: boolean
  handleBatteryModalPause?: (isInModal: boolean) => void
  inGame?: boolean
  inGameCubes?: string[]
  fullHeight?: boolean
  hideFooter?: boolean
  isSettingsEnabled?: boolean
  levelId?: string
}

const Header = styled(Box)`
  &,
  & .MuiButton-root {
    color: ${({ theme }) => theme.palette.primary.contrastText};
  }
  height: ${HEADER_HEIGHT}px;
  padding: 10px;
  box-shadow: 0 0 4px 0 black;
`

const PrimaryTitle = styled.h1`
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

const SecondaryTitle = styled.h2`
  margin-top: 25px;
  margin-bottom: 25px;
`

export const Screen = ({
  primaryTitle,
  secondaryTitle,
  children,
  actions,
  backButtonHandler,
  backButtonLabel,
  px,
  modal = false,
  handleBatteryModalPause,
  inGame,
  inGameCubes,
  fullHeight,
  hideFooter,
  isSettingsEnabled = true,
  levelId,
}: Props) => {
  const { categoryCode } = useParams<{ categoryCode: string }>()
  const intl = useIntl()
  const theme = useTheme()
  const push = useNavigate()
  const location = useLocation()
  const { selectedPatient, authState } = useGlobalState()
  const isPatient = authState.context.userDetails?.roles.includes('patient')
  const getText = (text?: string) => (text ? intl.messages[text] ?? text : '') as string

  const handleBackRoute = () => {
    if (location.state?.plan) {
      push(`/prepare-plan/${location.state.plan}`)
    } else if (location.state?.level) {
      push(`/category/${categoryCode}/prepare-game/${levelId}`)
    } else {
      push(-1)
    }
  }
  return (
    <div
      style={{
        position: 'absolute',
        width: modal ? '100%' : '100vw',
        top: 0,
        height: fullHeight ? '100vh' : 'initial',
      }}
    >
      {!modal && (
        <Header display="flex" alignItems="center" justifyContent="space-between">
          <Grid container alignItems="center" justifyContent="center" wrap="nowrap">
            <Grid item xs={3}>
              <Box height={50} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
                {backButtonHandler && (
                  <Button
                    sx={{ opacity: 0.5 }}
                    startIcon={
                      <img
                        width={40}
                        src={`/static/media/arrow-circle-left${
                          !categoryCode || categoryCode === DEFAULT_CATEGORY ? '-white' : ''
                        }.svg`}
                      />
                    }
                    onClick={backButtonHandler}
                  >
                    {getText(backButtonLabel || 'shared.back')}
                  </Button>
                )}
                {actions
                  ?.filter(({ right }) => !right)
                  .map(({ label, handler, disabled }, index) => (
                    <Button key={index} variant="text" onClick={handler} disabled={disabled}>
                      {getText(label)}
                    </Button>
                  ))}
              </Box>
            </Grid>
            <Grid item xs={6} zeroMinWidth>
              <PrimaryTitle>
                <Typography noWrap variant="inherit">
                  {typeof primaryTitle === 'string' ? getText(primaryTitle) : primaryTitle}
                </Typography>
              </PrimaryTitle>
            </Grid>
            <Grid
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              item
              xs={1}
            >
              {levelId && (
                <IconButton
                  aria-label="chart"
                  onClick={() =>
                    location.pathname === `/category/${categoryCode}/level-statistic/${levelId}`
                      ? handleBackRoute()
                      : push(`/category/${categoryCode}/level-statistic/${levelId}`)
                  }
                >
                  <BarChartIcon sx={{ fontSize: '45px', color: theme.palette.primary.contrastText }} />
                </IconButton>
              )}
              <Battery
                backgroundColor={theme.palette.primary.main}
                handleBatteryModalPause={handleBatteryModalPause}
                inGame={inGame}
                inGameCubes={inGameCubes}
              />
              {isSettingsEnabled && (
                <IconButton
                  aria-label="settings"
                  onClick={() => (location.pathname === '/settings' ? push(-1) : push('/settings'))}
                >
                  <SettingsIcon sx={{ fontSize: 35, color: theme.palette.primary.contrastText }} />
                </IconButton>
              )}
            </Grid>
            <Grid
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'end',
              }}
              item
              xs={2}
            >
              <Box>
                {actions
                  ?.filter(({ right }) => !!right)
                  .map(({ label, handler, disabled }, index) => (
                    <Button key={index} variant="text" onClick={handler} disabled={disabled}>
                      {getText(label)}
                    </Button>
                  ))}
              </Box>
            </Grid>
          </Grid>
        </Header>
      )}
      {secondaryTitle && (
        <Box display="flex" alignItems="center" justifyContent="center">
          <SecondaryTitle>{getText(secondaryTitle)}</SecondaryTitle>
        </Box>
      )}
      <Box
        display="flex"
        flexDirection="column"
        px={px ?? '10%'}
        height={`calc(100% - ${HEADER_HEIGHT}px)`}
        pb={!hideFooter ? '50px' : ''}
      >
        {children}
      </Box>
      {!isPatient && selectedPatient && !hideFooter && !modal && (
        <Box
          sx={{
            width: 'maxContent',
            position: 'fixed',
            bottom: 0,
            fontSize: 18,
          }}
        >
          <Button onClick={() => push('/player-profiles')}>
            <img width={40} src={`/static/media/arrow-circle-left.svg`} />
            <Typography
              ml={2}
              color={'black'}
            >{`${selectedPatient.firstName} ${selectedPatient.lastName}`}</Typography>
          </Button>
        </Box>
      )}
    </div>
  )
}
