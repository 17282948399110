import { MouseEventHandler, ReactElement } from 'react'
import { Box, Button, Dialog } from '@mui/material'
import { useIntl } from 'react-intl'
import { getPrettyTime } from '../helpers'
import { useFlagsmith } from '@proxyqb/react-feature-flags'
import { MaskIcon } from '@proxyqb/ui'
import { RehabilitationPlanProgress } from '../shared'
import { useNavigate, useParams } from 'react-router'

interface Props {
  open: boolean
  time?: number
  score?: number
  playAgainHandler: MouseEventHandler<HTMLButtonElement>
  playAnotherHandler: MouseEventHandler<HTMLButtonElement>
  playNextHandler?: MouseEventHandler<HTMLButtonElement>
  isLastLevelFromPlan?: boolean
  levelId: string
  rehabilitationPlanId: string | null
}

export const LevelFinishedModal = ({
  open,
  time,
  score,
  playAgainHandler,
  playAnotherHandler,
  playNextHandler,
  isLastLevelFromPlan,
  levelId,
  rehabilitationPlanId,
}: Props): ReactElement => {
  const intl = useIntl()
  const { flags } = useFlagsmith()
  const push = useNavigate()
  const { categoryCode } = useParams<{ categoryCode: string }>()

  return (
    <Dialog open={open} maxWidth={'md'}>
      <Box
        sx={{
          width: isLastLevelFromPlan === undefined ? 600 : 650,
          p: 5,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box sx={{ fontSize: '30px', fontWeight: 'bold', display: 'flex', justifyContent: 'center' }}>
          {intl.formatMessage({ id: 'levels.levelSuccess' })}
        </Box>
        <Box sx={{ mt: 2, mb: 5 }}>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            {intl.formatMessage({ id: `levels.${time ? 'totalTime' : 'totalScore'}` })}
          </Box>
          <Box sx={{ fontSize: '30px', fontWeight: 'bold', display: 'flex', justifyContent: 'center' }}>
            {time ? getPrettyTime(time) : score}
          </Box>
        </Box>
        {flags.smiley_face_on_level_finished?.enabled && (
          <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: 5 }}>
            <Box sx={{ backgroundColor: 'primary.main', borderRadius: '50%', width: 300, height: 300 }}>
              <MaskIcon
                url={'/static/media/smile-happy.svg'}
                color="primary.contrastText"
                width="300px"
                height="300px"
              />
            </Box>
          </Box>
        )}
        {isLastLevelFromPlan !== undefined && (
          <Box sx={{ mb: 3 }}>
            <RehabilitationPlanProgress levelId={levelId} isInPrepareGame={false} />
          </Box>
        )}
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button variant="contained" color="secondary" onClick={playAnotherHandler}>
            {intl.formatMessage({ id: 'levels.playAnother' })}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() =>
              push(
                `/category/${categoryCode}/level-statistic/${levelId}`,
                rehabilitationPlanId
                  ? { state: { plan: rehabilitationPlanId } }
                  : { state: { level: levelId } },
              )
            }
          >
            {intl.formatMessage({ id: 'levels.results' })}
          </Button>
          {playNextHandler ? (
            <Button variant="contained" onClick={playNextHandler}>
              {intl.formatMessage({ id: isLastLevelFromPlan ? 'shared.finish' : 'shared.continue' })}
            </Button>
          ) : (
            <Button variant="contained" onClick={playAgainHandler}>
              {intl.formatMessage({ id: 'levels.playAgain' })}
            </Button>
          )}
        </Box>
      </Box>
    </Dialog>
  )
}
