import { useMachine } from '@xstate/react'
import {
  authenticationMachine,
  AuthenticationMachineContext,
  AuthenticationMachineEvent,
  AuthenticationMachineTypestate,
} from './authentication-machine'
import { Interpreter, State } from 'xstate'
import { createCtx } from './create-ctx'
import { useSessionStorage } from 'react-use'
import { Dispatch, SetStateAction, useEffect } from 'react'
import * as Sentry from '@sentry/browser'

type Patient = {
  id: string
  firstName: string
  lastName: string
  phoneNumber?: string | null
  email?: string | null
}

interface GlobalStateContextType {
  authState: State<AuthenticationMachineContext, any, AuthenticationMachineTypestate>
  authService: Interpreter<
    AuthenticationMachineContext,
    any,
    AuthenticationMachineEvent,
    AuthenticationMachineTypestate
  >
  selectedPatient?: Patient
  setSelectedPatient: Dispatch<SetStateAction<Patient | null>>
}

const [useGlobalState, GlobalStateContextProvider] = createCtx<GlobalStateContextType>()

export const GlobalStateProvider = (props) => {
  // const authService = useInterpret(authenticationMachine)
  const [authState, , authService] = useMachine(authenticationMachine)
  const [selectedPatient, setSelectedPatient] = useSessionStorage<Patient>('selectedPatient')

  useEffect(() => {
    Sentry.setExtra('selectedPatient', selectedPatient)
  }, [selectedPatient])

  return (
    <GlobalStateContextProvider
      value={{
        authState,
        authService,
        selectedPatient,
        setSelectedPatient,
      }}
    >
      {props.children}
    </GlobalStateContextProvider>
  )
}

export { useGlobalState }
