import { Typography } from '@mui/material'
import { useGlobalState } from '@proxyqb/cube-global-state'
import { FC } from 'react'
import { useIntl } from 'react-intl'
import { Navigate, Outlet, RouteProps } from 'react-router-dom'
import { Role } from '@proxyqb/graphql-api-types'

export interface PrivateRouteProps extends RouteProps {
  anyRole?: Role[]
}

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
export const PrivateRoute: FC<PrivateRouteProps> = ({ anyRole = [] }) => {
  const { formatMessage } = useIntl()
  const { authState } = useGlobalState()
  const loggedIn = authState.matches('loggedIn') || authState.matches('reloadingUser')

  const { userDetails } = authState.context
  const isUserFetched = !!userDetails
  const shouldSkipRoleCheck = anyRole.length === 0
  // @ts-ignore // TODO: types
  const someRoleMatches = !anyRole.length || userDetails?.roles.some((role) => anyRole.includes(role))
  if (shouldSkipRoleCheck || (isUserFetched && someRoleMatches)) {
    if (loggedIn) {
      return <Outlet />
    } else {
      return (
        <Navigate
          to={{
            pathname: '/login',
          }}
          state={{ returnTo: location }}
        />
      )
    }
  } else {
    return <Typography>{formatMessage({ id: 'shared.insufficientRole' })}</Typography>
  }
}
