import { FC } from 'react'

interface Props {
  providers: any[] // TODO: proper type
}

export const ReduceProviders: FC<Props> = ({ children, providers }) => {
  return [...providers].reverse().reduce((acc, Provider) => {
    if (Array.isArray(Provider)) {
      const [Component, props] = Provider
      return <Component {...props}>{acc}</Component>
    } else {
      return <Provider>{acc}</Provider>
    }
  }, children)
}
