import { CssBaseline, Box } from '@mui/material'
import { FC, useState } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { GlobalProviders } from './global-providers'
import { Routes } from './routes'
import { SnackbarProvider } from 'notistack'
import { ReloadPrompt } from './reload-prompt'
import { LanguageProvider } from '@proxyqb/ui'
import { QueryClient, QueryClientProvider } from 'react-query'
import { PlaytroughExporter } from './PlaytroughExporter'
import styled from 'styled-components'
import NoInternetDialog from './NoInternetDialog'

const queryClient = new QueryClient({ defaultOptions: { queries: { refetchOnWindowFocus: false } } })

const HEADER_HEIGHT = 70

const Header = styled(Box)`
  transition: background-color 0.2s;
  background-color: ${({ theme }) => theme.palette.primary.main};
  &,
  & .MuiButton-root {
    color: ${({ theme }) => theme.palette.primary.contrastText};
  }
  height: ${HEADER_HEIGHT}px;
  padding: 10px;
  position: relative;
`

const App: FC = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine)

  const handleStatusChange = () => {
    if (!isOnline && navigator.onLine) {
      location.reload()
    }
    setIsOnline(navigator.onLine)
  }

  window.addEventListener('online', handleStatusChange)
  document.addEventListener('visibilitychange', () => {
    if (!document.hidden) {
      handleStatusChange()
    }
  })

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <LanguageProvider>
          <GlobalProviders>
            <CssBaseline />
            <SnackbarProvider maxSnack={3}>
              <div style={{ height: '100vh' }}>
                <Header></Header>
                <Routes />
                <NoInternetDialog isOnline={isOnline} />
              </div>
              <PlaytroughExporter />
            </SnackbarProvider>
            <ReloadPrompt />
          </GlobalProviders>
        </LanguageProvider>
      </BrowserRouter>
    </QueryClientProvider>
  )
}

export default App
