/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime'
import {
  BoxPlotData,
  BoxPlotDataFromJSON,
  Export,
  ExportFromJSON,
  LevelRunsChartData,
  LevelRunsChartDataFromJSON,
  Playthrough,
  PlaythroughCounter,
  PlaythroughCounterFromJSON,
  PlaythroughFilter,
  PlaythroughFilterToJSON,
  PlaythroughFromJSON,
  ProbabilityDensityData,
  ProbabilityDensityDataFromJSON,
  RehabPlanStatistic,
  RehabPlanStatisticFromJSON,
  RepeatedRunsChartData,
  RepeatedRunsChartDataFromJSON,
  ScoreChart,
  ScoreChartFromJSON,
  ScoreTable,
  ScoreTableFromJSON,
} from '../models'

export interface ExportPlaythroughsRequest {
  playthroughFilter: PlaythroughFilter
  authorization?: string
}

export interface GetLastPlaythroughsRequest {
  limit?: number
  authorization?: string
}

export interface GetLevelLastScoreTableRequest {
  limit: number
  playthroughFilter: PlaythroughFilter
  authorization?: string
}

export interface GetLevelRunsChartRequest {
  levelId: string
  userId: string
  authorization?: string
}

export interface GetLevelScoreChartRequest {
  playthroughFilter: PlaythroughFilter
  authorization?: string
}

export interface GetLevelTopScoreTableRequest {
  limit: number
  playthroughFilter: PlaythroughFilter
  authorization?: string
}

export interface GetPlaythroughCountRequest {
  fromDate?: Date
  toDate?: Date
  authorization?: string
}

export interface GetPlaythroughsRequest {
  playthroughFilter: PlaythroughFilter
  authorization?: string
}

export interface GetProbabilityDensityByLevelRequest {
  playthroughFilter: PlaythroughFilter
  authorization?: string
}

export interface GetRehabPlanStatisticsRequest {
  patientId: string
  rehabilitationPlanId: string
  authorization?: string
}

export interface GetRehabPlanStatistics23Request {
  playthroughFilter: PlaythroughFilter
  authorization?: string
}

export interface GetRepeatedRunsChartRequest {
  levelId: string
  userId: string
  authorization?: string
}

export interface GetTimeLevelsBoxPlotRequest {
  playthroughFilter: PlaythroughFilter
  quantilesN?: number
  authorization?: string
}

/**
 *
 */
export class PlaythroughApi extends runtime.BaseAPI {
  /**
   * Export
   */
  async exportPlaythroughsRaw(
    requestParameters: ExportPlaythroughsRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<Export>> {
    if (requestParameters.playthroughFilter === null || requestParameters.playthroughFilter === undefined) {
      throw new runtime.RequiredError(
        'playthroughFilter',
        'Required parameter requestParameters.playthroughFilter was null or undefined when calling exportPlaythroughs.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
      headerParameters['authorization'] = String(requestParameters.authorization)
    }

    const response = await this.request(
      {
        path: `/playthrough/export`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: PlaythroughFilterToJSON(requestParameters.playthroughFilter),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) => ExportFromJSON(jsonValue))
  }

  /**
   * Export
   */
  async exportPlaythroughs(
    requestParameters: ExportPlaythroughsRequest,
    initOverrides?: RequestInit,
  ): Promise<Export> {
    const response = await this.exportPlaythroughsRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * Get Last Route
   */
  async getLastPlaythroughsRaw(
    requestParameters: GetLastPlaythroughsRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<Array<Playthrough>>> {
    const queryParameters: any = {}

    if (requestParameters.limit !== undefined) {
      queryParameters['limit'] = requestParameters.limit
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
      headerParameters['authorization'] = String(requestParameters.authorization)
    }

    const response = await this.request(
      {
        path: `/playthrough/last`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PlaythroughFromJSON))
  }

  /**
   * Get Last Route
   */
  async getLastPlaythroughs(
    requestParameters: GetLastPlaythroughsRequest = {},
    initOverrides?: RequestInit,
  ): Promise<Array<Playthrough>> {
    const response = await this.getLastPlaythroughsRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * Get Level Last Score Table
   */
  async getLevelLastScoreTableRaw(
    requestParameters: GetLevelLastScoreTableRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<Array<ScoreTable>>> {
    if (requestParameters.limit === null || requestParameters.limit === undefined) {
      throw new runtime.RequiredError(
        'limit',
        'Required parameter requestParameters.limit was null or undefined when calling getLevelLastScoreTable.',
      )
    }

    if (requestParameters.playthroughFilter === null || requestParameters.playthroughFilter === undefined) {
      throw new runtime.RequiredError(
        'playthroughFilter',
        'Required parameter requestParameters.playthroughFilter was null or undefined when calling getLevelLastScoreTable.',
      )
    }

    const queryParameters: any = {}

    if (requestParameters.limit !== undefined) {
      queryParameters['limit'] = requestParameters.limit
    }

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
      headerParameters['authorization'] = String(requestParameters.authorization)
    }

    const response = await this.request(
      {
        path: `/playthrough/levelLastScoreTable`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: PlaythroughFilterToJSON(requestParameters.playthroughFilter),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ScoreTableFromJSON))
  }

  /**
   * Get Level Last Score Table
   */
  async getLevelLastScoreTable(
    requestParameters: GetLevelLastScoreTableRequest,
    initOverrides?: RequestInit,
  ): Promise<Array<ScoreTable>> {
    const response = await this.getLevelLastScoreTableRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * Get Level Runs Chart
   */
  async getLevelRunsChartRaw(
    requestParameters: GetLevelRunsChartRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<LevelRunsChartData>> {
    if (requestParameters.levelId === null || requestParameters.levelId === undefined) {
      throw new runtime.RequiredError(
        'levelId',
        'Required parameter requestParameters.levelId was null or undefined when calling getLevelRunsChart.',
      )
    }

    if (requestParameters.userId === null || requestParameters.userId === undefined) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter requestParameters.userId was null or undefined when calling getLevelRunsChart.',
      )
    }

    const queryParameters: any = {}

    if (requestParameters.levelId !== undefined) {
      queryParameters['levelId'] = requestParameters.levelId
    }

    if (requestParameters.userId !== undefined) {
      queryParameters['userId'] = requestParameters.userId
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
      headerParameters['authorization'] = String(requestParameters.authorization)
    }

    const response = await this.request(
      {
        path: `/playthrough/levelRunsChart`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) => LevelRunsChartDataFromJSON(jsonValue))
  }

  /**
   * Get Level Runs Chart
   */
  async getLevelRunsChart(
    requestParameters: GetLevelRunsChartRequest,
    initOverrides?: RequestInit,
  ): Promise<LevelRunsChartData> {
    const response = await this.getLevelRunsChartRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * Get Level Score Chart
   */
  async getLevelScoreChartRaw(
    requestParameters: GetLevelScoreChartRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<Array<ScoreChart>>> {
    if (requestParameters.playthroughFilter === null || requestParameters.playthroughFilter === undefined) {
      throw new runtime.RequiredError(
        'playthroughFilter',
        'Required parameter requestParameters.playthroughFilter was null or undefined when calling getLevelScoreChart.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
      headerParameters['authorization'] = String(requestParameters.authorization)
    }

    const response = await this.request(
      {
        path: `/playthrough/levelScoreChart`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: PlaythroughFilterToJSON(requestParameters.playthroughFilter),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ScoreChartFromJSON))
  }

  /**
   * Get Level Score Chart
   */
  async getLevelScoreChart(
    requestParameters: GetLevelScoreChartRequest,
    initOverrides?: RequestInit,
  ): Promise<Array<ScoreChart>> {
    const response = await this.getLevelScoreChartRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * Get Level Top Score Table
   */
  async getLevelTopScoreTableRaw(
    requestParameters: GetLevelTopScoreTableRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<Array<ScoreTable>>> {
    if (requestParameters.limit === null || requestParameters.limit === undefined) {
      throw new runtime.RequiredError(
        'limit',
        'Required parameter requestParameters.limit was null or undefined when calling getLevelTopScoreTable.',
      )
    }

    if (requestParameters.playthroughFilter === null || requestParameters.playthroughFilter === undefined) {
      throw new runtime.RequiredError(
        'playthroughFilter',
        'Required parameter requestParameters.playthroughFilter was null or undefined when calling getLevelTopScoreTable.',
      )
    }

    const queryParameters: any = {}

    if (requestParameters.limit !== undefined) {
      queryParameters['limit'] = requestParameters.limit
    }

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
      headerParameters['authorization'] = String(requestParameters.authorization)
    }

    const response = await this.request(
      {
        path: `/playthrough/levelTopScoreTable`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: PlaythroughFilterToJSON(requestParameters.playthroughFilter),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ScoreTableFromJSON))
  }

  /**
   * Get Level Top Score Table
   */
  async getLevelTopScoreTable(
    requestParameters: GetLevelTopScoreTableRequest,
    initOverrides?: RequestInit,
  ): Promise<Array<ScoreTable>> {
    const response = await this.getLevelTopScoreTableRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * Get Count In Date Range Route
   */
  async getPlaythroughCountRaw(
    requestParameters: GetPlaythroughCountRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<PlaythroughCounter>> {
    const queryParameters: any = {}

    if (requestParameters.fromDate !== undefined) {
      queryParameters['fromDate'] = (requestParameters.fromDate as any).toISOString()
    }

    if (requestParameters.toDate !== undefined) {
      queryParameters['toDate'] = (requestParameters.toDate as any).toISOString()
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
      headerParameters['authorization'] = String(requestParameters.authorization)
    }

    const response = await this.request(
      {
        path: `/playthrough/count`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) => PlaythroughCounterFromJSON(jsonValue))
  }

  /**
   * Get Count In Date Range Route
   */
  async getPlaythroughCount(
    requestParameters: GetPlaythroughCountRequest = {},
    initOverrides?: RequestInit,
  ): Promise<PlaythroughCounter> {
    const response = await this.getPlaythroughCountRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * Get Playtrough
   */
  async getPlaythroughsRaw(
    requestParameters: GetPlaythroughsRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<Array<Playthrough>>> {
    if (requestParameters.playthroughFilter === null || requestParameters.playthroughFilter === undefined) {
      throw new runtime.RequiredError(
        'playthroughFilter',
        'Required parameter requestParameters.playthroughFilter was null or undefined when calling getPlaythroughs.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
      headerParameters['authorization'] = String(requestParameters.authorization)
    }

    const response = await this.request(
      {
        path: `/playthrough/filtered`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: PlaythroughFilterToJSON(requestParameters.playthroughFilter),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PlaythroughFromJSON))
  }

  /**
   * Get Playtrough
   */
  async getPlaythroughs(
    requestParameters: GetPlaythroughsRequest,
    initOverrides?: RequestInit,
  ): Promise<Array<Playthrough>> {
    const response = await this.getPlaythroughsRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * Get Stage Times Grouped By Level Route
   */
  async getProbabilityDensityByLevelRaw(
    requestParameters: GetProbabilityDensityByLevelRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<Array<ProbabilityDensityData>>> {
    if (requestParameters.playthroughFilter === null || requestParameters.playthroughFilter === undefined) {
      throw new runtime.RequiredError(
        'playthroughFilter',
        'Required parameter requestParameters.playthroughFilter was null or undefined when calling getProbabilityDensityByLevel.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
      headerParameters['authorization'] = String(requestParameters.authorization)
    }

    const response = await this.request(
      {
        path: `/playthrough/probabilityDensityByLevel`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: PlaythroughFilterToJSON(requestParameters.playthroughFilter),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProbabilityDensityDataFromJSON))
  }

  /**
   * Get Stage Times Grouped By Level Route
   */
  async getProbabilityDensityByLevel(
    requestParameters: GetProbabilityDensityByLevelRequest,
    initOverrides?: RequestInit,
  ): Promise<Array<ProbabilityDensityData>> {
    const response = await this.getProbabilityDensityByLevelRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * Get Rehab Plan Statistic23
   */
  async getRehabPlanStatisticsRaw(
    requestParameters: GetRehabPlanStatisticsRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<RehabPlanStatistic>> {
    if (requestParameters.patientId === null || requestParameters.patientId === undefined) {
      throw new runtime.RequiredError(
        'patientId',
        'Required parameter requestParameters.patientId was null or undefined when calling getRehabPlanStatistics.',
      )
    }

    if (
      requestParameters.rehabilitationPlanId === null ||
      requestParameters.rehabilitationPlanId === undefined
    ) {
      throw new runtime.RequiredError(
        'rehabilitationPlanId',
        'Required parameter requestParameters.rehabilitationPlanId was null or undefined when calling getRehabPlanStatistics.',
      )
    }

    const queryParameters: any = {}

    if (requestParameters.patientId !== undefined) {
      queryParameters['patient_id'] = requestParameters.patientId
    }

    if (requestParameters.rehabilitationPlanId !== undefined) {
      queryParameters['rehabilitation_plan_id'] = requestParameters.rehabilitationPlanId
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
      headerParameters['authorization'] = String(requestParameters.authorization)
    }

    const response = await this.request(
      {
        path: `/playthrough/rehabPlanStatistic`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) => RehabPlanStatisticFromJSON(jsonValue))
  }

  /**
   * Get Rehab Plan Statistic23
   */
  async getRehabPlanStatistics(
    requestParameters: GetRehabPlanStatisticsRequest,
    initOverrides?: RequestInit,
  ): Promise<RehabPlanStatistic> {
    const response = await this.getRehabPlanStatisticsRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * Get Rehab Plan Statistic23
   */
  async getRehabPlanStatistics23Raw(
    requestParameters: GetRehabPlanStatistics23Request,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<RehabPlanStatistic>> {
    if (requestParameters.playthroughFilter === null || requestParameters.playthroughFilter === undefined) {
      throw new runtime.RequiredError(
        'playthroughFilter',
        'Required parameter requestParameters.playthroughFilter was null or undefined when calling getRehabPlanStatistics23.',
      )
    }

    const queryParameters: any = {}

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
      headerParameters['authorization'] = String(requestParameters.authorization)
    }

    const response = await this.request(
      {
        path: `/playthrough/rehabPlanStatistic23`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: PlaythroughFilterToJSON(requestParameters.playthroughFilter),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) => RehabPlanStatisticFromJSON(jsonValue))
  }

  /**
   * Get Rehab Plan Statistic23
   */
  async getRehabPlanStatistics23(
    requestParameters: GetRehabPlanStatistics23Request,
    initOverrides?: RequestInit,
  ): Promise<RehabPlanStatistic> {
    const response = await this.getRehabPlanStatistics23Raw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * Get Repeated Runs Chart
   */
  async getRepeatedRunsChartRaw(
    requestParameters: GetRepeatedRunsChartRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<RepeatedRunsChartData>> {
    if (requestParameters.levelId === null || requestParameters.levelId === undefined) {
      throw new runtime.RequiredError(
        'levelId',
        'Required parameter requestParameters.levelId was null or undefined when calling getRepeatedRunsChart.',
      )
    }

    if (requestParameters.userId === null || requestParameters.userId === undefined) {
      throw new runtime.RequiredError(
        'userId',
        'Required parameter requestParameters.userId was null or undefined when calling getRepeatedRunsChart.',
      )
    }

    const queryParameters: any = {}

    if (requestParameters.levelId !== undefined) {
      queryParameters['levelId'] = requestParameters.levelId
    }

    if (requestParameters.userId !== undefined) {
      queryParameters['userId'] = requestParameters.userId
    }

    const headerParameters: runtime.HTTPHeaders = {}

    if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
      headerParameters['authorization'] = String(requestParameters.authorization)
    }

    const response = await this.request(
      {
        path: `/playthrough/repeatedRunsChart`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) => RepeatedRunsChartDataFromJSON(jsonValue))
  }

  /**
   * Get Repeated Runs Chart
   */
  async getRepeatedRunsChart(
    requestParameters: GetRepeatedRunsChartRequest,
    initOverrides?: RequestInit,
  ): Promise<RepeatedRunsChartData> {
    const response = await this.getRepeatedRunsChartRaw(requestParameters, initOverrides)
    return await response.value()
  }

  /**
   * Get Stage Times Grouped By Level Route
   */
  async getTimeLevelsBoxPlotRaw(
    requestParameters: GetTimeLevelsBoxPlotRequest,
    initOverrides?: RequestInit,
  ): Promise<runtime.ApiResponse<Array<BoxPlotData>>> {
    if (requestParameters.playthroughFilter === null || requestParameters.playthroughFilter === undefined) {
      throw new runtime.RequiredError(
        'playthroughFilter',
        'Required parameter requestParameters.playthroughFilter was null or undefined when calling getTimeLevelsBoxPlot.',
      )
    }

    const queryParameters: any = {}

    if (requestParameters.quantilesN !== undefined) {
      queryParameters['quantiles_n'] = requestParameters.quantilesN
    }

    const headerParameters: runtime.HTTPHeaders = {}

    headerParameters['Content-Type'] = 'application/json'

    if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
      headerParameters['authorization'] = String(requestParameters.authorization)
    }

    const response = await this.request(
      {
        path: `/playthrough/timeLevelsBoxPlot`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: PlaythroughFilterToJSON(requestParameters.playthroughFilter),
      },
      initOverrides,
    )

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BoxPlotDataFromJSON))
  }

  /**
   * Get Stage Times Grouped By Level Route
   */
  async getTimeLevelsBoxPlot(
    requestParameters: GetTimeLevelsBoxPlotRequest,
    initOverrides?: RequestInit,
  ): Promise<Array<BoxPlotData>> {
    const response = await this.getTimeLevelsBoxPlotRaw(requestParameters, initOverrides)
    return await response.value()
  }
}
