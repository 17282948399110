import { FC, useCallback, useState } from 'react'
import { Group, Quaternion } from 'three'
import { createCtx } from '../shared/create-ctx'

interface CubeCalibrationState {
  quaternion: Quaternion
  conjugatedQuaternion: Quaternion
  offset: Group
  orientation: Group
}
interface CubeCalibrationActions {
  setQuaternion(quaternion: Quaternion): void
}
export type CubeCalibrationContextValue = CubeCalibrationState & CubeCalibrationActions

export const [useCubeCalibrationContext, CubeCalibrationContextProvider] =
  createCtx<CubeCalibrationContextValue>()

export const CubeCalibrationProvider: FC<unknown> = ({ children }) => {
  const [quaternion, setQ] = useState(new Quaternion())
  const [conjugatedQuaternion, setConjugatedQuaternion] = useState(new Quaternion())

  const setQuaternion = useCallback((q: Quaternion) => {
    setQ(q)
    setConjugatedQuaternion(q.conjugate())
  }, [])

  return (
    <CubeCalibrationContextProvider
      value={{
        quaternion,
        setQuaternion: setQuaternion,
        conjugatedQuaternion,
      }}
    >
      {children}
    </CubeCalibrationContextProvider>
  )
}
