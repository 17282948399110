import { authExchange } from '@urql/exchange-auth'
import { retryExchange } from '@urql/exchange-retry'
import type { RetryExchangeOptions } from '@urql/exchange-retry/dist/types/retryExchange'
import { getAuth, User } from 'firebase/auth'
import { cacheExchange, createClient, dedupExchange, errorExchange, fetchExchange, makeOperation } from 'urql'
import { devtoolsExchange } from '@urql/devtools'
import { env } from './env'
import { captureException } from '@sentry/react'
import { requestPolicyExchange } from '@urql/exchange-request-policy'
import { useGlobalState } from './global-state'
import { useMemo } from 'react'

function getCurrentUser(auth): Promise<User> {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      unsubscribe()
      resolve(user)
    }, reject)
  })
}

const options: RetryExchangeOptions = {
  initialDelayMs: 1000,
  maxDelayMs: 2000,
  randomDelay: true,
  maxNumberAttempts: 3,
  retryIf: (err) => !!err.networkError,
}

export const useGraphqlClient = () => {
  const { authState } = useGlobalState()
  const isLoggedIn = authState.matches('loggedIn')
  return useMemo(() => {
    if (isLoggedIn === null) {
      return null
    }

    return createGraphqlClient()
  }, [isLoggedIn])
}

export const createGraphqlClient = () => {
  return createClient({
    url: env.graphqlUrl,
    requestPolicy: 'network-only',
    exchanges: [
      devtoolsExchange,
      dedupExchange,
      requestPolicyExchange({
        ttl: 1000 * 60 * 60 * 24, // 1 day
      }),
      cacheExchange,
      retryExchange(options),
      authExchange<{ token: string }>({
        addAuthToOperation: ({ authState, operation }) => {
          if (!authState || !authState.token) {
            return operation
          }

          const fetchOptions =
            typeof operation.context.fetchOptions === 'function'
              ? operation.context.fetchOptions()
              : operation.context.fetchOptions || {}

          return makeOperation(operation.kind, operation, {
            ...operation.context,
            fetchOptions: {
              ...fetchOptions,
              headers: {
                ...fetchOptions.headers,
                Authorization: authState.token,
              },
            },
          })
        },
        getAuth: async ({ authState }) => {
          const user = await getCurrentUser(getAuth())
          if (!user) {
            return authState
          }
          const token = await user.getIdToken(true)
          return {
            token,
          }
        },
        didAuthError: ({ error }) => {
          return error.graphQLErrors.some((e) =>
            ['NOT_LOGGED_IN', 'TOKEN_EXPIRED'].includes(e.extensions?.code as string),
          )
        },
      }),
      errorExchange({
        onError: (error, operation) => {
          const isAuthError = error?.graphQLErrors?.some((e) =>
            ['NOT_LOGGED_IN', 'TOKEN_EXPIRED'].includes(e.extensions?.code as string),
          )
          if (!isAuthError) {
            captureException(error, {
              extra: {
                // @ts-ignore
                queries: operation.query.definitions.map((def) => def.name.value),
              },
            })
          }
        },
      }),
      fetchExchange,
    ],
    fetchOptions: () => {
      return {
        headers: {
          'Accept-Language':
            window.localStorage.getItem('rcml-lang') || navigator.language.split('-')[0] || 'cs',
        },
      }
    },
  })
}
