import { useEffectOnce } from 'react-use'
import { usePageVisibility } from './use-page-visibility'

let registration: ServiceWorkerRegistration | undefined

export const registerServiceWorker = (r: ServiceWorkerRegistration | undefined) => {
  registration = r
}

export const getServiceWorkerRegistration = (): ServiceWorkerRegistration | undefined => {
  return registration
}

let lastCheckTime: number
const CHECK_TIME = 300_000 // 5 minutes in ms
export const useCheckForUpdates = () => {
  useEffectOnce(() => {
    if (lastCheckTime === undefined || lastCheckTime + CHECK_TIME < Date.now()) {
      lastCheckTime = Date.now()
      console.log('Check for update')
      getServiceWorkerRegistration()?.update()
    }
  })
  usePageVisibility({
    onVisible: () => {
      if (lastCheckTime === undefined || lastCheckTime + CHECK_TIME < Date.now()) {
        lastCheckTime = Date.now()
        console.log('Check for update')
        getServiceWorkerRegistration()?.update()
      }
    },
  })
}
