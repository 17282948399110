import { FC, MouseEventHandler } from 'react'
// import { animated, useSpring } from 'react-spring'
import styled, { css } from 'styled-components'
import { ifProp, palette, switchProp } from 'styled-tools'

interface Props {
  onClick?: MouseEventHandler<any>
  kind?: 'primary' | 'secondary'
  backgroundColor?: any
  disabled?: boolean
}

const StyledButton = styled.button<Props>`
  ${ifProp(
    'disabled',
    css`
      background-color: ${palette('disabled')};
    `,
    switchProp('kind', {
      primary: css`
        background-color: ${palette('primary')};
      `,
      secondary: css`
        background-color: ${palette('secondary')};
      `,
      error: css`
        background-color: ${palette('error')};
      `,
    }),
  )}
  color: white;
  font-size: 2rem;
  padding: 0.5rem 1rem;
  border-radius: 2rem;
  border: none;
  outline: none;
  cursor: pointer;
  height: 4rem;
`

export const Button: FC<Props> = ({ children, ...rest }) => {
  return (
    <StyledButton type="button" {...rest}>
      {children}
    </StyledButton>
  )
}
